<template>

	<div class="contentheader">
		<el-space wrap>
			<span class="titleClass">电表单线设置</span>
			<el-divider direction="vertical" />
			<el-radio-group v-model="formdata.meterType" class="ml-4" v-if="ammeterType=='2'"  @change="categoryChange">
				<el-radio label="1" size="small">{{ammeterName1}}</el-radio>
				<el-radio label="2" size="small">{{ammeterName2}}</el-radio>
			</el-radio-group>
			<el-input v-model="params.keyword" placeholder="请输入关键字" size="small" style="width: 150px;"
				autocomplete="off" />
			<el-button type="primary" size="small" class="buttonforheader" @click="search">查 询</el-button>
			<el-button type="primary" size="small" class="buttonforheader" @click="refresh">刷 新</el-button>
			<el-divider direction="vertical" />
			<el-button type="success" size="small" class="buttonforheader" @click="setMultiMeter">多用户电表单线设置</el-button>
			
			<!-- <el-button type="primary" size="small" class="buttonforheader" @click="readOnOff">读电表通断</el-button> -->
			<!-- <el-button type="danger" size="small" class="buttonforheader" @click="read('25')">读电表通断</el-button>
			<el-button type="danger" size="small" class="buttonforheader" @click="read('26')">读断电原因</el-button> -->
			<!-- <el-button type="danger" size="small" class="buttonforheader" @click="read('2728')">读断电功率和时间</el-button> -->
			<!-- <el-divider direction="vertical" /> -->
			<!-- <el-button type="success" size="small" class="buttonforheader" @click="saveResult" v-if="needsave">保存断电结果</el-button> -->
		</el-space>
	</div>
	<div style="display: flex; flex-direction: row; flex-wrap: nowrap; align-items: flex-start;margin: 10px;">
		<div style="width: 200px;">
			<el-scrollbar :height="areaHeight" style="width:200px; border-radius: 6px;background-color: white;">
				<el-tree ref="treeAreaRef" :data="areaDatas" node-key="id" :expand-on-click-node="false"
					highlight-current :props="treeProps" @node-click="areaSelected" style="margin: 10px;">
				</el-tree>
			</el-scrollbar>
		</div>
		<div id="divTableData" style="width: 100%;border-radius: 6px;background-color: white; margin-left: 5px;">
			<el-table :data="tableDatas" fit="false" highlight-current-row="true" ref="multipleTable"
				tooltip-effect="dark" :height="tableheight" @selection-change="handleSelectionChange"
				@row-click="rowclick" row-key="id" default-expand-all :header-cell-style="headerCellStype"
				:cell-style="contentCellStyle" style="width: 100%; cursor: pointer;" :row-class-name="tableRowClassName"
				>
				<el-table-column type="selection" width="55" />
				<el-table-column fixed prop="roomId" label="roomId" width="0" v-if="false" />
				<el-table-column prop="areaName1" label="一级区域" width="100" />
				<el-table-column prop="areaName2" label="二级区域" width="100" />
				<el-table-column prop="areaName3" label="三级区域" width="100" />
				<!-- <el-table-column prop="protocolName" label="协议名称" width="100" /> -->
				<el-table-column prop="roomName" label="房间名称" width="100" />
				<el-table-column prop="roomNum" label="房间编号" width="100" />
				<el-table-column prop="familyName" label="户主名称" width="100"  />
				<el-table-column prop="familyPhone" label="户主电话" width="120" />
				<el-table-column prop="message" label="执行结果" width="150" />
				<!-- <el-table-column prop="message1" label="通断状态" width="100" />
				<el-table-column prop="message2" label="断电原因" width="250" />
				<el-table-column prop="message3" label="断电功率" width="100" />
				<el-table-column prop="message4" label="断电时间" width="150" /> -->
				<el-table-column />
			</el-table>
		</div>
	</div>
	
	
	
	
	
	
	<el-dialog width="500px" v-model="setMultiMeterDialogVisible" append-to-body=true title="多用户电表单线设置" center draggable
		:close-on-click-modal="false" :show-close="true">
		<el-row :gutter="20">
			<el-col :span="8" class="textaligncenter">
				<el-button type="success" size="small" class="buttonforheader" @click="setSingle">设置单个参数</el-button>
			</el-col>
			<el-col :span="8" class="textaligncenter">
				<el-button type="danger" size="small" class="buttonforheader" @click="setMalignantLoad">设负载增量参数</el-button>
			</el-col>
			<el-col :span="8" class="textaligncenter">
				<el-button type="warning" size="small" class="buttonforheader" @click="setTimerOnOff">定时断送电</el-button>
			</el-col>
		</el-row>
		<el-row :gutter="20">
			<el-col :span="8" class="textaligncenter">
				<el-button type="info" size="small" class="buttonforheader" @click="read('20')">读电表户号</el-button>
			</el-col>
			<el-col :span="8" class="textaligncenter">
				<el-button type="primary" size="small" class="buttonforheader" @click="read('29')">读限定功率</el-button>
			</el-col>
			<el-col :span="8" class="textaligncenter">
				<el-button type="primary" size="small" class="buttonforheader" @click="read('30')">读实时功率</el-button>
			</el-col>
		</el-row>
	</el-dialog>
	<el-dialog width="500px" v-model="dialogVisible" append-to-body=true :title="dialogtitle" center draggable
		:close-on-click-modal="false" :show-close="true">
		<el-form :model="formdata" class="demo-ruleForm formContent">
			<el-form-item label="请选择设置类型" :label-width="formLabelWidth">
				<el-select v-model="formdata.directiveType" placeholder="请选择设置类型" :style="formContentStyle" @change="handleSingleChange">
					<el-option v-for="item in directiveTypes" :key="item.value" :label="item.label"
						:value="item.value" />
				</el-select>
			</el-form-item>
			<el-form-item label="设置值" :label-width="formLabelWidth">
				<el-input v-model="formdata.directiveValue" autocomplete="off" :style="formContentStyle"
					placeholder="请输入设置值" >
					<template #append v-if="unitName!=''">{{unitName}}</template>
				</el-input>
			</el-form-item>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button size="mini" type="primary" @click="submitForm" class="buttonforform"> 设 置 </el-button>
			</span>
		</template>
	</el-dialog>
	<el-dialog width="500px" v-model="setTimerDialogVisible" append-to-body=true title="设置定时断送电时间" center draggable
		:close-on-click-modal="false" :show-close="true">
		<el-form :model="settimerformdata" ref="setTimerFormRef" class="demo-ruleForm formContent">
			<el-form-item label="请选择定时时段" :label-width="formLabelWidth">
				<el-select v-model="settimerformdata.timerName" placeholder="请选择定时时段" :style="formContentStyle">
					<el-option v-for="item in timerNames" :key="item.value" :label="item.label"
						:value="item.value" />
				</el-select>
			</el-form-item>
			<el-form-item label="请选择星期" :label-width="formLabelWidth">
				<el-select v-model="settimerformdata.week" placeholder="请选择星期" :style="formContentStyle">
					<el-option v-for="item in weeks" :key="item.value" :label="item.label"
						:value="item.value" />
				</el-select>
			</el-form-item>
			<el-form-item label="请选择小时" :label-width="formLabelWidth">
				<el-select v-model="settimerformdata.hour" placeholder="请选择小时" :style="formContentStyle">
					<el-option v-for="item in hours" :key="item.value" :label="item.label"
						:value="item.value" />
				</el-select>
			</el-form-item>
			<el-form-item label="请选择分钟" :label-width="formLabelWidth">
				<el-select v-model="settimerformdata.minute" placeholder="请选择分钟" :style="formContentStyle">
					<el-option v-for="item in minutes" :key="item.value" :label="item.label"
						:value="item.value" />
				</el-select>
			</el-form-item>
			<el-form-item label="请选择动作" :label-width="formLabelWidth">
				<el-radio-group v-model="settimerformdata.action">
				    <el-radio label="1">通电</el-radio>
				    <el-radio label="2">断电</el-radio>
				  </el-radio-group>
			</el-form-item>
		</el-form>	
		<template #footer>
			<span class="dialog-footer">
				<el-space wrap :size="30">
					<el-button size="mini" type="primary" @click="submitSetTimerForm(setTimerFormRef)" class="buttonforform"> 设 置 
					</el-button>
				</el-space>
			</span>
		</template>
	</el-dialog>
	<el-dialog width="510px" v-model="dialogVisibleMalignantLoad" append-to-body=true title="设置负载增量参数" center draggable
		:close-on-click-modal="false" :show-close="true">
		<el-form :model="formdataMalignantLoad" ref="formRefMalignantLoad" class="demo-ruleForm formContent">
			<el-row :gutter="10">
				<el-col :span="8">
					
				</el-col>
				<el-col :span="8">
					下 限
				</el-col>
				<el-col :span="8">
					上 限
				</el-col>
			</el-row>
			<el-row :gutter="10" align="middle">
				<el-col :span="8">
					允许范围1（W）
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataMalignantLoad.rangeLow1" autocomplete="off"  placeholder="下限" type="number" />
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataMalignantLoad.rangeHigh1" autocomplete="off"  placeholder="上限" type="number" />
				</el-col>
			</el-row>
			
			<el-row :gutter="10" align="middle">
				<el-col :span="8">
					限定范围1（W）
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataMalignantLoad.limitLow1" autocomplete="off"  placeholder="下限" type="number" />
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataMalignantLoad.limitHigh1" autocomplete="off"  placeholder="上限" type="number" />
				</el-col>
			</el-row>
			
			<el-row :gutter="10" align="middle">
				<el-col :span="8">
					限定参数1
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataMalignantLoad.limitParamLow1" autocomplete="off"  placeholder="下限" type="number" />
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataMalignantLoad.limitParamHigh1" autocomplete="off"  placeholder="上限" type="number" />
				</el-col>
			</el-row>
			<el-row :gutter="10" align="middle">
				<el-col :span="8">
					允许范围2（W）
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataMalignantLoad.rangeLow2" autocomplete="off"  placeholder="下限" type="number" />
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataMalignantLoad.rangeHigh2" autocomplete="off"  placeholder="上限" type="number" />
				</el-col>
			</el-row>
			
			<el-row :gutter="10" align="middle">
				<el-col :span="8">
					限定范围2（W）
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataMalignantLoad.limitLow2" autocomplete="off"  placeholder="下限" type="number" />
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataMalignantLoad.limitHigh2" autocomplete="off"  placeholder="上限" type="number" />
				</el-col>
			</el-row>
			
			<el-row :gutter="10" align="middle">
				<el-col :span="8">
					限定参数2
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataMalignantLoad.limitParamLow2" autocomplete="off"  placeholder="下限" type="number" />
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataMalignantLoad.limitParamHigh2" autocomplete="off"  placeholder="上限" type="number" />
				</el-col>
			</el-row>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button size="mini" type="primary" @click="submitFormMalignantLoad(formRefMalignantLoad)" class="buttonforform"> 设 置 </el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted,
		onActivated,
		getCurrentInstance
	} from 'vue'
	import {
		ElMessageBox
	} from 'element-plus'
	import {
		GetServerData
	} from '@/http/index.js'
	// 获取全局this，proxy相当于vue2中的this，用于调用全局对象使用
	const {
		proxy
	} = getCurrentInstance()
	const treeAreaRef = ref()
	const treeProps = reactive({
		children: 'children',
		label: 'name'
	})
	// 定义表单项的头部宽度
	const formLabelWidth = ref('150px')
	// 定义表单项的内容宽度
	const formContentStyle = ref('width:300px')
	let unitName=ref('')
	let settimerformdata=reactive({
		timerName:'',
		week:'FF',
		hour:'FF',
		minute:'FF',
		action:'1',
		roomId:'',
		meterType:'1'
	})
	let hours=reactive([])
	let needsave=ref(true);
	
	const weeks=reactive([
		{
			label: '无效',
			value: 'FF'
		},
		{
			label: '星期一',
			value: '01'
		},{
			label: '星期二',
			value: '02'
		},{
			label: '星期三',
			value: '03'
		},{
			label: '星期四',
			value: '04'
		},{
			label: '星期五',
			value: '05'
		},{
			label: '星期六',
			value: '06'
		},{
			label: '星期七',
			value: '00'
		}
	])
	
	const minutes=reactive([
		{
			label: '无效',
			value: 'FF'
		},
		{
			label: '00',
			value: '00'
		},{
			label: '10',
			value: '10'
		},{
			label: '20',
			value: '20'
		},{
			label: '30',
			value: '30'
		},{
			label: '40',
			value: '40'
		},{
			label: '50',
			value: '50'
		}
	])
	
	const timerNames = reactive([
		{
			label: '定时一',
			value: '1'
		},{
			label: '定时二',
			value: '2'
		},{
			label: '定时三',
			value: '3'
		},{
			label: '定时四',
			value: '4'
		},{
			label: '定时五',
			value: '5'
		},{
			label: '定时六',
			value: '6'
		},{
			label: '定时七',
			value: '7'
		},{
			label: '定时八',
			value: '8'
		},{
			label: '定时九',
			value: '9'
		},{
			label: '定时十',
			value: '10'
		},{
			label: '定时十一',
			value: '11'
		},{
			label: '定时十二',
			value: '12'
		}
	])
	const directiveTypes = reactive([
		{
			label: '设户号',
			value: '53'
		},
		{
			label: '预置电量',
			value: '41'
		},
		{
			label: '预置金额',
			value: '42'
		},
		{
			label: '设透支电量',
			value: '44'
		},
		{
			label: '设透支金额',
			value: '45'
		},
		{
			label: '设报警电量',
			value: '46'
		},
		{
			label: '设报警金额',
			value: '47'
		},
		{
			label: '设置限定功率',
			value: '49'
		}
	])
	let dialogtitle = ref("请选择要执行的设置")
	// 定义表单显示与隐藏
	let dialogVisible = ref(false)
	let setTimerDialogVisible= ref(false)
	let dialogVisibleMalignantLoad = ref(false)
	let setMultiMeterDialogVisible= ref(false)
	// 定义查询参数
	let params = reactive({
		keyword: ''
	})
	let formdata = reactive({
		directiveType: '',
		directiveValue: '',
		meterType:'1'
	})
	
	let formdataMalignantLoad= reactive({
		rangeHigh1:'150',
		rangeLow1:'100',
		limitHigh1:'300',
		limitLow1:'200',
		limitParamHigh1:'0',
		limitParamLow1:'0',
		rangeHigh2:'150',
		rangeLow2:'100',
		limitHigh2:'300',
		limitLow2:'200',
		limitParamHigh2:'0',
		limitParamLow2:'0'
	})
	
	
	let ammeterType=ref(localStorage.getItem("ammeterType"));
	let ammeterName1=ref(localStorage.getItem("ammeterName1"));
	let ammeterName2=ref(localStorage.getItem("ammeterName2"));


	// 定义表格数据
	let tableDatas = ref([])
	let roomDatas = ref([])
	let areaDatas = ref([])
	let saveDatas = ref([])
	let selectedArea=ref()

	let headerCellStype = ref(proxy.$headerCellStype)
	let contentCellStyle = ref(proxy.$contentCellStyle)
	// 定义选中的表格行
	let multipleSelection = reactive([])
	// 定义表格引用，用来执行多选
	const multipleTable = ref()

	const setTimerFormRef = ref()
	const formRefMalignantLoad = ref()
	// 定义表格隔行变色，暂时没用到
	const tableRowClassName = ({
		row,
		rowIndex
	}) => {
		if (rowIndex % 2 === 0) {
			return 'even-row'
		} else {
			return 'odd-row'
		}
	}
	// 定义表格多选
	const handleSelectionChange = (val) => {
		multipleSelection = val;
	}
	const toggleSelection = (rows) => {
		if (rows) {
			rows.forEach(row => {
				multipleTable.value.toggleRowSelection(row);
			});
		} else {
			multipleTable.value.clearSelection();
		}
	}
	const rowclick = (row, column, event) => {
		toggleSelection([row])
	}
	
	const handleSingleChange = (value) => { 
		switch(value){
			case '21':
				unitName.value='';
				break;
			case '41':
				unitName.value='度';
				break;
			case '42':
				unitName.value='元';
				break;
			case '44':
				unitName.value='度';
				break;
			case '45':
				unitName.value='元';
				break;
			case '46':
				unitName.value='度';
				break;
			case '47':
				unitName.value='元';
				break;
			case '49':
				unitName.value='kW';
				break;
			default:unitName.value='';
				break;
		}
	}
	
	const setMultiMeter =()=>{
		
		if (multipleSelection.length > 0) {
			setMultiMeterDialogVisible.value=true;
		} else {
			proxy.$tools.ShowMessage('请选择需要设置的房间', false)
		}
	}

	const areaSelected = (area) => {
		let loading = proxy.$tools.ShowLoading();
		selectedArea.value=area;
		setTimeout(()=>{
			tableDatas.value = []
			let grade = area.grade
			let id = area.id
			if (grade == "1") {
				tableDatas.value = roomDatas.value.filter(room => room.areaId1 == id)
			} else if (grade == "2") {
				tableDatas.value = roomDatas.value.filter(room => room.areaId2 == id)
			} else if (grade == "3") {
				tableDatas.value = roomDatas.value.filter(room => room.areaId3 == id)
			}
			loading.close();
		},300)
	}
	
	const resetResult=()=>{
		if(tableDatas.value.length){
			for (let j = 0; j < tableDatas.value.length; j++) {
				tableDatas.value[j].message = ''
			}
		}
	}

	const categoryChange = async () => {
		let loading = proxy.$tools.ShowLoading();
		tableDatas.value = []
		await GetRoomData()
		if (params.keyword.length > 0){
			search()
		}else{
			if(selectedArea&&selectedArea.value){
				areaSelected(selectedArea.value)
			}
		}
		loading.close()
	}

	const search = () => {
		if (params.keyword.length > 0) {
			let loading = proxy.$tools.ShowLoading();
			setTimeout(()=>{
				tableDatas.value = []
				tableDatas.value = roomDatas.value.filter(room =>
					room.areaName1?.indexOf(params.keyword) > -1 ||
					room.areaName2?.indexOf(params.keyword) > -1 || 
					room.areaName3?.indexOf(params.keyword) > -1 || 
					room.roomName?.indexOf(params.keyword) > -1 || 
					room.roomNum?.indexOf(params.keyword) > -1 || 
					room.familyName?.toString().indexOf(params.keyword) > -1 || 
					room.familyPhone?.toString().indexOf(params.keyword) > -1
				)
				loading.close();
			},300)
		} else {
			proxy.$tools.ShowMessage('请输入关键字', false)
		}
	}
	
	const saveResult= async () =>{
		if (multipleSelection.length > 0) {
			let loading = proxy.$tools.ShowLoading()
			saveDatas.value = [];
			for (let i = 0; i < multipleSelection.length; i++) {
				let roomId = multipleSelection[i].roomId
				let message1 = multipleSelection[i].message1
				let message2 = multipleSelection[i].message2
				let message3 = multipleSelection[i].message3
				let message4 = multipleSelection[i].message4
				if (message1=='断电') {
					saveDatas.value.push({
						roomId: roomId,
						ammeterType:formdata.meterType,
						offReason:message2,
						overPower:message3,
						offTime:message4
					})
				}
			}
			if (saveDatas.value.length > 0) {
				let response = await GetServerData('SetAmmeter/SaveResult', saveDatas.value)
				if (response.status == 1) {
					proxy.$tools.ShowMessage('保存成功！', true)
				} else {
					proxy.$tools.ShowMessage(response.message, false)
				}
			} else {
				proxy.$tools.ShowMessage('没有找到需要保存的断电数据', false)
			}
			loading.close()
		} else {
			proxy.$tools.ShowMessage('请选择需要保存断电信息的房间', false)
		}
	}

	const setSingle = () => {
		if (multipleSelection.length > 0) {
			formdata.directiveType = ''
			formdata.directiveValue = ''
			unitName.value=''
			dialogVisible.value = true;
		} else {
			proxy.$tools.ShowMessage('请选择需要设置的房间', false)
		}
	}

	const setTimerOnOff = () => {
		if (multipleSelection.length > 0) {
			settimerformdata.roomId=''
			settimerformdata.meterType='1'
			setTimerDialogVisible.value = true;
		} else {
			proxy.$tools.ShowMessage('请选择需要设置的房间', false)
		}
	}
	
	const setMalignantLoad = () => {
		if (multipleSelection.length > 0) {
			dialogVisibleMalignantLoad.value = true;
		} else {
			proxy.$tools.ShowMessage('请选择需要设置的房间', false)
		}
	}
	
	const readOnOff =async ()=>{
		if (multipleSelection.length > 0) {
			let loading = proxy.$tools.ShowLoading()
			resetResult();
			for (let i = 0; i < multipleSelection.length; i++) {
				let message1 ='';
				let message2='';
				let message3='';
				let message4='';
				let roomId = multipleSelection[i].roomId;
				// 读通断状态
				let response = await GetServerData('SetAmmeter/ReadMeter', {
					roomId:roomId,
					meterType:formdata.meterType,
					directiveType:'25'
				})
				if (response.status == 1) {
					message1 = response.data.onOffStatus;
				} else {
					message1 = response.message
				}
				if(message1=='断电'){
					// // 读断电原因
					// response = await GetServerData('SetAmmeter/ReadMeter', {
					// 	roomId:roomId,
					// 	meterType:formdata.meterType,
					// 	directiveType:'26'
					// })
					// if (response.status == 1) {
					// 	message2 = response.data;
					// } else {
					// 	message2 = response.message
					// }
					message2=response.data.onOffReason;
					if(message2.toString().indexOf('超出设定')>-1){
						// 读断电功率
						response = await GetServerData('SetAmmeter/ReadMeter', {
							roomId:roomId,
							meterType:formdata.meterType,
							directiveType:'27'
						})
						if (response.status == 1) {
							message3 = response.data;
						} else {
							message3 = response.message
						}
						// 读断电时间
						response = await GetServerData('SetAmmeter/ReadMeter', {
							roomId:roomId,
							meterType:formdata.meterType,
							directiveType:'28'
						})
						if (response.status == 1) {
							message4= response.data;
						} else {
							message4= response.message
						}
					}
				}
				for (let j = 0; j < tableDatas.value.length; j++) {
					if (tableDatas.value[j].roomId == roomId) {
						tableDatas.value[j].message1 = message1
						tableDatas.value[j].message2 = message2
						tableDatas.value[j].message3 = message3
						tableDatas.value[j].message4 = message4
						break;
					}
				}
			}
			loading.close();
		} else {
			proxy.$tools.ShowMessage('请选择需要读取信息的房间', false)
		}
	}
	
	const read =async (directiveType)=>{
		
		setMultiMeterDialogVisible.value=false;
		if (multipleSelection.length > 0) {
			let loading = proxy.$tools.ShowLoading()
			resetResult();
			for (let i = 0; i < multipleSelection.length; i++) {
				let roomId = multipleSelection[i].roomId
				let response = await GetServerData('SetAmmeter/ReadMeter', {
					roomId:roomId,
					meterType:formdata.meterType,
					directiveType:directiveType
				})
				let message ='';
				if (response.status == 1) {
					message = response.data
				} else {
					message = response.message
				}
				for (let j = 0; j < tableDatas.value.length; j++) {
					if (tableDatas.value[j].roomId == roomId) {
						tableDatas.value[j].message = message
						break;
					}
				}
			}
			loading.close();
		} else {
			proxy.$tools.ShowMessage('请选择需要读取信息的房间', false)
		}
	}
	
	const submitFormMalignantLoad =async (form) => {
		form.validate(async (valid) => {
			if (valid) {
				if (multipleSelection.length > 0) {
					resetResult();
					let loading = proxy.$tools.ShowLoading()
					dialogVisibleMalignantLoad.value=false;
					setMultiMeterDialogVisible.value=false;
					for (let i = 0; i < multipleSelection.length; i++) {
						let roomId = multipleSelection[i].roomId
						let response = await GetServerData('SetAmmeter/SetMalignantLoad', {
							roomId:roomId,
							meterType:formdata.meterType,
							rangeHigh1:formdataMalignantLoad.rangeHigh1,
							rangeLow1:formdataMalignantLoad.rangeLow1,
							limitHigh1:formdataMalignantLoad.limitHigh1,
							limitLow1:formdataMalignantLoad.limitLow1,
							limitParamHigh1:formdataMalignantLoad.limitParamHigh1,
							limitParamLow1:formdataMalignantLoad.limitParamLow1,
							rangeHigh2:formdataMalignantLoad.rangeHigh2,
							rangeLow2:formdataMalignantLoad.rangeLow2,
							limitHigh2:formdataMalignantLoad.limitHigh2,
							limitLow2:formdataMalignantLoad.limitLow2,
							limitParamHigh2:formdataMalignantLoad.limitParamHigh2,
							limitParamLow2:formdataMalignantLoad.limitParamLow2
						})
						let message =  response.message
						for (let j = 0; j < tableDatas.value.length; j++) {
							if (tableDatas.value[j].roomId == roomId) {
								tableDatas.value[j].message = message
								break;
							}
						}
					}
					loading.close();
				}else {
					proxy.$tools.ShowMessage('请选择需要操作的房间', false)
				}
			} else {
				proxy.$tools.ShowMessage('请您正确填写所有必填信息后，再提交！', false)
				return false;
			}
		})
	}

	const submitForm = async () => {
		if(formdata.directiveType.length>0&&formdata.directiveValue.length > 0){
			
		} else {
			proxy.$tools.ShowMessage('请输入设置值', false)
			return
		}
		if(formdata.directiveType=='53'){
			if(multipleSelection.length>1){
				proxy.$tools.ShowMessage('设置户号只能设置一个房间，不能批量设置', false)
				return
			}
			if(formdata.directiveValue.length > 4){
				proxy.$tools.ShowMessage('户号长度不能超过4位', false)
				return
			}
		}
		dialogVisible.value = false;
		setMultiMeterDialogVisible.value=false;
		let loading = proxy.$tools.ShowLoading()
		resetResult();
		for (let i = 0; i < multipleSelection.length; i++) {
			let message = '';
			let roomId = multipleSelection[i].roomId
			let data = {
				directiveType: formdata.directiveType,
				directiveValue: formdata.directiveValue,
				roomId: roomId,
				meterType:formdata.meterType
			}
			let response = await GetServerData('SetAmmeter/SetAmmeter', data)
			if (response.status == 1) {
				message = response.data
			} else {
				message = response.message
			}
			for (let j = 0; j < tableDatas.value.length; j++) {
				if (tableDatas.value[j].roomId == roomId) {
					tableDatas.value[j].message = message
					break;
				}
			}
		}
		loading.close()
	}
	
	const submitSetTimerForm = async (form) => {
		if (settimerformdata.timerName.length>0&&settimerformdata.week.length>0&&settimerformdata.hour.length>0&&settimerformdata.minute.length>0) {
			if(proxy.$tools.IsNumber(settimerformdata.week)&&proxy.$tools.IsNumber(settimerformdata.hour)&&proxy.$tools.IsNumber(settimerformdata.minute)){
				setTimerDialogVisible.value = false
		setMultiMeterDialogVisible.value=false;
				let loading = proxy.$tools.ShowLoading();
				resetResult();
				for (let i = 0; i < multipleSelection.length; i++) {
					let roomId = multipleSelection[i].roomId;
					settimerformdata.roomId=roomId;
					settimerformdata.meterType=formdata.meterType;
					let response = await GetServerData('SetAmmeter/SetTimer', settimerformdata);
					let message  = response.message
					for (let j = 0; j < tableDatas.value.length; j++) {
						if (tableDatas.value[j].roomId == roomId) {
							tableDatas.value[j].message = message
							break;
						}
					}
				}
				loading.close()
			}else{
				proxy.$tools.ShowMessage('请您正确填写所有必填信息后，再提交！', false)
				return false;
			}
		} else {
			proxy.$tools.ShowMessage('请您正确填写所有必填信息后，再提交！', false)
			return false;
		}
	}

	// 定义获取表格数据函数
	const GetRoomData = async () => {
		let response = await GetServerData('SetAmmeter/QueryAllRoom' + (formdata.meterType == '1' ? '1s' : '2s'))
		if (response.status == 1) {
			roomDatas.value = response.data
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}

	// 定义获取表格数据函数
	const GetAreaData = async () => {
		let response = await GetServerData('SetAmmeter/QueryAllAreas')
		if (response.status == 1) {
			areaDatas.value = response.data
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}

	// 定义获取所有数据函数
	const GetAllData = async () => {
		await GetRoomData()
		await GetAreaData()
	}
	
	const refresh=async ()=>{
		let loading = proxy.$tools.ShowLoading();
		tableDatas.value=[];
		await GetAllData()
		loading.close()
	}

	let areaHeight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60))
	let tableheight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60))
	// 定义加载事件
	onMounted(async () => {
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth - 425) +
			'px'
		window.onresize = () => {
			tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
			areaHeight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
			document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
					425) +
				'px'
		}
		let loading = proxy.$tools.ShowLoading();
		await GetAllData()
		
		hours.push({label: "无效",
			value: "FF"})
		for(let i=0;i<24;i++){
			hours.push({label: i.toString(),
			value: i.toString()})
		}
		loading.close()
	})
	onActivated(() => {
		tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
		areaHeight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
				425) +
			'px'
	})
</script>

<style scoped lang="less">
</style>
