export function GetPieOption(chartName,chartData,unitName,chartColor){
	let option = {
		tooltip: {
			trigger: 'item',
			formatter: '{a} <br/>{b} : {c}'+unitName+' ({d}%)'
		},
		series: [{
			name: chartName,
			type: 'pie',
			radius:[30,45],
			padAngle: 2,
			itemStyle: {
				borderRadius: 5
			},
			label: {
				alignTo: 'edge',
				formatter: '{name|{b}}\n{value|{c} '+unitName+'}',
				minMargin: 5,
				edgeDistance: '10%',
				lineHeight: 15,
				rich: {
					name:{
						fontSize: 15,
						color: '#fff'
					},
					value: {
						fontSize: 10,
						color: '#999'
					}
				},
				position: 'outer',
				alignTo: 'edge'
			},
			color: chartColor,
			data: chartData
		}]
	};
	return option;
}

export function GetGaugeOption(chartName,chartData,unitName,chartColor,maxNum,splitNum){
	let option = {
		series: [{
			type: 'gauge',
			center: ['50%', '50%'],
			startAngle: 200,
			endAngle: -20,
			min: 0,
			max: maxNum,
			splitNumber: splitNum,
			itemStyle: {
				color: chartColor[0]
			},
			progress: {
				show: true,
				width: 30
			},
			pointer: {
				show: true
			},
			axisLine: {
				lineStyle: {
					width: 30
				}
			},
			splitLine: {
				distance: -52,
				length: 14,
				lineStyle: {
					width: 3,
					color: chartColor[1]
				}
			},
			axisLabel: {
				distance: -20,
				color: chartColor[1],
				fontSize: 20
			},
			anchor: {
				show: false
			},
			title: {
				show: false
			},
			detail: {
				valueAnimation: true,
				width: '60%',
				lineHeight: 30,
				borderRadius: 8,
				offsetCenter: [0, '35%'],
				fontSize: 15,
				fontWeight: 'bolder',
				formatter: '{value} 户',
				color: 'inherit'
			},
			data: chartData
	    }]
	};
	return option;
}

export function GetSingleLineOption(chartName,unitName,xAxisData,yAxisData,chartColor){
	let option = {
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				type: 'cross',
				label: {
					backgroundColor: chartColor
				}
			},
			formatter: '{b}：{c}'+unitName
		},
		color:[chartColor],
		grid: {
			left: '3%',
			right: '4%',
			bottom: '3%',
			containLabel: true
		},
		xAxis: [{
			type: 'category',
			boundaryGap: false,
			data: xAxisData
	    }],
		yAxis: [{
			type: 'value',
			axisLine:{
				show:true
			},
			splitLine:{
				lineStyle:{
					type:'dashed',
					opacity:0.2
				}
			}
	    }],
		series: [{
			type: 'line',
			stack: 'Total',
			smooth: true,
			lineStyle: {
				width: 2
			},
			showSymbol: false,
			areaStyle: {
				opacity: 0.8,
			},
			emphasis: {
				focus: 'series'
			},
			data: yAxisData
	    }]
	};
	return option;
}

export function GetMultiLineOption(chartName,unitName,xAxisData,seriesData,legendData){
	let option = {
		title: {
			text: chartName+'('+unitName+')'
		},
		tooltip: {
			trigger: 'axis'
		},
		grid: {
			left: '3%',
			right: '4%',
			bottom: '3%',
			containLabel: true
		},
		toolbox: {
		    feature: {
				saveAsImage: {}
		    }
		},
		legend:{
			data:legendData
		},
		xAxis: [{
			type: 'category',
			boundaryGap: false,
			data: xAxisData
	    }],
		yAxis: [{
			type: 'value'
	    }],
		series: seriesData
	};
	console.log(option)
	return option;
}