<template>
	<div class="contentheader">
		<el-space wrap>
			<span class="titleClass">系统设置</span>
			<el-divider direction="vertical" />
			<el-button type="primary" size="small" class="buttonforheader" @click="refresh">刷 新</el-button>
			<el-divider direction="vertical" />
			<el-button type="success" size="small" class="buttonforheader" @click="save(formRef)"> 保 存 配 置 </el-button>
			<el-divider direction="vertical" />
			<el-tag type="danger">最后修改人：{{formdata.muName}}</el-tag>
			<el-tag type="danger">最后修改时间：{{formdata.mt}}</el-tag>
			<el-divider direction="vertical" />
			<!-- <el-button type="warning" size="small" class="buttonforheader" @click="giftAmount">赠送电量</el-button> -->
		</el-space>
	</div>
	<div id="divTableData" class="contentcontent">
		<el-form :model="formdata" ref="formRef" class="demo-ruleForm">
			<el-row :gutter="20">
				<el-col :span="8">
					<el-card class="box-card" v-if="ammeterType!='0'||watermeterType!='0'">
						<div slot="header" class="clearfix">
							<span>自动抄表设置</span>
						</div>
						<div>
							<el-form-item label="是否自动抄电表" :label-width="formLabelWidth" v-if="ammeterType!='0'">
								<el-switch v-model="formdata.autoRecordAmmeter" active-text="自动" active-value="1"
									inactive-value="0" inactive-text="不自动">
								</el-switch>
							</el-form-item>
							<el-form-item label="上次抄电表时间" :label-width="formLabelWidth" v-if="ammeterType!='0'">
								{{formdata.lastRecordAmmeterTime}}
							</el-form-item>
							<el-divider direction="horizontal"  v-if="ammeterType!='0'&&watermeterType!='0'"/>
							<el-form-item label="是否自动抄水表" :label-width="formLabelWidth" v-if="watermeterType!='0'">
								<el-switch v-model="formdata.autoRecordWatermeter" active-text="自动" active-value="1"
									inactive-value="0" inactive-text="不自动">
								</el-switch>
							</el-form-item>
							<el-form-item label="上次抄水表时间" :label-width="formLabelWidth" v-if="watermeterType!='0'">
								{{formdata.lastRecordWatermeterTime}}
							</el-form-item>
						</div>
					</el-card>
					<el-card class="box-card" v-if="ammeterType!='0'||watermeterType!='0'">
						<div slot="header" class="clearfix">
							<span>自动断送{{watermeterType!='0'?'水':''}}{{ammeterType!='0'?'电':''}}设置</span>
						</div>
						<div>
							<el-form-item label="是否自动断电" :label-width="formLabelWidth" v-if="ammeterType!='0'">
								<el-switch v-model="formdata.autoCloseAmmeter" active-text="自动" active-value="1"
									inactive-value="0" inactive-text="不自动">
								</el-switch>
							</el-form-item>
							<el-form-item label="是否自动断水" :label-width="formLabelWidth" v-if="watermeterType!='0'">
								<el-switch v-model="formdata.autoCloseWatermeter" active-text="自动" active-value="1"
									inactive-value="0" inactive-text="不自动">
								</el-switch>
							</el-form-item>
						</div>
					</el-card>
					<el-card class="box-card">
						<div slot="header" class="clearfix">
							<span>自动发送短信设置</span>
						</div>
						<div>
							<el-form-item label="自动发送提醒短信" :label-width="formLabelWidth">
								<el-switch v-model="formdata.autoSendSMS" active-text="自动" active-value="1"
									inactive-value="0" inactive-text="不自动">
								</el-switch>
							</el-form-item>
						</div>
					</el-card>
				</el-col>
				<el-col :span="8">
					<el-card class="box-card">
						<div slot="header" class="clearfix">
							<span>自动预警设置</span>
						</div>
						<div>
							<el-form-item :label="ammeterName1+'低电量/低余额是否自动提醒'" :label-width="formLabelWidth2" v-if="ammeterType!='0'">
								<el-switch v-model="formdata.autoAlterLowPower" active-text="自动" active-value="1"
									inactive-value="0" inactive-text="不自动">
								</el-switch>
							</el-form-item>
							<el-form-item label="默认低电量/低余额提醒值" prop="lowPower" :label-width="formLabelWidth2"
								:rules="[{ required: true, message: '请输入默认低电量提醒值' ,trigger:'blur'}]" v-if="ammeterType!='0'">
								<el-input v-model="formdata.lowPower" autocomplete="off" :style="formContentStyle2"
									type="number" placeholder="请输入默认低电量提醒值" />
							</el-form-item>
							<el-divider direction="horizontal" v-if="ammeterType=='2'" />
							<el-form-item :label="ammeterName2+'低电量/低余额是否自动提醒'" :label-width="formLabelWidth2" v-if="ammeterType=='2'">
								<el-switch v-model="formdata.autoAlterLowPower2" active-text="自动" active-value="1"
									inactive-value="0" inactive-text="不自动">
								</el-switch>
							</el-form-item>
							<el-form-item label="默认低电量/低余额提醒值" prop="lowPower" :label-width="formLabelWidth2"
								:rules="[{ required: true, message: '请输入默认低电量提醒值' ,trigger:'blur'}]" v-if="ammeterType=='2'">
								<el-input v-model="formdata.lowPower2" autocomplete="off" :style="formContentStyle2"
									type="number" placeholder="请输入默认低电量提醒值" />
							</el-form-item>
								<el-divider direction="horizontal"  v-if="ammeterType!='0'" />
							<el-form-item :label="watermeterName1+'低水量是否自动提醒'" :label-width="formLabelWidth2"  v-if="watermeterType!='0'">
								<el-switch v-model="formdata.autoAlterLowWater1" active-text="自动" active-value="1"
									inactive-value="0" inactive-text="不自动">
								</el-switch>
							</el-form-item>
							<el-form-item label="默认低水量提醒值" prop="lowWater1" :label-width="formLabelWidth2"
								:rules="[{ required: true, message: '请输入默认低水量提醒值' ,trigger:'blur'}]"   v-if="watermeterType!='0'">
								<el-input v-model="formdata.lowWater1" autocomplete="off" :style="formContentStyle2"
									type="number" placeholder="请输入默认低水量提醒值" />
							</el-form-item>
							<el-divider direction="horizontal"  v-if="watermeterType=='2'" />
							<el-form-item :label="watermeterName2+'低水量是否自动提醒'" :label-width="formLabelWidth2" v-if="watermeterType=='2'" >
								<el-switch v-model="formdata.autoAlterLowWater2" active-text="自动" active-value="1"
									inactive-value="0" inactive-text="不自动">
								</el-switch>
							</el-form-item>
							<el-form-item label="默认低水量提醒值" prop="lowWater2" :label-width="formLabelWidth2"
								:rules="[{ required: true, message: '请输入默认低水量提醒值' ,trigger:'blur'}]" v-if="watermeterType=='2'" >
								<el-input v-model="formdata.lowWater2" autocomplete="off" :style="formContentStyle2"
									type="number" placeholder="请输入默认低水量提醒值" />
							</el-form-item>
							<el-divider direction="horizontal"  v-if="watermeterType!='0'"/>
							<el-form-item label="账户低余额否自动提醒" :label-width="formLabelWidth2">
								<el-switch v-model="formdata.autoAlterLowBalance" active-text="自动" active-value="1"
									inactive-value="0" inactive-text="不自动">
								</el-switch>
							</el-form-item>
							<el-form-item label="默认低余额提醒值" prop="lowBalance" :label-width="formLabelWidth2"
								:rules="[{ required: true, message: '请输入默认低余额提醒值' ,trigger:'blur'}]">
								<el-input v-model="formdata.lowBalance" autocomplete="off" :style="formContentStyle2"
									type="number" placeholder="请输入默认低余额提醒值" />
							</el-form-item>
							<el-divider direction="horizontal" />
							<el-form-item label="账户余额不足是否自动提醒" :label-width="formLabelWidth2">
								<el-switch v-model="formdata.autoAlterOweBalance" active-text="自动" active-value="1"
									inactive-value="0" inactive-text="不自动">
								</el-switch>
							</el-form-item>
						</div>
					</el-card>
				</el-col>
				<el-col :span="8">
					<el-card class="box-card">
						<div slot="header" class="clearfix">
							<span>短信平台 - 短信剩余量</span>
						</div>
						<div style="text-align: center; margin-top: 10px;">
							短信剩余 {{smsBalance}} 条
						</div>
					</el-card>
					<el-card class="box-card">
						<div slot="header" class="clearfix">
							<span>左上角图标设置</span>
						</div>
						<div style="text-align: center; margin-top: 10px;">
							<el-form-item label="是否显示默认图标" :label-width="formLabelWidth2">
								<el-switch v-model="formdata.showDefaultIcon" active-text="显示" active-value="1"
									inactive-value="0" inactive-text="不显示">
								</el-switch>
							</el-form-item>
						</div>
					</el-card>
				</el-col>
			</el-row>
		</el-form>
	</div>
	<!-- <el-dialog width="500px" v-model="giftDialogVisible" append-to-body=true title="赠送电量" center draggable
		:close-on-click-modal="false" :show-close="true">
		<el-form :model="giftformdata" class="demo-ruleForm formContent">
			<el-form-item label="赠送量" :label-width="formLabelWidth">
				<el-input v-model="giftformdata.giftValue" autocomplete="off" :style="formContentStyle" type="number"
					placeholder="请输入赠送量" >
					<template #append>度</template>
				</el-input>
			</el-form-item>
			<el-form-item label="说明" :label-width="formLabelWidth">
				1. 每次赠送电量都会刷新房间当前剩余赠送量。<br />
				2. 赠送针对所有房间。<br />
				3. 每次结算账单时，会用赠送量抵扣使用的电量，抵扣完，剩余的使用电量会收费。
			</el-form-item>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button size="mini" type="primary" @click="submitGiftForm" class="buttonforform"> 赠 送 </el-button>
			</span>
		</template>
	</el-dialog> -->
</template>

<script setup>
	import {
		reactive,
		ref,
		onMounted,
		onActivated,
		getCurrentInstance
	} from 'vue';
	import {
		ElMessageBox
	} from 'element-plus'
	import {
		GetServerData
	} from '@/http/index.js'
	const {
		proxy
	} = getCurrentInstance()

	let startHours = ref([])
	for (let i = 0; i <= 23; i++) {
		startHours.value.push({
			label: i.toString(),
			value: i.toString()
		})
	}
	let intervalHours = ref([])
	for (let i = 6; i <= 168; i++) {
		if (i % 6 == 0) {
			intervalHours.value.push({
				label: i.toString(),
				value: i.toString()
			})
		}
	}
	
	let ammeterType=ref(localStorage.getItem("ammeterType"));
	let ammeterName1=ref(localStorage.getItem("ammeterName1"));
	let ammeterName2=ref(localStorage.getItem("ammeterName2"));
	
	let watermeterType=ref(localStorage.getItem("watermeterType"));
	let watermeterName1=ref(localStorage.getItem("watermeterName1"));
	let watermeterName2=ref(localStorage.getItem("watermeterName2"));
	
	let smsBalance=ref(0)
	
	let formdata = ref({
		id: '',
		estateId: '',
		autoRecordAmmeter: '0',
		lastRecordAmmeterTime: '',
		autoRecordWatermeter: '0',
		lastRecordWatermeterTime: '',
		autoAlterLowPower: '0',
		autoAlterLowPower2: '0',
		lowPower: '10',
		lowPower2: '10',
		autoAlterLowWater1: '0',
		lowWater1: '10',
		autoAlterLowWater2: '0',
		lowWater2: '10',
		autoAlterLowBalance: '0',
		lowBalance: '10',
		autoCloseAmmeter: '0',
		autoCloseWatermeter: '0',
		lastCountAmmeterUseAmountTime:'',
		lastCountWatermeterUseAmountTime:'',
		autoSendSMS:'0',
		autoAlterOweBalance:'0',
		muName: '',
		mt: '',
		showDefaultIcon:'1',
		customIcon:''
	})
	const formRef = ref()
	
	
	// let giftDialogVisible=ref(false)
	// let giftformdata=reactive({
	// 	roomIds:'',
	// 	giftValue:0
	// })
	
	// const giftAmount =()=>{
	// 	giftformdata.giftValue=0;
	// 	giftDialogVisible.value = true;
	// }
	
	// const submitGiftForm= async ()=>{	
	// 	giftformdata.roomIds="noids";
	// 	ElMessageBox.confirm('确定要进行赠送电量的操作吗?', "", {
	// 			confirmButtonText: '确 定',
	// 			cancelButtonText: '取 消',
	// 			type: 'warning'
	// 		})
	// 		.then(async () => {
	// 			let loading = proxy.$tools.ShowLoading();
	// 			let response = await GetServerData('EstateConfig/GiftAmount', giftformdata)
	// 			if (response.status == 1) {
	// 				proxy.$tools.ShowMessageDuration('赠送成功！', true, proxy.$config.showImportantTipsSecond)
	// 			} else {
	// 				proxy.$tools.ShowMessageDuration(response.message, false, proxy.$config.showImportantTipsSecond)
	// 			}
	// 			loading.close()
	// 		})
	// 	}
	
	
	// 定义表单项的头部宽度
	const formLabelWidth = ref('160px')
	// 定义表单项的内容宽度
	const formContentStyle = ref('width:200px')
	// 定义表单项的头部宽度
	const formLabelWidth2 = ref('230px')
	// 定义表单项的内容宽度
	const formContentStyle2 = ref('width:130px')
	const GetData = async () => {
		let response = await GetServerData('EstateConfig/QueryOne')
		if (response.status == 1) {
			formdata.value = response.data
			formdata.value.lowPower2= response.data.lowPower2.toString();
			formdata.value.lowPower = response.data.lowPower.toString();
			formdata.value.lowWater1 = response.data.lowWater1.toString();
			formdata.value.lowWater2 = response.data.lowWater2.toString();
			formdata.value.lowBalance = response.data.lowBalance.toString();
			smsBalance.value=response.otherData.toString();
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}
	onMounted(async () => {
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth - 220) +
			'px'
		document.getElementById("divTableData").style.height = (`${document.documentElement.clientHeight}` -
				proxy.$reduceHeight + 60) +
			'px'
		window.onresize = () => {
			document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
					220) +
				'px'
			document.getElementById("divTableData").style.height = (
					`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60) +
				'px'
		}
		let loading = proxy.$tools.ShowLoading();
		await GetData()
		loading.close()
	})
	onActivated(() => {
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth - 220) +
			'px'
		document.getElementById("divTableData").style.height = (`${document.documentElement.clientHeight}` - proxy
				.$reduceHeight + 60) +
			'px'
	})

	const save = (form) => {
		form.validate(async (valid) => {
			if (valid) {
				let loading = proxy.$tools.ShowLoading();
				console.log(formdata.value)
				let response = await GetServerData('EstateConfig/Modify', formdata.value)
				if (response.status == 1) {
					proxy.$tools.ShowMessage('修改成功', true)
				} else {
					proxy.$tools.ShowMessage(response.message, false)
				}
				loading.close()
			} else {
				proxy.$tools.ShowMessage('请您正确填写所有必填信息后，再提交！', false)
				return false;
			}
		})
	}

	const refresh = async () => {
		let loading = proxy.$tools.ShowLoading();
		await GetData()
		loading.close()
	}
</script>

<style lang="less" scoped>
	.clearfix {
		height: 50px;
		line-height: 50px;
		background-color: #409EFF;
	}

	.clearfix:before,
	.clearfix:after {
		display: table;
		content: "";
	}

	.clearfix:after {
		clear: both
	}

	.box-card {
		width: 480px;
		margin-top: 15px;
		margin-left: 15px;
		padding:0px;
	}
	::v-deep .el-form-item
	{
		margin-bottom: 10px !important;
	}
</style>
