<template>
	<div class="contentheader">
		<el-space wrap>
			<span class="titleClass">水表管理</span>
			<el-divider direction="vertical" />
			<el-cascader v-model="querySelectedArea" :options="areas" :props="{
						value:'id',
						label:'name',
						checkStrictly: true
					}" @change="handleQueryChange" placeholder="选择区域" style="width: 300px;" size="small"></el-cascader>
			<el-select v-model="params.payType" placeholder="选择付费类型" clearable style="width: 150px;" size="small">
				<el-option v-for="item in payTypes" :key="item.value" :label="item.label" :value="item.value" />
			</el-select>
			<el-select v-model="params.category" placeholder="选择水表类型" clearable style="width: 150px;" size="small">
				<el-option v-for="item in categorys" :key="item.value" :label="item.label" :value="item.value" />
			</el-select>
			<el-button type="primary" size="small" class="buttonforheader" @click="search">查 询</el-button>
			<el-button type="primary" size="small" class="buttonforheader" @click="refresh">刷 新</el-button>
			<el-divider direction="vertical" />
			<el-button type="success" size="small" class="buttonforheader" @click="add">新 增</el-button>
			<el-button type="warning" size="small" class="buttonforheader" @click="deletesome">批量删除</el-button>
			<!-- <el-button type="primary" size="small" class="buttonforheader" @click="resetparams">重 置</el-button> -->
		</el-space>
	</div>
	<div id="divTableData" class="contentcontent">
		<el-table :data="tableDatas" fit="false" highlight-current-row="true" ref="multipleTable" tooltip-effect="dark"
			:height="tableheight" @selection-change="handleSelectionChange" @row-click="rowclick" row-key="id"
			default-expand-all :header-cell-style="headerCellStype" :cell-style="contentCellStyle"
			style="width: 100%; cursor: pointer;" :row-class-name="tableRowClassName">
			<el-table-column type="selection" width="55" />
			<el-table-column fixed prop="id" label="id" width="0" v-if="false" />
			<el-table-column fixed prop="areaId1" label="areaId1" width="0" v-if="false" />
			<el-table-column fixed prop="areaId2" label="areaId2" width="0" v-if="false" />
			<el-table-column fixed prop="areaId3" label="areaId3" width="0" v-if="false" />
			<el-table-column fixed prop="protocolId" label="protocolId" width="0" v-if="false" />
			<el-table-column fixed prop="dtuId" label="dtuId" width="0" v-if="false" />
			<el-table-column fixed prop="payType" label="payType" width="0" v-if="false" />
			<el-table-column fixed prop="category" label="category" width="0" v-if="false" />
			<el-table-column fixed prop="storeType" label="storeType" width="0" v-if="false" />
			<el-table-column fixed prop="canChargeNegative" label="canChargeNegative" width="0"  v-if="false" />
			<el-table-column prop="areaId1Name" label="一级区域" width="100" />
			<el-table-column prop="areaId2Name" label="二级区域" width="100" />
			<el-table-column prop="areaId3Name" label="三级区域" width="100" />
			<el-table-column prop="protocolName" label="协议名称" width="200" />
			<el-table-column prop="dtuName" label="DTU名称" width="200" />
			<el-table-column prop="address" label="地址" width="150" />
			<el-table-column prop="payTypeName" label="付费类型" width="100"  align="center" />
			<el-table-column prop="categoryName" label="水表类型" width="100"  align="center" />
			<el-table-column prop="storeTypeName" label="表端存储" width="100"  align="center" />
			<el-table-column prop="canChargeNegativeName" label="是否可购负数" width="150"  align="center" />
			<el-table-column prop="number" label="出厂编号" width="200" />
			<el-table-column prop="location" label="安装位置" width="200" />
			<el-table-column prop="remark" label="备注" width="250" :show-overflow-tooltip="true" />
			<el-table-column prop="cuName" label="创建人" width="120" />
			<el-table-column prop="muName" label="修改人" width="120" />
			<el-table-column prop="ct" label="创建时间" width="150" align="center" />
			<el-table-column prop="mt" label="修改时间" width="150" align="center" />
			<el-table-column />
			<el-table-column fixed="right" label="操作" width="150" align="left">
				<template #default="scope">
					<el-button size="mini" @click.stop="modify(scope.row)" type="success" class="buttonfortable">编 辑
					</el-button>
					<el-button size="mini" @click.stop="deleteitem(scope.row)" type="warning" class="buttonfortable">删 除
					</el-button>
				</template>
			</el-table-column>
		</el-table>
	</div>
	<div class="contentpager" v-if="showpaging">
		<el-pagination background layout="prev, pager, next,jumper,sizes,total" :total="pagingInfo.totalCount"
			:page-sizes="[500,1000,2000,3000,4000]" v-model:current-page="params.pageNum"
			v-model:page-size="params.pageSize" @size-change="sizechange" @current-change="currentchange" />
	</div>
	<el-dialog width="1100px" v-model="dialogVisible" append-to-body=true :title="dialogtitle" center draggable
		:close-on-click-modal="false" :show-close="true">
		<el-form :model="formdata" ref="formRef" class="demo-ruleForm formContent">
			<el-row :gutter="20">
				<el-col :span="12">
					<el-form-item prop="areaId3" :rules="[{ required: true, message: '请选择区域' }]" label="选择区域"
						:label-width="formLabelWidth">
						<el-cascader v-model="selectedArea" :options="areas" :props="{
								value:'id',
								label:'name'
							}" @change="handleChange" placeholder="请选择区域" :style="formContentStyle"></el-cascader>
					</el-form-item>
					<el-form-item prop="protocolId" :rules="[{ required: true, message: '请选择协议类型' }]" label="协议类型"
						:label-width="formLabelWidth">
						<el-select v-model="formdata.protocolId" placeholder="请选择协议类型" :style="formContentStyle"
							@change="handleProtocolChange">
							<el-option v-for="item in protocols" :key="item.id" :label="item.name" :value="item.id" />
						</el-select>
					</el-form-item>
					<el-form-item prop="dtuId" :rules="[{ required: true, message: '请选择DTU' }]" label="DTU"
						:label-width="formLabelWidth">
						<el-select v-model="formdata.dtuId" placeholder="请选择DTU" :style="formContentStyle"
							@change="handleDTUChange">
							<el-option v-for="item in dtus" :key="item.id" :label="item.name" :value="item.id" />
						</el-select>
					</el-form-item>
					<el-form-item label="付费类型" :label-width="formLabelWidth">
						<el-radio-group v-model="formdata.payType" class="ml-4">
							<el-radio label="1" size="small" style="margin-left: 20px;">预付费</el-radio>
							<el-radio label="2" size="small">后付费</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="水表类型" :label-width="formLabelWidth">
						<el-radio-group v-model="formdata.category" class="ml-4">
							<el-radio label="1" size="small" style="margin-left: 20px;">冷水表</el-radio>
							<el-radio label="2" size="small">热水表</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="表端存储模式" :label-width="formLabelWidth">
						<el-radio-group v-model="formdata.storeType" class="ml-4">
							<el-radio label="1" size="small" style="margin-left: 20px;">水量</el-radio>
							<el-radio label="2" size="small">金额</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="是否可购负数" :label-width="formLabelWidth">
						<el-radio-group v-model="formdata.canChargeNegative" class="ml-4">
							<el-radio label="0" size="small" style="margin-left: 20px;">否</el-radio>
							<el-radio label="1" size="small">是</el-radio>
						</el-radio-group>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item prop="address" :rules="[{ required: true, message: '请输入水表地址' },{min:1,max:20,message:'水表地址的长度为1-20',trigger:'blur'}]" label="水表地址"
						:label-width="formLabelWidth">
						<el-input v-model="formdata.address" autocomplete="off" :style="formContentStyle"  type="number" maxlength="20"
							placeholder="请输入水表地址" />
					</el-form-item>
					<el-form-item prop="location" label="安装位置"
						:label-width="formLabelWidth">
						<el-input v-model="formdata.location" autocomplete="off" :style="formContentStyle"  maxlength="30"
							placeholder="请输入安装位置" />
					</el-form-item>
					<el-form-item label="出厂编号" :label-width="formLabelWidth">
						<el-input v-model="formdata.number" autocomplete="off" :style="formContentStyle" maxlength="30"
							placeholder="请输入出厂编号" />
					</el-form-item>
					<el-form-item label="备注" :label-width="formLabelWidth">
						<el-input v-model="formdata.remark" autocomplete="off" :style="formContentStyle" placeholder="请输入备注"  maxlength="200"/>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-space wrap :size="30">
					<span v-if="showgoon">是否继续添加：
						<el-switch v-model="goon" style="margin-right: 10px;" />
					</span>
					<el-button size="mini" type="warning" @click="btnreset" class="buttonforform"> 重 置 </el-button>
					<!-- <el-button @click="dialogVisible = false">取消</el-button> -->
					<el-button size="mini" type="primary" @click="submitForm(formRef)" class="buttonforform"> 确 定
					</el-button>
				</el-space>
			</span>
		</template>
	</el-dialog>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted,
		onActivated,
		getCurrentInstance
	} from 'vue'
	import {
		ElMessageBox
	} from 'element-plus'
	import {
		GetServerData
	} from '@/http/index.js'
	// 获取全局this，proxy相当于vue2中的this，用于调用全局对象使用
	const {
		proxy
	} = getCurrentInstance()

	// 定义查询参数
	let params = reactive({
		areaId1: '',
		areaId2: '',
		areaId3: '',
		payType: '',
		category: '',
		pageSize: 500,
		pageNum: 1
	})
	let pageNum = ref(1)
	let pagingInfo = reactive({
		totalCount: 0,
		totalPage: 0
	})
	// 定义combox数据
	let areas = ref([])
	let protocols = ref([])
	let dtus = ref([])
	let querySelectedArea = ref([])
	let selectedArea = ref([])
	let selectedProtocol = ref()
	let selectedDTU = ref()
	let payTypes = ref([{
			label: '预付费',
			value: '1'
		},
		{
			label: '后付费',
			value: '2'
		}
	])
	let categorys = ref([{
			label: '冷水表',
			value: '1'
		},
		{
			label: '热水表',
			value: '2'
		}
	])
	// 定义表单标题
	let dialogtitle = ref("新 增 水 表")
	// 定义表单数据
	let formdata = reactive({
		id: '',
		areaId1: '',
		areaId2: '',
		areaId3: '',
		protocolId: '',
		dtuId: '1',
		address: '',
		payType: '',
		category: '',
		storeType:'1',
		number: '',
		remark: '',
		location:'',
		canChargeNegative:'0'
	})

	// 定义表单显示与隐藏
	let dialogVisible = ref(false)
	// 定义是否显示继续添加
	let showgoon = ref(false)
	// 定义是否继续添加
	let goon = ref(false)
	// 定义是否显示分页
	let showpaging = ref(true)
	// 定义表格数据
	let tableDatas = ref([])

	let headerCellStype = ref(proxy.$headerCellStype)
	let contentCellStyle = ref(proxy.$contentCellStyle)
	// 定义选中的表格行
	let multipleSelection = reactive([])
	// 定义表格引用，用来执行多选
	const multipleTable = ref()
	// 定义表单引用，用来执行表单验证
	const formRef = ref()
	// 定义表单项的头部宽度
	const formLabelWidth = ref('150px')
	// 定义表单项的内容宽度
	const formContentStyle = ref('width:300px')

	// 定义表格隔行变色，暂时没用到
	const tableRowClassName = ({
		row,
		rowIndex
	}) => {
		if (rowIndex % 2 === 0) {
			return 'even-row'
		} else {
			return 'odd-row'
		}
	}
	// 定义表格多选
	const handleSelectionChange = (val) => {
		multipleSelection = val;
	}
	const toggleSelection = (rows) => {
		if (rows) {
			rows.forEach(row => {
				multipleTable.value.toggleRowSelection(row);
			});
		} else {
			multipleTable.value.clearSelection();
		}
	}
	const rowclick = (row, column, event) => {
		toggleSelection([row])
	}

	const handleQueryChange = (value) => {
		if (querySelectedArea.value.length == 1) {
			params.areaId1 = querySelectedArea.value[0]
		} else if (querySelectedArea.value.length == 2) {
			params.areaId2 = querySelectedArea.value[1]
		} else if (querySelectedArea.value.length == 3) {
			params.areaId3 = querySelectedArea.value[2]
		} else {
			params.areaId1 = '';
			params.areaId2 = '';
			params.areaId3 = '';
		}
	}

	const handleChange = (value) => {
		if (selectedArea.value.length == 3) {
			formdata.areaId1 = selectedArea.value[0]
			formdata.areaId2 = selectedArea.value[1]
			formdata.areaId3 = selectedArea.value[2]
		} else {
			formdata.areaId1 = ''
			formdata.areaId2 = ''
			formdata.areaId3 = ''
		}
	}

	const handleProtocolChange = (value) => {
		selectedProtocol.value = value;
		if(value&&value.toString()!=''){
			for(let i=0;i<protocols.value.length;i++){
				if(protocols.value[i].id==value){
					formdata.canChargeNegative=protocols.value[i].name.split('|')[1];
					break;
				}
			}
		}
	}

	const handleDTUChange = (value) => {
		selectedDTU.value = value;
	}
	const sizechange = async () => {
		params.pageNum = 1
		let loading = proxy.$tools.ShowLoading();
		await GetTableData()
		loading.close()
	}
	const currentchange = async () => {
		let loading = proxy.$tools.ShowLoading();
		await GetTableData()
		loading.close()
	}

	// 定义表单提交事件，带表单验证
	const submitForm = (form) => {
		form.validate(async (valid) => {
			if (valid) {
				let loading = proxy.$tools.ShowLoading();
				if (formdata.id.length > 0) {
					let response = await GetServerData('Watermeter/Modify', formdata)
					if (response.status == 1) {
						proxy.$tools.ShowMessage('修改成功', true)
						dialogVisible.value = false
						await GetAllData();
					} else {
						proxy.$tools.ShowMessage(response.message, false)
					}
				} else {
					let response = await GetServerData('Watermeter/Add', formdata)
					if (response.status == 1) {
						proxy.$tools.ShowMessage('新增成功！', true)
						if (goon.value) {
							reset()
						} else {
							dialogVisible.value = false
						}
						await GetAllData();
					} else {
						proxy.$tools.ShowMessage(response.message, false)
					}
				}
				loading.close()
			} else {
				proxy.$tools.ShowMessage('请您正确填写所有必填信息后，再提交！', false)
				return false;
			}
		})
	}
	const btnreset = () => {
		reset()
		selectedArea.value = []
		formdata.areaId1 = '';
		formdata.areaId2 = '';
		formdata.areaId3 = '';
		formdata.protocolId = '';
		formdata.dtuId = '';
		formdata.payType = '1';
		formdata.category = '1';
		formdata.storeType='1';
		formdata.canChargeNegative='0';
	}
	// 重置表单事件
	const reset = () => {
		if (formRef.value) {
			formRef.value.resetFields();
		}
		if (selectedArea.value.length == 3) {
			formdata.areaId1 = selectedArea.value[0]
			formdata.areaId2 = selectedArea.value[1]
			formdata.areaId3 = selectedArea.value[2]
		}
		formdata.protocolId = selectedProtocol.value;
		formdata.dtuId = selectedDTU.value;
		formdata.address = '';
		formdata.number = '';
		formdata.remark = '';
		formdata.location='';
	}
	// 定义新增按钮事件
	const add = () => {
		dialogtitle.value = "新 增 水 表"
		goon.value = false
		showgoon.value = true
		btnreset()
		formdata.id = '';
		dialogVisible.value = true
	}
	// 定义编辑按钮事件
	const modify = (row) => {
		dialogtitle.value = "修 改 水 表"
		showgoon.value = false
		formdata.id = row.id;
		formdata.areaId1 = row.areaId1;
		formdata.areaId2 = row.areaId2;
		formdata.areaId3 = row.areaId3;
		formdata.protocolId = row.protocolId;
		formdata.dtuId = row.dtuId;
		formdata.address = row.address;
		formdata.payType = row.payType;
		formdata.category = row.category;
		formdata.storeType = row.storeType;
		formdata.number = row.number;
		formdata.remark = row.remark;
		formdata.location=row.location;
		formdata.canChargeNegative=row.canChargeNegative;
		selectedArea.value = [row.areaId1, row.areaId2, row.areaId3]
		dialogVisible.value = true
	}
	// 定义删除按钮事件
	const deleteitem = (row) => {
		ElMessageBox.confirm('确定要删除这条记录吗?', "", {
				confirmButtonText: '确 定',
				cancelButtonText: '取 消',
				type: 'warning'
			})
			.then(async () => {
				let loading = proxy.$tools.ShowLoading();
				let response = await GetServerData('Watermeter/Delete', {
					id: row.id
				})

				if (response.status == 1) {
					proxy.$tools.ShowMessage('删除成功！', true)
					await GetAllData()
				} else {
					proxy.$tools.ShowMessage(response.message, false)
				}
				loading.close();
			})
	}
	// 定义批量删除按钮事件
	const deletesome = () => {
		var ids = ''
		multipleSelection.forEach((value, key, iterable) => {
			ids += "'" + value.id + "',";
		})
		if (ids.length > 0) {
			ids = ids.substr(0, ids.length - 1)
			ElMessageBox.confirm('确定要删除下面选中的所有记录吗?', "", {
					confirmButtonText: '确 定',
					cancelButtonText: '取 消',
					type: 'warning'
				})
				.then(async () => {
					let loading = proxy.$tools.ShowLoading();
					let response = await GetServerData('Watermeter/DeleteSome', {
						ids: ids
					})
					if (response.status == 1) {
						proxy.$tools.ShowMessage('删除成功！', true)
						await GetAllData()
					} else {
						proxy.$tools.ShowMessage(response.message, false)
					}
					loading.close()
				})
		}
	}
	// 定义查询按钮事件
	const search = async () => {
		let loading = proxy.$tools.ShowLoading();
		await GetTableData()
		loading.close()
	}
	// 定义刷新按钮事件
	const resetparams = () => {
		params.areaId1 = ''
		params.areaId2 = ''
		params.areaId3 = ''
		params.payType = ''
		params.category = ''
		querySelectedArea.value = [];
	}
	// 定义获取表格数据函数
	const GetTableData = async () => {
		let response = await GetServerData('Watermeter/QueryAll', params)
		if (response.status == 1) {
			tableDatas.value = response.data
			pagingInfo.totalCount = response.totalCount
			pagingInfo.totalPage = Math.ceil(pagingInfo.totalCount / params.pageSize)
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}
	// 定义获取combox数据函数
	const GetComboxData = async () => {
		let response = await GetServerData('Watermeter/QueryComboxData')
		if (response.status == 1) {
			protocols.value = response.data.protocols;
			dtus.value = response.data.dtus;
			areas.value = response.data.areas;
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}
	const refresh=async ()=>{
		let loading = proxy.$tools.ShowLoading();
		tableDatas.value=[];
		await GetAllData()
		loading.close()
	}
	// 定义获取所有数据函数
	const GetAllData = async () => {
		await GetTableData()
		await GetComboxData()
	}
	let tableheight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight))
	// 定义加载事件
	onMounted(async () => {
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth - 220) +
			'px'
		window.onresize = () => {
			tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight)
			document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
					220) +
				'px'
		}
		let loading = proxy.$tools.ShowLoading();
		await GetAllData()
		loading.close()
	})
	onActivated(() => {
		tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight)
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
			220) + 'px'
	})
</script>

<style scoped lang="less">
</style>
