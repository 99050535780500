import axios from 'axios'
import router from "@/router"

const config=JSON.parse(localStorage.getItem("config"))

// 把axios进行简单封装，定义路径头部、超时时间、内容类型
const http = axios.create({
	baseURL: config.baseURL,
	timeout: config.timeout,
	headers: {
		'Content-Type': 'application/json'
	}
})

const httpFile = axios.create({
	baseURL: config.baseURL,
	timeout: config.timeout,
	headers: {
		'Content-Type': 'multipart/form-data'
	}
})

// 添加请求拦截器
http.interceptors.request.use(function(config) {
	// 在发送请求前，如果有token，则把token加到header的Authorization里
	let token = localStorage.getItem("token")
	if (token) {
		config.headers.Authorization = token
	}
	//console.log(config.headers.Authorization)
	return config;
}, function(error) {
	console.log("异常发送：" + error)
	return Promise.reject(error);
});
httpFile.interceptors.request.use(function(config) {
	// 在发送请求前，如果有token，则把token加到header的Authorization里
	let token = localStorage.getItem("token")
	if (token) {
		config.headers.Authorization = token
	}
	//console.log(config.headers.Authorization)
	return config;
}, function(error) {
	console.log("异常发送：" + error)
	return Promise.reject(error);
});

// 添加响应拦截器
http.interceptors.response.use(function(response) {
	// 本项目只要有返回的数据，状态码都是200，需要根据返回内容中的status判断最终的状态
	//console.log(response)
	if (response.status == 200) {
		return response.data;
	} else {
		let returnData = {
			status: '',
			message: '',
			data: null
		}
		returnData.status = 28
		returnData.message = '发生了未知的错误1，请联系运维人员！'
		returnData.data = null
		return returnData;
	}
}, function(error) {
	// 封装返回错误的内容，把返回错误的内容页封装到返回正确的内容中，通过status判断返回的状态
	let returnData = {
		status: '',
		message: '',
		data: null
	}
	console.log(error)
	if (error.response) {
		if (error.response.status == 400) {
			returnData.status = 20
			returnData.message = '请求参数不正确，请修改后再试！'
			returnData.data = null
		} else if (error.response.status == 403||error.response.status == 401) {
			returnData.status = 21
			returnData.message = '您的登录信息已过期，请重新登录！'
			returnData.data = null
			localStorage.setItem('isExpired','1');
			router.replace('/');
		}else if (error.response.status == 404) {
			returnData.status = 22
			returnData.message = '您访问的页面不存在！'
			returnData.data = null
		} else if(error.response.status >= 500){
			returnData.status = 30
			returnData.message = '服务器发生了未知错误，请联系运维人员！'
			returnData.data = null
		}
		else {
			returnData.status = 29
			returnData.message = '发生了未知的错误2，请联系运维人员！'
			returnData.data = null
		}
	} else {
		returnData.status = 27
		returnData.message = '无法访问法服务器，请稍后再试！'
		returnData.data = null
	}
	// 这个是返回错误：return Promise.reject(error)
	// 下面这个是返回正确
	return Promise.resolve(returnData);
});

httpFile.interceptors.response.use(function(response) {
	// 本项目只要有返回的数据，状态码都是200，需要根据返回内容中的status判断最终的状态
	//console.log(response)
	if (response.status == 200) {
		return response.data;
	} else {
		let returnData = {
			status: '',
			message: '',
			data: null
		}
		returnData.status = 28
		returnData.message = '发生了未知的错误1，请联系运维人员！'
		returnData.data = null
		return returnData;
	}
}, function(error) {
	// 封装返回错误的内容，把返回错误的内容页封装到返回正确的内容中，通过status判断返回的状态
	let returnData = {
		status: '',
		message: '',
		data: null
	}
	console.log(error)
	if (error.response) {
		if (error.response.status == 400) {
			returnData.status = 20
			returnData.message = '请求参数不正确，请修改后再试！'
			returnData.data = null
		} else if (error.response.status == 403||error.response.status == 401) {
			returnData.status = 21
			returnData.message = '您的登录信息已过期，请重新登录！'
			returnData.data = null
			localStorage.setItem('isExpired','1');
			router.replace('/');
		}else if (error.response.status == 404) {
			returnData.status = 22
			returnData.message = '您访问的页面不存在！'
			returnData.data = null
		} else if(error.response.status >= 500){
			returnData.status = 30
			returnData.message = '服务器发生了未知错误，请联系运维人员！'
			returnData.data = null
		}
		else {
			returnData.status = 29
			returnData.message = '发生了未知的错误2，请联系运维人员！'
			returnData.data = null
		}
	} else {
		returnData.status = 27
		returnData.message = '无法访问法服务器，请稍后再试！'
		returnData.data = null
	}
	// 这个是返回错误：return Promise.reject(error)
	// 下面这个是返回正确
	return Promise.resolve(returnData);
});

const GetServerData = async (url, data) => {
	let res = await http.post(url, data)
	return res
}

const UploadFile=async (url, data)=>{
	let res = await httpFile.post(url, data)
	return res
}

export {
	http,
	httpFile,
	GetServerData,
	UploadFile
}
