<template>

	<div class="contentheader">
		<el-space wrap>
			<span class="titleClass">电表整表设置</span>
			<el-divider direction="vertical" />
			<el-input v-model="params.keyword" placeholder="请输入关键字" size="small" style="width: 150px;"
				autocomplete="off" />
			<el-button type="primary" size="small" class="buttonforheader" @click="search">查 询</el-button>
			<el-button type="primary" size="small" class="buttonforheader" @click="refresh">刷 新</el-button>
			<el-divider direction="vertical" />
			<el-button type="success" size="small" class="buttonforheader" @click="setMultiMeter">设置多用户电表</el-button>
			<el-button type="warning" size="small" class="buttonforheader" @click="setHanYangMeter">设置汉阳单表</el-button>
			
			
		</el-space>
	</div>
	<div style="display: flex; flex-direction: row; flex-wrap: nowrap; align-items: flex-start;margin: 10px;">
		<div style="width: 200px;">
			<el-scrollbar :height="areaHeight" style="width:200px; border-radius: 6px;background-color: white;">
				<el-tree ref="treeAreaRef" :data="areaDatas" node-key="id" :expand-on-click-node="false"
					highlight-current :props="treeProps" @node-click="areaSelected" style="margin: 10px;">
				</el-tree>
			</el-scrollbar>
		</div>
		<div id="divTableData" style="width: 100%;border-radius: 6px;background-color: white; margin-left: 5px;">
			<el-table :data="tableDatas" fit="false" highlight-current-row="true" ref="multipleTable"
				tooltip-effect="dark" :height="tableheight" @selection-change="handleSelectionChange"
				@row-click="rowclick" row-key="id" default-expand-all :header-cell-style="headerCellStype"
				:cell-style="contentCellStyle" style="width: 100%; cursor: pointer;" :row-class-name="tableRowClassName"
				>
				<el-table-column type="selection" width="55" />
				<el-table-column fixed prop="meterId" label="meterId" width="0" v-if="false" />
				<el-table-column prop="areaName1" label="一级区域" width="100" />
				<el-table-column prop="areaName2" label="二级区域" width="100" />
				<el-table-column prop="areaName3" label="三级区域" width="100" />
				<el-table-column prop="protocolName" label="协议名称" width="100" />
				<el-table-column prop="dtuName" label="DTU名称" width="100" />
				<el-table-column prop="address" label="电表地址" width="100" />
				<el-table-column prop="payTypeName" label="付费类型" width="100" />
				<el-table-column prop="categoryName" label="电表类型" width="100" />
				<el-table-column prop="location" label="安装位置" width="120" />
				<el-table-column prop="message" label="执行结果" width="250" />
				<el-table-column />
			</el-table>
		</div>
	</div>
	<el-dialog width="500px" v-model="setMultiMeterDialogVisible" append-to-body=true title="多用户电表整表设置" center draggable
		:close-on-click-modal="false" :show-close="true">
		<el-row :gutter="20">
			<el-col :span="8" class="textaligncenter">
				<el-button type="primary" size="small" class="buttonforheader" @click="setSingle">设置单个参数</el-button>
			</el-col>
			<el-col :span="8" class="textaligncenter">
				<el-button type="success" size="small" class="buttonforheader" @click="setMalignantLoad">设负载增量参数</el-button>
			</el-col>
			<el-col :span="8" class="textaligncenter">
				<el-button type="warning" size="small" class="buttonforheader" @click="setMultiPrice">设置复费率参数</el-button>
			</el-col>
		</el-row>
		<el-row :gutter="20">
			<el-col :span="8" class="textaligncenter">
				<el-button type="info" size="small" class="buttonforheader" @click="setTimerOnOff">定时断送电</el-button>
			</el-col>
			<el-col :span="8" class="textaligncenter">
				<el-button type="danger" size="small" class="buttonforheader" @click="setOnOff">断送电</el-button>
			</el-col>
			<el-col :span="8" class="textaligncenter">
				<el-button type="primary" size="small" class="buttonforheader" @click="setDatetime">同步电表时间</el-button>
			</el-col>
		</el-row>
	</el-dialog>
	<el-dialog width="500px" v-model="setHanYangMeterDialogVisible" append-to-body=true title="汉阳单表设置" center draggable
		:close-on-click-modal="false" :show-close="true">
		<el-row :gutter="20">
			<el-col :span="8" class="textaligncenter">
				<el-button type="primary" size="small" class="buttonforheader" @click="setHanYangMultiPrice">设置费率价格</el-button>
			</el-col>
			<el-col :span="8" class="textaligncenter">
				<el-button type="success" size="small" class="buttonforheader" @click="setHanYangMultiInterval">设置费率时段</el-button>
			</el-col>
			<el-col :span="8" class="textaligncenter">
				<el-button type="warning" size="small" class="buttonforheader" @click="setHanYangMultiTimezone">设置费率时区</el-button>
			</el-col>
		</el-row>
		<el-row :gutter="20">
			<el-col :span="8" class="textaligncenter">
				<el-button type="success" size="small" class="buttonforheader" @click="setOnOff">断送电</el-button>
			</el-col>
			<el-col :span="8" class="textaligncenter">
				<el-button type="info" size="small" class="buttonforheader" @click="setHanYangDate">同步日期</el-button>
			</el-col>
			<el-col :span="8" class="textaligncenter">
				<el-button type="primary" size="small" class="buttonforheader" @click="setHanYangTime">同步时间</el-button>
			</el-col>
		</el-row>
		<!-- <el-row :gutter="20">
			<el-col :span="8" class="textaligncenter">
				<el-button type="success" size="small" class="buttonforheader" @click="setOnOff">断送电</el-button>
			</el-col>
			<el-col :span="8" class="textaligncenter">
				
			</el-col>
			<el-col :span="8" class="textaligncenter">
				
			</el-col>
		</el-row> -->
	</el-dialog>
	<el-dialog width="500px" v-model="setHanYangMultiTimezoneDialogVisible" append-to-body=true :title="dialogtitle" center draggable
		:close-on-click-modal="false" :show-close="true">
		<el-form :model="hanyangtimezoneformdata" class="demo-ruleForm formContent">
			<el-form-item label="开始时间(月)" :label-width="formLabelWidth">
				<el-select v-model="hanyangtimezoneformdata.timezone01Month" class="m-2" size="small" style="width: 100px;">
					<el-option v-for="item in months2" :key="item.value" :label="item.label" :value="item.value" />
				</el-select> 月
			</el-form-item>
			<el-form-item label="开始时间(日)" :label-width="formLabelWidth">
				<el-select v-model="hanyangtimezoneformdata.timezone01Day" class="m-2" size="small" style="width: 100px;">
					<el-option v-for="item in days2" :key="item.value" :label="item.label" :value="item.value" />
				</el-select> 日
			</el-form-item>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button size="mini" type="primary" @click="submitHanYangTimezoneForm" class="buttonforform"> 设 置 </el-button>
			</span>
		</template>
	</el-dialog>
	<el-dialog width="700px" v-model="setHanYangMultiIntervalDialogVisible" append-to-body=true :title="dialogtitle" center draggable
		:close-on-click-modal="false" :show-close="true">
		<el-form :model="hanyangintervalformdata" class="demo-ruleForm formContent">
			<el-row :gutter="10">
				<el-col :span="6">
					<div style="text-align: right;line-height:40px;">
						<el-switch v-model="hanyangintervalformdata.interval01Enable" active-value="1"
							inactive-value="0" >
						</el-switch>  时段1</div>
				</el-col>
				<el-col :span="18">
					<el-select v-model="hanyangintervalformdata.interval01Hour" :style="formContentStyle2" :disabled="hanyangintervalformdata.interval01Enable=='0'">
						<el-option v-for="item in hours2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 时 
					<el-select v-model="hanyangintervalformdata.interval01Minute" :style="formContentStyle2" :disabled="hanyangintervalformdata.interval01Enable=='0'">
						<el-option v-for="item in minutes2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 分 | 单价 
					<el-select v-model="hanyangintervalformdata.interval01Price" :style="formContentStyle2" :disabled="hanyangintervalformdata.interval01Enable=='0'">
					<el-option v-for="item in priceSequences2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select>
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="6">
					<div style="text-align: right;line-height:40px;">
						<el-switch v-model="hanyangintervalformdata.interval02Enable" active-value="1"
							inactive-value="0" >
						</el-switch>  时段2</div>
				</el-col>
				<el-col :span="18">
					<el-select v-model="hanyangintervalformdata.interval02Hour" :style="formContentStyle2" :disabled="hanyangintervalformdata.interval02Enable=='0'">
						<el-option v-for="item in hours2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 时 
					<el-select v-model="hanyangintervalformdata.interval02Minute" :style="formContentStyle2" :disabled="hanyangintervalformdata.interval02Enable=='0'">
						<el-option v-for="item in minutes2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 分 | 单价 
					<el-select v-model="hanyangintervalformdata.interval02Price" :style="formContentStyle2" :disabled="hanyangintervalformdata.interval02Enable=='0'">
					<el-option v-for="item in priceSequences2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select>
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="6">
					<div style="text-align: right;line-height:40px;">
						<el-switch v-model="hanyangintervalformdata.interval03Enable" active-value="1"
							inactive-value="0" >
						</el-switch>  时段3</div>
				</el-col>
				<el-col :span="18">
					<el-select v-model="hanyangintervalformdata.interval03Hour" :style="formContentStyle2" :disabled="hanyangintervalformdata.interval03Enable=='0'">
						<el-option v-for="item in hours2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 时 
					<el-select v-model="hanyangintervalformdata.interval03Minute" :style="formContentStyle2" :disabled="hanyangintervalformdata.interval03Enable=='0'">
						<el-option v-for="item in minutes2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 分 | 单价 
					<el-select v-model="hanyangintervalformdata.interval03Price" :style="formContentStyle2" :disabled="hanyangintervalformdata.interval03Enable=='0'">
					<el-option v-for="item in priceSequences2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select>
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="6">
					<div style="text-align: right;line-height:40px;">
						<el-switch v-model="hanyangintervalformdata.interval04Enable" active-value="1"
							inactive-value="0" >
						</el-switch>  时段4</div>
				</el-col>
				<el-col :span="18">
					<el-select v-model="hanyangintervalformdata.interval04Hour" :style="formContentStyle2" :disabled="hanyangintervalformdata.interval04Enable=='0'">
						<el-option v-for="item in hours2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 时 
					<el-select v-model="hanyangintervalformdata.interval04Minute" :style="formContentStyle2" :disabled="hanyangintervalformdata.interval04Enable=='0'">
						<el-option v-for="item in minutes2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 分 | 单价 
					<el-select v-model="hanyangintervalformdata.interval04Price" :style="formContentStyle2" :disabled="hanyangintervalformdata.interval04Enable=='0'">
					<el-option v-for="item in priceSequences2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select>
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="6">
					<div style="text-align: right;line-height:40px;">
						<el-switch v-model="hanyangintervalformdata.interval05Enable" active-value="1"
							inactive-value="0" >
						</el-switch>  时段5</div>
				</el-col>
				<el-col :span="18">
					<el-select v-model="hanyangintervalformdata.interval05Hour" :style="formContentStyle2" :disabled="hanyangintervalformdata.interval05Enable=='0'">
						<el-option v-for="item in hours2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 时 
					<el-select v-model="hanyangintervalformdata.interval05Minute" :style="formContentStyle2" :disabled="hanyangintervalformdata.interval05Enable=='0'">
						<el-option v-for="item in minutes2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 分 | 单价 
					<el-select v-model="hanyangintervalformdata.interval05Price" :style="formContentStyle2" :disabled="hanyangintervalformdata.interval05Enable=='0'">
					<el-option v-for="item in priceSequences2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select>
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="6">
					<div style="text-align: right;line-height:40px;">
						<el-switch v-model="hanyangintervalformdata.interval06Enable" active-value="1"
							inactive-value="0" >
						</el-switch>  时段6</div>
				</el-col>
				<el-col :span="18">
					<el-select v-model="hanyangintervalformdata.interval06Hour" :style="formContentStyle2" :disabled="hanyangintervalformdata.interval06Enable=='0'">
						<el-option v-for="item in hours2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 时 
					<el-select v-model="hanyangintervalformdata.interval06Minute" :style="formContentStyle2" :disabled="hanyangintervalformdata.interval06Enable=='0'">
						<el-option v-for="item in minutes2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 分 | 单价 
					<el-select v-model="hanyangintervalformdata.interval06Price" :style="formContentStyle2" :disabled="hanyangintervalformdata.interval06Enable=='0'">
					<el-option v-for="item in priceSequences2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select>
				</el-col>
			</el-row>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button size="mini" type="primary" @click="submitHanYangIntervalForm" class="buttonforform"> 设 置 </el-button>
			</span>
		</template>
	</el-dialog>
	<el-dialog width="500px" v-model="setHanYangMultiPriceDialogVisible" append-to-body=true :title="dialogtitle" center draggable
		:close-on-click-modal="false" :show-close="true">
		<el-form :model="hanyangpriceformdata" class="demo-ruleForm formContent">
			<el-form-item label="单价（尖）" :label-width="formLabelWidth">
				<el-input v-model="hanyangpriceformdata.price2" autocomplete="off" :style="formContentStyle"  type="number" 
					placeholder="请输入值" >
					<template #append>元</template>
				</el-input>
			</el-form-item>
			<el-form-item label="单价（峰）" :label-width="formLabelWidth">
				<el-input v-model="hanyangpriceformdata.price3" autocomplete="off" :style="formContentStyle"  type="number" 
					placeholder="请输入值" >
					<template #append>元</template>
				</el-input>
			</el-form-item>
			<el-form-item label="单价（平）" :label-width="formLabelWidth">
				<el-input v-model="hanyangpriceformdata.price4" autocomplete="off" :style="formContentStyle"  type="number" 
					placeholder="请输入值" >
					<template #append>元</template>
				</el-input>
			</el-form-item>
			<el-form-item label="单价（谷）" :label-width="formLabelWidth">
				<el-input v-model="hanyangpriceformdata.price5" autocomplete="off" :style="formContentStyle"  type="number" 
					placeholder="请输入值" >
					<template #append>元</template>
				</el-input>
			</el-form-item>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button size="mini" type="primary" @click="submitHanYangPriceForm" class="buttonforform"> 设 置 </el-button>
			</span>
		</template>
	</el-dialog>
	<el-dialog width="500px" v-model="dialogVisible" append-to-body=true :title="dialogtitle" center draggable
		:close-on-click-modal="false" :show-close="true">
		<el-form :model="formdata" class="demo-ruleForm formContent">
			<el-form-item label="请选择设置类型" :label-width="formLabelWidth">
				<el-select v-model="formdata.directiveType" placeholder="请选择协议类型" :style="formContentStyle" @change="handleSingleChange">
					<el-option v-for="item in directiveTypes" :key="item.value" :label="item.label"
						:value="item.value" />
				</el-select>
			</el-form-item>
			<el-form-item label="设置值" :label-width="formLabelWidth">
				<el-input v-model="formdata.directiveValue" autocomplete="off" :style="formContentStyle"
					placeholder="请输入设置值" >
					<template #append v-if="unitName!=''">{{unitName}}</template>
				</el-input>
			</el-form-item>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button size="mini" type="primary" @click="submitForm" class="buttonforform"> 设 置 </el-button>
			</span>
		</template>
	</el-dialog>
	<el-dialog width="500px" v-model="setTimerDialogVisible" append-to-body=true title="设置定时断送电时间" center draggable
		:close-on-click-modal="false" :show-close="true">
		<el-form :model="settimerformdata" ref="setTimerFormRef" class="demo-ruleForm formContent">
			<el-form-item label="请选择定时时段" :label-width="formLabelWidth">
				<el-select v-model="settimerformdata.timerName" placeholder="请选择定时时段" :style="formContentStyle">
					<el-option v-for="item in timerNames" :key="item.value" :label="item.label"
						:value="item.value" />
				</el-select>
			</el-form-item>
			<el-form-item label="请选择星期" :label-width="formLabelWidth">
				<el-select v-model="settimerformdata.week" placeholder="请选择星期" :style="formContentStyle">
					<el-option v-for="item in weeks" :key="item.value" :label="item.label"
						:value="item.value" />
				</el-select>
			</el-form-item>
			<el-form-item label="请选择小时" :label-width="formLabelWidth">
				<el-select v-model="settimerformdata.hour" placeholder="请选择小时" :style="formContentStyle">
					<el-option v-for="item in hours" :key="item.value" :label="item.label"
						:value="item.value" />
				</el-select>
			</el-form-item>
			<el-form-item label="请选择分钟" :label-width="formLabelWidth">
				<el-select v-model="settimerformdata.minute" placeholder="请选择分钟" :style="formContentStyle">
					<el-option v-for="item in minutes" :key="item.value" :label="item.label"
						:value="item.value" />
				</el-select>
			</el-form-item>
			<el-form-item label="请选择动作" :label-width="formLabelWidth">
				<el-radio-group v-model="settimerformdata.action">
				    <el-radio label="1">通电</el-radio>
				    <el-radio label="2">断电</el-radio>
				  </el-radio-group>
			</el-form-item>
		</el-form>	
		<template #footer>
			<span class="dialog-footer">
				<el-space wrap :size="30">
					<el-button size="mini" type="primary" @click="submitSetTimerForm(setTimerFormRef)" class="buttonforform"> 设 置 
					</el-button>
				</el-space>
			</span>
		</template>
	</el-dialog>
	<el-dialog width="1500px"  v-model="dialogVisibleMultiPrice" append-to-body=true title="设置复费率参数" center draggable
		:close-on-click-modal="false" :show-close="true">
		<el-form :model="formdataMultiPrice" ref="formRefMultiPrice" class="demo-ruleForm formContent">
			<el-row :gutter="10">
				<el-col :span="12">
					<div style="text-align: center;line-height:40px;">费 率 一</div>
				</el-col>
				<el-col :span="12">
					<div style="text-align: center;line-height:40px;">费 率 二</div>
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="4">
					<div style="text-align: right;line-height:40px;">开始时间1</div>
				</el-col>
				<el-col :span="8">
					<el-select v-model="formdataMultiPrice.month1" class="m-2" size="small" style="width: 100px;">
						<el-option v-for="item in months" :key="item.value" :label="item.label" :value="item.value" />
					</el-select> 月 
					<el-select v-model="formdataMultiPrice.day1" class="m-2" size="small" style="width: 100px;">
						<el-option v-for="item in days" :key="item.value" :label="item.label" :value="item.value" />
					</el-select> 日 
				</el-col>
				<el-col :span="4">
					<div style="text-align: right;line-height:40px;">开始时间2</div>
				</el-col>
				<el-col :span="8">
					<el-select v-model="formdataMultiPrice.month2" class="m-2" size="small" style="width: 100px;">
						<el-option v-for="item in months" :key="item.value" :label="item.label" :value="item.value" />
					</el-select> 月 
					<el-select v-model="formdataMultiPrice.day2" class="m-2" size="small" style="width: 100px;">
						<el-option v-for="item in days" :key="item.value" :label="item.label" :value="item.value" />
					</el-select> 日 
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="4">
					<div style="text-align: right;line-height:40px;">单价（尖）</div>
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataMultiPrice.price11" autocomplete="off"  type="number" style="width: 240px;" >
						<template #append>元</template>
					</el-input>
				</el-col>
				<el-col :span="4">
					<div style="text-align: right;line-height:40px;">单价（尖）</div>
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataMultiPrice.price21" autocomplete="off"  type="number" style="width: 240px;" >
						<template #append>元</template>
					</el-input>
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="4">
					<div style="text-align: right;line-height:40px;">单价（峰）</div>
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataMultiPrice.price12" autocomplete="off"  type="number" style="width: 240px;" >
						<template #append>元</template>
					</el-input>
				</el-col>
				<el-col :span="4">
					<div style="text-align: right;line-height:40px;">单价（峰）</div>
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataMultiPrice.price22" autocomplete="off"  type="number" style="width: 240px;" >
						<template #append>元</template>
					</el-input>
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="4">
					<div style="text-align: right;line-height:40px;">单价（平）</div>
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataMultiPrice.price13" autocomplete="off"  type="number" style="width: 240px;" >
						<template #append>元</template>
					</el-input>
				</el-col>
				<el-col :span="4">
					<div style="text-align: right;line-height:40px;">单价（平）</div>
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataMultiPrice.price23" autocomplete="off"  type="number" style="width: 240px;" >
						<template #append>元</template>
					</el-input>
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="4">
					<div style="text-align: right;line-height:40px;">单价（谷）</div>
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataMultiPrice.price14" autocomplete="off"  type="number" style="width: 240px;" >
						<template #append>元</template>
					</el-input>
				</el-col>
				<el-col :span="4">
					<div style="text-align: right;line-height:40px;">单价（谷）</div>
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataMultiPrice.price24" autocomplete="off"  type="number" style="width: 240px;" >
						<template #append>元</template>
					</el-input>
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="4">
					<div style="text-align: right;line-height:40px;">
						<el-switch v-model="formdataMultiPrice.interval11enable" active-value="1"
							inactive-value="0" >
						</el-switch>  时段1</div>
				</el-col>
				<el-col :span="8">
					<el-select v-model="formdataMultiPrice.interval1hour1" :style="formContentStyle2" :disabled="formdataMultiPrice.interval11enable=='0'">
						<el-option v-for="item in hours2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 时 
					<el-select v-model="formdataMultiPrice.interval1minute1" :style="formContentStyle2" :disabled="formdataMultiPrice.interval11enable=='0'">
						<el-option v-for="item in minutes2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 分 | 单价 
					<el-select v-model="formdataMultiPrice.interval1price1" :style="formContentStyle2" :disabled="formdataMultiPrice.interval11enable=='0'">
					<el-option v-for="item in priceSequences" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select>
				</el-col>
				<el-col :span="4">
					<div style="text-align: right;line-height:40px;">
						<el-switch v-model="formdataMultiPrice.interval21enable" active-value="1"
							inactive-value="0" >
						</el-switch>  时段1</div>
				</el-col>
				<el-col :span="8">
					<el-select v-model="formdataMultiPrice.interval2hour1" :style="formContentStyle2" :disabled="formdataMultiPrice.interval21enable=='0'">
						<el-option v-for="item in hours2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 时 
					<el-select v-model="formdataMultiPrice.interval2minute1" :style="formContentStyle2" :disabled="formdataMultiPrice.interval21enable=='0'">
						<el-option v-for="item in minutes2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 分 | 单价 
					<el-select v-model="formdataMultiPrice.interval2price1" :style="formContentStyle2" :disabled="formdataMultiPrice.interval21enable=='0'">
					<el-option v-for="item in priceSequences" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select>
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="4">
					<div style="text-align: right;line-height:40px;">
						<el-switch v-model="formdataMultiPrice.interval12enable" active-value="1"
							inactive-value="0" >
						</el-switch>  时段2</div>
				</el-col>
				<el-col :span="8">
					<el-select v-model="formdataMultiPrice.interval1hour2" :style="formContentStyle2" :disabled="formdataMultiPrice.interval12enable=='0'">
						<el-option v-for="item in hours2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 时 
					<el-select v-model="formdataMultiPrice.interval1minute2" :style="formContentStyle2" :disabled="formdataMultiPrice.interval12enable=='0'">
						<el-option v-for="item in minutes2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 分 | 单价 
					<el-select v-model="formdataMultiPrice.interval1price2" :style="formContentStyle2" :disabled="formdataMultiPrice.interval12enable=='0'">
					<el-option v-for="item in priceSequences" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select>
				</el-col>
				<el-col :span="4">
					<div style="text-align: right;line-height:40px;">
						<el-switch v-model="formdataMultiPrice.interval22enable" active-value="1"
							inactive-value="0" >
						</el-switch>  时段2</div>
				</el-col>
				<el-col :span="8">
					<el-select v-model="formdataMultiPrice.interval2hour2" :style="formContentStyle2" :disabled="formdataMultiPrice.interval22enable=='0'">
						<el-option v-for="item in hours2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 时 
					<el-select v-model="formdataMultiPrice.interval2minute2" :style="formContentStyle2" :disabled="formdataMultiPrice.interval22enable=='0'">
						<el-option v-for="item in minutes2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 分 | 单价 
					<el-select v-model="formdataMultiPrice.interval2price2" :style="formContentStyle2" :disabled="formdataMultiPrice.interval22enable=='0'">
					<el-option v-for="item in priceSequences" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select>
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="4">
					<div style="text-align: right;line-height:40px;">
						<el-switch v-model="formdataMultiPrice.interval13enable" active-value="1"
							inactive-value="0" >
						</el-switch>  时段3</div>
				</el-col>
				<el-col :span="8">
					<el-select v-model="formdataMultiPrice.interval1hour3" :style="formContentStyle2" :disabled="formdataMultiPrice.interval13enable=='0'">
						<el-option v-for="item in hours2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 时 
					<el-select v-model="formdataMultiPrice.interval1minute3" :style="formContentStyle2" :disabled="formdataMultiPrice.interval13enable=='0'">
						<el-option v-for="item in minutes2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 分 | 单价 
					<el-select v-model="formdataMultiPrice.interval1price3" :style="formContentStyle2" :disabled="formdataMultiPrice.interval13enable=='0'">
					<el-option v-for="item in priceSequences" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select>
				</el-col>
				<el-col :span="4">
					<div style="text-align: right;line-height:40px;">
						<el-switch v-model="formdataMultiPrice.interval23enable" active-value="1"
							inactive-value="0" >
						</el-switch>  时段3</div>
				</el-col>
				<el-col :span="8">
					<el-select v-model="formdataMultiPrice.interval2hour3" :style="formContentStyle2" :disabled="formdataMultiPrice.interval23enable=='0'">
						<el-option v-for="item in hours2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 时 
					<el-select v-model="formdataMultiPrice.interval2minute3" :style="formContentStyle2" :disabled="formdataMultiPrice.interval23enable=='0'">
						<el-option v-for="item in minutes2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 分 | 单价 
					<el-select v-model="formdataMultiPrice.interval2price3" :style="formContentStyle2" :disabled="formdataMultiPrice.interval23enable=='0'">
					<el-option v-for="item in priceSequences" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select>
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="4" >
					<div style="text-align: right;line-height:40px;">
						<el-switch v-model="formdataMultiPrice.interval14enable" active-value="1"
							inactive-value="0" >
						</el-switch>  时段4</div>
				</el-col>
				<el-col :span="8">
					<el-select v-model="formdataMultiPrice.interval1hour4" :style="formContentStyle2" :disabled="formdataMultiPrice.interval14enable=='0'">
						<el-option v-for="item in hours2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 时 
					<el-select v-model="formdataMultiPrice.interval1minute4" :style="formContentStyle2" :disabled="formdataMultiPrice.interval14enable=='0'">
						<el-option v-for="item in minutes2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 分 | 单价 
					<el-select v-model="formdataMultiPrice.interval1price4" :style="formContentStyle2" :disabled="formdataMultiPrice.interval14enable=='0'">
					<el-option v-for="item in priceSequences" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select>
				</el-col>
				<el-col :span="4">
					<div style="text-align: right; line-height:40px;">
						<el-switch v-model="formdataMultiPrice.interval24enable" active-value="1"
							inactive-value="0" >
						</el-switch>  时段4</div>
				</el-col>
				<el-col :span="8">
					<el-select v-model="formdataMultiPrice.interval2hour4" :style="formContentStyle2" :disabled="formdataMultiPrice.interval24enable=='0'">
						<el-option v-for="item in hours2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 时 
					<el-select v-model="formdataMultiPrice.interval2minute4" :style="formContentStyle2" :disabled="formdataMultiPrice.interval24enable=='0'">
						<el-option v-for="item in minutes2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 分 | 单价 
					<el-select v-model="formdataMultiPrice.interval2price4" :style="formContentStyle2" :disabled="formdataMultiPrice.interval24enable=='0'">
					<el-option v-for="item in priceSequences" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select>
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="4" >
					<div style="text-align: right;line-height:40px;">
						<el-switch v-model="formdataMultiPrice.interval15enable" active-value="1"
							inactive-value="0" >
						</el-switch>  时段5</div>
				</el-col>
				<el-col :span="8">
					<el-select v-model="formdataMultiPrice.interval1hour5" :style="formContentStyle2" :disabled="formdataMultiPrice.interval15enable=='0'">
						<el-option v-for="item in hours2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 时 
					<el-select v-model="formdataMultiPrice.interval1minute5" :style="formContentStyle2" :disabled="formdataMultiPrice.interval15enable=='0'">
						<el-option v-for="item in minutes2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 分 | 单价 
					<el-select v-model="formdataMultiPrice.interval1price5" :style="formContentStyle2" :disabled="formdataMultiPrice.interval15enable=='0'">
					<el-option v-for="item in priceSequences" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select>
				</el-col>
				<el-col :span="4">
					<div style="text-align: right; line-height:40px;">
						<el-switch v-model="formdataMultiPrice.interval25enable" active-value="1"
							inactive-value="0" >
						</el-switch>  时段5</div>
				</el-col>
				<el-col :span="8">
					<el-select v-model="formdataMultiPrice.interval2hour5" :style="formContentStyle2" :disabled="formdataMultiPrice.interval25enable=='0'">
						<el-option v-for="item in hours2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 时 
					<el-select v-model="formdataMultiPrice.interval2minute5" :style="formContentStyle2" :disabled="formdataMultiPrice.interval25enable=='0'">
						<el-option v-for="item in minutes2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 分 | 单价 
					<el-select v-model="formdataMultiPrice.interval2price5" :style="formContentStyle2" :disabled="formdataMultiPrice.interval25enable=='0'">
					<el-option v-for="item in priceSequences" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select>
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="4" >
					<div style="text-align: right;line-height:40px;">
						<el-switch v-model="formdataMultiPrice.interval16enable" active-value="1"
							inactive-value="0" >
						</el-switch>  时段6</div>
				</el-col>
				<el-col :span="8">
					<el-select v-model="formdataMultiPrice.interval1hour6" :style="formContentStyle2" :disabled="formdataMultiPrice.interval16enable=='0'">
						<el-option v-for="item in hours2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 时 
					<el-select v-model="formdataMultiPrice.interval1minute6" :style="formContentStyle2" :disabled="formdataMultiPrice.interval16enable=='0'">
						<el-option v-for="item in minutes2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 分 | 单价 
					<el-select v-model="formdataMultiPrice.interval1price6" :style="formContentStyle2" :disabled="formdataMultiPrice.interval16enable=='0'">
					<el-option v-for="item in priceSequences" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select>
				</el-col>
				<el-col :span="4">
					<div style="text-align: right; line-height:40px;">
						<el-switch v-model="formdataMultiPrice.interval26enable" active-value="1"
							inactive-value="0" >
						</el-switch>  时段6</div>
				</el-col>
				<el-col :span="8">
					<el-select v-model="formdataMultiPrice.interval2hour6" :style="formContentStyle2" :disabled="formdataMultiPrice.interval26enable=='0'">
						<el-option v-for="item in hours2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 时 
					<el-select v-model="formdataMultiPrice.interval2minute6" :style="formContentStyle2" :disabled="formdataMultiPrice.interval26enable=='0'">
						<el-option v-for="item in minutes2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 分 | 单价 
					<el-select v-model="formdataMultiPrice.interval2price6" :style="formContentStyle2" :disabled="formdataMultiPrice.interval26enable=='0'">
					<el-option v-for="item in priceSequences" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select>
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="4" >
					<div style="text-align: right;line-height:40px;">
						<el-switch v-model="formdataMultiPrice.interval17enable" active-value="1"
							inactive-value="0" >
						</el-switch>  时段7</div>
				</el-col>
				<el-col :span="8">
					<el-select v-model="formdataMultiPrice.interval1hour7" :style="formContentStyle2" :disabled="formdataMultiPrice.interval17enable=='0'">
						<el-option v-for="item in hours2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 时 
					<el-select v-model="formdataMultiPrice.interval1minute7" :style="formContentStyle2" :disabled="formdataMultiPrice.interval17enable=='0'">
						<el-option v-for="item in minutes2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 分 | 单价 
					<el-select v-model="formdataMultiPrice.interval1price7" :style="formContentStyle2" :disabled="formdataMultiPrice.interval17enable=='0'">
					<el-option v-for="item in priceSequences" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select>
				</el-col>
				<el-col :span="4">
					<div style="text-align: right; line-height:40px;">
						<el-switch v-model="formdataMultiPrice.interval27enable" active-value="1"
							inactive-value="0" >
						</el-switch>  时段7</div>
				</el-col>
				<el-col :span="8">
					<el-select v-model="formdataMultiPrice.interval2hour7" :style="formContentStyle2" :disabled="formdataMultiPrice.interval27enable=='0'">
						<el-option v-for="item in hours2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 时 
					<el-select v-model="formdataMultiPrice.interval2minute7" :style="formContentStyle2" :disabled="formdataMultiPrice.interval27enable=='0'">
						<el-option v-for="item in minutes2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 分 | 单价 
					<el-select v-model="formdataMultiPrice.interval2price7" :style="formContentStyle2" :disabled="formdataMultiPrice.interval27enable=='0'">
					<el-option v-for="item in priceSequences" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select>
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="4" >
					<div style="text-align: right;line-height:40px;">
						<el-switch v-model="formdataMultiPrice.interval18enable" active-value="1"
							inactive-value="0" >
						</el-switch>  时段8</div>
				</el-col>
				<el-col :span="8">
					<el-select v-model="formdataMultiPrice.interval1hour8" :style="formContentStyle2" :disabled="formdataMultiPrice.interval18enable=='0'">
						<el-option v-for="item in hours2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 时 
					<el-select v-model="formdataMultiPrice.interval1minute8" :style="formContentStyle2" :disabled="formdataMultiPrice.interval18enable=='0'">
						<el-option v-for="item in minutes2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 分 | 单价 
					<el-select v-model="formdataMultiPrice.interval1price8" :style="formContentStyle2" :disabled="formdataMultiPrice.interval18enable=='0'">
					<el-option v-for="item in priceSequences" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select>
				</el-col>
				<el-col :span="4">
					<div style="text-align: right; line-height:40px;">
						<el-switch v-model="formdataMultiPrice.interval28enable" active-value="1"
							inactive-value="0" >
						</el-switch>  时段8</div>
				</el-col>
				<el-col :span="8">
					<el-select v-model="formdataMultiPrice.interval2hour8" :style="formContentStyle2" :disabled="formdataMultiPrice.interval28enable=='0'">
						<el-option v-for="item in hours2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 时 
					<el-select v-model="formdataMultiPrice.interval2minute8" :style="formContentStyle2" :disabled="formdataMultiPrice.interval28enable=='0'">
						<el-option v-for="item in minutes2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 分 | 单价 
					<el-select v-model="formdataMultiPrice.interval2price8" :style="formContentStyle2" :disabled="formdataMultiPrice.interval28enable=='0'">
					<el-option v-for="item in priceSequences" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select>
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="4" >
					<div style="text-align: right;line-height:40px;">
						<el-switch v-model="formdataMultiPrice.interval19enable" active-value="1"
							inactive-value="0" >
						</el-switch>  时段9</div>
				</el-col>
				<el-col :span="8">
					<el-select v-model="formdataMultiPrice.interval1hour9" :style="formContentStyle2" :disabled="formdataMultiPrice.interval19enable=='0'">
						<el-option v-for="item in hours2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 时 
					<el-select v-model="formdataMultiPrice.interval1minute9" :style="formContentStyle2" :disabled="formdataMultiPrice.interval19enable=='0'">
						<el-option v-for="item in minutes2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 分 | 单价 
					<el-select v-model="formdataMultiPrice.interval1price9" :style="formContentStyle2" :disabled="formdataMultiPrice.interval19enable=='0'">
					<el-option v-for="item in priceSequences" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select>
				</el-col>
				<el-col :span="4">
					<div style="text-align: right; line-height:40px;">
						<el-switch v-model="formdataMultiPrice.interval29enable" active-value="1"
							inactive-value="0" >
						</el-switch>  时段9</div>
				</el-col>
				<el-col :span="8">
					<el-select v-model="formdataMultiPrice.interval2hour9" :style="formContentStyle2" :disabled="formdataMultiPrice.interval29enable=='0'">
						<el-option v-for="item in hours2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 时 
					<el-select v-model="formdataMultiPrice.interval2minute9" :style="formContentStyle2" :disabled="formdataMultiPrice.interval29enable=='0'">
						<el-option v-for="item in minutes2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 分 | 单价 
					<el-select v-model="formdataMultiPrice.interval2price9" :style="formContentStyle2" :disabled="formdataMultiPrice.interval29enable=='0'">
					<el-option v-for="item in priceSequences" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select>
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="4" >
					<div style="text-align: right;line-height:40px;">
						<el-switch v-model="formdataMultiPrice.interval110enable" active-value="1"
							inactive-value="0" >
						</el-switch>  时段10</div>
				</el-col>
				<el-col :span="8">
					<el-select v-model="formdataMultiPrice.interval1hour10" :style="formContentStyle2" :disabled="formdataMultiPrice.interval110enable=='0'">
						<el-option v-for="item in hours2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 时 
					<el-select v-model="formdataMultiPrice.interval1minute10" :style="formContentStyle2" :disabled="formdataMultiPrice.interval110enable=='0'">
						<el-option v-for="item in minutes2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 分 | 单价 
					<el-select v-model="formdataMultiPrice.interval1price10" :style="formContentStyle2" :disabled="formdataMultiPrice.interval110enable=='0'">
					<el-option v-for="item in priceSequences" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select>
				</el-col>
				<el-col :span="4">
					<div style="text-align: right; line-height:40px;">
						<el-switch v-model="formdataMultiPrice.interval210enable" active-value="1"
							inactive-value="0" >
						</el-switch>  时段10</div>
				</el-col>
				<el-col :span="8">
					<el-select v-model="formdataMultiPrice.interval2hour10" :style="formContentStyle2" :disabled="formdataMultiPrice.interval210enable=='0'">
						<el-option v-for="item in hours2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 时 
					<el-select v-model="formdataMultiPrice.interval2minute10" :style="formContentStyle2" :disabled="formdataMultiPrice.interval210enable=='0'">
						<el-option v-for="item in minutes2" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select> 分 | 单价 
					<el-select v-model="formdataMultiPrice.interval2price10" :style="formContentStyle2" :disabled="formdataMultiPrice.interval210enable=='0'">
					<el-option v-for="item in priceSequences" :key="item.value" :label="item.label"
							:value="item.value" />
					</el-select>
				</el-col>
			</el-row>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button size="mini" type="primary" @click="submitMultiPrice(formRefMultiPrice)" class="buttonforform"> 设 置 </el-button>
			</span>
		</template>
	</el-dialog>
	<el-dialog width="510px" v-model="dialogVisibleMalignantLoad" append-to-body=true title="设置负载增量参数" center draggable
		:close-on-click-modal="false" :show-close="true">
		<el-form :model="formdataMalignantLoad" ref="formRefMalignantLoad" class="demo-ruleForm formContent">
			<el-row :gutter="10">
				<el-col :span="8">
					
				</el-col>
				<el-col :span="8">
					下 限
				</el-col>
				<el-col :span="8">
					上 限
				</el-col>
			</el-row>
			<el-row :gutter="10" align="middle">
				<el-col :span="8">
					允许范围1（W）
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataMalignantLoad.rangeLow1" autocomplete="off"  placeholder="下限" type="number" />
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataMalignantLoad.rangeHigh1" autocomplete="off"  placeholder="上限" type="number" />
				</el-col>
			</el-row>
			
			<el-row :gutter="10" align="middle">
				<el-col :span="8">
					限定范围1（W）
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataMalignantLoad.limitLow1" autocomplete="off"  placeholder="下限" type="number" />
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataMalignantLoad.limitHigh1" autocomplete="off"  placeholder="上限" type="number" />
				</el-col>
			</el-row>
			
			<el-row :gutter="10" align="middle">
				<el-col :span="8">
					限定参数1
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataMalignantLoad.limitParamLow1" autocomplete="off"  placeholder="下限" type="number" />
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataMalignantLoad.limitParamHigh1" autocomplete="off"  placeholder="上限" type="number" />
				</el-col>
			</el-row>
			<el-row :gutter="10" align="middle">
				<el-col :span="8">
					允许范围2（W）
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataMalignantLoad.rangeLow2" autocomplete="off"  placeholder="下限" type="number" />
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataMalignantLoad.rangeHigh2" autocomplete="off"  placeholder="上限" type="number" />
				</el-col>
			</el-row>
			
			<el-row :gutter="10" align="middle">
				<el-col :span="8">
					限定范围2（W）
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataMalignantLoad.limitLow2" autocomplete="off"  placeholder="下限" type="number" />
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataMalignantLoad.limitHigh2" autocomplete="off"  placeholder="上限" type="number" />
				</el-col>
			</el-row>
			
			<el-row :gutter="10" align="middle">
				<el-col :span="8">
					限定参数2
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataMalignantLoad.limitParamLow2" autocomplete="off"  placeholder="下限" type="number" />
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataMalignantLoad.limitParamHigh2" autocomplete="off"  placeholder="上限" type="number" />
				</el-col>
			</el-row>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button size="mini" type="primary" @click="submitFormMalignantLoad(formRefMalignantLoad)" class="buttonforform"> 设 置 </el-button>
			</span>
		</template>
	</el-dialog>
	
	<el-dialog width="500px" v-model="setOnOffDialogVisible" append-to-body=true title="断送电" center draggable
		:close-on-click-modal="false" :show-close="true">
		<el-form :model="setonoffformdata" ref="setOnOffFormRef" class="demo-ruleForm formContent">
			<!-- <el-form-item label="请输入线号" :label-width="formLabelWidth">
				<el-input v-model="setonoffformdata.lineNum" autocomplete="off" :style="formContentStyle"
					placeholder="请输入线号,多个用-隔开" />
			</el-form-item> -->
			<el-form-item label="请选择动作" :label-width="formLabelWidth">
				<el-radio-group v-model="setonoffformdata.action">
				    <el-radio label="1">通电</el-radio>
				    <el-radio label="2">断电</el-radio>
				  </el-radio-group>
			</el-form-item>
		</el-form>	
		<template #footer>
			<span class="dialog-footer">
				<el-space wrap :size="30">
					<el-button size="mini" type="primary" @click="submitSetOnOffForm(setOnOffFormRef)" class="buttonforform"> 设 置 
					</el-button>
				</el-space>
			</span>
		</template>
	</el-dialog>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted,
		onActivated,
		getCurrentInstance
	} from 'vue'
	import {
		ElMessageBox
	} from 'element-plus'
	import {
		GetServerData
	} from '@/http/index.js'
	// 获取全局this，proxy相当于vue2中的this，用于调用全局对象使用
	const {
		proxy
	} = getCurrentInstance()
	const treeAreaRef = ref()
	const treeProps = reactive({
		children: 'children',
		label: 'name'
	})
	// 定义表单项的头部宽度
	const formLabelWidth = ref('150px')
	// 定义表单项的内容宽度
	const formContentStyle = ref('width:300px')
	const formContentStyle2 = ref('width:100px')
	let unitName=ref('')
	let setOnOffDialogVisible= ref(false)
	let settimerformdata=reactive({
		timerName:'',
		week:'FF',
		hour:'FF',
		minute:'FF',
		action:'1',
		ammeterId:''
	})
	let hours=reactive([])
	let hours2=reactive([])
	
	let days=reactive([])
	let days2=reactive([])
	let priceSequences=reactive([
		{
			label: '尖',
			value: '1'
		},{
			label: '峰',
			value: '2'
		},{
			label: '平',
			value: '3'
		},{
			label: '谷',
			value: '4'
		}])
	let priceSequences2=reactive([
		{
			label: '尖',
			value: '2'
		},{
			label: '峰',
			value: '3'
		},{
			label: '平',
			value: '4'
		},{
			label: '谷',
			value: '5'
		}])
	let months=ref([
		{
			label:'无效',
			value:'FF'
		},
		{
			label:'01',
			value:'01'
		},{
			label:'02',
			value:'02'
		},{
			label:'03',
			value:'03'
		},{
			label:'04',
			value:'04'
		},{
			label:'05',
			value:'05'
		},{
			label:'06',
			value:'06'
		},{
			label:'07',
			value:'07'
		},{
			label:'08',
			value:'08'
		},{
			label:'09',
			value:'09'
		},{
			label:'10',
			value:'10'
		},{
			label:'11',
			value:'11'
		},{
			label:'12',
			value:'12'
		}
	])
	
	let months2=ref([
		{
			label:'01',
			value:'01'
		},{
			label:'02',
			value:'02'
		},{
			label:'03',
			value:'03'
		},{
			label:'04',
			value:'04'
		},{
			label:'05',
			value:'05'
		},{
			label:'06',
			value:'06'
		},{
			label:'07',
			value:'07'
		},{
			label:'08',
			value:'08'
		},{
			label:'09',
			value:'09'
		},{
			label:'10',
			value:'10'
		},{
			label:'11',
			value:'11'
		},{
			label:'12',
			value:'12'
		}
	])
	const weeks=reactive([
		{
			label: '无效',
			value: 'FF'
		},
		{
			label: '星期一',
			value: '01'
		},{
			label: '星期二',
			value: '02'
		},{
			label: '星期三',
			value: '03'
		},{
			label: '星期四',
			value: '04'
		},{
			label: '星期五',
			value: '05'
		},{
			label: '星期六',
			value: '06'
		},{
			label: '星期七',
			value: '00'
		}
	])
	
	const minutes=reactive([
		{
			label: '无效',
			value: 'FF'
		},
		{
			label: '00',
			value: '00'
		},{
			label: '10',
			value: '10'
		},{
			label: '20',
			value: '20'
		},{
			label: '30',
			value: '30'
		},{
			label: '40',
			value: '40'
		},{
			label: '50',
			value: '50'
		}
	])
	
	const minutes2=reactive([
		
		{
			label: '0',
			value: '0'
		},{
			label: '10',
			value: '10'
		},{
			label: '20',
			value: '20'
		},{
			label: '30',
			value: '30'
		},{
			label: '40',
			value: '40'
		},{
			label: '50',
			value: '50'
		}
	])
	
	const timerNames = reactive([
		{
			label: '定时一',
			value: '1'
		},{
			label: '定时二',
			value: '2'
		},{
			label: '定时三',
			value: '3'
		},{
			label: '定时四',
			value: '4'
		},{
			label: '定时五',
			value: '5'
		},{
			label: '定时六',
			value: '6'
		},{
			label: '定时七',
			value: '7'
		},{
			label: '定时八',
			value: '8'
		},{
			label: '定时九',
			value: '9'
		},{
			label: '定时十',
			value: '10'
		},{
			label: '定时十一',
			value: '11'
		},{
			label: '定时十二',
			value: '12'
		}
	])
	const setOnOffFormRef = ref()
	const setOnOff = () => {
		if (multipleSelection.length > 0) {
			setOnOffDialogVisible.value=true;
		} else {
			proxy.$tools.ShowMessage('请选择需要设置电表', false)
		}
	}
	let setonoffformdata=reactive({
		lineNum:'153',
		action:'1',
		ammeterId:''
	})
	const directiveTypes = reactive([
		{
			label: '预置电量',
			value: '41'
		},
		{
			label: '预置金额',
			value: '42'
		},
		{
			label: '设透支电量',
			value: '44'
		},
		{
			label: '设透支金额',
			value: '45'
		},
		{
			label: '设报警电量',
			value: '46'
		},
		{
			label: '设报警金额',
			value: '47'
		},
		{
			label: '设置限定功率',
			value: '49'
		}
	])
	let dialogtitle = ref("请选择要执行的设置")
	// 定义表单显示与隐藏
	let dialogVisible = ref(false)
	let setTimerDialogVisible= ref(false)
	let dialogVisibleMalignantLoad = ref(false)
	let dialogVisibleMultiPrice = ref(false)
	let setMultiMeterDialogVisible  = ref(false)
	let setHanYangMeterDialogVisible = ref(false)
	let setHanYangMultiPriceDialogVisible = ref(false)
	let setHanYangMultiIntervalDialogVisible = ref(false)
	let setHanYangMultiTimezoneDialogVisible = ref(false)
	
	// 定义查询参数
	let params = reactive({
		keyword: ''
	})
	let formdata = reactive({
		directiveType: '',
		directiveValue: ''
	})
	let hanyangpriceformdata =reactive({
		ammeterId: '',
		priceNo:'1',
		price1: 0,
		price2: 0,
		price3: 0,
		price4: 0,
		price5: 0,
		price6: 0
	})
	
	let hanyangintervalformdata =reactive({
		ammeterId: '',
		intervalNo:'1',
		interval01Enable:'1',
		interval02Enable:'1',
		interval03Enable:'1',
		interval04Enable:'1',
		interval05Enable:'1',
		interval06Enable:'1',
		interval07Enable:'0',
		interval08Enable:'0',
		
		interval01Hour:'0',
		interval01Minute:'0',
		interval01Price:'2',
		
		interval02Hour:'0',
		interval02Minute:'0',
		interval02Price:'3',
		
		interval03Hour:'0',
		interval03Minute:'0',
		interval03Price:'4',
		
		interval04Hour:'0',
		interval04Minute:'0',
		interval04Price:'5',
		
		interval05Hour:'0',
		interval05Minute:'0',
		interval05Price:'2',
		
		interval06Hour:'0',
		interval06Minute:'0',
		interval06Price:'3',
		
		interval07Hour:'0',
		interval07Minute:'0',
		interval07Price:'0',
		
		interval08Hour:'0',
		interval08Minute:'0',
		interval08Price:'0'
	})
	
	let hanyangtimezoneformdata =reactive({
		ammeterId: '',
		timezoneNo:'1',
		timezone01Month:'1',
		timezone01Day:'1',
		timezone01Interval:'1'
	})
	
	let formdataMalignantLoad= reactive({
		rangeHigh1:'150',
		rangeLow1:'100',
		limitHigh1:'300',
		limitLow1:'200',
		limitParamHigh1:'0',
		limitParamLow1:'0',
		rangeHigh2:'150',
		rangeLow2:'100',
		limitHigh2:'300',
		limitLow2:'200',
		limitParamHigh2:'0',
		limitParamLow2:'0'
	})
	
	let formdataMultiPrice= reactive({
		ammeterId:'',
		month1:'FF',
		day1:'FF',
		price11:0,
		price12:0,
		price13:0,
		price14:0,
		price15:0,
		interval11enable:'1',
		interval12enable:'1',
		interval13enable:'1',
		interval14enable:'1',
		interval15enable:'0',
		interval16enable:'0',
		interval17enable:'0',
		interval18enable:'0',
		interval19enable:'0',
		interval110enable:'0',
		
		interval1hour1:'0',
		interval1minute1:'0',
		interval1price1:'1',
		
		
		interval1hour2:'0',
		interval1minute2:'0',
		interval1price2:'2',
		
		interval1hour3:'0',
		interval1minute3:'0',
		interval1price3:'3',
		
		interval1hour4:'0',
		interval1minute4:'0',
		interval1price4:'4',
		
		interval1hour5:'0',
		interval1minute5:'0',
		interval1price5:'1',
		
		interval1hour6:'0',
		interval1minute6:'0',
		interval1price6:'2',
		
		interval1hour7:'0',
		interval1minute7:'0',
		interval1price7:'3',
		
		interval1hour8:'0',
		interval1minute8:'0',
		interval1price8:'4',
		
		interval1hour9:'0',
		interval1minute9:'0',
		interval1price9:'1',
		
		interval1hour10:'0',
		interval1minute10:'0',
		interval1price10:'2',
		
		
		
		month2:'FF',
		day2:'FF',
		price21:0,
		price22:0,
		price23:0,
		price24:0,
		price25:0,
		interval21enable:'1',
		interval22enable:'1',
		interval23enable:'1',
		interval24enable:'1',
		interval25enable:'0',
		interval26enable:'0',
		interval27enable:'0',
		interval28enable:'0',
		interval29enable:'0',
		interval210enable:'0',
		
		interval2hour1:'0',
		interval2minute1:'0',
		interval2price1:'1',
		
		interval2hour2:'0',
		interval2minute2:'0',
		interval2price2:'2',
		
		interval2hour3:'0',
		interval2minute3:'0',
		interval2price3:'3',
		
		interval2hour4:'0',
		interval2minute4:'0',
		interval2price4:'4',
		
		interval2hour5:'0',
		interval2minute5:'0',
		interval2price5:'1',
		
		interval2hour6:'0',
		interval2minute6:'0',
		interval2price6:'2',
		
		interval2hour7:'0',
		interval2minute7:'0',
		interval2price7:'3',
		
		interval2hour8:'0',
		interval2minute8:'0',
		interval2price8:'4',
		
		interval2hour9:'0',
		interval2minute9:'0',
		interval2price9:'1',
		
		interval2hour10:'0',
		interval2minute10:'0',
		interval2price10:'2'
	})


	// 定义表格数据
	let tableDatas = ref([])
	let ammeterDatas = ref([])
	let areaDatas = ref([])
	let saveDatas = ref([])

	let headerCellStype = ref(proxy.$headerCellStype)
	let contentCellStyle = ref(proxy.$contentCellStyle)
	// 定义选中的表格行
	let multipleSelection = reactive([])
	// 定义表格引用，用来执行多选
	const multipleTable = ref()

	const setTimerFormRef = ref()
	const formRefMalignantLoad = ref()
	const formRefMultiPrice = ref()
	// 定义表格隔行变色，暂时没用到
	const tableRowClassName = ({
		row,
		rowIndex
	}) => {
		if (rowIndex % 2 === 0) {
			return 'even-row'
		} else {
			return 'odd-row'
		}
	}
	// 定义表格多选
	const handleSelectionChange = (val) => {
		multipleSelection = val;
	}
	const toggleSelection = (rows) => {
		if (rows) {
			rows.forEach(row => {
				multipleTable.value.toggleRowSelection(row);
			});
		} else {
			multipleTable.value.clearSelection();
		}
	}
	const rowclick = (row, column, event) => {
		toggleSelection([row])
	}
	
	const setMultiMeter =()=>{
		
		if (multipleSelection.length > 0) {
			setMultiMeterDialogVisible.value=true;
		} else {
			proxy.$tools.ShowMessage('请选择需要设置电表', false)
		}
	}
	
	const setHanYangMeter=()=>{
		if (multipleSelection.length > 0) {
			setHanYangMeterDialogVisible.value=true;
		} else {
			proxy.$tools.ShowMessage('请选择需要设置电表', false)
		}
	}
	
	const handleSingleChange = (value) => {
		switch(value){
			case '21':
				unitName.value='';
				break;
			case '41':
				unitName.value='度';
				break;
			case '42':
				unitName.value='元';
				break;
			case '44':
				unitName.value='度';
				break;
			case '45':
				unitName.value='元';
				break;
			case '46':
				unitName.value='度';
				break;
			case '47':
				unitName.value='元';
				break;
			case '49':
				unitName.value='kW';
				break;
			default:unitName.value='';
				break;
		}
	}

	const areaSelected =  (area) => {
		let loading = proxy.$tools.ShowLoading();
		setTimeout(()=>{
			tableDatas.value = []
			let grade = area.grade
			let id = area.id
			if (grade == "1") {
				tableDatas.value =  ammeterDatas.value.filter(ammeter => ammeter.areaId1 == id)
			} else if (grade == "2") {
				tableDatas.value =  ammeterDatas.value.filter(ammeter => ammeter.areaId2 == id)
			} else if (grade == "3") {
				tableDatas.value =  ammeterDatas.value.filter(ammeter => ammeter.areaId3 == id)
			}
			loading.close();
		},300)
	}

	const resetResult=()=>{
		if(tableDatas.value.length){
			for (let j = 0; j < tableDatas.value.length; j++) {
				tableDatas.value[j].message = ''
			}
		}
	}
	
	const search = async () => {
		if (params.keyword.length > 0) {
			tableDatas.value = await ammeterDatas.value.filter(ammeter => ammeter.areaName1?.indexOf(params.keyword) > -1 ||
				ammeter
				.areaName2?.indexOf(params.keyword) > -1 || ammeter.areaName3?.indexOf(params.keyword) > -1 || ammeter
				.dtuName?.indexOf(params.keyword) > -1 || ammeter.address?.indexOf(params.keyword) > -1 || ammeter
				.protocolName?.indexOf(params.keyword) > -1 || ammeter.location?.indexOf(params.keyword) > -1)
		} else {
			proxy.$tools.ShowMessage('请输入关键字', false)
		}
	}

	const setSingle = () => {
		if (multipleSelection.length > 0) {
			formdata.directiveType = ''
			formdata.directiveValue = ''
			unitName.value=''
			dialogVisible.value = true;
		} else {
			proxy.$tools.ShowMessage('请选择需要设置电表', false)
		}
	}

	const setTimerOnOff = () => {
		if (multipleSelection.length > 0) {
			settimerformdata.ammeterId=''
			setTimerDialogVisible.value = true;
		} else {
			proxy.$tools.ShowMessage('请选择需要设置电表', false)
		}
	}
	
	const setMalignantLoad = () => {
		if (multipleSelection.length > 0) {
			dialogVisibleMalignantLoad.value = true;
		} else {
			proxy.$tools.ShowMessage('请选择需要设置电表', false)
		}
	}
	
	const setMultiPrice=()=>{
		if (multipleSelection.length > 0) {
			dialogVisibleMultiPrice.value=true;
		} else {
			proxy.$tools.ShowMessage('请选择需要设置电表', false)
		}
	}
	
	const setHanYangMultiPrice=()=>{
		setHanYangMultiPriceDialogVisible.value=true;
	}
	
	const setHanYangMultiInterval=()=>{
		setHanYangMultiIntervalDialogVisible.value=true;
	}
	
	const setHanYangMultiTimezone=()=>{
		setHanYangMultiTimezoneDialogVisible.value=true;
	}
	
	const submitMultiPrice= async ()=>{
		dialogVisibleMultiPrice.value = false
		setMultiMeterDialogVisible.value=false;
		setHanYangMeterDialogVisible.value=false;
		let loading = proxy.$tools.ShowLoading();
		resetResult();
		for (let i = 0; i < multipleSelection.length; i++) {
			let ammeterId = multipleSelection[i].meterId
			formdataMultiPrice.ammeterId=ammeterId;
			let response = await GetServerData('SetWholeAmmeter/SetMultiPrice', formdataMultiPrice)
			let message =  response.message
			for (let j = 0; j < tableDatas.value.length; j++) {
				if (tableDatas.value[j].meterId == ammeterId) {
					if(tableDatas.value[j].message){
						tableDatas.value[j].message = message;
					}else{
						tableDatas.value[j].message =message;
					}
					break;
				}
			}
		}
		loading.close()
	}
	
	const submitSetOnOffForm = async (form) => {
		//if (setonoffformdata.lineNum.length>0) {
			setOnOffDialogVisible.value = false
			setMultiMeterDialogVisible.value=false;
			setHanYangMeterDialogVisible.value=false;
			let loading = proxy.$tools.ShowLoading();
			resetResult();
			for (let i = 0; i < multipleSelection.length; i++) {
				let ammeterId = multipleSelection[i].meterId
				setonoffformdata.ammeterId=ammeterId;
				let response = await GetServerData('SetWholeAmmeter/SetOnOff', setonoffformdata)
				let message =  response.message
				for (let j = 0; j < tableDatas.value.length; j++) {
					if (tableDatas.value[j].meterId == ammeterId) {
						if(tableDatas.value[j].message){
							tableDatas.value[j].message = message;
						}else{
							tableDatas.value[j].message =message;
						}
						break;
					}
				}
			}
			loading.close()
		//} else {
			//proxy.$tools.ShowMessage('请您正确填写所有必填信息后，再提交！', false)
			//return false;
		//}
	}
	
	const submitFormMalignantLoad =async (form) => {
		form.validate(async (valid) => {
			if (valid) {
				if (multipleSelection.length > 0) {
					let loading = proxy.$tools.ShowLoading()
					resetResult();
					dialogVisibleMalignantLoad.value=false;
					setMultiMeterDialogVisible.value=false;
					setHanYangMeterDialogVisible.value=false;
					for (let i = 0; i < multipleSelection.length; i++) {
						let meterId = multipleSelection[i].meterId
						let response = await GetServerData('SetWholeAmmeter/SetMalignantLoad', {
							ammeterId:meterId,
							rangeHigh1:formdataMalignantLoad.rangeHigh1,
							rangeLow1:formdataMalignantLoad.rangeLow1,
							limitHigh1:formdataMalignantLoad.limitHigh1,
							limitLow1:formdataMalignantLoad.limitLow1,
							limitParamHigh1:formdataMalignantLoad.limitParamHigh1,
							limitParamLow1:formdataMalignantLoad.limitParamLow1,
							rangeHigh2:formdataMalignantLoad.rangeHigh2,
							rangeLow2:formdataMalignantLoad.rangeLow2,
							limitHigh2:formdataMalignantLoad.limitHigh2,
							limitLow2:formdataMalignantLoad.limitLow2,
							limitParamHigh2:formdataMalignantLoad.limitParamHigh2,
							limitParamLow2:formdataMalignantLoad.limitParamLow2
						})
						let message =  response.message
						for (let j = 0; j < tableDatas.value.length; j++) {
							if (tableDatas.value[j].meterId == meterId) {
								tableDatas.value[j].message = message
								break;
							}
						}
					}
					loading.close();
				}else {
					proxy.$tools.ShowMessage('请选择需要操作的电表', false)
				}
			} else {
				proxy.$tools.ShowMessage('请您正确填写所有必填信息后，再提交！', false)
				return false;
			}
		})
	}
	
	const submitHanYangTimezoneForm=async()=>{
		setHanYangMultiTimezoneDialogVisible.value=false;
		setMultiMeterDialogVisible.value=false;
		setHanYangMeterDialogVisible.value=false;
		
		let loading = proxy.$tools.ShowLoading()
		resetResult();
		for (let i = 0; i < multipleSelection.length; i++) {
			let meterId = multipleSelection[i].meterId
			hanyangtimezoneformdata.ammeterId=meterId;
			let response = await GetServerData('SetWholeAmmeter/SetHanYangMultiTimezone', hanyangtimezoneformdata)
			let message = response.message
			for (let j = 0; j < tableDatas.value.length; j++) {
				if (tableDatas.value[j].meterId == meterId) {
					tableDatas.value[j].message = message
					break;
				}
			}
		}
		loading.close()
	}
	
	const submitHanYangPriceForm=async()=>{
		setHanYangMultiPriceDialogVisible.value=false;
		setMultiMeterDialogVisible.value=false;
		setHanYangMeterDialogVisible.value=false;
		
		let loading = proxy.$tools.ShowLoading()
		resetResult();
		for (let i = 0; i < multipleSelection.length; i++) {
			let meterId = multipleSelection[i].meterId
			hanyangpriceformdata.ammeterId=meterId;
			let response = await GetServerData('SetWholeAmmeter/SetHanYangMultiPrice', hanyangpriceformdata)
			let message = response.message
			for (let j = 0; j < tableDatas.value.length; j++) {
				if (tableDatas.value[j].meterId == meterId) {
					tableDatas.value[j].message = message
					break;
				}
			}
		}
		loading.close()
	}
	
	const submitHanYangIntervalForm=async()=>{
		setHanYangMultiIntervalDialogVisible.value=false;
		setMultiMeterDialogVisible.value=false;
		setHanYangMeterDialogVisible.value=false;
		let loading = proxy.$tools.ShowLoading()
		resetResult();
		for (let i = 0; i < multipleSelection.length; i++) {
			let meterId = multipleSelection[i].meterId
			hanyangintervalformdata.ammeterId=meterId;
			let response = await GetServerData('SetWholeAmmeter/SetHanYangMultiInterval', hanyangintervalformdata)
			let message = response.message
			for (let j = 0; j < tableDatas.value.length; j++) {
				if (tableDatas.value[j].meterId == meterId) {
					tableDatas.value[j].message = message
					break;
				}
			}
		}
		loading.close()
	}

	const submitForm = async () => {
		if(formdata.directiveType.length>0&&formdata.directiveValue.length > 0){
			
		} else {
			proxy.$tools.ShowMessage('请输入设置值', false)
			return
		}
		
		dialogVisible.value = false;
		setMultiMeterDialogVisible.value=false;
		setHanYangMeterDialogVisible.value=false;
		let loading = proxy.$tools.ShowLoading()
		resetResult();
		for (let i = 0; i < multipleSelection.length; i++) {
			let meterId = multipleSelection[i].meterId
			let data = {
				directiveType: formdata.directiveType,
				directiveValue: formdata.directiveValue,
				ammeterId: meterId
			}
			let response = await GetServerData('SetWholeAmmeter/SetAmmeter', data)
			let message = response.message
			for (let j = 0; j < tableDatas.value.length; j++) {
				if (tableDatas.value[j].meterId == meterId) {
					tableDatas.value[j].message = message
					break;
				}
			}
		}
		loading.close()
	}
	
	const submitSetTimerForm = async (form) => {
		if (settimerformdata.timerName.length>0&&settimerformdata.week.length>0&&settimerformdata.hour.length>0&&settimerformdata.minute.length>0) {
			if(proxy.$tools.IsNumber(settimerformdata.week)&&proxy.$tools.IsNumber(settimerformdata.hour)&&proxy.$tools.IsNumber(settimerformdata.minute)){
				setTimerDialogVisible.value = false
				setMultiMeterDialogVisible.value=false;
				setHanYangMeterDialogVisible.value=false;
				let loading = proxy.$tools.ShowLoading();
				resetResult();
				for (let i = 0; i < multipleSelection.length; i++) {
					let meterId = multipleSelection[i].meterId;
					settimerformdata.ammeterId=meterId;
					let response = await GetServerData('SetWholeAmmeter/SetTimer', settimerformdata);
					let message = response.message
					for (let j = 0; j < tableDatas.value.length; j++) {
						if (tableDatas.value[j].meterId == meterId) {
							tableDatas.value[j].message = message
							break;
						}
					}
				}
				loading.close()
			}else{
				proxy.$tools.ShowMessage('请您正确填写所有必填信息后，再提交！', false)
				return false;
			}
		} else {
			proxy.$tools.ShowMessage('请您正确填写所有必填信息后，再提交！', false)
			return false;
		}
	}
	
	const setDatetime=async ()=>{
		if (multipleSelection.length > 0) {
			ElMessageBox.confirm("您确定要同步所选电表的时间吗？是否继续？", "提 示", {
					confirmButtonText: "确 定",
					cancelButtonText: "取 消",
					type: "warning",
				})
				.then(async() => {
					setMultiMeterDialogVisible.value=false;
					setHanYangMeterDialogVisible.value=false;
					let loading = proxy.$tools.ShowLoading();
					resetResult();
					for (let i = 0; i < multipleSelection.length; i++) {
						let meterId = multipleSelection[i].meterId;
						let response = await GetServerData('SetWholeAmmeter/SetDatetime', '"' + meterId + '"');
						let message = response.message
						for (let j = 0; j < tableDatas.value.length; j++) {
							if (tableDatas.value[j].meterId == meterId) {
								tableDatas.value[j].message = message
								break;
							}
						}
					}
					loading.close()		
				})
				.catch((e) => {
					//console.log(e);
				});
		} else {
			proxy.$tools.ShowMessage('请选择需要设置电表', false)
		}
		
	}
	
	const setHanYangDate=async ()=>{
		if (multipleSelection.length > 0) {
			ElMessageBox.confirm("您确定要同步所选电表的日期吗？是否继续？", "提 示", {
					confirmButtonText: "确 定",
					cancelButtonText: "取 消",
					type: "warning",
				})
				.then(async() => {
					setMultiMeterDialogVisible.value=false;
					setHanYangMeterDialogVisible.value=false;
					let loading = proxy.$tools.ShowLoading();
					resetResult();
					for (let i = 0; i < multipleSelection.length; i++) {
						let meterId = multipleSelection[i].meterId;
						let response = await GetServerData('SetWholeAmmeter/SetHanYangDate', '"' + meterId + '"');
						let message = response.message
						for (let j = 0; j < tableDatas.value.length; j++) {
							if (tableDatas.value[j].meterId == meterId) {
								tableDatas.value[j].message = message
								break;
							}
						}
					}
					loading.close()		
				})
				.catch((e) => {
					//console.log(e);
				});
		} else {
			proxy.$tools.ShowMessage('请选择需要设置电表', false)
		}
		
	}
	
	const setHanYangTime=async ()=>{
		if (multipleSelection.length > 0) {
			ElMessageBox.confirm("您确定要同步所选电表的日期吗？是否继续？", "提 示", {
					confirmButtonText: "确 定",
					cancelButtonText: "取 消",
					type: "warning",
				})
				.then(async() => {
					setMultiMeterDialogVisible.value=false;
					setHanYangMeterDialogVisible.value=false;
					let loading = proxy.$tools.ShowLoading();
					resetResult();
					for (let i = 0; i < multipleSelection.length; i++) {
						let meterId = multipleSelection[i].meterId;
						let response = await GetServerData('SetWholeAmmeter/SetHanYangTime', '"' + meterId + '"');
						let message = response.message
						for (let j = 0; j < tableDatas.value.length; j++) {
							if (tableDatas.value[j].meterId == meterId) {
								tableDatas.value[j].message = message
								break;
							}
						}
					}
					loading.close()		
				})
				.catch((e) => {
					//console.log(e);
				});
		} else {
			proxy.$tools.ShowMessage('请选择需要设置电表', false)
		}
		
	}

	// 定义获取表格数据函数
	const GetAmmeterData = async () => {
		let response = await GetServerData('SetWholeAmmeter/QueryAllAmmeters')
		if (response.status == 1) {
			ammeterDatas.value = response.data
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}

	// 定义获取表格数据函数
	const GetAreaData = async () => {
		let response = await GetServerData('SetWholeAmmeter/QueryAllAreas')
		if (response.status == 1) {
			areaDatas.value = response.data
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}

	// 定义获取所有数据函数
	const GetAllData = async () => {
		await GetAmmeterData()
		await GetAreaData()
	}
	
	const refresh=async ()=>{
		let loading = proxy.$tools.ShowLoading();
		tableDatas.value=[];
		await GetAllData()
		loading.close()
	}

	let areaHeight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60))
	let tableheight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60))
	// 定义加载事件
	onMounted(async () => {
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth - 425) +
			'px'
		window.onresize = () => {
			tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
			areaHeight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
			document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
					425) +
				'px'
		}
		let loading = proxy.$tools.ShowLoading();
		await GetAllData()
		hours.push({label: "无效",
			value: "FF"})
		for(let i=0;i<24;i++){
			hours.push({label: i.toString(),
			value: i.toString()})
			hours2.push({label: i.toString(),
			value: i.toString()})
		}
		days.push({label: "无效",
			value: "FF"});
		for(let i=1;i<32;i++){
			days.push({label: i.toString(),
			value: i.toString()})
			days2.push({label: i.toString(),
			value: i.toString()})
		}
		loading.close()
	})
	onActivated(() => {
		tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
		areaHeight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
				425) +
			'px'
	})
</script>

<style scoped lang="less">

</style>
