<template>
	<el-config-provider :locale="locale">
		<router-view></router-view>
	</el-config-provider>
</template>

<script>
	import{
		onMounted
	} from 'vue'
	// 更改Element-Plus为中文
	import {
		ElConfigProvider
	} from 'element-plus'
	import zhCn from 'element-plus/lib/locale/lang/zh-cn'

	export default {
		name: 'App',
		components: {
			ElConfigProvider
		},
		setup() {
			return {
				locale: zhCn
			}
		}
	}
	onMounted(()=>{
		// history.pushState(null, null, document.URL);
		// window.addEventListener('popstate', function () {
		//     history.pushState(null, null, document.URL);
		// });
	})
</script>
<style lang="less">
	// 设置ELLoading的样式
	.el-loading-spinner .path {
		-webkit-animation: loading-dash 1.5s ease-in-out infinite;
		animation: loading-dash 1.5s ease-in-out infinite;
		stroke-dasharray: 90, 150;
		stroke-dashoffset: 0;
		stroke-width: 2;
		/* stroke: var(--el-color-primary); */
		stroke-linecap: round;
		stroke: rgb(240, 228, 188) !important;
	}
	.el-loading-spinner .el-loading-text {
		/* color: var(--el-color-primary); */
		color: rgb(240, 228, 188) !important;
		margin: 3px 0;
		font-size: 14px;
	}

	// 设置表格鼠标悬浮的样式
	// .el-table--enable-row-hover .el-table__body tr:hover>td{
	//      background-color: #dedfe0  !important;
	// }
	.el-table__body tr:hover>td{
	    background-color: #dedfe0  !important;
	}
	.el-table__body .el-table__row.hover-row td{
		background-color: #dedfe0 !important;
	}
	
	// 设置表格鼠标点击后的样式
	.current-row > td {
	   // background: orange !important;
	}
	
	// 设置树形控件中鼠标点击（即选中节点）后节点的样式
	.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
	    background-color: #409EFF !important;
		color:white;
	  }
</style>
