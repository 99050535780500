<template>
	<div class="contentheader">
		<el-space wrap>
			<span class="titleClass">住宅区管理</span>
			<el-divider direction="vertical" />
			<elui-china-area-dht v-model="paramAreaSelect" @change="onParamChange" placeholder="选择省份和城市" :leave="2" size="small" 
				:style="{width: '200px'}">
			</elui-china-area-dht>
			<el-input v-model="params.name" placeholder="输入住宅区名称" size="small" style="width: 200px;"
				autocomplete="off" />
			<el-button type="primary" size="small" class="buttonforheader" @click="search">查 询</el-button>
			<el-divider direction="vertical" />
			<el-button type="success" size="small" class="buttonforheader" @click="add">新 增</el-button>
			<el-button type="warning" size="small" class="buttonforheader" @click="deletesome">批量删除</el-button>
			<!-- <el-button type="primary" size="small" class="buttonforheader" @click="resetparams">重置</el-button> -->
		</el-space>
	</div>
	<div id="divTableData" class="contentcontent">
		<el-table :data="tableDatas" fit="false" highlight-current-row="true" ref="multipleTable" tooltip-effect="dark"
			:height="tableheight" @selection-change="handleSelectionChange" @row-click="rowclick" row-key="id"
			:header-cell-style="headerCellStype" :cell-style="contentCellStyle" style="width: 100%; cursor: pointer;"
			:row-class-name="tableRowClassName">
			<el-table-column type="selection" width="55" />
			<el-table-column prop="id" label="id" width="0" v-if="false" />
			<el-table-column prop="smsAccount" label="smsAccount" width="0" v-if="false" />
			<el-table-column prop="smsPassword" label="smsPassword" width="0" v-if="false" />
			<el-table-column prop="wxShopCode" label="wxShopCode" width="0" v-if="false" />
			<el-table-column prop="provinceCode" label="provinceCode" width="0" v-if="false" />
			<el-table-column prop="cityCode" label="cityCode" width="0" v-if="false" />
			<el-table-column prop="countyCode" label="countyCode" width="0" v-if="false" />
			<el-table-column prop="ammeterType" label="ammeterType" width="0" v-if="false" />
			<el-table-column prop="watermeterType" label="watermeterType" width="0" v-if="false" />
			<el-table-column prop="provinceName" label="省份" width="120" />
			<el-table-column prop="cityName" label="城市" width="120" />
			<el-table-column prop="countyName" label="区县" width="120" />
			<el-table-column prop="name" label="住宅区名称" width="200" />
			<el-table-column prop="shortName" label="简称" width="90" align="center" />
			<el-table-column prop="uniqueCode" label="唯一编码" width="350" />
			<el-table-column prop="smsLastCount" label="短信余量" width="100" />
			<el-table-column prop="ammeterTypeName" label="电表类型" width="100" />
			<el-table-column prop="watermeterTypeName" label="水表类型" width="100" />
			<el-table-column prop="ammeterName1" label="电表1名称" width="100" />
			<el-table-column prop="ammeterName2" label="电表2名称" width="100" />
			<el-table-column prop="watermeterName1" label="水表1名称" width="100" />
			<el-table-column prop="watermeterName2" label="水表2名称" width="100" />
			<el-table-column prop="remark" label="备注" width="250" :show-overflow-tooltip="true" />
			<el-table-column prop="cuName" label="创建人" width="120" />
			<el-table-column prop="muName" label="修改人" width="120" />
			<el-table-column prop="ct" label="创建时间" width="150" align="center" />
			<el-table-column prop="mt" label="修改时间" width="150" align="center" />
			<el-table-column />
			<el-table-column fixed="right" label="操作" width="550" align="center">
				<template #default="scope">
					<el-button size="mini" @click.stop="modify(scope.row)" type="success" class="buttonfortable">编 辑
					</el-button>
					<el-button size="mini" @click.stop="deleteitem(scope.row)" type="danger" class="buttonfortable">删 除
					</el-button>
					<el-button size="mini" @click.stop="resetuseamount(scope.row)" type="success" class="buttonfortable">重置用量统计
					</el-button>
					<el-button size="mini" @click.stop="smsset(scope.row)" type="warning" class="buttonfortable">短信平台设置
					</el-button>
					<el-button size="mini" @click.stop="shopset(scope.row)" type="info" class="buttonfortable">微信商户号设置
					</el-button>
				</template>
			</el-table-column>
		</el-table>
	</div>
	<div class="contentpager" v-if="showpaging">
		<el-pagination background layout="prev, pager, next,jumper,sizes,total" :total="pagingInfo.totalCount"
			:page-sizes="[50,100,200]" v-model:current-page="params.pageNum" v-model:page-size="params.pageSize"
			@size-change="sizechange" @current-change="currentchange" />
	</div>
	<el-dialog width="500px" v-model="dialogVisible" append-to-body=true :title="dialogtitle" center draggable
		:close-on-click-modal="false" :show-close="true">
		<el-form :model="formdata" ref="formRef" class="demo-ruleForm formContent">
			<el-form-item label="选择地区" :label-width="formLabelWidth">
				<elui-china-area-dht @change="onChange" placeholder="请选择省市区" v-model="areaSelected"
					:style="formContentStyle"></elui-china-area-dht>
			</el-form-item>
			<el-form-item prop="name" :rules="[{ required: true, message: '请输入住宅区名称' }]" label="住宅区名称"
				:label-width="formLabelWidth">
				<el-input v-model="formdata.name" autocomplete="off" :style="formContentStyle" placeholder="请输入住宅区名称" />
			</el-form-item>
			<el-form-item prop="shortName" :rules="[{ required: true, message: '请输入简称(大写字母)' }]" label="简称(大写字母)"
				:label-width="formLabelWidth">
				<el-input v-model="formdata.shortName" autocomplete="off" :style="formContentStyle"
					placeholder="请输入简称(大写字母)" />
			</el-form-item>
			<el-form-item prop="account" :rules="[{ required: true, message: '请输入管理员账号' }]" label="管理员账号"
				v-if="formdata.id.length==0" :label-width="formLabelWidth">
				<el-input v-model="formdata.account" autocomplete="off" :style="formContentStyle"
					placeholder="请输入管理员账号" />
			</el-form-item>
			<el-form-item label="电表类型" :label-width="formLabelWidth">
				<el-radio-group v-model="formdata.ammeterType" class="ml-4">
					<el-radio label="0" size="small" style="margin-left: 20px;">无电表</el-radio>
					<el-radio label="1" size="small">1个电表</el-radio>
					<el-radio label="2" size="small">2个电表</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="电表1统称" :label-width="formLabelWidth" v-if="formdata.ammeterType=='1'||formdata.ammeterType=='2'">
				<el-input v-model="formdata.ammeterName1" autocomplete="off" :style="formContentStyle" placeholder="请输入电表1统称" />
			</el-form-item>
			<el-form-item label="电表2统称" :label-width="formLabelWidth" v-if="formdata.ammeterType=='2'">
				<el-input v-model="formdata.ammeterName2" autocomplete="off" :style="formContentStyle" placeholder="请输入电表2统称" />
			</el-form-item>
			<el-form-item label="电表类型" :label-width="formLabelWidth">
				<el-radio-group v-model="formdata.watermeterType" class="ml-4">
					<el-radio label="0" size="small" style="margin-left: 20px;">无水表</el-radio>
					<el-radio label="1" size="small">1个水表</el-radio>
					<el-radio label="2" size="small">2个水表</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="水表1统称" :label-width="formLabelWidth" v-if="formdata.watermeterType=='1'||formdata.watermeterType=='2'">
				<el-input v-model="formdata.watermeterName1" autocomplete="off" :style="formContentStyle" placeholder="请输入水表1统称" />
			</el-form-item>
			<el-form-item label="水表2统称" :label-width="formLabelWidth" v-if="formdata.watermeterType=='2'">
				<el-input v-model="formdata.watermeterName2" autocomplete="off" :style="formContentStyle" placeholder="请输入水表2统称" />
			</el-form-item>
			<el-form-item label="备注" :label-width="formLabelWidth">
				<el-input v-model="formdata.remark" autocomplete="off" :style="formContentStyle" placeholder="请输入备注" />
			</el-form-item>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-space wrap :size="30">
					<span v-if="showgoon">是否继续添加：
						<el-switch v-model="goon" style="margin-right: 10px;" />
					</span>
					<el-button size="mini" type="warning" @click="reset" class="buttonforform"> 重 置 </el-button>
					<!-- <el-button @click="dialogVisible = false">取消</el-button> -->
					<el-button size="mini" type="primary" @click="submitForm(formRef)" class="buttonforform"> 确 定
					</el-button>
				</el-space>
			</span>
		</template>
	</el-dialog>
	
	<el-dialog width="500px" v-model="smsSetDialogVisible" append-to-body=true title="短信平台设置" center draggable
		:close-on-click-modal="false" :show-close="true">
		<el-form :model="smsformdata" ref="formSmsRef" class="demo-ruleForm formContent">
			<el-form-item prop="smsAccount" :rules="[{ required: false, message: '请输入短信平台账号' }]" label="短信平台账号"
				:label-width="formLabelWidth">
				<el-input v-model="smsformdata.smsAccount" autocomplete="off" :style="formContentStyle"
					placeholder="请输入短信平台账号" />
			</el-form-item>
			<el-form-item prop="smsPassword" :rules="[{ required: false, message: '请输入短信平台密码' }]" label="短信平台密码"
				:label-width="formLabelWidth">
				<el-input v-model="smsformdata.smsPassword" autocomplete="off" :style="formContentStyle"
					placeholder="请输入短信平台密码" />
			</el-form-item>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-space wrap :size="30">
					<el-button size="mini" type="primary" @click="submitSmsSetForm(formSmsRef)" class="buttonforform"> 确 定
					</el-button>
				</el-space>
			</span>
		</template>
	</el-dialog>
	
	<el-dialog width="500px" v-model="shopSetDialogVisible" append-to-body=true title="微信商户号设置" center draggable
		:close-on-click-modal="false" :show-close="true">
		<el-form :model="shopformdata" ref="formShopRef" class="demo-ruleForm formContent">
			<el-form-item prop="shopCode" :rules="[{ required: false, message: '请输入微信商户号' }]" label="微信商户号"
				:label-width="formLabelWidth">
				<el-input v-model="shopformdata.shopCode" autocomplete="off" :style="formContentStyle"
					placeholder="请输入微信商户号" />
			</el-form-item>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-space wrap :size="30">
					<el-button size="mini" type="primary" @click="submitShopSetForm(formShopRef)" class="buttonforform"> 确 定
					</el-button>
				</el-space>
			</span>
		</template>
	</el-dialog>
	
	<el-dialog width="500px" v-model="resetDialogVisible" append-to-body=true title="重置用量统计信息" center draggable
		:close-on-click-modal="false" :show-close="true">
		<el-form :model="resetformdata" ref="formResetRef" class="demo-ruleForm formContent">
			<el-form-item prop="password" :rules="[{ required: true, message: '请输入操作密码' }]" label="操作密码"
				:label-width="formLabelWidth">
				<el-input v-model="resetformdata.password" autocomplete="off" :style="formContentStyle"
					placeholder="请输入密码" />
			</el-form-item>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-space wrap :size="30">
					<el-button size="mini" type="primary" @click="submitResetForm(formResetRef)" class="buttonforform"> 确 定
					</el-button>
				</el-space>
			</span>
		</template>
	</el-dialog>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted,
		onActivated,
		getCurrentInstance
	} from 'vue'
	import {
		ElMessageBox
	} from 'element-plus'
	import {
		GetServerData
	} from '@/http/index.js'
	import {
		EluiChinaAreaDht
	} from 'elui-china-area-dht'

	// 获取全局this，proxy相当于vue2中的this，用于调用全局对象使用
	const {
		proxy
	} = getCurrentInstance()
	let paramAreaSelect = ref([])
	// 定义查询参数
	let params = reactive({
		provinceCode: '',
		cityCode: '',
		name: '',
		pageSize: 50,
		pageNum: 1
	})
	let pageNum = ref(1)
	let pagingInfo = reactive({
		totalCount: 0,
		totalPage: 0
	})
	// 定义表单标题
	let dialogtitle = ref("新 增 住 宅 区")
	// 定义表单数据
	let formdata = reactive({
		id: '',
		provinceName: '',
		provinceCode: '',
		cityName: '',
		cityCode: '',
		countyName: '',
		countyCode: '',
		name: '',
		shortName: '',
		account: '',
		remark: '',
		ammeterType:'0',
		watermeterType:'0',
		ammeterName1:'',
		ammeterName2:'',
		watermeterName1:'',
		watermeterName2:''
	})
	let smsformdata=reactive({
		estateId:'',
		smsAccount:'',
		smsPassword:''
	})
	
	let shopformdata=reactive({
		shopCode:'',
		estateId:''
	})
	
	let resetformdata=reactive({
		password:'',
		estateId:''
	})

	let areaSelected = ref([])
	const chinaData = new EluiChinaAreaDht.ChinaArea().chinaAreaflat
	const onParamChange = (e) => {
		params.provinceCode = chinaData[e[0]].value
		params.cityCode = chinaData[e[1]].value
	}
	const onChange = (e) => {
		formdata.provinceName = chinaData[e[0]].label
		formdata.provinceCode = chinaData[e[0]].value
		formdata.cityName = chinaData[e[1]].label
		formdata.cityCode = chinaData[e[1]].value
		formdata.countyName = chinaData[e[2]].label
		formdata.countyCode = chinaData[e[2]].value
	}

	// 定义表单显示与隐藏
	let dialogVisible = ref(false)
	let smsSetDialogVisible=ref(false)
	let shopSetDialogVisible=ref(false)
	let resetDialogVisible=ref(false)
	// 定义是否显示继续添加
	let showgoon = ref(false)
	// 定义是否继续添加
	let goon = ref(false)
	// 定义是否显示分页
	let showpaging = ref(true)
	// 定义表格数据
	let tableDatas = ref([])
	let headerCellStype = ref(proxy.$headerCellStype)
	let contentCellStyle = ref(proxy.$contentCellStyle)
	// 定义选中的表格行
	let multipleSelection = reactive([])
	// 定义表格引用，用来执行多选
	const multipleTable = ref()
	// 定义表单引用，用来执行表单验证
	const formRef = ref()
	const formSmsRef=ref()
	const formShopRef=ref()
	const formResetRef=ref()
	// 定义表单项的头部宽度
	const formLabelWidth = ref('150px')
	// 定义表单项的内容宽度
	const formContentStyle = ref('width:300px')

	// 定义表格隔行变色，暂时没用到
	const tableRowClassName = ({
		row,
		rowIndex
	}) => {
		if (rowIndex % 2 === 0) {
			return 'even-row'
		} else {
			return 'odd-row'
		}
	}
	// 定义表格多选
	const handleSelectionChange = (val) => {
		multipleSelection = val;
	}
	const toggleSelection = (rows) => {
		if (rows) {
			rows.forEach(row => {
				multipleTable.value.toggleRowSelection(row);
			});
		} else {
			multipleTable.value.clearSelection();
		}
	}
	const rowclick = (row, column, event) => {
		toggleSelection([row])
	}
	const sizechange = async () => {
		params.pageNum = 1
		let loading = proxy.$tools.ShowLoading();
		await GetTableData()
		loading.close()
	}
	const currentchange = async () => {
		let loading = proxy.$tools.ShowLoading();
		await GetTableData()
		loading.close()
	}
	
	const submitSmsSetForm=(form)=>{
		form.validate(async (valid) => {
			if (valid) {
				if (smsformdata.estateId.length > 0) {
					let loading = proxy.$tools.ShowLoading();
					let response = await GetServerData('Estate/SMSSet', smsformdata)
					if (response.status == 1) {
						proxy.$tools.ShowMessage('短信平台信息设置成功！', true)
						await GetAllData();
						smsSetDialogVisible.value = false
					} else {
						proxy.$tools.ShowMessage(response.message, false)
					}
					loading.close()
				}
			}
		})
	}
	
	const submitResetForm=(form)=>{
		form.validate(async (valid) => {
			if (valid) {
				let loading = proxy.$tools.ShowLoading();
				let response = await GetServerData('Estate/ResetUseAmount', resetformdata)
				if (response.status == 1) {
					proxy.$tools.ShowMessage('用量统计信息重置成功！', true)
					//await GetAllData();
					resetDialogVisible.value = false
				} else {
					proxy.$tools.ShowMessage(response.message, false)
				}
				loading.close()
			}
		})
	}
	
	const submitShopSetForm=(form)=>{
		form.validate(async (valid) => {
			if (valid) {
				if (shopformdata.estateId.length > 0) {
					let loading = proxy.$tools.ShowLoading();
					let response = await GetServerData('Estate/ShopCodeSet', shopformdata)
					if (response.status == 1) {
						proxy.$tools.ShowMessage('微信商户号信息设置成功！', true)
						await GetAllData();
						shopSetDialogVisible.value = false
					} else {
						proxy.$tools.ShowMessage(response.message, false)
					}
					loading.close()
				}
			}
		})
	}

	// 定义表单提交事件，带表单验证
	const submitForm = (form) => {
		form.validate(async (valid) => {
			if (valid) {
				if (formdata.countyCode.length > 0 && formdata.countyName.length > 0) {
					var reg1=new RegExp('^[A-Za-z]+$');
					var reg2=new RegExp('^[A-Z]+$');
					if(reg1.test(formdata.account)){
						if(reg2.test((formdata.shortName))){
							let loading = proxy.$tools.ShowLoading();
							if (formdata.id.length > 0) {
								let response = await GetServerData('Estate/Modify', formdata)
								if (response.status == 1) {
									proxy.$tools.ShowMessage('修改成功', true)
									dialogVisible.value = false
									await GetAllData();
								} else {
									proxy.$tools.ShowMessage(response.message, false)
								}
							} else {
								let response = await GetServerData('Estate/Add', formdata)
								if (response.status == 1) {
									proxy.$tools.ShowMessage('新增成功！', true)
									if (goon.value) {
										reset()
									} else {
										dialogVisible.value = false
									}
									await GetAllData();
								} else {
									proxy.$tools.ShowMessage(response.message, false)
								}
							}
							loading.close()
						}else{
							proxy.$tools.ShowMessage('住宅区简称只能是大写字母！', false)
							return false;
						}
					}else{
						proxy.$tools.ShowMessage('账号只能是字母！', false)
						return false;
					}
				} else {
					proxy.$tools.ShowMessage('请选择地区信息后，再提交！', false)
					return false;
				}
			} else {
				proxy.$tools.ShowMessage('请您正确填写所有必填信息后，再提交！', false)
				return false;
			}
		})
	}
	// 重置表单事件
	const reset = () => {
		if (formRef.value) {
			formRef.value.resetFields();
		}
		formdata.name = '';
		formdata.shortName = '';
		formdata.account = '';
		formdata.remark = '';
	}
	const smsset=(row)=>{
		if (formSmsRef.value) {
			formSmsRef.value.resetFields();
		}
		smsformdata.estateId=row.id;
		smsformdata.smsAccount=row.smsAccount;
		smsformdata.smsPassword=row.smsPassword;
		smsSetDialogVisible.value=true;
	}
	const shopset=(row)=>{
		if (formShopRef.value) {
			formShopRef.value.resetFields();
		}
		shopformdata.estateId=row.id;
		shopformdata.shopCode=row.wxShopCode;
		shopSetDialogVisible.value=true;
	}
	const resetuseamount=(row)=>{
		resetformdata.password='';
		resetformdata.estateId=row.id;
		resetDialogVisible.value=true;
	}
	// 定义新增按钮事件
	const add = () => {
		dialogtitle.value = "新 增 住 宅 区"
		goon.value = false
		showgoon.value = true
		reset()
		formdata.id = '';
		areaSelected.value = [];
		formdata.provinceName = '';
		formdata.provinceCode = '';
		formdata.cityName = '';
		formdata.cityCode = '';
		formdata.countyName = '';
		formdata.countyCode = '';
		dialogVisible.value = true
	}
	// 定义编辑按钮事件
	const modify = (row) => {
		dialogtitle.value = "修 改 住 宅 区"
		showgoon.value = false
		formdata.id = row.id;
		formdata.provinceName = row.provinceName;
		formdata.provinceCode = row.provinceCode;
		formdata.cityName = row.cityName;
		formdata.cityCode = row.cityCode;
		formdata.countyName = row.countyName;
		formdata.countyCode = row.countyCode;
		formdata.name = row.name;
		formdata.shortName = row.shortName;
		formdata.account = 'placeholder';
		formdata.remark = row.remark;
		formdata.ammeterType=row.ammeterType;
		formdata.ammeterName1=row.ammeterName1;
		formdata.ammeterName2=row.ammeterName2;
		formdata.watermeterType=row.watermeterType;
		formdata.watermeterName1=row.watermeterName1;
		formdata.watermeterName2=row.watermeterName2;
		areaSelected.value = [row.provinceCode, row.cityCode, row.countyCode]
		dialogVisible.value = true
	}
	// 定义删除按钮事件
	const deleteitem = (row) => {
		ElMessageBox.confirm('确定要删除这条记录吗?', "", {
				confirmButtonText: '确 定',
				cancelButtonText: '取 消',
				type: 'warning'
			})
			.then(async () => {
				let loading = proxy.$tools.ShowLoading();
				let response = await GetServerData('Estate/Delete', {
					id: row.id
				})

				if (response.status == 1) {
					proxy.$tools.ShowMessage('删除成功！', true)
					await GetAllData()
				} else {
					proxy.$tools.ShowMessage(response.message, false)
				}
				loading.close();
			})
	}
	// 定义批量删除按钮事件
	const deletesome = () => {
		var ids = ''
		multipleSelection.forEach((value, key, iterable) => {
			ids += "'" + value.id + "',";
		})
		if (ids.length > 0) {
			ids = ids.substr(0, ids.length - 1)
			ElMessageBox.confirm('确定要删除下面选中的所有记录吗?', "", {
					confirmButtonText: '确 定',
					cancelButtonText: '取 消',
					type: 'warning'
				})
				.then(async () => {
					let loading = proxy.$tools.ShowLoading();
					let response = await GetServerData('Estate/DeleteSome', {
						ids: ids
					})
					if (response.status == 1) {
						proxy.$tools.ShowMessage('删除成功！', true)
						await GetAllData()
					} else {
						proxy.$tools.ShowMessage(response.message, false)
					}
					loading.close()
				})
		}
	}
	// 定义查询按钮事件
	const search = async () => {
		let loading = proxy.$tools.ShowLoading();
		await GetTableData()
		loading.close()
	}
	// 定义刷新按钮事件
	const resetparams = () => {
		params.provinceCode = '';
		params.cityCode = '';
		params.name = '';
		paramAreaSelect.value = [];
	}
	// 定义获取表格数据函数
	const GetTableData = async () => {
		let response = await GetServerData('Estate/QueryAll', params)
		if (response.status == 1) {
			console.log(response.data)
			tableDatas.value = response.data
			pagingInfo.totalCount = response.totalCount
			pagingInfo.totalPage = Math.ceil(pagingInfo.totalCount / params.pageSize)
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}

	// 定义获取所有数据函数
	const GetAllData = async () => {
		await GetTableData()
	}

	let tableheight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight))
	// 定义加载事件
	onMounted(async () => {
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth - 220) +
			'px'
		window.onresize = () => {
			tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight)
			document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
					220) +
				'px'
		}
		let loading = proxy.$tools.ShowLoading();
		await GetAllData()
		loading.close()
	})
	onActivated(() => {
		tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight)
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
			220) + 'px'
	})
</script>

<style scoped lang="less">
</style>
