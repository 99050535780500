<template>

	<div class="contentheader">
		<el-space wrap>
			<span class="titleClass">断送水表</span>
			<el-divider direction="vertical" />
			<el-radio-group v-model="params.category" class="ml-4" @change="categoryChange" v-if="watermeterType=='2'">
				<el-radio label="3" size="small">{{watermeterName1}}</el-radio>
				<el-radio label="4" size="small">{{watermeterName2}}</el-radio>
			</el-radio-group>
			<el-divider direction="vertical" />
			<el-input v-model="params.keyword" placeholder="输入关键字" size="small" style="width: 150px;"
				autocomplete="off" />
			<el-button type="primary" size="small" class="buttonforheader" @click="search">查 询</el-button>
			<el-button type="primary" size="small" class="buttonforheader" @click="refresh">刷 新</el-button>
			<el-divider direction="vertical" />
			<el-button type="success" size="small" class="buttonforheader" @click="onManyMeter()" v-if="watermeterType!='0'">送水</el-button>
			<el-divider direction="vertical" />
			<el-button type="warning" size="small" class="buttonforheader" @click="offManyMeter()" v-if="watermeterType!='0'">断水</el-button>
		</el-space>
	</div>
	<div style="display: flex; flex-direction: row; flex-wrap: nowrap; align-items: flex-start;margin: 10px;">
		<div style="width: 200px;">
			<el-scrollbar :height="areaHeight" style="width:200px; border-radius: 6px;background-color: white;">
				<el-tree ref="treeAreaRef" :data="areaDatas" node-key="id" :expand-on-click-node="false"
					highlight-current :props="treeProps" @node-click="areaSelected" style="margin: 10px;">
				</el-tree>
			</el-scrollbar>
		</div>
		<div id="divTableData" style="width: 100%;border-radius: 6px;background-color: white; margin-left: 5px;">
			<el-table :data="tableDatas" fit="false" highlight-current-row="true" ref="multipleTable"
				tooltip-effect="dark" :height="tableheight" @selection-change="handleSelectionChange"
				@row-click="rowclick" row-key="id" default-expand-all :header-cell-style="headerCellStype"
				:cell-style="contentCellStyle" style="width: 100%; cursor: pointer;" :row-class-name="tableRowClassName"
				>
				<el-table-column type="selection" width="55" />
				<el-table-column fixed prop="roomId" label="roomId" width="0" v-if="false" />
				<el-table-column prop="areaName1" label="一级区域" width="100" />
				<el-table-column prop="areaName2" label="二级区域" width="100" />
				<el-table-column prop="areaName3" label="三级区域" width="100" />
				<!-- <el-table-column prop="protocolName" label="协议名称" width="100" /> -->
				<el-table-column prop="roomName" label="房间名称" width="100" />
				<el-table-column prop="roomNum" label="房间编号" width="100" />
				<el-table-column prop="familyName" label="户主名称" width="150"  />
				<el-table-column prop="familyPhone" label="户主电话" width="150"  />
				<el-table-column prop="message" label="执行结果" width="250" />
				<el-table-column />
				<!-- <el-table-column fixed="right" label="操作" width="320" align="center">
					<template #default="scope">
						<el-button type="success" size="mini" class="buttonfortable" @click.stop="onMeter(scope.row.roomId,'3')" v-if="watermeteType!='0'">{{watermeteName1}}送水</el-button>
						<el-button type="success" size="mini" class="buttonfortable" @click.stop="onMeter(scope.row.roomId,'4')"  v-if="watermeteType=='2'">{{watermeteName2}}送水</el-button>
						<el-button type="warning" size="mini" class="buttonfortable" @click.stop="offMeter(scope.row.roomId,'3')" v-if="watermeteType!='0'">{{watermeteName1}}断水</el-button>
						<el-button type="warning" size="mini" class="buttonfortable" @click.stop="offMeter(scope.row.roomId,'4')" v-if="watermeteType=='2'">{{watermeteName2}}断水</el-button>
					</template>
				</el-table-column> -->
			</el-table>
		</div>
	</div>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted,
		onActivated,
		getCurrentInstance
	} from 'vue'
	import {
		ElMessageBox
	} from 'element-plus'
	import {
		GetServerData
	} from '@/http/index.js'
	// 获取全局this，proxy相当于vue2中的this，用于调用全局对象使用
	const {
		proxy
	} = getCurrentInstance()
	const treeAreaRef = ref()
	const treeProps = reactive({
		children: 'children',
		label: 'name'
	})
	// 定义查询参数
	let params = reactive({
		keyword: '',
		category:'3'
	})

	let watermeterType=ref(localStorage.getItem("watermeterType"));
	let watermeterName1=ref(localStorage.getItem("watermeterName1"));
	let watermeterName2=ref(localStorage.getItem("watermeterName2"));

	// 定义表格数据
	let tableDatas = ref([])
	let roomDatas = ref([])
	let areaDatas = ref([])
	let saveDatas = ref([])
	let selectedArea=ref()

	let headerCellStype = ref(proxy.$headerCellStype)
	let contentCellStyle = ref(proxy.$contentCellStyle)
	// 定义选中的表格行
	let multipleSelection = reactive([])
	// 定义表格引用，用来执行多选
	const multipleTable = ref()

	// 定义表格隔行变色，暂时没用到
	const tableRowClassName = ({
		row,
		rowIndex
	}) => {
		if (rowIndex % 2 === 0) {
			return 'even-row'
		} else {
			return 'odd-row'
		}
	}
	// 定义表格多选
	const handleSelectionChange = (val) => {
		multipleSelection = val;
	}
	const toggleSelection = (rows) => {
		if (rows) {
			rows.forEach(row => {
				multipleTable.value.toggleRowSelection(row);
			});
		} else {
			multipleTable.value.clearSelection();
		}
	}
	const rowclick = (row, column, event) => {
		toggleSelection([row])
	}
	
	const categoryChange = async () => {
		let loading = proxy.$tools.ShowLoading();
		tableDatas.value = []
		await GetRoomData()
		if (params.keyword.length > 0){
			search()
		}else{
			if(selectedArea&&selectedArea.value){
				areaSelected(selectedArea.value)
			}
		}
		loading.close()
	}

	const areaSelected = (area) => {
		let loading = proxy.$tools.ShowLoading();
		selectedArea.value=area;
		setTimeout(()=>{
			tableDatas.value = []
			let grade = area.grade
			let id = area.id
			if (grade == "1") {
				tableDatas.value = roomDatas.value.filter(room => room.areaId1 == id)
			} else if (grade == "2") {
				tableDatas.value = roomDatas.value.filter(room => room.areaId2 == id)
			} else if (grade == "3") {
				tableDatas.value = roomDatas.value.filter(room => room.areaId3 == id)
			}
			loading.close();
		},300)
	}
	const resetResult=()=>{
		if(tableDatas.value.length){
			for (let j = 0; j < tableDatas.value.length; j++) {
				tableDatas.value[j].message = ''
			}
		}
	}

	const search = () => {
		if (params.keyword.length > 0) {
			let loading = proxy.$tools.ShowLoading();
			setTimeout(()=>{
				tableDatas.value = []
				tableDatas.value = roomDatas.value.filter(room =>
					room.areaName1?.indexOf(params.keyword) > -1 ||
					room.areaName2?.indexOf(params.keyword) > -1 || 
					room.areaName3?.indexOf(params.keyword) > -1 || 
					room.roomName?.indexOf(params.keyword) > -1 || 
					room.roomNum?.indexOf(params.keyword) > -1 || 
					room.familyName?.toString().indexOf(params.keyword) > -1 || 
					room.familyPhone?.toString().indexOf(params.keyword) > -1
				)
				loading.close();
			},300)
		} else {
			proxy.$tools.ShowMessage('请输入关键字', false)
		}
	}

	const onManyMeter=()=>{
		if (multipleSelection.length > 0) {
			onOffMany('1');
		}else {
			proxy.$tools.ShowMessage('请选择需要操作的房间', false)
		}
	}
	const offManyMeter=()=>{
		if (multipleSelection.length > 0) {
			onOffMany('2');
		}else {
			proxy.$tools.ShowMessage('请选择需要操作的房间', false)
		}
	}
	// const onMeter=(roomId,category)=>{
	// 	onOffOne(roomId,category,'1');
	// }
	// const offMeter=(roomId,category)=>{
	// 	onOffOne(roomId,category,'2');
	// }
	const onOffMany=async (action)=>{
		let loading = proxy.$tools.ShowLoading()
		resetResult();
		for (let i = 0; i < multipleSelection.length; i++) {
			let roomId = multipleSelection[i].roomId
			let response = await GetServerData('OnOffMeter/OnOffMeter', {
				roomId:roomId,
				category:params.category,
				action:action
			})
			let message =  response.message
			for (let j = 0; j < tableDatas.value.length; j++) {
				if (tableDatas.value[j].roomId == roomId) {
					tableDatas.value[j].message = message
					break;
				}
			}
		}
		loading.close()
	}
	// const onOffOne=async (roomId,category,action)=>{
	// 	let loading = proxy.$tools.ShowLoading()
	// 		let response = await GetServerData('OnOffMeter/OnOffMeter', {
	// 			roomId:roomId,
	// 			category:category,
	// 			action:action
	// 		})
	// 		let message =  response.message
	// 		for (let j = 0; j < tableDatas.value.length; j++) {
	// 			if (tableDatas.value[j].roomId == roomId) {
	// 				tableDatas.value[j].message = message
	// 				break;
	// 			}
	// 		}
	// 	loading.close()
	// }

	
	// 定义获取表格数据函数
	const GetRoomData = async () => {
		let response = await GetServerData('OnOffMeter/QueryAllRoom' + (params.category == '3' ? '3s' : '4s'))
		if (response.status == 1) {
			roomDatas.value = response.data
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}

	// 定义获取表格数据函数
	const GetAreaData = async () => {
		let response = await GetServerData('OnOffMeter/QueryAllAreas')
		if (response.status == 1) {
			areaDatas.value = response.data
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}

	// 定义获取所有数据函数
	const GetAllData = async () => {
		await GetRoomData()
		await GetAreaData()
	}
	
	const refresh=async ()=>{
		let loading = proxy.$tools.ShowLoading();
		tableDatas.value=[];
		await GetAllData()
		loading.close()
	}

	let areaHeight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60))
	let tableheight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60))
	// 定义加载事件
	onMounted(async () => {
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth - 425) +
			'px'
		window.onresize = () => {
			tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
			areaHeight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
			document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
					425) +
				'px'
		}
		let loading = proxy.$tools.ShowLoading();
		await GetAllData()
		loading.close()
		//console.log("watermeterType:"+watermeterType.value)
	})
	onActivated(() => {
		tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
		areaHeight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
				425) +
			'px'
	})
</script>

<style scoped lang="less">
</style>
