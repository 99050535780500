<template>
	<div class="contentheader">
		<el-space wrap>
			<span class="titleClass">购买电量</span>
			<el-divider direction="vertical" />
			<el-input v-model="params.keyword" placeholder="输入关键字" size="small" style="width: 150px;"
				autocomplete="off" />
			<el-button type="primary" size="small" class="buttonforheader" @click="search">查 询</el-button>
			<el-button type="primary" size="small" class="buttonforheader" @click="refresh">刷 新</el-button>
		</el-space>
	</div>
	<div style="display: flex; flex-direction: row; flex-wrap: nowrap; align-items: flex-start;margin: 10px;">
		<div style="width: 200px;">
			<el-scrollbar :height="areaHeight" style="width:200px; border-radius: 6px;background-color: white;">
				<el-tree ref="treeAreaRef" :data="areaDatas" node-key="id" :expand-on-click-node="false"
					:props="treeProps" @node-click="areaSelected" style="margin: 10px;" highlight-current>
				</el-tree>
			</el-scrollbar>
		</div>
		<div id="divTableData" style="width: 100%;border-radius: 6px;background-color: white; margin-left: 5px;">
			<el-table :data="tableDatas" fit="false" highlight-current-row="true" ref="multipleTable"
				tooltip-effect="dark" :height="tableheight" @selection-change="handleSelectionChange"
				@row-click="rowclick" row-key="id" default-expand-all :header-cell-style="headerCellStype"
				:cell-style="contentCellStyle" style="width: 100%; cursor: pointer;" :row-class-name="tableRowClassName"
				>
				<el-table-column fixed prop="roomId" label="roomId" width="0" v-if="false" />
				<el-table-column fixed prop="ammeterId" label="ammeterId" width="0" v-if="false" />
				<el-table-column fixed prop="ammeterPrice" label="ammeterPrice" width="0" v-if="false" />
				<el-table-column fixed prop="ammeterPayType" label="ammeterPayType" width="0" v-if="false" />
				<el-table-column fixed prop="priceCategory" label="priceCategory" width="0" v-if="false" />
				<el-table-column fixed prop="ammeterId2" label="ammeterId2" width="0" v-if="false" />
				<el-table-column fixed prop="ammeterPrice2" label="ammeterPrice2" width="0" v-if="false" />
				<el-table-column fixed prop="ammeterPayType2" label="ammeterPayType2" width="0" v-if="false" />
				<el-table-column fixed prop="priceCategory2" label="priceCategory2" width="0" v-if="false" />
				<el-table-column fixed prop="balance" label="balance" width="0" v-if="false" />
				<el-table-column prop="areaName1" label="一级区域" width="100" />
				<el-table-column prop="areaName2" label="二级区域" width="100" />
				<el-table-column prop="areaName3" label="三级区域" width="100" />
				<el-table-column prop="roomName" label="房间名称" width="100" />
				<el-table-column prop="roomNum" label="房间编号" width="100" />
				<el-table-column prop="familyName" label="户主名称" width="200" />
				<el-table-column prop="familyPhone" label="户主电话" width="150" align="center" />
				<el-table-column />
				<el-table-column fixed="right" label="操作" width="250" align="left">
					<template #default="scope">
						<el-button size="mini" @click.stop="chargeAmmeter1(scope.row)" type="success"
							class="buttonfortable" v-if="ammeterType!='0'&&scope.row.ammeterPayType=='1'&&scope.row.priceCategory=='1'">
							{{'购'+ammeterName1+'电量'}}
						</el-button>
						<el-button size="mini" @click.stop="chargeAmmeter2(scope.row)" type="warning"
							class="buttonfortable" v-if="ammeterType=='2'&&scope.row.ammeterPayType2=='1'&&scope.row.priceCategory2=='1'">
							{{'购'+ammeterName2+'电量'}}
						</el-button>
						<el-button size="mini" @click.stop="chargeAmmeterBalance1(scope.row)" type="success"
							class="buttonfortable" v-if="ammeterType!='0'&&scope.row.ammeterPayType=='1'&&scope.row.priceCategory=='2'">
							{{ammeterName1+'充值'}}
						</el-button>
						<el-button size="mini" @click.stop="chargeAmmeterBalance2(scope.row)" type="warning"
							class="buttonfortable" v-if="ammeterType=='2'&&scope.row.ammeterPayType2=='1'&&scope.row.priceCategory2=='2'">
							{{ammeterName2+'充值'}}
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
	<el-dialog width="1000px" v-model="dialogVisible" append-to-body=true :title="dialogtitle" center draggable
		:close-on-click-modal="false" :show-close="true">
		<el-form :model="formdata" ref="formRef" class="demo-ruleForm formContent">
			<el-row>
				<el-col :span="12">
					<el-form-item prop="price" label="单价" :label-width="formLabelWidth">
						<el-input v-model="formdata.price" autocomplete="off" :style="formContentStyle" readonly>
							<template #append>元/度</template>
						</el-input>
					</el-form-item>
					<el-form-item prop="amount" :rules="[{ required: true, message: '请输入数量',trigger:'blur' }]" label="数量"
						:label-width="formLabelWidth">
						<el-input v-model="formdata.amount" autocomplete="off" :style="formContentStyle" type="number" oninput="value=value.replace(/[^\d]/g,'')"
							@focus="selectAllText($event)" @blur="calcTotalMoney">
							<template #append>度</template>
						</el-input>
					</el-form-item>
					<el-form-item prop="totalMoney" :rules="[{ required: true, message: '请输入总价',trigger:'blur'}]" label="总价"
						:label-width="formLabelWidth">
						<el-input v-model="formdata.totalMoney" autocomplete="off" :style="formContentStyle" type="number" oninput="value=value.replace(/[^\d]/g,'')"
							@focus="selectAllText($event)" @blur="calcAmount">
							<template #append>元</template>
						</el-input>
					</el-form-item>
					<el-form-item prop="realPayMoney" :rules="[{ required: true, message: '请输入实缴金额',trigger:'blur' }]" label="实缴金额"
						:label-width="formLabelWidth">
						<el-input v-model="formdata.realPayMoney" autocomplete="off" :style="formContentStyle" type="number" oninput="value=value.replace(/[^\d]/g,'')"
							@focus="selectAllText($event)" @blur="calcBalance" >
							<template #append>元</template>
						</el-input>
					</el-form-item>
					<el-form-item prop="payType" :rules="[{ required: true, message: '请选择缴费方式' }]" label="缴费方式"
						:label-width="formLabelWidth">
						<el-select v-model="formdata.payType" placeholder="请选择缴费方式" :style="formContentStyle">
							<el-option v-for="item in payTypes" :key="item.value" :label="item.label"
								:value="item.value" />
						</el-select>
					</el-form-item>
					<el-form-item prop="remark" label="备注" :label-width="formLabelWidth">
						<el-input v-model="formdata.remark" autocomplete="off" :style="formContentStyle" type="textarea"  maxlength="200"
							:rows="2" />
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item prop="oldValue" label="原始剩余量" :label-width="formLabelWidth">
						<el-input v-model="formdata.oldValue" autocomplete="off" :style="formContentStyle" readonly  type="number">
							<template #append>度</template>
						</el-input>
					</el-form-item>
					<el-form-item prop="newValue" label="新剩余量" :label-width="formLabelWidth">
						<el-input v-model="formdata.newValue" autocomplete="off" :style="formContentStyle" readonly  type="number">
							<template #append>度</template>
						</el-input>
					</el-form-item>
					<el-form-item prop="shouldPayMoney" label="应缴金额" :label-width="formLabelWidth">
						<el-input v-model="formdata.shouldPayMoney" autocomplete="off" :style="formContentStyle" type="number"
							readonly >
							<template #append>元</template>
						</el-input>
					</el-form-item>
					<el-form-item prop="oldBalance" label="原始余额" :label-width="formLabelWidth">
						<el-input v-model="formdata.oldBalance" autocomplete="off" :style="formContentStyle" readonly  type="number">
							<template #append>元</template>
						</el-input>
					</el-form-item>
					<el-form-item prop="newBalance" label="新余额" :label-width="formLabelWidth">
						<el-input v-model="formdata.newBalance" autocomplete="off" :style="formContentStyle" readonly  type="number">
							<template #append>元</template>
						</el-input>
					</el-form-item>
					<el-form-item prop="turnToBalance" label="转入余额" :label-width="formLabelWidth">
						<el-input v-model="formdata.turnToBalance" autocomplete="off" :style="formContentStyle" type="number"
							readonly>
							<template #append>元</template>
						</el-input>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button size="mini" type="primary" @click="submitForm(formRef)" class="buttonforform">{{dialogbtnname}}
				</el-button>
			</span>
		</template>
	</el-dialog>
	
	<el-dialog width="1000px" v-model="dialogVisible2" append-to-body=true :title="dialogtitle2" center draggable
		:close-on-click-modal="false" :show-close="true">
		<el-form :model="formdata2" ref="formRef2" class="demo-ruleForm formContent">
			<el-form-item prop="oldBalance" label="原始余额" :label-width="formLabelWidth">
				<el-input v-model="formdata2.oldBalance" autocomplete="off" :style="formContentStyle" readonly  type="number">
					<template #append>元</template>
				</el-input>
			</el-form-item>
			<el-form-item prop="newBalance" label="新余额" :label-width="formLabelWidth">
				<el-input v-model="formdata2.newBalance" autocomplete="off" :style="formContentStyle" readonly  type="number">
					<template #append>元</template>
				</el-input>
			</el-form-item>
			<el-form-item prop="dealMoney" label="充值金额" :rules="[{ required: true, message: '请输入充值金额',trigger:'blur' }]"
				:label-width="formLabelWidth">
				<el-input ref="txtPayMoneyRef" v-model="formdata2.dealMoney" autocomplete="off" :style="formContentStyle" type="number"
					@blur="calcBalance2" @focus="selectAllText($event)" autofocus >
					<template #append>元</template>
				</el-input>
			</el-form-item>
			<el-form-item prop="payType" :rules="[{ required: true, message: '请输选择缴费方式' }]" label="缴费方式"
				:label-width="formLabelWidth">
				<el-select v-model="formdata2.payType" placeholder="请选择缴费方式" :style="formContentStyle">
					<el-option v-for="item in payTypes" :key="item.value" :label="item.label" :value="item.value" />
				</el-select>
			</el-form-item>
			<el-form-item prop="remark" label="备注" :label-width="formLabelWidth">
				<el-input v-model="formdata2.remark" autocomplete="off" :style="formContentStyle" type="textarea"  maxlength="200"
					:rows="2" />
			</el-form-item>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button size="mini" type="primary" @click="submitForm2(formRef2)" class="buttonforform">{{dialogbtnname2}}
				</el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted,
		onActivated,
		getCurrentInstance
	} from 'vue'
	import {
		ElMessageBox
	} from 'element-plus'
	import {
		GetServerData
	} from '@/http/index.js'
	// 获取全局this，proxy相当于vue2中的this，用于调用全局对象使用
	const {
		proxy
	} = getCurrentInstance()
	let payTypes = reactive([{
			label: '现金',
			value: '1'
		},
		{
			label: '微信',
			value: '2'
		},
		{
			label: '支付宝',
			value: '3'
		},
		{
			label: '银联',
			value: '4'
		},
		{
			label: '其他',
			value: '5'
		}
	])
	
	let ammeterType=ref(localStorage.getItem("ammeterType"));
	let ammeterName1=ref(localStorage.getItem("ammeterName1"));
	let ammeterName2=ref(localStorage.getItem("ammeterName2"));
	
	// 定义表单标题
	let dialogtitle = ref("购 买 电 量")
	let dialogbtnname=ref("购 买 电 量")
	// 定义表单显示与隐藏
	let dialogVisible = ref(false)
	// 定义表单标题
	let dialogtitle2 = ref("充 值 金 额")
	let dialogbtnname2=ref("充 值 金 额")
	// 定义表单显示与隐藏
	let dialogVisible2 = ref(false)
	// 定义表单引用，用来执行表单验证
	const formRef = ref()
	const formRef2 = ref()
	const txtPayMoneyRef = ref()
	// 定义表单项的头部宽度
	const formLabelWidth = ref('150px')
	// 定义表单项的内容宽度
	const formContentStyle = ref('width:300px')
	// 定义表单数据
	let formdata = reactive({
		oldBalance: 0.0,
		price: 0.0,
		amount: 0.0,
		newBalance: 0.0,
		totalMoney: 0.0,
		shouldPayMoney: 0.0,
		realPayMoney: 0.0,
		turnToBalance: 0.0,
		remark: '',
		payType: '',
		roomId: '',
		category: '1',
		oldValue: 0.0,
		newValue: 0.0
	})
	let formdata2 = ref({
		oldBalance: 0.0,
		dealMoney: 0.0,
		newBalance: 0.0,
		remark: '',
		payType: '1',
		roomId: '',
		category: '1'
	})
	let currentRow = ref()
	let paymentId = ref('')
	const treeAreaRef = ref()
	const treeProps = reactive({
		children: 'children',
		label: 'name'
	})
	// 定义查询参数
	let params = reactive({
		keyword: ''
	})


	// 定义表格数据
	let tableDatas = ref([])
	let roomDatas = ref([])
	let areaDatas = ref([])

	let headerCellStype = ref(proxy.$headerCellStype)
	let contentCellStyle = ref(proxy.$contentCellStyle)
	// 定义选中的表格行
	let multipleSelection = reactive([])
	// 定义表格引用，用来执行多选
	const multipleTable = ref()

	// 定义表格隔行变色，暂时没用到
	const tableRowClassName = ({
		row,
		rowIndex
	}) => {
		if (rowIndex % 2 === 0) {
			return 'even-row'
		} else {
			return 'odd-row'
		}
	}

	const areaSelected = (area) => {
		let loading = proxy.$tools.ShowLoading();
		setTimeout(()=>{
			tableDatas.value = []
			let grade = area.grade
			let id = area.id
			if (grade == "1") {
				tableDatas.value = roomDatas.value.filter(room => room.areaId1 == id)
			} else if (grade == "2") {
				tableDatas.value = roomDatas.value.filter(room => room.areaId2 == id)
			} else if (grade == "3") {
				tableDatas.value = roomDatas.value.filter(room => room.areaId3 == id)
			}
			loading.close();
		},300)
	}

	const calcTotalMoney = () => {
		formdata.amount = parseFloat((parseInt((parseFloat(formdata.amount.toString()) * 10).toString())/10).toFixed(1));
		if(formdata.amount>0){
			formdata.newValue = parseFloat((formdata.amount + parseFloat(formdata.oldValue)).toFixed(1));
			formdata.totalMoney = parseFloat((formdata.price * formdata.amount).toFixed(2));
			formdata.shouldPayMoney = formdata.totalMoney;
			formdata.oldBalance=parseFloat(parseFloat(formdata.oldBalance).toFixed(2));
			if(formdata.oldBalance>=formdata.totalMoney){
				formdata.realPayMoney=0.0;
				formdata.newBalance=parseFloat((formdata.oldBalance-formdata.totalMoney).toFixed(2));
			}else{
				formdata.realPayMoney=parseFloat((formdata.totalMoney-formdata.oldBalance).toFixed(2));
				formdata.newBalance=0.0;
			}
			calcBalance();
		}else{
			formdata.amount=0;
			formdata.newValue = parseFloat((parseFloat(formdata.oldValue)).toFixed(1));
			formdata.totalMoney = 0;
			formdata.shouldPayMoney = formdata.totalMoney;
			formdata.oldBalance=parseFloat(parseFloat(formdata.oldBalance).toFixed(2));
			formdata.realPayMoney=0.0;
			formdata.newBalance=parseFloat((formdata.oldBalance).toFixed(2));
			formdata.turnToBalance=0;
		}
	}

	const calcAmount = () => {
		formdata.totalMoney=parseFloat(parseFloat(formdata.totalMoney).toFixed(2));
		if(formdata.totalMoney>0){
			formdata.amount = parseFloat((parseInt((formdata.totalMoney / formdata.price * 10).toString())/10).toFixed(1));
			formdata.newValue = parseFloat((formdata.amount + parseFloat(formdata.oldValue)).toFixed(1));
			formdata.shouldPayMoney = formdata.totalMoney;
			formdata.oldBalance=parseFloat(parseFloat(formdata.oldBalance).toFixed(2));
			if(formdata.oldBalance>=formdata.totalMoney){
				formdata.realPayMoney=0.0;
				formdata.newBalance=parseFloat((formdata.oldBalance-formdata.totalMoney).toFixed(2));
			}else{
				formdata.realPayMoney=parseFloat((formdata.totalMoney-formdata.oldBalance).toFixed(2));
				formdata.newBalance=0.0;
			}
			calcBalance();
		}else{
			formdata.amount=0;
			formdata.newValue = parseFloat((parseFloat(formdata.oldValue)).toFixed(1));
			formdata.totalMoney = 0;
			formdata.shouldPayMoney = formdata.totalMoney;
			formdata.oldBalance=parseFloat(parseFloat(formdata.oldBalance).toFixed(2));
			formdata.realPayMoney=0.0;
			formdata.newBalance=parseFloat((formdata.oldBalance).toFixed(2));
			formdata.turnToBalance=0;
		}
	}

	const calcBalance = () => {
		formdata.newBalance = parseFloat((parseFloat(formdata.realPayMoney) - parseFloat(formdata.shouldPayMoney) + parseFloat(formdata.oldBalance)).toFixed(2));
		formdata.turnToBalance = parseFloat((formdata.newBalance - parseFloat(formdata.oldBalance)).toFixed(2));
		if (formdata.turnToBalance < 0) {
			formdata.turnToBalance = 0.0;
		}
		formdata.newBalance = formdata.newBalance;
	}
	
	const calcBalance2 = () => {
		if (formdata2.value.dealMoney) {
			formdata2.value.dealMoney=parseFloat(parseFloat(formdata2.value.dealMoney).toFixed(2));
			formdata2.value.newBalance = parseFloat((parseFloat(formdata2.value.oldBalance) + formdata2.value.dealMoney).toFixed(2));
		}
	}

	const selectAllText = (event) => {
		event.currentTarget.select();
	}

	const chargeAmmeter1 = (row) => {
		if(row.ammeterId&&row.ammeterId.length>0){
			if(row.ammeterPayType&&row.ammeterPayType=='1'){
				dialogtitle.value = '('+row.areaName1+'-'+row.areaName2+'-'+row.areaName3+'-'+row.roomName+'-'+row.roomNum+')'+'购'+ammeterName1.value+'电量';
				dialogbtnname.value = '购'+ammeterName1.value+'电量';
				charge(row, "1");
			}else{
				proxy.$tools.ShowMessage(ammeterName1.value+'是后付费，不能进行购电操作！', false)
			}
		}else{
			proxy.$tools.ShowMessage('没有'+ammeterName1.value+'，无法进行操作！', false)
		}
	}
	
	const chargeAmmeter2 = (row) => {
		if(row.ammeterId2&&row.ammeterId2.length>0){
			if(row.ammeterPayType2&&row.ammeterPayType2=='1'){
				dialogtitle.value = '('+row.areaName1+'-'+row.areaName2+'-'+row.areaName3+'-'+row.roomName+'-'+row.roomNum+')'+'购'+ammeterName2.value+'电量';
				dialogbtnname.value = '购'+ammeterName2.value+'电量';
				charge(row, "2");
			}else{
				proxy.$tools.ShowMessage(ammeterName2.value+'是后付费，不能进行购电操作！', false)
			}
		}else{
			proxy.$tools.ShowMessage('没有'+ammeterName2.value+'，无法进行操作！', false)
		}
	}
	
	const chargeAmmeterBalance1 = (row) => {
		if(row.ammeterId&&row.ammeterId.length>0){
			if(row.ammeterPayType&&row.ammeterPayType=='1'){
				if(row.priceCategory&&row.priceCategory=='2'){
					dialogtitle2.value = '('+row.areaName1+'-'+row.areaName2+'-'+row.areaName3+'-'+row.roomName+'-'+row.roomNum+')'+ammeterName1.value+'充值';
					dialogbtnname2.value = ammeterName1.value+'充值';
					chargeMoney(row, "1");
				}else{
					proxy.$tools.ShowMessage(ammeterName1.value+'不是复费率，不能进行表端充值操作！', false)
				}
			}else{
				proxy.$tools.ShowMessage(ammeterName1.value+'是后付费，不能进行表端充值操作！', false)
			}
		}else{
			proxy.$tools.ShowMessage('没有'+ammeterName1.value+'，无法进行操作！', false)
		}
	}
	
	const chargeAmmeterBalance2 = (row) => {
		if(row.ammeterId2&&row.ammeterId2.length>0){
			if(row.ammeterPayType2&&row.ammeterPayType2=='1'){
				if(row.priceCategory2&&row.priceCategory2=='2'){
					dialogtitle2.value = '('+row.areaName1+'-'+row.areaName2+'-'+row.areaName3+'-'+row.roomName+'-'+row.roomNum+')'+ammeterName2.value+'充值';
					dialogbtnname2.value = ammeterName2.value+'充值';
					chargeMoney(row, "2");
				}else{
					proxy.$tools.ShowMessage(ammeterName2.value+'不是复费率，不能进行表端充值操作！', false)
				}
			}else{
				proxy.$tools.ShowMessage(ammeterName2.value+'是后付费，不能进行表端充值操作！', false)
			}
		}else{
			proxy.$tools.ShowMessage('没有'+ammeterName2.value+'，无法进行操作！', false)
		}
	}
	
	const chargeMoney = async (row, temp) => {
		let loading = proxy.$tools.ShowLoading();
		let response = await GetServerData('ChargeMeter/ReadMeter', {
			"roomId": row.roomId,
			"category": temp
		})
		//response.status=1
		if (response.status == 1) 
		{
			if (formRef2.value) {
				formRef2.value.resetFields();
			}
			console.log(row.roomId+" | "+response.data)
			proxy.$tools.ShowMessage('获取剩余金额成功', true)
			formdata2.value.oldBalance = parseFloat(response.data);
			console.log(formdata2.value.oldBalance)
			formdata2.value.category = temp;
			formdata2.value.dealMoney = 0.0;
			formdata2.value.newBalance = formdata2.value.oldBalance;
			formdata2.value.remark = '';
			formdata2.value.roomId = row.roomId;
			formdata2.value.payType = '';
			
			dialogVisible2.value = true;
		} 
		else if(response.status == 2){
			proxy.$tools.ShowMessage(response.message, false,proxy.$config.showImportantTipsSecond)
		}else{
			proxy.$tools.ShowMessage('获取剩余金额失败', false)
		}
		loading.close()
	}

	const charge = async (row, temp) => {
		let loading = proxy.$tools.ShowLoading();
		let response = await GetServerData('ChargeMeter/ReadMeter', {
			"roomId": row.roomId,
			"category": temp
		})
		if (response.status == 1) 
		{
			if (formRef.value) {
				formRef.value.resetFields();
			}
			proxy.$tools.ShowMessage('获取剩余量成功', true)
			formdata.oldValue = parseFloat(response.data).toFixed(1);
			formdata.category = temp;
			if (temp == "1") {
				formdata.price = row.ammeterPrice;
			} else if (temp == "2") {
				formdata.price = row.ammeterPrice2;
			} 
			formdata.newValue = 0.0;
			formdata.oldBalance = row.balance;
			formdata.newBalance = row.balance;
			formdata.turnToBalance = 0.0;
			formdata.shouldPayMoney = 0.0;
			formdata.realPayMoney = 0.0;
			formdata.remark = '';
			formdata.amount = 0.0;
			formdata.totalMoney = 0.0;
			formdata.roomId = row.roomId;
			formdata.payType = '';
			dialogVisible.value = true;
		} 
		else {
			proxy.$tools.ShowMessage('获取剩余量失败！' + response.message, false)
		}
		loading.close()
	}
	// 定义表单提交事件，带表单验证
	const submitForm = (form) => {
		//console.log(formdata)
		form.validate(async (valid) => {
			if (valid) {
				if (formdata.amount > 0 && formdata.totalMoney > 0) {
					if (formdata.newBalance >= 0) {
						let loading = proxy.$tools.ShowLoading();
						let response = await GetServerData('ChargeMeter/ChargeMeter', {
							"roomId": formdata.roomId,
							"amount": formdata.amount,
							"shouldPayMoney":formdata.shouldPayMoney,
							"realPayMoney": formdata.realPayMoney,
							"payType": formdata.payType,
							"category": formdata.category,
							"remark": formdata.remark
						})
						if (response.status == 1) {
							proxy.$tools.ShowMessageDuration(response.message, true, proxy.$config.showImportantTipsSecond)
							paymentId.value = response.data
						} else {
							proxy.$tools.ShowMessageDuration(response.message, false, proxy.$config.showImportantTipsSecond)
						}
						dialogVisible.value = false
						loading.close()
					} else {
						proxy.$tools.ShowMessage('请输入正确实缴金额，再提交！', false)
					}
				} else {
					proxy.$tools.ShowMessage('请输入正确数量和总价，再提交！', false)
				}
			} else {
				proxy.$tools.ShowMessage('请您正确填写所有必填信息后，再提交！', false)
				return false;
			}
		})
	}
	
	const submitForm2 = (form) => {
		//console.log(formdata)
		form.validate(async (valid) => {
			if (valid) {
				if (formdata2.value.dealMoney > 0 && formdata2.value.newBalance > 0) {
						let loading = proxy.$tools.ShowLoading();
						let response = await GetServerData('ChargeMeter/ChargeMeterBalance', {
							"roomId": formdata2.value.roomId,
							"amount": formdata2.value.dealMoney,
							"realPayMoney": formdata2.value.dealMoney,
							"payType": formdata2.value.payType,
							"category": formdata2.value.category,
							"remark": formdata2.value.remark
						})
						if (response.status == 1) {
							proxy.$tools.ShowMessageDuration(response.message, true, proxy.$config.showImportantTipsSecond)
							paymentId.value = response.data
						} else {
							proxy.$tools.ShowMessageDuration(response.message, false, proxy.$config.showImportantTipsSecond)
						}
						dialogVisible2.value = false
						loading.close()
				} else {
					proxy.$tools.ShowMessage('请输入正确的充值金额，再提交！', false)
				}
			} else {
				proxy.$tools.ShowMessage('请您正确填写所有必填信息后，再提交！', false)
				return false;
			}
		})
	}

	const search = () => {
		if (params.keyword.length > 0) {
			let loading = proxy.$tools.ShowLoading();
			setTimeout(()=>{
				tableDatas.value = []
				tableDatas.value = roomDatas.value.filter(room =>
					room.areaName1?.indexOf(params.keyword) > -1 ||
					room.areaName2?.indexOf(params.keyword) > -1 || 
					room.areaName3?.indexOf(params.keyword) > -1 || 
					room.roomName?.indexOf(params.keyword) > -1 || 
					room.roomNum?.indexOf(params.keyword) > -1 || 
					room.familyName?.toString().indexOf(params.keyword) > -1 || 
					room.familyPhone?.toString().indexOf(params.keyword) > -1
				)
				loading.close();
			},300)
		} else {
			proxy.$tools.ShowMessage('请输入关键字', false)
		}
	}


	// 定义获取表格数据函数
	const GetRoomData = async () => {
		let response = await GetServerData('ChargeMeter/QueryAllRooms')
		if (response.status == 1) {
			roomDatas.value = response.data
			//console.log(roomDatas.value)
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}

	// 定义获取表格数据函数
	const GetAreaData = async () => {
		let response = await GetServerData('ChargeMeter/QueryAllAreas')
		if (response.status == 1) {
			areaDatas.value = response.data
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}

	// 定义获取所有数据函数
	const GetAllData = async () => {
		await GetRoomData();
		console.log("getalldatadone1");
		await GetAreaData();
		console.log("getalldatadone2");
	}
	
	const refresh=async ()=>{
		let loading = proxy.$tools.ShowLoading();
		tableDatas.value=[];
		await GetAllData()
		loading.close()
	}

	let areaHeight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60))
	let tableheight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60))
	// 定义加载事件
	onMounted(async () => {
		//ammeterType.value=localStorage.getItem("ammeterType");
		//ammeterName1.value=localStorage.getItem("ammeterName1");
		//ammeterName2.value=localStorage.getItem("ammeterName2");
		
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth - 425) +
			'px'
		window.onresize = () => {
			tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
			areaHeight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
			document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
					425) +
				'px'
		}
		let loading = proxy.$tools.ShowLoading();
		await GetAllData()
		loading.close()
	})
	onActivated(() => {
		tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
		areaHeight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
				425) +
			'px'
	})
</script>

<style scoped lang="less">
	
</style>
