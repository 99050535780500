<template>
	<div class="contentheader">
		<el-space wrap>
			<span class="titleClass">电表管理</span>
			<el-divider direction="vertical" />
			<el-cascader v-model="querySelectedArea" :options="areas" :props="{
						value:'id',
						label:'name',
						checkStrictly: true
					}" @change="handleQueryChange" placeholder="选择区域" style="width: 300px;" size="small"></el-cascader>
			<el-select v-model="params.payType" placeholder="选择付费类型" clearable style="width: 150px;" size="small">
				<el-option v-for="item in payTypes" :key="item.value" :label="item.label" :value="item.value" />
			</el-select>
			<el-select v-model="params.category" placeholder="选择电表类型" clearable style="width: 150px;" size="small">
				<el-option v-for="item in categorys" :key="item.value" :label="item.label" :value="item.value" />
			</el-select>
			<el-button type="primary" size="small" class="buttonforheader" @click="search">查 询</el-button>
			<el-button type="primary" size="small" class="buttonforheader" @click="refresh">刷 新</el-button>
			<el-divider direction="vertical" />
			<el-button type="success" size="small" class="buttonforheader" @click="add">新 增</el-button>
			<el-button type="warning" size="small" class="buttonforheader" @click="deletesome">批量删除</el-button>
			<!-- <el-divider direction="vertical" />
			<el-button type="danger" size="small" class="buttonforheader" @click="setTotalAmount" v-if="false">预置用电量</el-button>
			<el-button type="danger" size="small" class="buttonforheader" @click="setStartPower" v-if="false">设置启动功率</el-button>
			<el-button type="danger" size="small" class="buttonforheader" @click="setLimitPower" v-if="false">设置限定功率</el-button>
			<el-button type="danger" size="small" class="buttonforheader" @click="setTimer" v-if="false">定时断送电设置</el-button>
			<el-button type="danger" size="small" class="buttonforheader" @click="setOnOff" v-if="false">断送电</el-button> -->
			
			<!-- <el-button type="primary" size="small" class="buttonforheader" @click="resetparams">重 置</el-button> -->
		</el-space>
	</div>
	<div id="divTableData" class="contentcontent">
		<el-table :data="tableDatas" fit="false" highlight-current-row="true" ref="multipleTable" tooltip-effect="dark"
			:height="tableheight" @selection-change="handleSelectionChange" @row-click="rowclick" row-key="id"
			default-expand-all :header-cell-style="headerCellStype" :cell-style="contentCellStyle"
			style="width: 100%; cursor: pointer;" :row-class-name="tableRowClassName">
			<el-table-column type="selection" width="55" />
			<el-table-column fixed prop="id" label="id" width="0" v-if="false" />
			<el-table-column fixed prop="areaId1" label="areaId1" width="0" v-if="false" />
			<el-table-column fixed prop="areaId2" label="areaId2" width="0" v-if="false" />
			<el-table-column fixed prop="areaId3" label="areaId3" width="0" v-if="false" />
			<el-table-column fixed prop="protocolId" label="protocolId" width="0" v-if="false" />
			<el-table-column fixed prop="dtuId" label="dtuId" width="0" v-if="false" />
			<el-table-column fixed prop="payType" label="payType" width="0"   v-if="false" />
			<el-table-column fixed prop="category" label="category" width="0"   v-if="false" />
			<el-table-column fixed prop="priceCategory" label="priceCategory" width="0"   v-if="false" />
			<el-table-column fixed prop="storeType" label="storeType" width="0"   v-if="false" />
			<el-table-column fixed prop="canOverdraw" label="canOverdraw" width="0"  v-if="false" />
			<el-table-column fixed prop="canChargeNegative" label="canChargeNegative" width="0"  v-if="false" />
			<el-table-column prop="areaId1Name" label="一级区域" width="100" />
			<el-table-column prop="areaId2Name" label="二级区域" width="100" />
			<el-table-column prop="areaId3Name" label="三级区域" width="100" />
			<el-table-column prop="protocolName" label="协议名称" width="200" />
			<el-table-column prop="dtuName" label="DTU名称" width="200" />
			<el-table-column prop="address" label="地址" width="150" />
			<el-table-column prop="priceCategoryName" label="价格类型" width="100"  align="center" />
			<el-table-column prop="payTypeName" label="付费类型" width="100"  align="center" />
			<el-table-column prop="categoryName" label="电表类型" width="100"  align="center" />
			<el-table-column prop="storeTypeName" label="表端存储" width="100"  align="center" />
			<el-table-column prop="canOverdrawName" label="是否透支" width="100"  align="center" />
			<el-table-column prop="canChargeNegativeName" label="是否可购负数" width="150"  align="center" />
			<el-table-column prop="number" label="出厂编号" width="200" />
			<el-table-column prop="password" label="电表密码" width="100" />
			<el-table-column prop="onePhaseAmount" label="单相数" width="100" />
			<el-table-column prop="threePhaseAmount" label="三相数" width="100" />
			<el-table-column prop="twoCircuitAmount" label="双回路数" width="100" />
			<el-table-column prop="threeCircuitAmount" label="三回路数" width="100" />
			<el-table-column prop="location" label="安装位置" width="200" />
			<el-table-column prop="remark" label="备注" width="250" :show-overflow-tooltip="true" />
			<el-table-column prop="cuName" label="创建人" width="120" />
			<el-table-column prop="muName" label="修改人" width="120" />
			<el-table-column prop="ct" label="创建时间" width="150" align="center" />
			<el-table-column prop="mt" label="修改时间" width="150" align="center" />
			<!-- <el-table-column prop="message" label="信息" width="150" align="center" /> -->
			<el-table-column />
			<el-table-column fixed="right" label="操作" width="150" align="left">
				<template #default="scope">
					<el-button size="mini" @click.stop="modify(scope.row)" type="success" class="buttonfortable">编 辑
					</el-button>
					<el-button size="mini" @click.stop="deleteitem(scope.row)" type="warning" class="buttonfortable">删 除
					</el-button>
				</template>
			</el-table-column>
		</el-table>
	</div>
	<div class="contentpager" v-if="showpaging">
		<el-pagination background layout="prev, pager, next,jumper,sizes,total" :total="pagingInfo.totalCount"
			:page-sizes="[500,1000,2000,3000,4000]" v-model:current-page="params.pageNum"
			v-model:page-size="params.pageSize" @size-change="sizechange" @current-change="currentchange" />
	</div>
	<el-dialog width="1100px" v-model="dialogVisible" append-to-body=true :title="dialogtitle" center draggable
		:close-on-click-modal="false" :show-close="true">
		<el-form :model="formdata" ref="formRef" class="demo-ruleForm formContent">
			<el-row :gutter="20">
				<el-col :span="12">
					<el-form-item prop="areaId3" :rules="[{ required: true, message: '请选择区域' }]" label="选择区域"
						:label-width="formLabelWidth">
						<el-cascader v-model="selectedArea" :options="areas" :props="{
						value:'id',
						label:'name'
					}" @change="handleChange" placeholder="请选择区域" :style="formContentStyle"></el-cascader>
					</el-form-item>
					<el-form-item prop="protocolId" :rules="[{ required: true, message: '请选择协议类型' }]" label="协议类型"
						:label-width="formLabelWidth">
						<el-select v-model="formdata.protocolId" placeholder="请选择协议类型" :style="formContentStyle"
							@change="handleProtocolChange">
							<el-option v-for="item in protocols" :key="item.id" :label="item.name" :value="item.id" />
						</el-select>
					</el-form-item>
					<el-form-item prop="dtuId" :rules="[{ required: true, message: '请选择DTU' }]" label="DTU"
						:label-width="formLabelWidth">
						<el-select v-model="formdata.dtuId" placeholder="请选择DTU" :style="formContentStyle"
							@change="handleDTUChange">
							<el-option v-for="item in dtus" :key="item.id" :label="item.name" :value="item.id" />
						</el-select>
					</el-form-item>
					<el-form-item label="价格类型" :label-width="formLabelWidth">
						<el-radio-group v-model="formdata.priceCategory" class="ml-4">
							<el-radio label="1" size="small" style="margin-left: 20px;">单价</el-radio>
							<el-radio label="2" size="small">复费率</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="付费类型" :label-width="formLabelWidth">
						<el-radio-group v-model="formdata.payType" class="ml-4">
							<el-radio label="1" size="small" style="margin-left: 20px;">预付费</el-radio>
							<el-radio label="2" size="small">后付费</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="电表类型" :label-width="formLabelWidth">
						<el-radio-group v-model="formdata.category" class="ml-4" @change="handleCategoryChange">
							<el-radio label="1" size="small" style="margin-left: 20px;">多用户表</el-radio>
							<el-radio label="2" size="small">单表</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="表端存储模式" :label-width="formLabelWidth">
						<el-radio-group v-model="formdata.storeType" class="ml-4">
							<el-radio label="1" size="small" style="margin-left: 20px;">电量</el-radio>
							<el-radio label="2" size="small">金额</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="是否透支" :label-width="formLabelWidth">
						<el-radio-group v-model="formdata.canOverdraw" class="ml-4">
							<el-radio label="0" size="small" style="margin-left: 20px;">否</el-radio>
							<el-radio label="1" size="small">是</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="是否可购负数" :label-width="formLabelWidth">
						<el-radio-group v-model="formdata.canChargeNegative" class="ml-4">
							<el-radio label="0" size="small" style="margin-left: 20px;">否</el-radio>
							<el-radio label="1" size="small">是</el-radio>
						</el-radio-group>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item prop="address" :rules="[{ required: true, message: '请输入电表地址',trigger:'blur' },{min:1,max:20,message:'电表地址的长度为1-20',trigger:'blur'}]" label="电表地址"
						:label-width="formLabelWidth">
						<el-input v-model="formdata.address" autocomplete="off" :style="formContentStyle" type="number" maxlength="20"
							placeholder="请输入电表地址" />
					</el-form-item>
					<el-form-item prop="location" label="安装位置"
						:label-width="formLabelWidth">
						<el-input v-model="formdata.location" autocomplete="off" :style="formContentStyle"  maxlength="30"
							placeholder="请输入安装位置" />
					</el-form-item>
					<el-form-item label="出厂编号" :label-width="formLabelWidth">
						<el-input v-model="formdata.number" autocomplete="off" :style="formContentStyle" maxlength="30"
							placeholder="请输入出厂编号" />
					</el-form-item>
					<el-form-item prop="onePhaseAmount" label="单相数" :label-width="formLabelWidth"
						:rules="[{ required: true, message: '请输入单相数' ,trigger:'blur'}]">
						<el-input v-model="formdata.onePhaseAmount" autocomplete="off" :style="formContentStyle" type="number"
							placeholder="请输入单相数" />
					</el-form-item>
					<el-form-item prop="threePhaseAmount" label="三相数" :label-width="formLabelWidth"
						:rules="[{ required: true, message: '请输入三相数',trigger:'blur' }]" v-if="formdata.category=='1'">
						<el-input v-model="formdata.threePhaseAmount" autocomplete="off" :style="formContentStyle" type="number"
							placeholder="请输入三相数" />
					</el-form-item>
					<el-form-item prop="twoCircuitAmount" label="双回路数" :label-width="formLabelWidth"
						:rules="[{ required: true, message: '请输入双回路数',trigger:'blur' }]" v-if="formdata.category=='1'">
						<el-input v-model="formdata.twoCircuitAmount" autocomplete="off" :style="formContentStyle" type="number"
							placeholder="请输入双回路数" />
					</el-form-item>
					<el-form-item prop="threeCircuitAmount" label="三回路数" :label-width="formLabelWidth"
						:rules="[{ required: true, message: '请输入三回路数',trigger:'blur' }]" v-if="formdata.category=='1'">
						<el-input v-model="formdata.threeCircuitAmount" autocomplete="off" :style="formContentStyle" type="number"
							placeholder="请输入三回路数" />
					</el-form-item>
					<el-form-item label="电表密码" :label-width="formLabelWidth">
						<el-input v-model="formdata.password" autocomplete="off" :style="formContentStyle" maxlength="8"
							placeholder="请输入密码" />
					</el-form-item>
					<el-form-item label="备注" :label-width="formLabelWidth">
						<el-input v-model="formdata.remark" autocomplete="off" :style="formContentStyle" maxlength="200"
							placeholder="请输入备注" />
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-space wrap :size="30">
					<span v-if="showgoon">是否继续添加：
						<el-switch v-model="goon" style="margin-right: 10px;" />
					</span>
					<el-button size="mini" type="warning" @click="btnreset" class="buttonforform"> 重 置 </el-button>
					<!-- <el-button @click="dialogVisible = false">取消</el-button> -->
					<el-button size="mini" type="primary" @click="submitForm(formRef)" class="buttonforform"> 确 定
					</el-button>
				</el-space>
			</span>
		</template>
	</el-dialog>
	<el-dialog width="500px" v-model="setTimerDialogVisible" append-to-body=true title="设置定时断送电时间" center draggable
		:close-on-click-modal="false" :show-close="true">
		<el-form :model="settimerformdata" ref="setTimerFormRef" class="demo-ruleForm formContent">
			<el-form-item label="请选择定时时段" :label-width="formLabelWidth">
				<el-select v-model="settimerformdata.timerName" placeholder="请选择定时时段" :style="formContentStyle">
					<el-option v-for="item in timerNames" :key="item.value" :label="item.label"
						:value="item.value" />
				</el-select>
			</el-form-item>
			<el-form-item label="请选择星期" :label-width="formLabelWidth">
				<el-select v-model="settimerformdata.week" placeholder="请选择星期" :style="formContentStyle">
					<el-option v-for="item in weeks" :key="item.value" :label="item.label"
						:value="item.value" />
				</el-select>
			</el-form-item>
			<el-form-item label="请选择小时" :label-width="formLabelWidth">
				<el-select v-model="settimerformdata.hour" placeholder="请选择小时" :style="formContentStyle">
					<el-option v-for="item in hours" :key="item.value" :label="item.label"
						:value="item.value" />
				</el-select>
			</el-form-item>
			<el-form-item label="请选择分钟" :label-width="formLabelWidth">
				<el-select v-model="settimerformdata.minute" placeholder="请选择分钟" :style="formContentStyle">
					<el-option v-for="item in minutes" :key="item.value" :label="item.label"
						:value="item.value" />
				</el-select>
			</el-form-item>
			<el-form-item label="请选择动作" :label-width="formLabelWidth">
				<el-radio-group v-model="settimerformdata.action">
				    <el-radio label="1">通电</el-radio>
				    <el-radio label="2">断电</el-radio>
				  </el-radio-group>
			</el-form-item>
		</el-form>	
		<template #footer>
			<span class="dialog-footer">
				<el-space wrap :size="30">
					<el-button size="mini" type="primary" @click="submitSetTimerForm(setTimerFormRef)" class="buttonforform"> 设 置 
					</el-button>
				</el-space>
			</span>
		</template>
	</el-dialog>
	<el-dialog width="500px" v-model="setTotalAmountDialogVisible" append-to-body=true title="预置总用电量" center draggable
		:close-on-click-modal="false" :show-close="true">
		<el-form :model="settotalamountformdata" ref="setTotalAmountFormRef" class="demo-ruleForm formContent">
			<el-form-item label="设置值" :label-width="formLabelWidth">
				<el-input v-model="settotalamountformdata.totalAmount" autocomplete="off" :style="formContentStyle"
					placeholder="请输入设置值" >
					<template #append>{{'度'}}</template>
				</el-input>
			</el-form-item>
		</el-form>	
		<template #footer>
			<span class="dialog-footer">
				<el-space wrap :size="30">
					<el-button size="mini" type="primary" @click="submitSetTotalAmountForm(setTotalAmountFormRef)" class="buttonforform"> 设 置 
					</el-button>
				</el-space>
			</span>
		</template>
	</el-dialog>
	<el-dialog width="500px" v-model="setStartPowerDialogVisible" append-to-body=true title="设置启动功率" center draggable
		:close-on-click-modal="false" :show-close="true">
		<el-form :model="setstartpowerformdata" ref="setStartPowerFormRef" class="demo-ruleForm formContent">
			<el-form-item label="设置值" :label-width="formLabelWidth">
				<el-input v-model="setstartpowerformdata.value" autocomplete="off" :style="formContentStyle"
					placeholder="请输入设置值" />
			</el-form-item>
		</el-form>	
		<template #footer>
			<span class="dialog-footer">
				<el-space wrap :size="30">
					<el-button size="mini" type="primary" @click="submitSetStartPowerForm(setStartPowerFormRef)" class="buttonforform"> 设 置 
					</el-button>
				</el-space>
			</span>
		</template>
	</el-dialog>
	<el-dialog width="500px" v-model="setLimitPowerDialogVisible" append-to-body=true title="设置限定功率" center draggable
		:close-on-click-modal="false" :show-close="true">
		<el-form :model="setlimitpowerformdata" ref="setLimitPowerFormRef" class="demo-ruleForm formContent">
			<el-form-item label="设置值" :label-width="formLabelWidth">
				<el-input v-model="setlimitpowerformdata.value" autocomplete="off" :style="formContentStyle" placeholder="请输入设置值" >
					<template #append>{{'KW'}}</template>
				</el-input>
			</el-form-item>
		</el-form>	
		<template #footer>
			<span class="dialog-footer">
				<el-space wrap :size="30">
					<el-button size="mini" type="primary" @click="submitSetLimitPowerForm(setLimitPowerFormRef)" class="buttonforform"> 设 置 
					</el-button>
				</el-space>
			</span>
		</template>
	</el-dialog>
	<el-dialog width="500px" v-model="setOnOffDialogVisible" append-to-body=true title="断送电" center draggable
		:close-on-click-modal="false" :show-close="true">
		<el-form :model="setonoffformdata" ref="setOnOffFormRef" class="demo-ruleForm formContent">
			<el-form-item label="请输入线号" :label-width="formLabelWidth">
				<el-input v-model="setonoffformdata.lineNum" autocomplete="off" :style="formContentStyle"
					placeholder="请输入线号,多个用-隔开" />
			</el-form-item>
			<el-form-item label="请选择动作" :label-width="formLabelWidth">
				<el-radio-group v-model="setonoffformdata.action">
				    <el-radio label="1">通电</el-radio>
				    <el-radio label="2">断电</el-radio>
				  </el-radio-group>
			</el-form-item>
		</el-form>	
		<template #footer>
			<span class="dialog-footer">
				<el-space wrap :size="30">
					<el-button size="mini" type="primary" @click="submitSetOnOffForm(setOnOffFormRef)" class="buttonforform"> 设 置 
					</el-button>
				</el-space>
			</span>
		</template>
	</el-dialog>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted,
		onActivated,
		getCurrentInstance
	} from 'vue'
	import {
		ElMessageBox
	} from 'element-plus'
	import {
		GetServerData
	} from '@/http/index.js'
	// 获取全局this，proxy相当于vue2中的this，用于调用全局对象使用
	const {
		proxy
	} = getCurrentInstance()

	// 定义查询参数
	let params = reactive({
		areaId1: '',
		areaId2: '',
		areaId3: '',
		payType: '',
		category: '',
		pageSize: 500,
		pageNum: 1
	})
	let pageNum = ref(1)
	let pagingInfo = reactive({
		totalCount: 0,
		totalPage: 0
	})
	let headerCellStype = ref(proxy.$headerCellStype)
	let contentCellStyle = ref(proxy.$contentCellStyle)
	// 定义combox数据
	let areas = ref([])
	let protocols = ref([])
	let dtus = ref([])
	let querySelectedArea = ref([])
	let selectedArea = ref([])
	let selectedProtocol = ref()
	let selectedDTU = ref()
	let payTypes = ref([{
			label: '预付费',
			value: '1'
		},
		{
			label: '后付费',
			value: '2'
		}
	])
	let categorys = ref([{
			label: '多用户表',
			value: '1'
		},
		{
			label: '单表',
			value: '2'
		}
	])
	// 定义表单标题
	let dialogtitle = ref("新 增 电 表")
	// 定义表单数据
	let formdata = reactive({
		id: '',
		areaId1: '',
		areaId2: '',
		areaId3: '',
		protocolId: '',
		dtuId: '',
		address: '',
		payType: '1',
		category: '1',
		storeType:'1',
		canOverdraw: '0',
		threeCircuitAmount: '0',
		twoCircuitAmount: '0',
		threePhaseAmount: '1',
		onePhaseAmount: '1',
		number: '',
		remark: '',
		location:'',
		message:'',
		priceCategory:'1',
		password:'',
		canChargeNegative:'0'
	})
	
	let settimerformdata=reactive({
		timerName:'',
		lineNum:'153',
		week:'FF',
		hour:'FF',
		minute:'FF',
		action:'1',
		ammeterId:''
	})
	
	let settotalamountformdata=reactive({
		lineNum:'153',
		totalAmount:'0',
		ammeterId:''
	})
	
	let setstartpowerformdata=reactive({
		lineNum:'153',
		value:'0',
		ammeterId:''
	})
	
	let setlimitpowerformdata=reactive({
		lineNum:'153',
		value:'0',
		ammeterId:''
	})
	
	let setonoffformdata=reactive({
		lineNum:'',
		action:'1',
		ammeterId:''
	})
	
	let hours=reactive([])
	
	const weeks=reactive([
		{
			label: '无效',
			value: 'FF'
		},
		{
			label: '星期一',
			value: '01'
		},{
			label: '星期二',
			value: '02'
		},{
			label: '星期三',
			value: '03'
		},{
			label: '星期四',
			value: '04'
		},{
			label: '星期五',
			value: '05'
		},{
			label: '星期六',
			value: '06'
		},{
			label: '星期七',
			value: '00'
		}
	])
	
	const minutes=reactive([
		{
			label: '无效',
			value: 'FF'
		},
		{
			label: '00',
			value: '00'
		},{
			label: '10',
			value: '10'
		},{
			label: '20',
			value: '20'
		},{
			label: '30',
			value: '30'
		},{
			label: '40',
			value: '40'
		},{
			label: '50',
			value: '50'
		}
	])
	
	const timerNames = reactive([
		{
			label: '定时一',
			value: '1'
		},{
			label: '定时二',
			value: '2'
		},{
			label: '定时三',
			value: '3'
		},{
			label: '定时四',
			value: '4'
		},{
			label: '定时五',
			value: '5'
		},{
			label: '定时六',
			value: '6'
		},{
			label: '定时七',
			value: '7'
		},{
			label: '定时八',
			value: '8'
		},{
			label: '定时九',
			value: '9'
		},{
			label: '定时十',
			value: '10'
		},{
			label: '定时十一',
			value: '11'
		},{
			label: '定时十二',
			value: '12'
		}
	])

	// 定义表单显示与隐藏
	let dialogVisible = ref(false)
	let setTimerDialogVisible= ref(false)
	let setTotalAmountDialogVisible= ref(false)
	let setStartPowerDialogVisible= ref(false)
	let setLimitPowerDialogVisible= ref(false)
	let setOnOffDialogVisible= ref(false)
	// 定义是否显示继续添加
	let showgoon = ref(false)
	// 定义是否继续添加
	let goon = ref(false)
	// 定义是否显示分页
	let showpaging = ref(true)
	// 定义表格数据
	let tableDatas = ref([])

	// 定义选中的表格行
	let multipleSelection = reactive([])
	// 定义表格引用，用来执行多选
	const multipleTable = ref()
	// 定义表单引用，用来执行表单验证
	const formRef = ref()
	const setTimerFormRef = ref()
	const setTotalAmountFormRef = ref()
	const setStartPowerFormRef = ref()
	const setLimitPowerFormRef = ref()
	const setOnOffFormRef = ref()
	// 定义表单项的头部宽度
	const formLabelWidth = ref('150px')
	// 定义表单项的内容宽度
	const formContentStyle = ref('width:300px')

	// 定义表格隔行变色，暂时没用到
	const tableRowClassName = ({
		row,
		rowIndex
	}) => {
		if (rowIndex % 2 === 0) {
			return 'even-row'
		} else {
			return 'odd-row'
		}
	}
	// 定义表格多选
	const handleSelectionChange = (val) => {
		multipleSelection = val;
	}
	const toggleSelection = (rows) => {
		if (rows) {
			rows.forEach(row => {
				multipleTable.value.toggleRowSelection(row);
			});
		} else {
			multipleTable.value.clearSelection();
		}
	}
	const rowclick = (row, column, event) => {
		toggleSelection([row])
	}

	const handleCategoryChange = (value) => {
		if (value == '1') {

		} else if (value == '2') {

		}
	}

	const handleQueryChange = (value) => {
		if (querySelectedArea.value.length == 1) {
			params.areaId1 = querySelectedArea.value[0]
		} else if (querySelectedArea.value.length == 2) {
			params.areaId2 = querySelectedArea.value[1]
		} else if (querySelectedArea.value.length == 3) {
			params.areaId3 = querySelectedArea.value[2]
		} else {
			params.areaId1 = '';
			params.areaId2 = '';
			params.areaId3 = '';
		}
	}

	const handleChange = (value) => {
		if (selectedArea.value.length == 3) {
			formdata.areaId1 = selectedArea.value[0]
			formdata.areaId2 = selectedArea.value[1]
			formdata.areaId3 = selectedArea.value[2]
		} else {
			formdata.areaId1 = ''
			formdata.areaId2 = ''
			formdata.areaId3 = ''
		}
	}

	const handleProtocolChange = (value) => {
		selectedProtocol.value = value;
		if(value&&value.toString()!=''){
			for(let i=0;i<protocols.value.length;i++){
				if(protocols.value[i].id==value){
					formdata.canChargeNegative=protocols.value[i].name.split('|')[1];
					break;
				}
			}
		}
	}

	const handleDTUChange = (value) => {
		selectedDTU.value = value;
	}
	const sizechange = async () => {
		params.pageNum = 1
		let loading = proxy.$tools.ShowLoading();
		await GetTableData()
		loading.close()
	}
	const currentchange = async () => {
		let loading = proxy.$tools.ShowLoading();
		await GetTableData()
		loading.close()
	}
	
	const setTimer = () => {
		if (multipleSelection.length > 0) {
			setTimerDialogVisible.value=true;
		} else {
			proxy.$tools.ShowMessage('请选择需要设置房间', false)
		}
	}
	
	const setTotalAmount = () => {
		if (multipleSelection.length > 0) {
			setTotalAmountDialogVisible.value=true;
		} else {
			proxy.$tools.ShowMessage('请选择需要设置房间', false)
		}
	}
	
	const setStartPower = () => {
		if (multipleSelection.length > 0) {
			setStartPowerDialogVisible.value=true;
		} else {
			proxy.$tools.ShowMessage('请选择需要设置房间', false)
		}
	}
	
	const setLimitPower = () => {
		if (multipleSelection.length > 0) {
			setLimitPowerDialogVisible.value=true;
		} else {
			proxy.$tools.ShowMessage('请选择需要设置房间', false)
		}
	}
	
	const setOnOff = () => {
		if (multipleSelection.length > 0) {
			setOnOffDialogVisible.value=true;
		} else {
			proxy.$tools.ShowMessage('请选择需要设置房间', false)
		}
	}

	// 定义表单提交事件，带表单验证
	const submitForm = (form) => {
		if (formdata.category == '2') {
			formdata.threeCircuitAmount = '0';
			formdata.twoCircuitAmount = '0';
			formdata.threePhaseAmount = '0';
		}
		form.validate(async (valid) => {
			if (valid) {
				let loading = proxy.$tools.ShowLoading();
				if (formdata.id.length > 0) {
					let response = await GetServerData('Ammeter/Modify', formdata)
					if (response.status == 1) {
						proxy.$tools.ShowMessage('修改成功', true)
						dialogVisible.value = false
						await GetAllData();
					} else {
						proxy.$tools.ShowMessage(response.message, false)
					}
				} else {
					let response = await GetServerData('Ammeter/Add', formdata)
					if (response.status == 1) {
						proxy.$tools.ShowMessage('新增成功！', true)
						if (goon.value) {
							reset()
						} else {
							dialogVisible.value = false
						}
						await GetAllData();
					} else {
						proxy.$tools.ShowMessage(response.message, false)
					}
				}
				loading.close()
			} else {
				proxy.$tools.ShowMessage('请您正确填写所有必填信息后，再提交！', false)
				return false;
			}
		})
	}
	
	const submitSetTimerForm = async (form) => {
		if (settimerformdata.timerName.length>0&&settimerformdata.week.length>0&&settimerformdata.hour.length>0&&settimerformdata.minute.length>0) {
			if(proxy.$tools.IsNumber(settimerformdata.week)&&proxy.$tools.IsNumber(settimerformdata.hour)&&proxy.$tools.IsNumber(settimerformdata.minute)){
				setTimerDialogVisible.value = false
				let loading = proxy.$tools.ShowLoading();
				for (let i = 0; i < multipleSelection.length; i++) {
					settimerformdata.ammeterId = multipleSelection[i].id
					let response = await GetServerData('Ammeter/SetTimer', settimerformdata)
					let message =  response.message
					for (let j = 0; j < tableDatas.value.length; j++) {
						if (tableDatas.value[j].id == settimerformdata.ammeterId) {
							tableDatas.value[j].message = message
							break;
						}
					}
				}
				loading.close()
			}else{
				proxy.$tools.ShowMessage('请您正确填写所有必填信息后，再提交！', false)
				return false;
			}
		} else {
			proxy.$tools.ShowMessage('请您正确填写所有必填信息后，再提交！', false)
			return false;
		}
	}
	
	
	const submitSetTotalAmountForm = async (form) => {
		if (settotalamountformdata.totalAmount.length>0) {
			if(proxy.$tools.IsNumber(settotalamountformdata.totalAmount)){
				setTotalAmountDialogVisible.value = false
				let loading = proxy.$tools.ShowLoading();
				for (let i = 0; i < multipleSelection.length; i++) {
					settotalamountformdata.ammeterId = multipleSelection[i].id
					let response = await GetServerData('Ammeter/SetTotalAmount', settotalamountformdata)
					let message =  response.message
					for (let j = 0; j < tableDatas.value.length; j++) {
						if (tableDatas.value[j].id == settotalamountformdata.ammeterId) {
							tableDatas.value[j].message = message
							break;
						}
					}
				}
				loading.close()
			}else{
				proxy.$tools.ShowMessage('请您正确填写所有必填信息后，再提交！', false)
				return false;
			}
		} else {
			proxy.$tools.ShowMessage('请您正确填写所有必填信息后，再提交！', false)
			return false;
		}
	}
	
	const submitSetStartPowerForm = async (form) => {
		if (setstartpowerformdata.value.length>0) {
			if(proxy.$tools.IsNumber(setstartpowerformdata.value)){
				setStartPowerDialogVisible.value = false
				let loading = proxy.$tools.ShowLoading();
				for (let i = 0; i < multipleSelection.length; i++) {
					setstartpowerformdata.ammeterId = multipleSelection[i].id
					let response = await GetServerData('Ammeter/SetStartPower', setstartpowerformdata)
					let message =  response.message
					for (let j = 0; j < tableDatas.value.length; j++) {
						if (tableDatas.value[j].id == setstartpowerformdata.ammeterId) {
							tableDatas.value[j].message = message
							break;
						}
					}
				}
				loading.close()
			}else{
				proxy.$tools.ShowMessage('请您正确填写所有必填信息后，再提交！', false)
				return false;
			}
		} else {
			proxy.$tools.ShowMessage('请您正确填写所有必填信息后，再提交！', false)
			return false;
		}
	}
	
	const submitSetLimitPowerForm = async (form) => {
		if (setlimitpowerformdata.value.length>0) {
			if(proxy.$tools.IsNumber(setlimitpowerformdata.value)){
				setLimitPowerDialogVisible.value = false
				let loading = proxy.$tools.ShowLoading();
				for (let i = 0; i < multipleSelection.length; i++) {
					setlimitpowerformdata.ammeterId = multipleSelection[i].id
					let response = await GetServerData('Ammeter/SetLimitPower', setlimitpowerformdata)
					let message =  response.message
					for (let j = 0; j < tableDatas.value.length; j++) {
						if (tableDatas.value[j].id == setlimitpowerformdata.ammeterId) {
							tableDatas.value[j].message = message
							break;
						}
					}
				}
				loading.close()
			}else{
				proxy.$tools.ShowMessage('请您正确填写所有必填信息后，再提交！', false)
				return false;
			}
		} else {
			proxy.$tools.ShowMessage('请您正确填写所有必填信息后，再提交！', false)
			return false;
		}
	}
	
	const submitSetOnOffForm = async (form) => {
		if (setonoffformdata.lineNum.length>0) {
			setOnOffDialogVisible.value = false
			let loading = proxy.$tools.ShowLoading();
			for (let i = 0; i < multipleSelection.length; i++) {
				let ammeterId = multipleSelection[i].id
				let lineNums=setonoffformdata.lineNum.split('-');
				for (let j = 0; j < tableDatas.value.length; j++) {
					if (tableDatas.value[j].id == ammeterId) {
						tableDatas.value[j].message="";
					}
				}
				for(let k=0;k<lineNums.length;k++){
					let response = await GetServerData('Ammeter/SetOnOff', {
						lineNum:lineNums[k],
						action:setonoffformdata.action,
						ammeterId:ammeterId
					})
					let message =  response.message
					for (let j = 0; j < tableDatas.value.length; j++) {
						if (tableDatas.value[j].id == ammeterId) {
							if(tableDatas.value[j].message){
								tableDatas.value[j].message += (lineNums[k]+"线"+message+";");
							}else{
								tableDatas.value[j].message = (lineNums[k]+"线"+message+";");
							}
							break;
						}
					}
				}
			}
			loading.close()
		} else {
			proxy.$tools.ShowMessage('请您正确填写所有必填信息后，再提交！', false)
			return false;
		}
	}
	
	const refresh=async ()=>{
		let loading = proxy.$tools.ShowLoading();
		tableDatas.value=[];
		await GetAllData()
		loading.close()
	}
	
	
	const btnreset = () => {
		reset()
		selectedArea.value = []
		formdata.areaId1 = '';
		formdata.areaId2 = '';
		formdata.areaId3 = '';
		formdata.protocolId = '';
		formdata.dtuId = '';
		formdata.payType = '1';
		formdata.category = '1';
		formdata.storeType = '1';
		formdata.priceCategory='1';
		formdata.canChargeNegative='0';
	}
	// 重置表单事件
	const reset = () => {
		if (formRef.value) {
			formRef.value.resetFields();
		}
		if (selectedArea.value.length == 3) {
			formdata.areaId1 = selectedArea.value[0]
			formdata.areaId2 = selectedArea.value[1]
			formdata.areaId3 = selectedArea.value[2]
		}
		formdata.protocolId = selectedProtocol.value;
		formdata.dtuId = selectedDTU.value;
		formdata.address = '';
		formdata.number = '';
		formdata.remark = '';
		formdata.location='';
		formdata.threeCircuitAmount = '0';
		formdata.twoCircuitAmount = '0';
		formdata.threePhaseAmount = '1';
		formdata.onePhaseAmount = '1';
	}
	// 定义新增按钮事件
	const add = () => {
		dialogtitle.value = "新 增 电 表"
		goon.value = false
		showgoon.value = true
		btnreset()
		formdata.id = '';
		dialogVisible.value = true
	}
	// 定义编辑按钮事件
	const modify = (row) => {
		dialogtitle.value = "修 改 电 表"
		showgoon.value = false
		formdata.id = row.id;
		formdata.areaId1 = row.areaId1;
		formdata.areaId2 = row.areaId2;
		formdata.areaId3 = row.areaId3;
		formdata.protocolId = row.protocolId;
		formdata.dtuId = row.dtuId;
		formdata.address = row.address;
		formdata.priceCategory=row.priceCategory;
		formdata.payType = row.payType;
		formdata.storeType = row.storeType;
		formdata.category = row.category;
		formdata.number = row.number;
		formdata.remark = row.remark;
		formdata.location=row.location;
		formdata.password=row.password;
		formdata.threeCircuitAmount = row.threeCircuitAmount.toString();
		formdata.twoCircuitAmount = row.twoCircuitAmount.toString();
		formdata.threePhaseAmount = row.threePhaseAmount.toString();
		formdata.onePhaseAmount = row.onePhaseAmount.toString();
		formdata.canChargeNegative=row.canChargeNegative;
		selectedArea.value = [row.areaId1, row.areaId2, row.areaId3]
		dialogVisible.value = true
	}
	// 定义删除按钮事件
	const deleteitem = (row) => {
		ElMessageBox.confirm('确定要删除这条记录吗?', "", {
				confirmButtonText: '确 定',
				cancelButtonText: '取 消',
				type: 'warning'
			})
			.then(async () => {
				let loading = proxy.$tools.ShowLoading();
				let response = await GetServerData('Ammeter/Delete', {
					id: row.id
				})

				if (response.status == 1) {
					proxy.$tools.ShowMessage('删除成功！', true)
					await GetAllData()
				} else {
					proxy.$tools.ShowMessage(response.message, false)
				}
				loading.close();
			})
	}
	// 定义批量删除按钮事件
	const deletesome = () => {
		var ids = ''
		multipleSelection.forEach((value, key, iterable) => {
			ids += "'" + value.id + "',";
		})
		if (ids.length > 0) {
			ids = ids.substr(0, ids.length - 1)
			ElMessageBox.confirm('确定要删除下面选中的所有记录吗?', "", {
					confirmButtonText: '确 定',
					cancelButtonText: '取 消',
					type: 'warning'
				})
				.then(async () => {
					let loading = proxy.$tools.ShowLoading();
					let response = await GetServerData('Ammeter/DeleteSome', {
						ids: ids
					})
					if (response.status == 1) {
						proxy.$tools.ShowMessage('删除成功！', true)
						await GetAllData()
					} else {
						proxy.$tools.ShowMessage(response.message, false)
					}
					loading.close()
				})
		}
	}
	// 定义查询按钮事件
	const search = async () => {
		let loading = proxy.$tools.ShowLoading();
		await GetTableData()
		loading.close()
	}
	// 定义刷新按钮事件
	const resetparams = () => {
		params.areaId1 = ''
		params.areaId2 = ''
		params.areaId3 = ''
		params.payType = ''
		params.category = ''
		querySelectedArea.value = [];
	}
	// 定义获取表格数据函数
	const GetTableData = async () => {
		let response = await GetServerData('Ammeter/QueryAll', params)
		if (response.status == 1) {
			tableDatas.value = response.data
			pagingInfo.totalCount = response.totalCount
			pagingInfo.totalPage = Math.ceil(pagingInfo.totalCount / params.pageSize)
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}
	// 定义获取combox数据函数
	const GetComboxData = async () => {
		let response = await GetServerData('Ammeter/QueryComboxData')
		if (response.status == 1) {
			protocols.value = response.data.protocols;
			dtus.value = response.data.dtus;
			areas.value = response.data.areas;
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}
	// 定义获取所有数据函数
	const GetAllData = async () => {
		await GetTableData()
		await GetComboxData()
	}

	let tableheight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight))
	// 定义加载事件
	onMounted(async () => {
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth - 220) +
			'px'
		window.onresize = () => {
			tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight)
			document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
					220) +
				'px'
		}
		let loading = proxy.$tools.ShowLoading();
		await GetAllData()
		hours.push({label: "无效",
			value: "FF"})
		for(let i=0;i<=24;i++){
			hours.push({label: i.toString(),
			value: i.toString()})
		}
		loading.close()
	})
	onActivated(() => {
		tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight)
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
			220) + 'px'
	})
</script>

<style scoped lang="less">
</style>
