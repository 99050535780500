<template>
	<div class="contentheader">
		<el-space wrap>
			<span class="titleClass">房间管理</span>
			<el-divider direction="vertical" />
			<el-cascader v-model="querySelectedArea" :options="areas" :props="{
						value:'id',
						label:'name',
						checkStrictly: true
					}" @change="handleQueryChange" placeholder="选择区域" style="width: 250px;" size="small"></el-cascader>
			<el-input v-model="params.roomNum" placeholder="输入房间编号" size="small" style="width: 150px;"
				autocomplete="off" />
			<el-input v-model="params.roomName" placeholder="输入房间名称" size="small" style="width: 120px;"
				autocomplete="off" />
			<el-input v-model="params.familyName" placeholder="输入住户名称" size="small" style="width: 120px;"
				autocomplete="off" />
			<el-input v-model="params.familyPhone" placeholder="输入住户电话" size="small" style="width: 120px;"
				autocomplete="off" />
			<el-button type="primary" size="small" class="buttonforheader" @click="search">查 询</el-button>
			<el-button type="primary" size="small" class="buttonforheader" @click="refresh">刷 新</el-button>
			<el-divider direction="vertical" />
			<el-button type="success" size="small" class="buttonforheader" @click="add">新 增</el-button>
			<el-button type="warning" size="small" class="buttonforheader" @click="deletesome">批量删除</el-button>
			<!-- <el-button type="primary" size="small" class="buttonforheader" @click="resetparams">重 置</el-button> -->
			<el-divider direction="vertical" />
			<el-button type="success" size="small" class="buttonforheader" @click="exportPhoneModule">导出姓名电话模板</el-button>
			<el-button type="warning" size="small" class="buttonforheader" @click="importPhoneExcel">导入姓名电话数据</el-button>
			<input id="choosefile" type="file" accept=".xlsx" @change="handleFileSelect" style="visibility: hidden;width: 10px;" ref="choosefile" />
		</el-space>
	</div>
	<div id="divTableData" class="contentcontent">
		<el-table :data="tableDatas" fit="false" highlight-current-row="true" ref="multipleTable" tooltip-effect="dark"
			:height="tableheight" @selection-change="handleSelectionChange" @row-click="rowclick" row-key="id"
			default-expand-all :header-cell-style="headerCellStypeDouble" :cell-style="contentCellStyle"
			style="width: 100%; cursor: pointer;" :row-class-name="tableRowClassName">
			<el-table-column type="selection" width="55" />
			<el-table-column fixed prop="id" label="id" width="0" v-if="false" />
			<el-table-column fixed prop="areaId1" label="areaId1" width="0" v-if="false" />
			<el-table-column fixed prop="areaId2" label="areaId2" width="0" v-if="false" />
			<el-table-column fixed prop="areaId3" label="areaId3" width="0" v-if="false" />
			<el-table-column fixed prop="lineTag" label="lineTag" width="0" v-if="false" />
			<el-table-column fixed prop="ammeterId" label="ammeterId" width="0" v-if="false" />
			<el-table-column fixed prop="ammeterPriceId" label="ammeterPriceId" width="0" v-if="false" />
			<el-table-column fixed prop="canCharge" label="ammeterPriceId" width="0" v-if="false" />
			<el-table-column fixed prop="canWeiXin" label="ammeterPriceId" width="0" v-if="false" />
			<el-table-column fixed prop="lineTag2" label="lineTag2" width="0" v-if="false" />
			<el-table-column fixed prop="ammeterId2" label="ammeterId2" width="0" v-if="false" />
			<el-table-column fixed prop="ammeterPriceId2" label="ammeterPriceId2" width="0" v-if="false" />
			<el-table-column fixed prop="watermeterId1" label="watermeterId1" width="0" v-if="false" />
			<el-table-column fixed prop="watermeterPriceId1" label="watermeterPriceId1" width="0" v-if="false" />
			<el-table-column fixed prop="watermeterId2" label="watermeterId2" width="0" v-if="false" />
			<el-table-column fixed prop="watermeterPriceId2" label="watermeterPriceId2" width="0" v-if="false" />
			<el-table-column fixed prop="createBillStatus" label="createBillStatus" width="0" v-if="false" />
			<el-table-column fixed prop="giftPowerStatus" label="giftPowerStatus" width="0" v-if="false" />
			<el-table-column fixed prop="giftWaterStatus" label="giftWaterStatus" width="0" v-if="false" />
			<el-table-column prop="areaId1Name" label="一级区域" width="100" />
			<el-table-column prop="areaId2Name" label="二级区域" width="100" />
			<el-table-column prop="areaId3Name" label="三级区域" width="100" />
			<el-table-column prop="roomNum" label="房间编号" width="100" />
			<el-table-column prop="roomName" label="房间名称" width="150" />
			<el-table-column prop="familyName" label="住户名称" width="150" />
			<el-table-column prop="familyPhone" label="住户电话" width="150"  align="center" />
			<el-table-column prop="canChargeName" label="允许缴费" width="100"  align="center" />
			<el-table-column prop="canWeiXinName" label="允许微信" width="100"  align="center" />
			<el-table-column prop="balance" label="余额" width="100" />
			<el-table-column prop="lineTagName" :label="ammeterName1+'线号标识'" width="120"  v-if="ammeterType=='1'||ammeterType=='2'"/>
			<el-table-column prop="ammeterAddress" :label="ammeterName1+'电表地址'" width="120"  v-if="ammeterType=='1'||ammeterType=='2'"/>
			<el-table-column prop="lineNum" :label="ammeterName1+'线号'" width="100"  v-if="ammeterType=='1'||ammeterType=='2'"/>
			<el-table-column prop="ratio" :label="ammeterName1+'变比系数'" width="120"  v-if="ammeterType=='1'||ammeterType=='2'"/>
			<el-table-column prop="ammeterPriceName" :label="ammeterName1+'电单价'" width="100"  v-if="ammeterType=='1'||ammeterType=='2'"/>
			<el-table-column prop="amSoldAmount" :label="ammeterName1+'售电次数'" width="120"  v-if="ammeterType=='1'||ammeterType=='2'"/>
			<el-table-column prop="lineTagName2" :label="ammeterName2+'线号标识'" width="120"  v-if="ammeterType=='2'"/>
			<el-table-column prop="ammeterAddress2" :label="ammeterName2+'电表地址'" width="120"  v-if="ammeterType=='2'"/>
			<el-table-column prop="lineNum2" :label="ammeterName2+'线号'" width="100"  v-if="ammeterType=='2'"/>
			<el-table-column prop="ratio2" :label="ammeterName2+'变比系数'" width="120"  v-if="ammeterType=='2'"/>
			<el-table-column prop="ammeterPriceName2" :label="ammeterName2+'电单价'" width="100"  v-if="ammeterType=='2'"/>
			<el-table-column prop="amSoldAmount2" :label="ammeterName2+'售电次数'" width="120"  v-if="ammeterType=='2'"/>
			<el-table-column prop="watermeterAddress1" :label="watermeterName1+'水表地址'" width="100" v-if="watermeterType=='1'||watermeterType=='2'" />
			<el-table-column prop="watermeterPriceName1" :label="watermeterName1+'水单价'" width="100" v-if="watermeterType=='1'||watermeterType=='2'" />
			<el-table-column prop="wmSoldAmount1" :label="watermeterName1+'水表售水次数'" width="150" v-if="watermeterType=='1'||watermeterType=='2'" />
			<el-table-column prop="watermeterAddress2" :label="watermeterName2+'水表地址'" width="100" v-if="pwatermeterType=='2'" />
			<el-table-column prop="watermeterPriceName2" :label="watermeterName2+'水单价'" width="100" v-if="pwatermeterType=='2'"  />
			<el-table-column prop="wmSoldAmount2" :label="watermeterName2+'水表售水次数'" width="150" v-if="pwatermeterType=='2'"  />
			<el-table-column prop="lowPower" :label="ammeterName1+'低电量提醒值'" width="100"  v-if="ammeterType=='1'||ammeterType=='2'"/>
			<el-table-column prop="lowPower2" :label="ammeterName2+'低电量提醒值'" width="100"  v-if="ammeterType=='2'"/>
			<el-table-column prop="lowWater1" :label="watermeterName1+'低水量提醒值'" width="120" v-if="watermeterType=='1'||watermeterType=='2'" />
			<el-table-column prop="lowWater2" :label="watermeterName2+'低水量提醒值'" width="120" v-if="pwatermeterType=='2'"  />
			<el-table-column prop="lowBalance" label="低余额提醒值" width="100" />
			<el-table-column prop="onOffAmmeterException" label="断电例外" width="100"  v-if="ammeterType!='0'"/>
			<el-table-column prop="onOffWatermeterException" label="断水例外" width="100" v-if="watermeterType!='0'" />
			<el-table-column prop="giftPowerStatusName" label="是否赠送电" width="120"  v-if="ammeterType!='0'"/>
			<el-table-column prop="giftWaterStatusName" label="是否赠送水" width="120" v-if="watermeterType!='0'" />
			<el-table-column prop="createBillStatusName" label="是否产生账单" width="120" />
			<el-table-column prop="canSendLowBalanceMessage" label="低余额短信" width="120" />
			<el-table-column prop="canSendLowPowerMessage" :label="ammeterName1+'低电量短信'" width="150"  v-if="ammeterType=='1'||ammeterType=='2'"/>
			<el-table-column prop="canSendLowPowerMessage2" :label="ammeterName2+'低电量短信'" width="150"  v-if="ammeterType=='2'"/>
			<el-table-column prop="canSendLowWater1Message" :label="watermeterName1+'低水量短信'" width="150" v-if="watermeterType=='1'||watermeterType=='2'" />
			<el-table-column prop="canSendLowWater2Message" :label="watermeterName2+'低水量短信'" width="150" v-if="pwatermeterType=='2'"  />
			<el-table-column prop="canSendOweBalanceMessage" label="欠费短信" width="100" />
			<el-table-column prop="remark" label="备注" width="250" :show-overflow-tooltip="true" />
			<el-table-column prop="cuName" label="创建人" width="120" />
			<el-table-column prop="muName" label="修改人" width="120" />
			<el-table-column prop="ct" label="创建时间" width="150" align="center" />
			<el-table-column prop="mt" label="修改时间" width="150" align="center" />
			<el-table-column />
			<el-table-column fixed="right" label="操作" width="150" align="left">
				<template #default="scope">
					<el-button size="mini" @click.stop="modify(scope.row)" type="success" class="buttonfortable">编 辑
					</el-button>
					<el-button size="mini" @click.stop="deleteitem(scope.row)" type="warning" class="buttonfortable">删 除
					</el-button>
				</template>
			</el-table-column>
		</el-table>
	</div>
	<div class="contentpager" v-if="showpaging">
		<el-pagination background layout="prev, pager, next,jumper,sizes,total" :total="pagingInfo.totalCount"
			:page-sizes="[100,200,500,1000,2000,3000]" v-model:current-page="params.pageNum"
			v-model:page-size="params.pageSize" @size-change="sizechange" @current-change="currentchange" />
	</div>
	<el-dialog :width="dialogWidth+'px'" v-model="dialogVisible" append-to-body=true :title="dialogtitle" top="50px" center
		draggable :close-on-click-modal="false" :show-close="true">
		<el-form :model="formdata" ref="formRef" class="demo-ruleForm formContent">
			<el-row :gutter="5">
				<el-col :span="dialogSpan">
					<el-form-item prop="areaId3" :rules="[{ required: true, message: '请选择区域' }]" label="选择区域"
						:label-width="formLabelWidth">
						<el-cascader v-model="selectedArea" :options="areas" :props="{
						value:'id',
						label:'name'
					}" @change="handleChange" placeholder="请选择区域" :style="formContentStyle" clearable></el-cascader>
					</el-form-item>
					<el-form-item prop="roomNum" label="房间编号" :rules="[{ required: true, message: '请输入房间编号'  ,trigger:'blur'},{min:3,max:20,message:'房间编号的长度为3-20',trigger:'blur'}]"
						:label-width="formLabelWidth">
						<el-input v-model="formdata.roomNum" autocomplete="off" :style="formContentStyle" maxlength="20" clearable
							placeholder="请输入房间编号" />
					</el-form-item>
					<el-form-item label="房间名称" :label-width="formLabelWidth">
						<el-input v-model="formdata.roomName" autocomplete="off" :style="formContentStyle" maxlength="30" clearable
							placeholder="请输入房间名称" />
					</el-form-item>
					<el-form-item label="住户名称" :label-width="formLabelWidth">
						<el-input v-model="formdata.familyName" autocomplete="off" :style="formContentStyle" maxlength="30" clearable
							placeholder="请输入住户名称" />
					</el-form-item>
					<el-form-item label="住户电话" :label-width="formLabelWidth">
						<el-input v-model="formdata.familyPhone" autocomplete="off" :style="formContentStyle" type="number" maxlength="15" clearable
							placeholder="请输入住户电话" />
					</el-form-item>
					<el-form-item label="备注" :label-width="formLabelWidth">
						<el-input v-model="formdata.remark" autocomplete="off" :style="formContentStyle" maxlength="200" clearable
							placeholder="请输入备注" />
					</el-form-item>
				</el-col>
				<el-col :span="dialogSpan"  v-if="ammeterType!='0'">
					<el-form-item :label="ammeterName1+'线号标识'" :label-width="formLabelWidth" v-if="ammeterType=='1'||ammeterType=='2'">
						<el-select v-model="formdata.lineTag" placeholder="请选择线号标识" :style="formContentStyle" clearable>
							<el-option v-for="item in lineTags" :key="item.value" :label="item.label"
								:value="item.value" />
						</el-select>
					</el-form-item>
					<el-form-item :label="ammeterName1" :label-width="formLabelWidth" v-if="ammeterType=='1'||ammeterType=='2'">
						<el-select v-model="formdata.ammeterId" placeholder="请选择电表" :style="formContentStyle" clearable @change="handleAmmeterChange1">
							<el-option v-for="item in ammeters" :key="item.id" :label="item.name" :value="item.id" />
						</el-select>
					</el-form-item>
					<el-form-item :label="ammeterName1+'线号'" :label-width="formLabelWidth" v-if="ammeterType=='1'||ammeterType=='2'">
						<el-input v-model="formdata.lineNum" autocomplete="off" :style="formContentStyle" clearable :disabled='!lineNumEnable'
							placeholder="请输入电表线号" />
					</el-form-item>
					<el-form-item :label="ammeterName1+'电单价'" :label-width="formLabelWidth" v-if="ammeterType=='1'||ammeterType=='2'">
						<el-select v-model="formdata.ammeterPriceId" placeholder="请选择电单价" :style="formContentStyle" clearable>
							<el-option v-for="item in ammeterPrices" :key="item.id" :label="item.name"
								:value="item.id" />
						</el-select>
					</el-form-item>
					<el-form-item :label="ammeterName1+'变比系数'" :label-width="formLabelWidth" v-if="ammeterType=='1'||ammeterType=='2'">
						<el-input v-model="formdata.ratio" autocomplete="off" :style="formContentStyle" type="number" clearable
							placeholder="请输入变比系数" />
					</el-form-item>
					<el-form-item :label="ammeterName2+'线号标识'" :label-width="formLabelWidth" v-if="ammeterType=='2'">
						<el-select v-model="formdata.lineTag2" placeholder="请选择线号标识" :style="formContentStyle" clearable>
							<el-option v-for="item in lineTags" :key="item.value" :label="item.label"
								:value="item.value" />
						</el-select>
					</el-form-item>
					<el-form-item :label="ammeterName2" :label-width="formLabelWidth" v-if="ammeterType=='2'">
						<el-select v-model="formdata.ammeterId2" placeholder="请选择电表" :style="formContentStyle" clearable  @change="handleAmmeterChange2">
							<el-option v-for="item in ammeters" :key="item.id" :label="item.name" :value="item.id" />
						</el-select>
					</el-form-item>
					<el-form-item :label="ammeterName2+'线号'" :label-width="formLabelWidth" v-if="ammeterType=='2'">
						<el-input v-model="formdata.lineNum2" autocomplete="off" :style="formContentStyle" clearable :disabled='!lineNumEnable2'
							placeholder="请输入电表线号" />
					</el-form-item>
					<el-form-item :label="ammeterName2+'电单价'" :label-width="formLabelWidth" v-if="ammeterType=='2'">
						<el-select v-model="formdata.ammeterPriceId2" placeholder="请选择电单价" :style="formContentStyle" clearable>
							<el-option v-for="item in ammeterPrices" :key="item.id" :label="item.name"
								:value="item.id" />
						</el-select>
					</el-form-item>
					<el-form-item :label="ammeterName2+'变比系数'" :label-width="formLabelWidth" v-if="ammeterType=='2'">
						<el-input v-model="formdata.ratio2" autocomplete="off" :style="formContentStyle" type="number" clearable
							placeholder="请输入变比系数" />
					</el-form-item>
				</el-col>
				<el-col :span="dialogSpan"  v-if="watermeterType!='0'">
					<el-form-item :label="watermeterName1" :label-width="formLabelWidth" v-if="watermeterType=='1'||watermeterType=='2'">
						<el-select v-model="formdata.watermeterId1" placeholder="请选择水表" :style="formContentStyle" clearable>
							<el-option v-for="item in watermeter1s" :key="item.id" :label="item.name"
								:value="item.id" />
						</el-select>
					</el-form-item>
					<el-form-item :label="watermeterName1+'水单价'" :label-width="formLabelWidth" v-if="watermeterType=='1'||watermeterType=='2'">
						<el-select v-model="formdata.watermeterPriceId1" placeholder="请选择水单价" clearable
							:style="formContentStyle">
							<el-option v-for="item in watermeterPrices" :key="item.id" :label="item.name"
								:value="item.id" />
						</el-select>
					</el-form-item>
					<el-form-item :label="watermeterName2" :label-width="formLabelWidth" v-if="watermeterType=='2'">
						<el-select v-model="formdata.watermeterId2" placeholder="请选择水表" :style="formContentStyle" clearable>
							<el-option v-for="item in watermeter2s" :key="item.id" :label="item.name"
								:value="item.id" />
						</el-select>
					</el-form-item>
					<el-form-item :label="watermeterName2+'水单价'" :label-width="formLabelWidth" v-if="watermeterType=='2'">
						<el-select v-model="formdata.watermeterPriceId2" placeholder="请选择水单价" clearable
							:style="formContentStyle">
							<el-option v-for="item in watermeterPrices" :key="item.id" :label="item.name"
								:value="item.id" />
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="dialogSpan">
					<el-form-item :label="ammeterName1+'低电量/低余额提醒值'" :label-width="formLabelWidth2"  v-if="ammeterType=='1'||ammeterType=='2'">
						<el-input v-model="formdata.lowPower" autocomplete="off" :style="formContentStyle2" type="number" clearable
							placeholder="请输入低电量/低余额提醒值" >
							<template #append>度|元</template>
						</el-input>
					</el-form-item>
					<el-form-item :label="ammeterName2+'低电量/低余额提醒值'" :label-width="formLabelWidth2" v-if="ammeterType=='2'">
						<el-input v-model="formdata.lowPower2" autocomplete="off" :style="formContentStyle2" type="number" clearable
							placeholder="请输入低电量/低余额提醒值" >
							<template #append>度|元</template>
						</el-input>
					</el-form-item>
					<el-form-item :label="watermeterName1+'低水量提醒值'" :label-width="formLabelWidth" v-if="watermeterType=='1'||watermeterType=='2'">
						<el-input v-model="formdata.lowWater1" autocomplete="off" :style="formContentStyle" type="number" clearable
							placeholder="请输入低水量提醒值" >
							<template #append>方</template>
						</el-input>
					</el-form-item>
					<el-form-item :label="watermeterName2+'低水量提醒值'" :label-width="formLabelWidth" v-if="watermeterType=='2'">
						<el-input v-model="formdata.lowWater2" autocomplete="off" :style="formContentStyle" type="number" clearable
							placeholder="请输入低水量提醒值" >
							<template #append>方</template>
						</el-input>
					</el-form-item>
					<el-form-item label="账户低余额提醒值" :label-width="formLabelWidth">
						<el-input v-model="formdata.lowBalance" autocomplete="off" :style="formContentStyle" type="number" clearable
							placeholder="请输入低余额提醒值" >
							<template #append>元</template>
						</el-input>
					</el-form-item>
					<el-form-item label="赠送" :label-width="formLabelWidth">
						<el-checkbox v-model="formdata.giftPowerStatus" label="赠送电" size="mini" border true-label="1" false-label="0" v-if="ammeterType!='0'"/>
						<el-checkbox v-model="formdata.giftWaterStatus" label="赠送水" size="mini" border  true-label="1" false-label="0" v-if="watermeterType!='0'"/>
					</el-form-item>
					<el-form-item label="生成账单" :label-width="formLabelWidth">
						<el-checkbox v-model="formdata.createBillStatus" label="是否生成账单" size="mini" border true-label="1" false-label="0"/>
					</el-form-item>
					<el-form-item label="例外" :label-width="formLabelWidth">
						<el-checkbox v-model="formdata.onOffAmmeterException" label="断电例外" size="mini" border true-label="1" false-label="0" v-if="ammeterType!='0'"/>
						<el-checkbox v-model="formdata.onOffWatermeterException" label="断水例外" size="mini" border  true-label="1" false-label="0" v-if="watermeterType!='0'"/>
					</el-form-item>
					<el-form-item label="是否允许缴费" :label-width="formLabelWidth">
						<el-switch v-model="formdata.canCharge" active-text="允许" active-value="1"
							inactive-value="2" inactive-text="不允许">
						</el-switch>
					</el-form-item>
					<el-form-item label="是否允许微信" :label-width="formLabelWidth">
						<el-switch v-model="formdata.canWeiXin" active-text="允许" active-value="1"
							inactive-value="2" inactive-text="不允许">
						</el-switch>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24">
					<el-form-item label="发送短信" :label-width="formLabelWidth">
						<el-checkbox v-model="formdata.canSendLowPowerMessage" :label="ammeterName1+'低电量/低余额短信'" size="mini" border  true-label="1" false-label="0" v-if="ammeterType=='1'||ammeterType=='2'"/>
						<el-checkbox v-model="formdata.canSendLowPowerMessage2" :label="ammeterName2+'低电量/低余额短信'" size="mini" border  true-label="1" false-label="0" v-if="ammeterType=='2'"/>
						<el-checkbox v-model="formdata.canSendLowWater1Message" :label="watermeterName1+'低水量短信'" size="mini" border  true-label="1" false-label="0" v-if="watermeterType=='1'||watermeterType=='2'"/>
						<el-checkbox v-model="formdata.canSendLowWater2Message" :label="watermeterName2+'低水量短信'" size="mini" border  true-label="1" false-label="0" v-if="watermeterType=='2'"/>
						<el-checkbox v-model="formdata.canSendLowBalanceMessage" label="账户低余额短信" size="mini" border true-label="1" false-label="0"/>
						<el-checkbox v-model="formdata.canSendOweBalanceMessage" label="账户欠费短信" size="mini" border  true-label="1" false-label="0"/>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-space wrap :size="30">
					<span v-if="showgoon">是否继续添加：
						<el-switch v-model="goon" style="margin-right: 10px;" />
					</span>
					<el-button size="mini" type="warning" @click="btnreset" class="buttonforform"> 重 置 </el-button>
					<!-- <el-button @click="dialogVisible = false">取消</el-button> -->
					<el-button size="mini" type="primary" @click="submitForm(formRef)" class="buttonforform"> 确 定
					</el-button>
				</el-space>
			</span>
		</template>
	</el-dialog>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted,
		onActivated,
		getCurrentInstance
	} from 'vue'
	import {
		ElMessageBox
	} from 'element-plus'
	import {
		GetServerData,
		UploadFile
	} from '@/http/index.js'
	// 获取全局this，proxy相当于vue2中的this，用于调用全局对象使用
	const {
		proxy
	} = getCurrentInstance()
	// 定义查询参数
	let params = reactive({
		areaId1: '',
		areaId2: '',
		areaId3: '',
		roomNum: '',
		roomName: '',
		familyName: '',
		familyPhone: '',
		pageSize: 100,
		pageNum: 1
	})
	let deviceParams = reactive({
		areaId1: '',
		areaId2: '',
		areaId3: ''
	})
	let pageNum = ref(1)
	let pagingInfo = reactive({
		totalCount: 0,
		totalPage: 0
	})
	// 定义combox数据
	let areas = ref([])
	let estateConfig=ref()
	let ammeterPrice1=ref()
	let ammeterPrice2=ref()
	let watermeterPrice1=ref()
	let watermeterPrice2=ref()
	let ammeterPrices = ref([])
	let watermeter1s = ref([])
	let watermeter2s = ref([])
	let ammeters = ref([])
	let watermeterPrices = ref([])
	let querySelectedArea = ref([])
	let selectedArea = ref([])
	let lineTag = ref()
	let ammeterPriceId = ref()
	let ratio = ref()
	let lineTag2 = ref()
	let ammeterPriceId2 = ref()
	let ratio2 = ref()
	let watermeterPriceId1 = ref()
	let watermeterPriceId2 = ref()
	let lowPower = ref()
	let lowPower2 = ref()
	let lowWater1 = ref()
	let lowWater2 = ref()
	let lowBalance = ref()
	let ammeterId = ref()
	let lineNum = ref()
	let lineNum2 = ref()

	let lineTags = ref([{
			label: '单相户',
			value: '1'
		},
		{
			label: '三相户',
			value: '2'
		},
		{
			label: '双回路户',
			value: '3'
		},
		{
			label: '三回路户',
			value: '4'
		}
	])
	// 定义表单标题
	let dialogtitle = ref("新 增 房 间")
	// 定义表单数据
	let formdata = reactive({
		id: '',
		areaId1: '',
		areaId2: '',
		areaId3: '',
		lineTag: '1',
		ammeterId: '',
		lineNum: '1',
		ammeterPriceId: '',
		ratio: '1',
		lineTag2: '1',
		ammeterId2: '',
		lineNum2: '1',
		ammeterPriceId2: '',
		ratio2: '1',
		watermeterId1: '',
		watermeterPriceId1: '',
		watermeterId2: '',
		watermeterPriceId2: '',
		roomNum: '',
		roomName: '',
		familyName: '',
		familyPhone: '',
		lowPower: '0',
		lowPower2: '0',
		lowWater1: '0',
		lowWater2: '0',
		lowBalance: '0',
		remark: '',
		onOffAmmeterException:'0',
		onOffWatermeterException:'0',
		canSendLowBalanceMessage:'1',
		canSendLowPowerMessage:'1',
		canSendLowPowerMessage2:'1',
		canSendLowWater1Message:'1',
		canSendLowWater2Message:'1',
		canSendOweBalanceMessage:'1',
		createBillStatus:'1',
		giftPowerStatus:'1',
		giftWaterStatus:'1',
		canCharge:'1',
		canWeiXin:'1'
	})
	

	// 定义表单显示与隐藏
	let dialogVisible = ref(false)
	// 定义是否显示继续添加
	let showgoon = ref(false)
	// 定义是否继续添加
	let goon = ref(false)
	// 定义是否显示分页
	let showpaging = ref(true)
	// 定义表格数据
	let tableDatas = ref([])
	let lineNumEnable=ref(true)
	let lineNumEnable2=ref(true)

	let headerCellStypeDouble = ref(proxy.$headerCellStypeDouble)
	let contentCellStyle = ref(proxy.$contentCellStyle)
	
	let ammeterType=ref(localStorage.getItem("ammeterType"));
	let ammeterName1=ref(localStorage.getItem("ammeterName1"));
	let ammeterName2=ref(localStorage.getItem("ammeterName2"));
	
	let watermeterType=ref(localStorage.getItem("watermeterType"));
	let watermeterName1=ref(localStorage.getItem("watermeterName1"));
	let watermeterName2=ref(localStorage.getItem("watermeterName2"));
	
	// 定义选中的表格行
	let multipleSelection = reactive([])
	// 定义表格引用，用来执行多选
	const multipleTable = ref()
	// 定义表单引用，用来执行表单验证
	const formRef = ref()
	const choosefile=ref()
	// 定义表单项的头部宽度
	const formLabelWidth = ref('150px')
	// 定义表单项的内容宽度
	const formContentStyle = ref('width:300px')
	// 定义表单项的头部宽度
	const formLabelWidth2 = ref('250px')
	// 定义表单项的内容宽度
	const formContentStyle2 = ref('width:200px')

	// 定义表格隔行变色，暂时没用到
	const tableRowClassName = ({
		row,
		rowIndex
	}) => {
		if (rowIndex % 2 === 0) {
			return 'even-row'
		} else {
			return 'odd-row'
		}
	}
	// 定义表格多选
	const handleSelectionChange = (val) => {
		multipleSelection = val;
	}
	const toggleSelection = (rows) => {
		if (rows) {
			rows.forEach(row => {
				multipleTable.value.toggleRowSelection(row);
			});
		} else {
			multipleTable.value.clearSelection();
		}
	}
	const rowclick = (row, column, event) => {
		toggleSelection([row])
	}

	const handleQueryChange = (value) => {
		if (querySelectedArea.value.length == 1) {
			params.areaId1 = querySelectedArea.value[0]
		} else if (querySelectedArea.value.length == 2) {
			params.areaId2 = querySelectedArea.value[1]
		} else if (querySelectedArea.value.length == 3) {
			params.areaId3 = querySelectedArea.value[2]
		} else {
			params.areaId1 = '';
			params.areaId2 = '';
			params.areaId3 = '';
		}
	}

	const handleChange = async (value) => {
		if (selectedArea.value&&selectedArea.value.length == 3) {
			formdata.areaId1 = selectedArea.value[0]
			formdata.areaId2 = selectedArea.value[1]
			formdata.areaId3 = selectedArea.value[2]
			deviceParams.areaId1 = formdata.areaId1
			deviceParams.areaId2 = formdata.areaId2
			deviceParams.areaId3 = formdata.areaId3
			//console.log(formdata.areaId3)
			await GetDeviceData();
		} else {
			formdata.areaId1 = ''
			formdata.areaId2 = ''
			formdata.areaId3 = ''
			ammeters.value = [];
			watermeter1s.value = [];
			watermeter2s.value = [];
		}
	}
	
	const handleFileSelect=async(event)=>{
		if(event.target.files.length>0){
			let file=event.target.files[0];
			if(file){
				if(file.size<1024*1024*10){
					let formData = new FormData();
					formData.append('file', file);
					let loading = proxy.$tools.ShowLoading();
					let response = await UploadFile('Room/UploadExcel', formData)
					if (response.status == 1) {
						proxy.$tools.ShowMessage(response.message, true)
						tableDatas.value=[];
						await GetAllData()
					} else {
						proxy.$tools.ShowMessage(response.message, false)
					}
					loading.close()
				}else{
					proxy.$tools.ShowMessage("文件不能超过10MB！", false)
				}
			}
		}
	}

	const sizechange = async () => {
		params.pageNum = 1
		let loading = proxy.$tools.ShowLoading();
		await GetTableData()
		loading.close()
	}
	const currentchange = async () => {
		let loading = proxy.$tools.ShowLoading();
		await GetTableData()
		loading.close()
	}

	// 定义表单提交事件，带表单验证
	const submitForm = (form) => {
		form.validate(async (valid) => {
			if (valid) {
				let loading = proxy.$tools.ShowLoading();
				if (formdata.id.length > 0) {
					let response = await GetServerData('Room/Modify', formdata)
					if (response.status == 1) {
						proxy.$tools.ShowMessage('修改成功', true)
						dialogVisible.value = false
						await GetAllData();
					} else {
						proxy.$tools.ShowMessage(response.message, false)
					}
				} else {
					let response = await GetServerData('Room/Add', formdata)
					if (response.status == 1) {
						proxy.$tools.ShowMessage('新增成功！', true)
						if (goon.value) {
							lineTag.value = formdata.lineTag
							ammeterPriceId.value = formdata.ammeterPriceId
							ratio.value = formdata.ratio
							lineTag2.value = formdata.lineTag2
							ammeterPriceId2.value = formdata.ammeterPriceId2
							ratio2.value = formdata.ratio2
							watermeterPriceId1.value = formdata.watermeterPriceId1
							watermeterPriceId2.value = formdata.watermeterPriceId2
							lowPower.value = formdata.lowPower
							lowPower2.value = formdata.lowPower2
							lowWater1.value = formdata.lowWater1
							lowWater2.value = formdata.lowWater2
							lowBalance.value = formdata.lowBalance
							let temp1 = parseInt(formdata.lineNum)
							temp1++;
							lineNum.value = temp1.toString()
							let temp2 = parseInt(formdata.lineNum2)
							temp2++;
							lineNum2.value = temp2.toString()
							//ammeterId.value = formdata.ammeterId
							reset()
						} else {
							dialogVisible.value = false
						}
						await GetAllData();
					} else {
						proxy.$tools.ShowMessage(response.message, false)
					}
				}
				loading.close()
			} else {
				proxy.$tools.ShowMessage('请您正确填写所有必填信息后，再提交！', false)
				return false;
			}
		})
	}
	
	const handleAmmeterChange1 = async (value) => {
		if(value&&value.toString()!=''){
			for(let i=0;i<ammeters.value.length;i++){
				if(ammeters.value[i].id==value){
					let temp=ammeters.value[i].name.split('-');
					let category=temp[temp.length-1];
					if(category=='2'){
						formdata.lineNum='153'
						lineNumEnable.value=false;
					}else{
						lineNumEnable.value=true;
					}
				}
			}
		}
	}
	
	const handleAmmeterChange2 = async (value) => {
		if(value&&value.toString()!=''){
			for(let i=0;i<ammeters.value.length;i++){
				if(ammeters.value[i].id==value){
					let temp=ammeters.value[i].name.split('-');
					let category=temp[temp.length-1];
					if(category=='2'){
						formdata.lineNum2='153'
						lineNumEnable2.value=false;
					}else{
						lineNumEnable2.value=true;
					}
				}
			}
		}
	}
	
	
	const btnreset = () => {
		reset()
		selectedArea.value = []
		formdata.areaId1 = '';
		formdata.areaId2 = '';
		formdata.areaId3 = '';
		formdata.ratio = '1';
		formdata.ratio2 = '1';
		formdata.lowPower = estateConfig.value.lowPower;
		formdata.lowPower2 = estateConfig.value.lowPower2;
		formdata.lowWater1 = estateConfig.value.lowWater1;
		formdata.lowWater2 =estateConfig.value.lowWater2;
		formdata.lowBalance =estateConfig.value.lowBalance;
		formdata.ammeterPriceId=ammeterPrice1.value;
		formdata.ammeterPriceId2=ammeterPrice2.value;
		formdata.watermeterPriceId1=watermeterPrice1.value;
		formdata.watermeterPriceId2=watermeterPrice2.value;
		formdata.lineNum = '1';
		formdata.lineNum2 = '1';
		ammeters.value = [];
		watermeter1s.value = [];
		watermeter2s.value = [];
		formdata.ammeterId = ''
		formdata.ammeterId2 = ''
		formdata.onOffAmmeterException='0'
		formdata.onOffWatermeterException='0'
		formdata.canSendLowBalanceMessage='0'
		formdata.canSendLowPowerMessage='0'
		formdata.canSendLowPowerMessage2='0'
		formdata.canSendLowWater1Message='0'
		formdata.canSendLowWater2Message='0'
		formdata.canSendOweBalanceMessage='0'
	}
	// 重置表单事件
	const reset = () => {
		if (formRef.value) {
			formRef.value.resetFields();
		}
		if (selectedArea.value&&selectedArea.value.length == 3) {
			formdata.areaId1 = selectedArea.value[0]
			formdata.areaId2 = selectedArea.value[1]
			formdata.areaId3 = selectedArea.value[2]
		}
		//formdata.ammeterId = ammeterId.value
		formdata.lineTag = lineTag.value
		formdata.lineNum = lineNum.value
		formdata.lineTag2 = lineTag2.value
		formdata.lineNum2 = lineNum2.value
		formdata.ammeterPriceId = ammeterPriceId.value
		formdata.ratio = ratio.value
		formdata.ammeterPriceId2 = ammeterPriceId2.value
		formdata.ratio2 = ratio2.value
		//formdata.watermeterId1 = ''
		formdata.watermeterPriceId1 = watermeterPriceId1.value
		//formdata.watermeterId2 = ''
		formdata.watermeterPriceId2 = watermeterPriceId2.value
		formdata.roomNum = ''
		formdata.roomName = ''
		formdata.familyName = ''
		formdata.familyPhone = ''
		formdata.lowPower = lowPower.value
		formdata.lowPower2 = lowPower2.value
		formdata.lowWater1 = lowWater1.value
		formdata.lowWater2 = lowWater2.value
		formdata.lowBalance = lowBalance.value
		formdata.remark = '';
	}
	// 定义新增按钮事件
	const add = () => {
		dialogtitle.value = "新 增 房 间"
		goon.value = false
		showgoon.value = true
		btnreset()
		formdata.id = '';
		dialogVisible.value = true
	}
	// 定义编辑按钮事件
	const modify = async (row) => {
		dialogtitle.value = "修 改 房 间"
		deviceParams.areaId1 = row.areaId1;
		deviceParams.areaId2 = row.areaId2;
		deviceParams.areaId3 = row.areaId3;
		await GetDeviceData();
		showgoon.value = false
		formdata.id = row.id;
		formdata.areaId1 = row.areaId1;
		formdata.areaId2 = row.areaId2;
		formdata.areaId3 = row.areaId3;
		formdata.lineTag = row.lineTag;
		let flag=false;
		if(row.ammeterId&&row.ammeterId!=''&&ammeters.value&&ammeters.value.length>0){
			for(let i=0;i<ammeters.value.length;i++){
				if(ammeters.value[i].id==row.ammeterId){
					flag=true;
					break;
				}
			}
		}
		if(flag){
			formdata.ammeterId = row.ammeterId;
		}
		else{
			formdata.ammeterId = '';
		}
		formdata.lineNum = row.lineNum.toString();
		formdata.ammeterPriceId = row.ammeterPriceId;
		formdata.ratio = row.ratio.toString();
		formdata.lineTag2 = row.lineTag2;
		flag=false;
		if(row.ammeterId2&&row.ammeterId2!=''&&ammeters.value&&ammeters.value.length>0){
			for(let i=0;i<ammeters.value.length;i++){
				if(ammeters.value[i].id==row.ammeterId2){
					flag=true;
					break;
				}
			}
		}
		if(flag){
			formdata.ammeterId2 = row.ammeterId2;
		}
		else{
			formdata.ammeterId2 = '';
		}
		formdata.lineNum2 = row.lineNum2.toString();
		formdata.ammeterPriceId2 = row.ammeterPriceId2;
		formdata.ratio2 = row.ratio2.toString();
		flag=false;
		if(row.watermeterId1&&row.watermeterId1!=''&&watermeter1s.value&&watermeter1s.length>0){
			for(let i=0;i<watermeter1s.value.length;i++){
				if(watermeter1s.value[i].id==row.watermeterId1){
					flag=true;
					break;
				}
			}
		}
		if(flag){
			formdata.watermeterId1 = row.watermeterId1;
		}
		else{
			formdata.watermeterId1 = '';
		}
		formdata.watermeterPriceId1 = row.watermeterPriceId1;
		flag=false;
		if(row.watermeterId2&&row.watermeterId2!=''&&watermeter2s.value&&watermeter2s.length>0){
			for(let i=0;i<watermeter2s.value.length;i++){
				if(watermeter2s.value[i].id==row.watermeterId2){
					flag=true;
					break;
				}
			}
		}
		if(flag){
			formdata.watermeterId2 = row.watermeterId2;
		}
		else{
			formdata.watermeterId2 = '';
		}
		formdata.watermeterPriceId2 = row.watermeterPriceId2;
		formdata.roomNum = row.roomNum;
		formdata.roomName = row.roomName;
		formdata.familyName = row.familyName;
		formdata.familyPhone = row.familyPhone;
		formdata.remark = row.remark;
		formdata.lowPower = row.lowPower.toString();
		formdata.lowPower2 = row.lowPower2.toString();
		formdata.lowWater1 = row.lowWater1.toString();
		formdata.lowWater2 = row.lowWater2.toString();
		formdata.lowBalance = row.lowBalance.toString();
		formdata.onOffAmmeterException=row.onOffAmmeterException;
		formdata.onOffWatermeterException=row.onOffWatermeterException;
		formdata.canSendLowBalanceMessage=row.canSendLowBalanceMessage;
		formdata.canSendLowPowerMessage=row.canSendLowPowerMessage;
		formdata.canSendLowPowerMessage2=row.canSendLowPowerMessage2;
		formdata.canSendLowWater1Message=row.canSendLowWater1Message;
		formdata.canSendLowWater2Message=row.canSendLowWater2Message;
		formdata.canSendOweBalanceMessage=row.canSendOweBalanceMessage;
		formdata.createBillStatus=row.createBillStatus;
		formdata.giftPowerStatus=row.giftPowerStatus;
		formdata.giftWaterStatus=row.giftWaterStatus;
		formdata.canCharge=row.canCharge;
		selectedArea.value = [row.areaId1, row.areaId2, row.areaId3]
		dialogVisible.value = true
	}
	// 定义删除按钮事件
	const deleteitem = (row) => {
		ElMessageBox.confirm('确定要删除这条记录吗?', "", {
				confirmButtonText: '确 定',
				cancelButtonText: '取 消',
				type: 'warning'
			})
			.then(async () => {
				let loading = proxy.$tools.ShowLoading();
				let response = await GetServerData('Room/Delete', {
					id: row.id
				})

				if (response.status == 1) {
					proxy.$tools.ShowMessage('删除成功！', true)
					await GetAllData()
				} else {
					proxy.$tools.ShowMessage(response.message, false)
				}
				loading.close();
			})
	}
	// 定义批量删除按钮事件
	const deletesome = () => {
		var ids = ''
		multipleSelection.forEach((value, key, iterable) => {
			ids += "'" + value.id + "',";
		})
		if (ids.length > 0) {
			ids = ids.substr(0, ids.length - 1)
			ElMessageBox.confirm('确定要删除下面选中的所有记录吗?', "", {
					confirmButtonText: '确 定',
					cancelButtonText: '取 消',
					type: 'warning'
				})
				.then(async () => {
					let loading = proxy.$tools.ShowLoading();
					let response = await GetServerData('Room/DeleteSome', {
						ids: ids
					})
					if (response.status == 1) {
						proxy.$tools.ShowMessage('删除成功！', true)
						await GetAllData()
					} else {
						proxy.$tools.ShowMessage(response.message, false)
					}
					loading.close()
				})
		}
	}
	
	const exportPhoneModule=async()=>{
		let response = await GetServerData('Room/DownloadExcelModule', params)
		if (response.status == 1) {
			let fileurl=response.data;
			window.open(fileurl);
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}
	
	const importPhoneExcel=()=>{
		choosefile.value.value="";
		choosefile.value.click();
		
	}
	
	// 定义查询按钮事件
	const search = async () => {
		let loading = proxy.$tools.ShowLoading();
		await GetTableData()
		loading.close()
	}
	// 定义刷新按钮事件
	const resetparams = () => {
		params.areaId1 = ''
		params.areaId2 = ''
		params.areaId3 = ''
		params.roomNum = ''
		params.roomName = ''
		params.familyName = ''
		params.familyPhone = ''
		querySelectedArea.value = [];
	}
	// 定义获取表格数据函数
	const GetTableData = async () => {
		let response = await GetServerData('Room/QueryAll', params)
		if (response.status == 1) {
			tableDatas.value = response.data
			pagingInfo.totalCount = response.totalCount
			pagingInfo.totalPage = Math.ceil(pagingInfo.totalCount / params.pageSize)
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}
	const GetDeviceData = async () => {
		let loading = proxy.$tools.ShowLoading();
		let response = await GetServerData('Room/QueryComboxData', deviceParams)
		if (response.status == 1) {
			ammeters.value = response.data.ammeters;
			watermeter1s.value = response.data.watermeter1s;
			watermeter2s.value = response.data.watermeter2s;
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
		loading.close()
	}
	// 定义获取combox数据函数
	const GetComboxData = async () => {
		let response = await GetServerData('Room/QueryComboxInitData')
		if (response.status == 1) {
			ammeterPrices.value = response.data.ammeterPrices;
			watermeterPrices.value = response.data.watermeterPrices;
			areas.value = response.data.areas;
			estateConfig.value=response.data.estateConfig;
			ammeterPrice1.value=response.data.ammeterPrice1;
			ammeterPrice2.value=response.data.ammeterPrice2;
			watermeterPrice1.value=response.data.watermeterPrice1;
			watermeterPrice2.value=response.data.watermeterPrice2;
			//console.log(response.data.estateConfig)
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}
	
	const refresh=async ()=>{
		let loading = proxy.$tools.ShowLoading();
		tableDatas.value=[];
		await GetAllData()
		loading.close()
	}
	
	// 定义获取所有数据函数
	const GetAllData = async () => {
		await GetTableData()
		await GetComboxData()
	}
	let tableheight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight))
	
	let dialogWidth=ref(1900)
	let dialogSpan=ref(6)

	// 定义加载事件
	onMounted(async () => {
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth - 220) +
			'px'
		window.onresize = () => {
			tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight)
			document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
					220) +
				'px'
		}
		let loading = proxy.$tools.ShowLoading();
		await GetAllData()
		loading.close()
		if(ammeterType.value=="0"||watermeterType.value=="0"){
			dialogWidth.value=1500;
			dialogSpan.value=8;
		}
	})
	onActivated(() => {
		tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight)
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
			220) + 'px'
	})
</script>

<style scoped lang="less">
</style>
