<template>
	<div class="contentheader">
		<el-space wrap>
			<span class="titleClass">账单统计</span>
			<el-divider direction="vertical" />
			<el-radio-group v-model="params.category" class="ml-4">
				<el-radio label="1" size="small">按账单年统计</el-radio>
				<el-radio label="2" size="small">按账单月统计</el-radio>
			</el-radio-group>
			<el-divider direction="vertical" />
			<el-select v-model="params.year" class="m-2" size="small" style="width: 100px;">
				<el-option v-for="item in years" :key="item.value" :label="item.label" :value="item.value" />
			</el-select>
			<el-select v-model="params.month" class="m-2" size="small" style="width: 100px;" v-if="params.category=='2'">
				<el-option v-for="item in months" :key="item.value" :label="item.label" :value="item.value" />
			</el-select>
			<el-divider direction="vertical" />
			<el-button type="primary" size="small" class="buttonforheader" @click="search">统 计</el-button>
			<el-divider direction="vertical" />
			<el-button type="info" size="small" class="buttonforheader" @click="ExportExcel">导 出</el-button>
		</el-space>
	</div>
	<div id="divTableData" style="width: 100%;border-radius: 6px;background-color: white; margin-left: 5px; overflow-y: scroll;" class="divTableDataClass">
		<el-table :data="tableDatas" fit="false" highlight-current-row="true" ref="multipleTable" tooltip-effect="dark"
			:height="tableheight" row-key="areaName2"  id="tableData"
			:header-cell-style="headerCellStype" :cell-style="contentCellStyle" style="width: 100%; cursor: pointer;"
			:row-class-name="tableRowClassName">
			<el-table-column prop="year" label="账单年" width="100" align="center" />
			<el-table-column prop="month" label="账单月" width="100" align="center"/>
			<el-table-column prop="areaName1" label="一级区域" width="100" align="left"  />
			<el-table-column prop="areaName2" label="二级区域" width="100" align="left" />
			<el-table-column prop="useAmount1" :label="ammeterName1+'用量'" width="120" align="right"/>
			<el-table-column prop="useAmount2" :label="ammeterName2+'用量'" width="120" align="right"/>
			<el-table-column prop="useAmount" label="总用量" width="100" align="right"/>
			<el-table-column prop="giftAmount" label="赠送量" width="100" align="right"/>
			<el-table-column prop="shouldPayAmount" label="应收用量" width="100" align="right"/>
			<el-table-column prop="shouldPayMoney" label="应收金额" width="100" align="right"/>
			<el-table-column prop="hadPayAmount" label="已收用量" width="100" align="right"/>
			<el-table-column prop="hadPayMoney" label="已收金额" width="100" align="right"/>
			<el-table-column prop="noPayAmount" label="未收用量" width="100" align="right"/>
			<el-table-column prop="noPayMoney" label="未收金额" width="100" align="right"/>
			<el-table-column prop="noSendAmount" label="未发送账单用量" width="150" align="right"/>
			<el-table-column />
		</el-table>
	</div>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted,
		onActivated,
		getCurrentInstance
	} from 'vue'
	import {
		ElMessageBox
	} from 'element-plus'
	import {
		GetServerData
	} from '@/http/index.js'
	// 获取全局this，proxy相当于vue2中的this，用于调用全局对象使用
	const {
		proxy
	} = getCurrentInstance()

	// 定义表格数据
	let tableDatas = ref([])
	let headerCellStype = ref(proxy.$headerCellStype)
	let contentCellStyle = ref(proxy.$contentCellStyle)
	
	let ammeterName1=ref(localStorage.getItem("ammeterName1"));
	let ammeterName2=ref(localStorage.getItem("ammeterName2"));
	
	// 定义表格隔行变色，暂时没用到
	const tableRowClassName = ({
		row,
		rowIndex
	}) => {
		if (rowIndex % 2 === 0) {
			return 'even-row'
		} else {
			return 'odd-row'
		}
	}
	
	let params = ref({
		category:'2',
		year:'2023',
		month:'01'
	})
	
	let years=ref([
		{
			label:'2023',
			value:'2023'
		}
	])
	
	let months=ref([
		{
			label:'01',
			value:'01'
		},{
			label:'02',
			value:'02'
		},{
			label:'03',
			value:'03'
		},{
			label:'04',
			value:'04'
		},{
			label:'05',
			value:'05'
		},{
			label:'06',
			value:'06'
		},{
			label:'07',
			value:'07'
		},{
			label:'08',
			value:'08'
		},{
			label:'09',
			value:'09'
		},{
			label:'10',
			value:'10'
		},{
			label:'11',
			value:'11'
		},{
			label:'12',
			value:'12'
		}
	])
	
	const ExportExcel=()=>{
		// ElMessageBox.confirm("您确定要将表格导出为PDF？是否继续？", "提 示", {
		// 		confirmButtonText: "确 定",
		// 		cancelButtonText: "取 消",
		// 		type: "warning",
		// 	})
		// 	.then(() => {
		// 		let nianyue='';
		// 		if(params.value.category=='1'){
		// 			nianyue=params.value.year;
		// 		}else{
		// 			nianyue=params.value.year+'年'+params.value.month+'月';
		// 		}
		// 		let fileName="账单统计（"+nianyue+"）";
		// 		proxy.$pdf("tableData",fileName);
		
		// 	})
		// 	.catch((e) => {
		// 		//console.log(e);
		// 	});
		let nianyue='';
		if(params.value.category=='1'){
			nianyue=params.value.year;
		}else{
			nianyue=params.value.year+'年'+params.value.month+'月';
		}
		proxy.$tools.ExportExcel(document.querySelector("#tableData"),"账单统计（"+nianyue+"）.xlsx")
	}
	
	// 定义查询按钮事件
	const search = async () => {
		let loading = proxy.$tools.ShowLoading();
		let response = await GetServerData('BillStatistics/QueryAll',params.value)
		if (response.status == 1) {
			tableDatas.value=response.data
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
		loading.close()
	}
	
	const setYear=()=>{
		let currentDateTime = new Date();
		let year=currentDateTime.getFullYear();
		//year=2025;
		let span=year-2023;
		for(let i=0;i<span;i++){
			years.value.push({
				label:(2023+i+1).toString(),
				value:(2023+i+1).toString()
			})
		}
		params.value.year=year.toString();
		params.value.month=proxy.$tools.PadLeftString((currentDateTime.getMonth()+1).toString(),2,'0');
	}

	let tableheight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight+ 60))
	// 定义加载事件
	onMounted(async () => {
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth - 220) +
			'px'
		window.onresize = () => {
			tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight+ 60)
			document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
					220) +
				'px'
		}
		setYear();
		//let loading = proxy.$tools.ShowLoading();
		//await search()
		//loading.close()
	})
	onActivated(() => {
		tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight+ 60)
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
			220) + 'px'
	})
</script>

<style scoped lang="less">
</style>
