<template>
	
	<div class="login" v-if="showlogin" style="background-image: url('loginbg.png'); background-repeat: no-repeat; background-size: 100% 100%;">
		<div class="userpass">
			<el-card class="box-card">
				<div slot="header" class="clearfix">
					<span>绿 色 建 筑 能 效 管 理 系 统</span>
				</div>
				<div style="margin-top: 30px;">
					<el-form ref="formRef" :model="formData" label-width="100px" class="demo-ruleForm">
						<el-form-item label="账号" prop="name" :rules="[{ required: true, message: '请输入账号' }]">
							<el-input v-model="formData.name" type="text" autocomplete="off" placeholder="请输入账号" focus/>
						</el-form-item>
						<el-form-item label="密码" prop="pass" :rules="[{ required: true, message: '请输入密码' }]">
							<el-input v-model="formData.pass" type="password" autocomplete="off" show-password
								placeholder="请输入密码" />
						</el-form-item>
						<el-button type="primary" style="width: 100%; font-size: 20px; text-align: center;" @click="submitForm(formRef)"
							id="btnLogin">登&nbsp&nbsp&nbsp;录</el-button>
					</el-form>
					<div v-if="showIsExpired" style="color: red;">登录超时，请重新登录！</div>
				</div>																
			</el-card>
		</div>
	</div>
	<div v-if="!showlogin" style="margin: 200px auto; text-align: center; width: 600px;">
		页面正在跳转中，请稍后。。。
	</div>
</template>

<script setup>
	import {
		reactive,
		ref,
		getCurrentInstance,
		onMounted
	} from 'vue'
	import router from "@/router"
	import {
		GetServerData
	} from '@/http/index.js'
	import {
		ElMessageBox
	} from 'element-plus'
	// 获取全局this，proxy相当于vue2中的this，用于调用全局对象使用
	const {
		proxy
	} = getCurrentInstance()

	// 定义表单数据
	let formData = reactive({
		name: '',
		pass: ''
	})
	
	let showlogin=ref(true);
	let showIsExpired=ref(false);

	// 定义表单引用，这个必须有，在自动校验中使用
	const formRef = ref()


	// 定义表单提交方法，内有element-plus提供的表单验证功能
	const submitForm = (form) => {
		form.validate(async (valid) => {
			if (valid) {
				let loading = proxy.$tools.ShowLoading();
				let response = await GetServerData('Login/Login', {
					name: formData.name,
					pass: formData.pass
				});
				loading.close()
				if (response.status == 1) {
					// 登录成功后，存储以下数据：用户名、用户头像、token、菜单、用户住宅区名称
					localStorage.setItem("useraccount", formData.name)
					localStorage.setItem("username", response.data.name)
					localStorage.setItem("userpicture", response.data.picture)
					localStorage.setItem("token", "Bearer " + response.data.token)
					localStorage.setItem("usermenus", JSON.stringify(response.data.menus))
					localStorage.setItem("userestatename", response.data.estateName)
					localStorage.setItem("ammeterType", response.data.ammeterType)
					localStorage.setItem("ammeterName1", response.data.ammeterName1)
					localStorage.setItem("ammeterName2", response.data.ammeterName2)
					localStorage.setItem("watermeterType", response.data.watermeterType)
					localStorage.setItem("watermeterName1", response.data.watermeterName1)
					localStorage.setItem("watermeterName2", response.data.watermeterName2)
					localStorage.setItem("adminFlag", response.data.adminFlag)
					localStorage.setItem("isManager", response.data.isManager)
					localStorage.setItem("showDefaultIcon", response.data.showDefaultIcon)
					localStorage.setItem("customIcon", response.data.customIcon)
					router.replace("/Home");
				} else {
					proxy.$tools.ShowMessageBox("错误信息", response.message)
				}
			} else {
				return false;
			}
		})
	}
	const keyDown = (e) => {
		if (e.keyCode === 13) {
			document.getElementById('btnLogin').click();
		}
	}
	onMounted(async () => {
		window.addEventListener('keydown', keyDown)
		let isExpired=localStorage.getItem('isExpired');
		if(isExpired=='1'){
			localStorage.setItem('isExpired','0');
			showIsExpired.value=true;
			setTimeout(()=>{
				showIsExpired.value=false;
			},1000*2);
		}
		let loading = proxy.$tools.ShowLoading();
		let response = await GetServerData('Refresh/GetRefreshNum', "83DC86D4CD7340B6B6871903453DF1EE");		
		loading.close()
		if (response.status == 1) {
			if(localStorage.getItem("refreshNum")!=response.data){
				localStorage.setItem("refreshNum",response.data);
				console.log("Refresh:"+response.data);
				window.location.reload(true);
			}
		}
		
	})
</script>

<style scoped lang="less">
	.login {
		width: 1150px;
		height: 600px; 
		margin: 0 auto;
		text-align: center;
		align-self: center;
		border-radius: 10px;
	}
	.userpass {
		width: 400px;
		margin-top: 210px;
		margin-bottom: auto;
		margin-left: auto;
		margin-right: 30px;
		padding: 30px;
		text-align: center;
		align-self: center;
		border-radius: 10px;
		background: white;
	}
	.clearfix {
		height: 50px;
		line-height: 50px;
		background-color: #409EFF;
	}
	
	.clearfix:before,
	.clearfix:after {
		display: table;
		content: "";
	}
	
	.clearfix:after {
		clear: both
	}
	
	.box-card {
		width: 390px;
		margin-top: 5px;
		margin-left: 5px;
		padding:0px;
	}
</style>
