
<template>

	<div class="contentheader">
		<el-space wrap>
			<span class="titleClass">设置表具记录</span>
			<el-divider direction="vertical" />
			<el-input v-model="params.keyword" placeholder="输入关键字" size="small" style="width: 140px;" clearable
				autocomplete="off" />
			<el-date-picker v-model="dateRange" type="datetimerange" :shortcuts="shortcuts" range-separator="至"
				:defaultTime="defaultTime" start-placeholder="开始时间" end-placeholder="结束时间" @change="dateChange"
				size="small" />
			<el-select v-model="params.meterType" class="m-2" size="small" style="width: 140px;" v-if="(ammeterType=='2'||watermeterType=='2')||(ammeterType=='1'&&watermeterType=='1')">
				<el-option key="0" label="全部" value="0" />
				<el-option key="1" :label="ammeterName1" value="1" v-if="ammeterType!='0'" />
				<el-option key="2" :label="ammeterName2" value="2" v-if="ammeterType=='2'" />
				<el-option key="3" :label="watermeterName1" value="3" v-if="watermeterType!='0'" />
				<el-option key="4" :label="watermeterName2" value="4"  v-if="watermeterType=='2'"/>
			</el-select>
			<el-button type="primary" size="small" class="buttonforheader" @click="search">查 询</el-button>
			<el-button type="primary" size="small" class="buttonforheader" @click="refresh">刷 新</el-button>
			<el-divider direction="vertical" />
			<el-button type="info" size="small" class="buttonforheader" @click="ExportExcel">导 出</el-button>
		</el-space>
	</div>
	<div style="display: flex; flex-direction: row; flex-wrap: nowrap; align-items: flex-start;margin: 10px;">
		<div style="width: 200px;">
			<el-scrollbar :height="areaHeight" style="width:200px; border-radius: 6px;background-color: white;">
				<el-tree ref="treeAreaRef" :data="areaDatas" node-key="id" :expand-on-click-node="false"
					:props="treeProps" @node-click="areaSelected" style="margin: 10px;" highlight-current >
				</el-tree>
			</el-scrollbar>
		</div>
		<div id="divTableData" style="width: 100%;border-radius: 6px;background-color: white; margin-left: 5px;">
			<el-table :data="tableDatas" fit="false" highlight-current-row="true" ref="multipleTable"
				tooltip-effect="dark" :height="tableheight" row-key="id" :header-cell-style="headerCellStype"
				:cell-style="contentCellStyle" style="width: 100%; cursor: pointer;"
				:row-class-name="tableRowClassName"
				id="tableData"
				>
				<el-table-column fixed prop="id" label="id" width="0" v-if="false" />
				<el-table-column prop="areaName1" label="一级区域" width="100" />
				<el-table-column prop="areaName2" label="二级区域" width="100" />
				<el-table-column prop="areaName3" label="三级区域" width="100" />
				<el-table-column prop="roomName" label="房间名称" width="100" />
				<el-table-column prop="roomNum" label="房间编号" width="100" />
				<el-table-column prop="familyName" label="户主名称" width="150"  v-if="false" />
				<el-table-column prop="familyPhone" label="户主电话" width="150"  v-if="false" />
				<el-table-column prop="meterTypeName" label="设备类型" width="100" />
				<el-table-column prop="meterAddress" label="表地址" width="120" align="center" />
				<el-table-column prop="lineNum" label="线号" width="120" align="center" />
				<el-table-column prop="categoryName" label="设置类型" width="120" align="center" />
				<el-table-column prop="statusName" label="状态" width="120" align="center" />
				<el-table-column prop="userName" label="操作员" width="120" align="center" />
				<el-table-column prop="description" label="描述" width="300" />
				<el-table-column prop="ct" label="时间" width="150" align="center" />
				<el-table-column />
			</el-table>
		</div>
	</div>
	<div class="contentpager" v-if="showpaging">
		<el-pagination background layout="prev, pager, next,jumper,sizes,total" :total="pagingInfo.totalCount"
			:page-sizes="[500,1000,2000,3000]" v-model:current-page="params.pageNum" v-model:page-size="params.pageSize"
			@size-change="sizechange" @current-change="currentchange" />
	</div>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted,
		onActivated,
		getCurrentInstance
	} from 'vue'
	import {
		ElMessageBox
	} from 'element-plus'
	import {
		GetServerData
	} from '@/http/index.js'
	// 获取全局this，proxy相当于vue2中的this，用于调用全局对象使用
	const {
		proxy
	} = getCurrentInstance()
	const treeAreaRef = ref()
	const treeProps = reactive({
		children: 'children',
		label: 'name'
	})
	let showTag = ref(false)
	// 定义查询参数
	let params = reactive({
		keyword: '',
		areaId1: '',
		areaId2: '',
		areaId3: '',
		roomId: '',
		beginTime: '',
		endTime: '',
		meterType:'0',
		pageSize: 500,
		pageNum: 1
	})
	let selectedArea = ref('')

	let headerCellStype = ref(proxy.$headerCellStype)
	let contentCellStyle = ref(proxy.$contentCellStyle)
	
	let ammeterType=ref(localStorage.getItem("ammeterType"));
	let ammeterName1=ref(localStorage.getItem("ammeterName1"));
	let ammeterName2=ref(localStorage.getItem("ammeterName2"));
	
	let watermeterType=ref(localStorage.getItem("watermeterType"));
	let watermeterName1=ref(localStorage.getItem("watermeterName1"));
	let watermeterName2=ref(localStorage.getItem("watermeterName2"));

	// 定义表格数据
	let tableDatas = ref([])
	let areaDatas = ref([])
	// 定义是否显示分页
	let showpaging = ref(true)
	let pagingInfo = reactive({
		totalCount: 0,
		totalPage: 0
	})


	// 定义表格引用，用来执行多选
	const multipleTable = ref()

	const ExportExcel=()=>{
		proxy.$tools.ExportExcel(document.querySelector("#tableData"),"设置表具记录（"+params.beginTime+"至"+params.endTime+"）.xlsx")
	}
	// 定义表格隔行变色，暂时没用到
	const tableRowClassName = ({
		row,
		rowIndex
	}) => {
		if (rowIndex % 2 === 0) {
			return 'even-row'
		} else {
			return 'odd-row'
		}
	}
	
	

	const sizechange = async () => {
		params.pageNum = 1
		await search()
		multipleTable.value.$refs.bodyWrapper.scrollTo(0, 0);
	}
	const currentchange = async () => {
		await search()
		multipleTable.value.$refs.bodyWrapper.scrollTo(0, 0);
	}

	let currentDateTime = new Date();
	let defaultBeginTime = new Date(currentDateTime.getFullYear(), currentDateTime.getMonth(), currentDateTime.getDate(),
		0, 0, 0)
	let defaultEndTime = new Date(currentDateTime.getFullYear(), currentDateTime.getMonth(), currentDateTime.getDate(), 23,
		23, 59)
	let dateRange = ref([defaultBeginTime, defaultEndTime])
	let defaultTime = ref([defaultBeginTime, defaultEndTime])
	params.beginTime = proxy.$tools.DateTimeToString(defaultBeginTime);
	params.endTime = proxy.$tools.DateTimeToString(defaultEndTime);
	const shortcuts = ref([{
			text: '今天',
			value: () => {
				let end = new Date()
				let start = new Date()
				start.setHours(0)
				start.setMinutes(0)
				start.setSeconds(0)
				end.setHours(23);
				end.setMinutes(59)
				end.setSeconds(59)
				return [start, end]
			}
		}, {
			text: '昨天',
			value: () => {
				let end = new Date()
				let start = new Date()
				start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
				start.setHours(0)
				start.setMinutes(0)
				start.setSeconds(0)
				end.setHours(23);
				end.setMinutes(59)
				end.setSeconds(59)
				return [start, end]
			}
		}, {
			text: '前天',
			value: () => {
				let end = new Date()
				let start = new Date()
				start.setTime(start.getTime() - 3600 * 1000 * 24 * 2)
				start.setHours(0)
				start.setMinutes(0)
				start.setSeconds(0)
				end.setHours(23);
				end.setMinutes(59)
				end.setSeconds(59)
				return [start, end]
			}
		}, {
			text: '之前一周',
			value: () => {
				let end = new Date()
				let start = new Date()
				start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
				end.setHours(23);
				end.setMinutes(59)
				end.setSeconds(59)
				start.setHours(0)
				start.setMinutes(0)
				start.setSeconds(0)
				return [start, end]
			}
		},
		{
			text: '之前一个月',
			value: () => {
				let end = new Date()
				let start = proxy.$tools.ReduceMonth(end, 1)
				end.setHours(23);
				end.setMinutes(59)
				end.setSeconds(59)
				return [start, end]
			}
		},
		{
			text: '之前二个月',
			value: () => {
				let end = new Date()
				let start = proxy.$tools.ReduceMonth(end, 2)
				end.setHours(23);
				end.setMinutes(59)
				end.setSeconds(59)
				return [start, end]
			}
		},
		{
			text: '之前三个月',
			value: () => {
				let end = new Date()
				let start = proxy.$tools.ReduceMonth(end, 3)
				end.setHours(23);
				end.setMinutes(59)
				end.setSeconds(59)
				return [start, end]
			}
		}
	])
	const resetSelectedArea = () => {
		selectedArea.value = ''
		showTag.value = false
		params.areaId1 = ''
		params.areaId2 = ''
		params.areaId3 = ''
		params.roomId = ''
	}

	const areaSelected = async (area) => {
		console.log(area)
		let grade = area.grade
		let id = area.id
		let selectedName = getSelectedArea(id)
		if (selectedName.length > 0) {
			selectedName = "所选区域：" + selectedName
			showTag.value = true
		} else {
			showTag.value = false
		}
		selectedArea.value = selectedName
		params.areaId1 = ''
		params.areaId2 = ''
		params.areaId3 = ''
		params.roomId = ''
		console.log(grade, id)
		if (grade == "1") {
			params.areaId1 = id;
		} else if (grade == "2") {
			params.areaId2 = id;
		} else if (grade == "3") {
			params.areaId3 = id;
		} else if (grade == "4") {
			params.roomId = id;
		}
	}

	const getSelectedArea = (id) => {
		let selectedNames = ''
		for (let i = 0; i < areaDatas.value.length; i++) {
			selectedNames += areaDatas.value[i].name
			if (areaDatas.value[i].id == id) {
				return selectedNames
			}
			for (let j = 0; j < areaDatas.value[i].children.length; j++) {
				selectedNames += " - " + areaDatas.value[i].children[j].name
				if (areaDatas.value[i].children[j].id == id) {
					return selectedNames
				}
				for (let k = 0; k < areaDatas.value[i].children[j].children.length; k++) {
					selectedNames += " - " + areaDatas.value[i].children[j].children[k].name
					if (areaDatas.value[i].children[j].children[k].id == id) {
						return selectedNames
					}
					for (let m = 0; m < areaDatas.value[i].children[j].children[k].children.length; m++) {
						selectedNames += " - " + areaDatas.value[i].children[j].children[k].children[m].name
						if (areaDatas.value[i].children[j].children[k].children[m].id == id) {
							return selectedNames
						}
						selectedNames = areaDatas.value[i].name + " - " + areaDatas.value[i].children[j].name + " - " +
							areaDatas.value[i].children[j].children[k].name
					}
					selectedNames = areaDatas.value[i].name + " - " + areaDatas.value[i].children[j].name
				}
				selectedNames = areaDatas.value[i].name
			}
			selectedNames = ''
		}
		return selectedNames
	}

	const dateChange = (value) => {
		params.beginTime = proxy.$tools.DateTimeToString(value[0]);
		params.endTime = proxy.$tools.DateTimeToString(value[1]);
	}

	const search = async () => {
		if (params.beginTime.length > 0 && params.endTime.length > 0) {
			let loading = proxy.$tools.ShowLoading()
			let response = await GetServerData('SetMeterRecord/QueryAll', params)
			if (response.status == 1) {
				tableDatas.value = response.data
				pagingInfo.totalCount = response.totalCount
				pagingInfo.totalPage = Math.ceil(pagingInfo.totalCount / params.pageSize)
				console.log(multipleTable.value)
			} else {
				proxy.$tools.ShowMessage(response.message, false)
			}
			loading.close();
		} else {
			proxy.$tools.ShowMessage("请选择开始时间和结束时间", false)
		}
	}
	
	const refresh=async ()=>{
		let loading = proxy.$tools.ShowLoading();
		tableDatas.value=[];
		await GetAllData()
		loading.close()
	}

	// 定义获取表格数据函数
	const GetRoomData = async () => {
		let response = await GetServerData('SetMeterRecord/QueryAllRooms')
		if (response.status == 1) {
			areaDatas.value = response.data
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}

	// 定义获取所有数据函数
	const GetAllData = async () => {
		await GetRoomData()
	}
	let areaHeight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight))
	let tableheight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight))

	// 定义加载事件
	onMounted(async () => {
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth - 425) +
			'px'
		window.onresize = () => {
			tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight)
			areaHeight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight)
			document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
				425) + 'px'
		}
		let loading = proxy.$tools.ShowLoading();
		await GetAllData()
		loading.close()
	})
	onActivated(()=>{
		tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight)
		areaHeight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight)
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
			425) + 'px'
	})
</script>

<style scoped lang="less">
</style>
