<template>
	<div class="contentheader">
		<el-space wrap>
			<span class="titleClass">注销住户</span>
			<el-divider direction="vertical" />
			<el-input v-model="params.keyword" placeholder="输入关键字" size="small" style="width: 150px;"
				autocomplete="off" />
			<el-button type="primary" size="small" class="buttonforheader" @click="search">查 询</el-button>
			<el-button type="primary" size="small" class="buttonforheader" @click="refresh">刷 新</el-button>
		</el-space>
	</div>
	<div style="display: flex; flex-direction: row; flex-wrap: nowrap; align-items: flex-start;margin: 10px;">
		<div style="width: 200px;">
			<el-scrollbar :height="areaHeight" style="width:200px; border-radius: 6px;background-color: white;">
				<el-tree ref="treeAreaRef" :data="areaDatas" node-key="id" :expand-on-click-node="false"
					:props="treeProps" @node-click="areaSelected" style="margin: 10px;" highlight-current>
				</el-tree>
			</el-scrollbar>
		</div>
		<div id="divTableData" style="width: 100%;border-radius: 6px;background-color: white; margin-left: 5px;">
			<el-table :data="tableDatas" fit="false" highlight-current-row="true" ref="multipleTable"
				tooltip-effect="dark" :height="tableheight" @selection-change="handleSelectionChange"
				@row-click="rowclick" row-key="id" default-expand-all :header-cell-style="headerCellStype"
				:cell-style="contentCellStyle" style="width: 100%; cursor: pointer;" :row-class-name="tableRowClassName"
				>
				<el-table-column fixed prop="roomId" label="roomId" width="0" v-if="false" />
				<el-table-column prop="areaName1" label="一级区域" width="100" />
				<el-table-column prop="areaName2" label="二级区域" width="100" />
				<el-table-column prop="areaName3" label="三级区域" width="100" />
				<el-table-column prop="roomName" label="房间名称" width="100" />
				<el-table-column prop="roomNum" label="房间编号" width="100" />
				<el-table-column prop="familyName" label="户主名称" width="200" />
				<el-table-column prop="familyPhone" label="户主电话" width="150" align="center" />
				<el-table-column />
				<el-table-column fixed="right" label="操作" width="100" align="left">
					<template #default="scope">
						<el-button size="mini" @click.stop="confirmCancel(scope.row)" type="success"
							class="buttonfortable">
							注销住户
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
	<el-dialog width="800px" v-model="dialogVisible" append-to-body=true :title="dialogtitle" center draggable
		:close-on-click-modal="false" :show-close="true">
		<el-row>
			<el-col :span="10" class="alignright">账户余额(元)：</el-col>
			<el-col :span="12" class="alignleft">
				{{canceldata.roomBalance}}
			</el-col>
		</el-row>
		<el-row v-if="canceldata.ammeterType!='0'">
			<el-col :span="10" class="alignright">{{canceldata.ammeterName1}}余量({{ammeterStoreType1=="2"?"元":"度"}})：</el-col>
			<el-col :span="12" class="alignleft">
				{{canceldata.ammeterStoreType1=="2"?canceldata.ammeterBalance1:canceldata.ammeterValue1}}
			</el-col>
		</el-row>
		<el-row v-if="canceldata.ammeterType!='0'">
			<el-col :span="10" class="alignright">{{canceldata.ammeterName1}}金额(元)：</el-col>
			<el-col :span="12" class="alignleft">
				{{canceldata.ammeterStoreType1=="2"?canceldata.ammeterBalance1:canceldata.ammeterMoney1}}
			</el-col>
		</el-row>
		
		<el-row v-if="canceldata.ammeterType=='2'">
			<el-col :span="10" class="alignright">{{canceldata.ammeterName2}}余量({{ammeterStoreType2=="2"?"元":"度"}})：</el-col>
			<el-col :span="12" class="alignleft">
				{{canceldata.ammeterStoreType2=="2"?canceldata.ammeterBalance2:canceldata.ammeterValue2}}
			</el-col>
		</el-row>
		<el-row v-if="canceldata.ammeterType=='2'">
			<el-col :span="10" class="alignright">{{canceldata.ammeterName2}}金额(元)：</el-col>
			<el-col :span="12" class="alignleft">
				{{canceldata.ammeterStoreType2=="2"?canceldata.ammeterBalance2:canceldata.ammeterMoney2}}
			</el-col>
		</el-row>
		
		<el-row v-if="canceldata.watermeterType!='0'">
			<el-col :span="10" class="alignright">{{canceldata.watermeterName1}}余量({{watermeterStoreType1=="2"?"元":"度"}})：</el-col>
			<el-col :span="12" class="alignleft">
				{{canceldata.watermeterStoreType1=="2"?canceldata.watermeterBalance1:canceldata.watermeterValue1}}
			</el-col>
		</el-row>
		<el-row v-if="canceldata.watermeterType!='0'">
			<el-col :span="10" class="alignright">{{canceldata.watermeterName1}}金额(元)：</el-col>
			<el-col :span="12" class="alignleft">
				{{canceldata.watermeterStoreType1=="2"?canceldata.watermeterBalance1:canceldata.watermeterMoney1}}
			</el-col>
		</el-row>
		
		<el-row v-if="canceldata.watermeterType=='2'">
			<el-col :span="10" class="alignright">{{canceldata.watermeterName2}}余量({{watermeterStoreType2=="2"?"元":"度"}})：</el-col>
			<el-col :span="12" class="alignleft">
				{{canceldata.watermeterStoreType2=="2"?canceldata.watermeterBalance2:canceldata.watermeterValue2}}
			</el-col>
		</el-row>
		<el-row v-if="canceldata.watermeterType=='2'">
			<el-col :span="10" class="alignright">{{canceldata.watermeterName2}}金额(元)：</el-col>
			<el-col :span="12" class="alignleft">
				{{canceldata.watermeterStoreType2=="2"?canceldata.watermeterBalance2:canceldata.watermeterMoney2}}
			</el-col>
		</el-row>
		<el-divider direction="horizontal" />
		<el-row>
			<el-col :span="10" class="alignright red">总退费金额(元)：</el-col>
			<el-col :span="12" class="alignleft red">
				{{canceldata.totalMoney}}
			</el-col>
		</el-row>
		<template #footer>
			<span class="dialog-footer">
				<el-button size="mini" type="primary" @click="cancelRoomAccount" class="buttonforform">注 销
				</el-button>
				<el-button size="mini" type="info" @click="cancelCancel" class="buttonforform">取 消
				</el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted,
		onActivated,
		getCurrentInstance
	} from 'vue'
	import {
		ElMessageBox
	} from 'element-plus'
	import {
		GetServerData
	} from '@/http/index.js'
	// 获取全局this，proxy相当于vue2中的this，用于调用全局对象使用
	const {
		proxy
	} = getCurrentInstance()
	let payTypes = reactive([{
			label: '现金',
			value: '1'
		},
		{
			label: '微信',
			value: '2'
		},
		{
			label: '支付宝',
			value: '3'
		},
		{
			label: '银联',
			value: '4'
		},
		{
			label: '其他',
			value: '5'
		}
	])
	// 定义表单标题
	let dialogtitle = ref("注销住户")
	// 定义表单显示与隐藏
	let dialogVisible = ref(false)
	// 定义表单项的头部宽度
	const formLabelWidth = ref('150px')
	// 定义表单项的内容宽度
	const formContentStyle = ref('width:300px')
	// 定义表单数据
	// 定义表单数据
	let canceldata = reactive({
		roomId:'',
		ammeterType:'',
		watermeterType:'',
		ammeterName1:'',
		ammeterName2:'',
		watermeterName1:'',
		watermeterName2:'',
		ammeterPayType1:'',
		ammeterPayType2:'',
		watermeterPayType1:'',
		watermeterPayType2:'',
		ammeterPriceCategory1:'',
		ammeterPriceCategory2:'',
		ammeterStoreType1:'',
		ammeterStoreType2:'',
		watermeterStoreType1:'',
		watermeterStoreType2:'',
		roomBalance:0.0,
		ammeterValue1:0.0,
		ammeterValue2:0.0,
		ammeterBalance1:0.0,
		ammeterBalance2:0.0,
		watermeterValue1:0.0,
		watermeterValue2:0.0,
		watermeterBalance1:0.0,
		watermeterBalance2:0.0,
		ammeterPrice1:0.0,
		ammeterPrice2:0.0,
		watermeterPrice1:0.0,
		watermeterPrice2:0.0,
		ammeterMoney1:0.0,
		ammeterMoney2:0.0,
		watermeterMoney1:0.0,
		watermeterMoney2:0.0,
		totalMoney :0.0,
		datetime:''
	})
	
	let currentRow = ref()
	const treeAreaRef = ref()
	const treeProps = reactive({
		children: 'children',
		label: 'name'
	})
	// 定义查询参数
	let params = reactive({
		keyword: ''
	})


	// 定义表格数据
	let tableDatas = ref([])
	let roomDatas = ref([])
	let areaDatas = ref([])

	let headerCellStype = ref(proxy.$headerCellStype)
	let contentCellStyle = ref(proxy.$contentCellStyle)
	// 定义选中的表格行
	let multipleSelection = reactive([])
	// 定义表格引用，用来执行多选
	const multipleTable = ref()

	// 定义表格隔行变色，暂时没用到
	const tableRowClassName = ({
		row,
		rowIndex
	}) => {
		if (rowIndex % 2 === 0) {
			return 'even-row'
		} else {
			return 'odd-row'
		}
	}

	const areaSelected = (area) => {
		let loading = proxy.$tools.ShowLoading();
		setTimeout(()=>{
			tableDatas.value = []
			let grade = area.grade
			let id = area.id
			if (grade == "1") {
				tableDatas.value = roomDatas.value.filter(room => room.areaId1 == id)
			} else if (grade == "2") {
				tableDatas.value = roomDatas.value.filter(room => room.areaId2 == id)
			} else if (grade == "3") {
				tableDatas.value = roomDatas.value.filter(room => room.areaId3 == id)
			}
			loading.close();
		},300)
	}

	const search = () => {
		if (params.keyword.length > 0) {
			let loading = proxy.$tools.ShowLoading();
			setTimeout(()=>{
				tableDatas.value = []
				tableDatas.value = roomDatas.value.filter(room =>
					room.areaName1?.indexOf(params.keyword) > -1 ||
					room.areaName2?.indexOf(params.keyword) > -1 || 
					room.areaName3?.indexOf(params.keyword) > -1 || 
					room.roomName?.indexOf(params.keyword) > -1 || 
					room.roomNum?.indexOf(params.keyword) > -1 || 
					room.familyName?.toString().indexOf(params.keyword) > -1 || 
					room.familyPhone?.toString().indexOf(params.keyword) > -1
				)
				loading.close();
			},300)
		} else {
			proxy.$tools.ShowMessage('请输入关键字', false)
		}
	}
	
	// 获取住户的信息，包括账户余额，剩余电量，剩余水量
	const confirmCancel=async (row)=>{
		
		let loading = proxy.$tools.ShowLoading()
		let response = await GetServerData('CancelRoomAccount/VerifyData', {
			roomId:row.roomId
		})
		if (response.status == 1) {
			dialogtitle.value='('+row.areaName1+'-'+row.areaName2+'-'+row.areaName3+'-'+row.roomName+'-'+row.roomNum+')注销住户';
			console.log(response.data);
			canceldata.roomId=response.data.roomId;
			canceldata=response.data;
			proxy.$tools.ShowMessage(response.message, true)
			dialogVisible.value=true
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
		loading.close();
	}
	
	const cancelCancel=()=>{
		//清空注销信息
		dialogVisible.value=false;
		canceldata.roomId='';
		canceldata.ammeterType='';
		canceldata.watermeterType='';
		canceldata.ammeterName1='';
		canceldata.ammeterName2='';
		canceldata.watermeterName1='';
		canceldata.watermeterName2='';
		canceldata.ammeterPayType1='';
		canceldata.ammeterPayType2='';
		canceldata.watermeterPayType1='';
		canceldata.watermeterPayType2='';
		canceldata.ammeterPriceCategory1='';
		canceldata.ammeterPriceCategory2='';
		canceldata.ammeterStoreType1='';
		canceldata.ammeterStoreType2='';
		canceldata.watermeterStoreType1='';
		canceldata.watermeterStoreType2='';
		canceldata.roomBalance=0.0;
		canceldata.ammeterValue1=0.0;
		canceldata.ammeterValue2=0.0;
		canceldata.ammeterBalance1=0.0;
		canceldata.ammeterBalance2=0.0;
		canceldata.watermeterValue1=0.0;
		canceldata.watermeterValue2=0.0;
		canceldata.watermeterBalance1=0.0;
		canceldata.watermeterBalance2=0.0;
		canceldata.ammeterPrice1=0.0;
		canceldata.ammeterPrice2=0.0;
		canceldata.watermeterPrice1=0.0;
		canceldata.watermeterPrice2=0.0;
		canceldata.ammeterMoney1=0.0;
		canceldata.ammeterMoney2=0.0;
		canceldata.watermeterMoney1=0.0;
		canceldata.watermeterMoney2=0.0;
		canceldata.totalMoney =0.0;
		canceldata.datetime='';
	}
	
	// 定义表单提交事件，带表单验证
	const cancelRoomAccount = async () => {
		let loading = proxy.$tools.ShowLoading()
		let response = await GetServerData('CancelRoomAccount/CancelRoomAccount', {
			roomId:canceldata.roomId
		})
		if (response.status == 1) {
			proxy.$tools.ShowMessage('注销成功！', true)
		}else {
			proxy.$tools.ShowMessage(response.message, false)
		}
		dialogVisible.value=false;
		cancelCancel();
		loading.close();
	}

	// 定义获取表格数据函数
	const GetRoomData = async () => {
		let response = await GetServerData('CancelRoomAccount/QueryAllRooms')
		if (response.status == 1) {
			roomDatas.value = response.data
			//console.log(roomDatas.value)
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}

	// 定义获取表格数据函数
	const GetAreaData = async () => {
		let response = await GetServerData('CancelRoomAccount/QueryAllAreas')
		if (response.status == 1) {
			areaDatas.value = response.data
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}

	// 定义获取所有数据函数
	const GetAllData = async () => {
		await GetRoomData();
		console.log("getalldatadone1");
		await GetAreaData();
		console.log("getalldatadone2");
	}
	
	const refresh=async ()=>{
		let loading = proxy.$tools.ShowLoading();
		tableDatas.value=[];
		await GetAllData()
		loading.close()
	}

	let areaHeight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60))
	let tableheight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60))
	// 定义加载事件
	onMounted(async () => {
		//ammeterType.value=localStorage.getItem("ammeterType");
		//ammeterName1.value=localStorage.getItem("ammeterName1");
		//ammeterName2.value=localStorage.getItem("ammeterName2");
		
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth - 425) +
			'px'
		window.onresize = () => {
			tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
			areaHeight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
			document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
					425) +
				'px'
		}
		let loading = proxy.$tools.ShowLoading();
		await GetAllData()
		loading.close()
	})
	onActivated(() => {
		tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
		areaHeight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
				425) +
			'px'
	})
</script>

<style scoped lang="less">
	.alignleft{
		text-align: left;
		margin-left: 10px;
		font-size: 20px;
		line-height: 25px;
	}
	.alignright{
		text-align: right;
		margin-right: 10px;
		font-size: 20px;
		line-height: 25px;
	}
	.red{
		color: red;
	}
</style>
