<template>
	<div class="contentheader">
		<el-space wrap>
			<span class="titleClass">结算设置</span>
			<el-divider direction="vertical" />
			<el-button type="primary" size="small" class="buttonforheader" @click="refresh">刷 新</el-button>
		</el-space>
	</div>
	<div id="divTableData" class="contentcontent">
		<el-table :data="tableDatas" fit="false" highlight-current-row="true" ref="multipleTable" tooltip-effect="dark"
			:height="tableheight" row-key="id" default-expand-all :header-cell-style="headerCellStype"
			:cell-style="contentCellStyle" style="width: 100%; cursor: pointer;" :row-class-name="tableRowClassName"
			>
			<el-table-column fixed prop="id" label="id" width="0" v-if="false" />
			<el-table-column fixed prop="estateId" label="category" width="0" v-if="false" />
			<el-table-column fixed prop="priceId" label="category" width="0" v-if="false" />
			<el-table-column fixed prop="category" label="category" width="0" v-if="false" />
			<el-table-column fixed prop="cycUnit" label="category" width="0" v-if="false" />
			<el-table-column fixed prop="autoCreateBill" label="category" width="0" v-if="false" />
			<el-table-column fixed prop="autoCountBill" label="category" width="0" v-if="false" />
			<el-table-column prop="categoryName" label="结算设备" width="100" align="center" />
			<el-table-column prop="priceName" label="价格名称" width="200" />
			<el-table-column prop="cycUnitName" label="结算周期" width="100" />
			<el-table-column prop="cycTime" label="月结日" width="150" />
			<el-table-column prop="lastCreateBillTime" label="上次生成账单时间" width="150"  align="center" />
			<el-table-column prop="lastCountBillTime" label="上次结算账单时间" width="150"  align="center" />
			<el-table-column prop="autoCreateBillName" label="自动生成账单" width="150" align="center" />
			<el-table-column prop="autoCountBillName" label="自动结算" width="150" align="center" />
			<el-table-column prop="deviceOffSpan" label="关阀延迟" width="120" />
			<el-table-column prop="muName" label="修改人" width="120" />
			<el-table-column prop="mt" label="修改时间" width="150" align="center" />
			<el-table-column />
			<el-table-column fixed="right" label="操作" width="100" align="left">
				<template #default="scope">
					<el-button size="mini" @click.stop="modify(scope.row)" type="success" class="buttonfortable">设 置
					</el-button>
				</template>
			</el-table-column>
		</el-table>
	</div>
	<el-dialog width="500px" v-model="dialogVisible" append-to-body=true :title="dialogtitle" center draggable
		:close-on-click-modal="false" :show-close="true">
		<el-form :model="formdata" ref="formRef" class="demo-ruleForm formContent">
			<el-form-item label="结算设备" :label-width="formLabelWidth">
				<el-select v-model="formdata.category" placeholder="请选择结算设备" :style="formContentStyle" disabled
					@change="handleCategoryChange">
					<el-option v-for="item in categorys" :key="item.value" :label="item.label" :value="item.value" />
				</el-select>
			</el-form-item>
			<el-form-item label="默认单价" :label-width="formLabelWidth" prop="priceId"
				:rules="[{ required: true, message: '请选择默认单价' }]">
				<el-select v-model="formdata.priceId" placeholder="请选择默认单价" :style="formContentStyle">
					<el-option v-for="item in priceIds" :key="item.id" :label="item.name" :value="item.id" />
				</el-select>
			</el-form-item>
			<el-form-item label="结算周期" :label-width="formLabelWidth" prop="cycUnit"
				:rules="[{ required: true, message: '请选择结算周期' }]">
				<el-radio-group v-model="formdata.cycUnit" class="ml-4">
					<el-radio label="1" size="small" style="margin-left: 20px;">日</el-radio>
					<el-radio label="2" size="small">月</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item prop="cycTime" :rules="[{ required: true, message: '请输入月结日(1-31)' }]" label="月结日(1-31)"
				:label-width="formLabelWidth" v-if="formdata.cycUnit==2">
				<el-input v-model="formdata.cycTime" autocomplete="off" :style="formContentStyle"
					placeholder="请输入月结日(1-31)" />
			</el-form-item>
			<el-form-item label="自动生成账单" :label-width="formLabelWidth">
				<el-switch v-model="formdata.autoCreateBill" active-text="启用" active-value="1" inactive-value="0"
					inactive-text="停用">
				</el-switch>
			</el-form-item>
			<el-form-item label="自动结算" :label-width="formLabelWidth">
				<el-switch v-model="formdata.autoCountBill" active-text="启用" active-value="1" inactive-value="0"
					inactive-text="停用">
				</el-switch>
			</el-form-item>
			<el-form-item prop="deviceOffSpan" :rules="[{ required: true, message: '请输入关阀延迟(天)'  ,trigger:'blur'}]" label="关阀延迟(天)"
				:label-width="formLabelWidth">
				<el-input v-model="formdata.deviceOffSpan" autocomplete="off" :style="formContentStyle" type="number"
					placeholder="关阀延迟(天)" />
			</el-form-item>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button size="mini" type="primary" @click="submitForm(formRef)" class="buttonforform"> 确 定
				</el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted,
		onActivated,
		getCurrentInstance
	} from 'vue'
	import {
		ElMessageBox
	} from 'element-plus'
	import {
		GetServerData
	} from '@/http/index.js'
	// 获取全局this，proxy相当于vue2中的this，用于调用全局对象使用
	const {
		proxy
	} = getCurrentInstance()

	let ammeterType=ref(localStorage.getItem("ammeterType"));
	let ammeterName1=ref(localStorage.getItem("ammeterName1"));
	let ammeterName2=ref(localStorage.getItem("ammeterName2"));
	
	let watermeterType=ref(localStorage.getItem("watermeterType"));
	let watermeterName1=ref(localStorage.getItem("watermeterName1"));
	let watermeterName2=ref(localStorage.getItem("watermeterName2"));
	
	
	let categorys = ref([{
			label: ammeterName1,
			value: '1'
		},
		{
			label: ammeterName2,
			value: '2'
		},
		{
			label: watermeterName1,
			value: '3'
		},
		{
			label: watermeterName2,
			value: '4'
		}
	])
	let timePoints = ref([])
	for (let i = 0; i <= 23; i++) {
		timePoints.value.push({
			label: i.toString(),
			value: i.toString()
		})
	}
	let priceIds = ref([])
	// 定义表单标题
	let dialogtitle = ref("自动结算设置")
	// 定义表单数据
	let formdata = reactive({
		id: '',
		estateId: '',
		category: '',
		priceId: '',
		cycUnit: '',
		cycTime: '',
		autoCreateBill: '',
		autoCountBill: '',
		deviceOffSpan: ''
	})

	// 定义表单显示与隐藏
	let dialogVisible = ref(false)
	let tableDatas = ref([])
	let headerCellStype = ref(proxy.$headerCellStype)
	let contentCellStyle = ref(proxy.$contentCellStyle)
	// 定义选中的表格行
	let multipleSelection = reactive([])
	// 定义表格引用，用来执行多选
	const multipleTable = ref()
	// 定义表单引用，用来执行表单验证
	const formRef = ref()
	// 定义表单项的头部宽度
	const formLabelWidth = ref('150px')
	// 定义表单项的内容宽度
	const formContentStyle = ref('width:300px')

	// 定义表格隔行变色，暂时没用到
	const tableRowClassName = ({
		row,
		rowIndex
	}) => {
		if (rowIndex % 2 === 0) {
			return 'even-row'
		} else {
			return 'odd-row'
		}
	}
	const toggleSelection = (rows) => {
		if (rows) {
			rows.forEach(row => {
				multipleTable.value.toggleRowSelection(row);
			});
		} else {
			multipleTable.value.clearSelection();
		}
	}

	const handleCategoryChange = async () => {
		GetPriceData()
	}

	const GetPriceData = async () => {
		let loading = proxy.$tools.ShowLoading();
		let response = await GetServerData('AutoCountConfig/QueryPrice', '"' + formdata.category + '"')
		if (response.status == 1) {
			priceIds.value = response.data
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
		loading.close()
	}
	// 定义表单提交事件，带表单验证
	const submitForm = (form) => {
		form.validate(async (valid) => {
			if (valid) {
				let cycTime = parseInt(formdata.cycTime)
				if (cycTime >= 1 && cycTime <= 31) {
					let loading = proxy.$tools.ShowLoading();
					if (formdata.id.length > 0) {
						console.log(formdata)
						let response = await GetServerData('AutoCountConfig/Modify', formdata)
						if (response.status == 1) {
							proxy.$tools.ShowMessage('设置成功', true)
							dialogVisible.value = false
							await GetAllData();
						} else {
							proxy.$tools.ShowMessage(response.message, false)
						}
					}
					loading.close()
				} else {
					proxy.$tools.ShowMessage('结算日必须在1号至31号之间（包含1号和31号）！', false)
				}
			} else {
				proxy.$tools.ShowMessage('请您正确填写所有必填信息后，再提交！', false)
				return false;
			}
		})
	}
	// 定义编辑按钮事件
	const modify = (row) => {
		try {
			formRef.value.resetFields();
		} catch (e) {

		}
		//dialogtitle.value=row.categoryName+" 自动结算设置"
		formdata.id = row.id;
		formdata.estateId = row.estateId;
		formdata.category = row.category;
		formdata.cycUnit = row.cycUnit;
		formdata.cycTime = row.cycTime?.toString();
		formdata.deviceOffSpan = row.deviceOffSpan?.toString();
		formdata.autoCreateBill = row.autoCreateBill;
		formdata.autoCountBill = row.autoCountBill;
		GetPriceData();
		formdata.priceId = row.priceId;
		dialogVisible.value = true
	}

	// 定义获取表格数据函数
	const GetTableData = async () => {
		let response = await GetServerData('AutoCountConfig/QueryAll')
		if (response.status == 1) {
			tableDatas.value = response.data
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}
	// 定义获取所有数据函数
	const GetAllData = async () => {
		await GetTableData()
	}
	
	const refresh=async ()=>{
		let loading = proxy.$tools.ShowLoading();
		tableDatas.value=[];
		await GetAllData()
		loading.close()
	}

	let tableheight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60))
	// 定义加载事件
	onMounted(async () => {
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth - 220) +
			'px'
		window.onresize = () => {
			tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
			document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
					220) +
				'px'
		}
		let loading = proxy.$tools.ShowLoading();
		await GetAllData()
		loading.close()
	})
	onActivated(() => {
		tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
				220) +
			'px'
	})
</script>

<style scoped lang="less">
</style>
