<template>

	<div class="contentheader">
		<el-space wrap>
			<span class="titleClass">房间用电量查询</span>
			<el-divider direction="vertical" />
			<el-input v-model="params.keyword" placeholder="输入关键字" size="small" style="width: 140px;" clearable
				autocomplete="off" />
			<el-date-picker v-model="dateRange" type="daterange" :shortcuts="shortcuts" range-separator="至"
				:defaultTime="defaultDate" start-placeholder="开始日期" end-placeholder="结束日期" @change="dateChange"
				size="small" />
			<el-select v-model="params.meterType" class="m-2" size="small" style="width: 100px;"
				v-if="ammeterType=='2'">
				<el-option key="1" :label="ammeterName1" value="1" />
				<el-option key="2" :label="ammeterName2" value="2" />
			</el-select>
			<el-select v-model="params.searchType" class="m-2" size="small" style="width: 100px;"
				v-if="ammeterType=='2'">
				<el-option key="1" label="合并" value="1" />
				<el-option key="2" label="明细" value="2" />
			</el-select>
			<el-button type="primary" size="small" class="buttonforheader" @click="search">查 询</el-button>
			<el-button type="primary" size="small" class="buttonforheader" @click="refresh">刷 新</el-button>
			<el-divider direction="vertical" />
			<el-button type="info" size="small" class="buttonforheader" @click="ExportExcel">导 出</el-button>
			<el-divider direction="vertical" />
			<!-- <el-radio-group v-model="showType" class="ml-4" @change="showTypeChange" v-if="true">
				<el-radio label="1" size="small">数据展示</el-radio>
				<el-radio label="2" size="small">图表展示</el-radio>
			</el-radio-group> -->
			<!-- <el-tag class="ml-2" type="success" closable v-if="showTag" @close="resetSelectedArea">{{selectedArea}}
			</el-tag> -->
			<!-- <el-tag class="ml-2" type="danger">总用量：{{totalUse}}</el-tag> -->
		</el-space>
	</div>
	<div style="display: flex; flex-direction: row; flex-wrap: nowrap; align-items: flex-start;margin: 10px;">
		<div style="width: 200px;">
			<el-scrollbar :height="areaHeight" style="width:200px; border-radius: 6px;background-color: white;">
				<el-tree ref="treeAreaRef" :data="areaDatas" node-key="id" :expand-on-click-node="false"
					highlight-current :props="treeProps" @node-click="areaSelected" style="margin: 10px;">
				</el-tree>
			</el-scrollbar>
		</div>
		<div id="divTableData" style="width: 100%;border-radius: 6px;background-color: white; margin-left: 5px;"
			v-if="showType=='1'">
			<el-table :data="tableDatas" fit="false" highlight-current-row="true" ref="multipleTable"
				tooltip-effect="dark" :height="tableheight" row-key="id" :header-cell-style="headerCellStype"
				id="tableData" :cell-style="contentCellStyle" style="width: 100%; cursor: pointer;"
				:row-class-name="tableRowClassName">
				<el-table-column fixed prop="id" label="id" width="0" v-if="false" />
				<el-table-column prop="areaName1" label="一级区域" width="100" />
				<el-table-column prop="areaName2" label="二级区域" width="100" />
				<el-table-column prop="areaName3" label="三级区域" width="100" />
				<el-table-column prop="roomName" label="房间名称" width="100" />
				<el-table-column prop="roomNum" label="房间编号" width="100" />
				<el-table-column prop="familyName" label="户主名称" width="150" />
				<el-table-column prop="familyPhone" label="户主电话" width="150" />
				<el-table-column prop="beginDate" label="开始日期" width="100" align="center" />
				<el-table-column prop="endDate" label="结束日期" width="100" align="center" />
				<el-table-column prop="beginValue" label="开始值" width="120" />
				<el-table-column prop="totalBuyValue" label="购量" width="120" />
				<el-table-column prop="endValue" label="结束值" width="120" />
				<el-table-column prop="totalUseValue" label="总用量" width="120" />
				<el-table-column prop="beginValue1" label="尖开始值" width="120" v-if="priceCategory=='2'" />
				<el-table-column prop="beginValue2" label="峰开始值" width="120" v-if="priceCategory=='2'" />
				<el-table-column prop="beginValue3" label="平开始值" width="120" v-if="priceCategory=='2'" />
				<el-table-column prop="beginValue4" label="谷开始值" width="120" v-if="priceCategory=='2'" />
				<el-table-column prop="beginValue5" label="深开始值" width="120" v-if="priceCategory=='2'" />
				<el-table-column prop="endValue1" label="尖结束值" width="120" v-if="priceCategory=='2'" />
				<el-table-column prop="endValue2" label="峰结束值" width="120" v-if="priceCategory=='2'" />
				<el-table-column prop="endValue3" label="平结束值" width="120" v-if="priceCategory=='2'" />
				<el-table-column prop="endValue4" label="谷结束值" width="120" v-if="priceCategory=='2'" />
				<el-table-column prop="endValue5" label="深结束值" width="120" v-if="priceCategory=='2'" />
				<el-table-column prop="useValue1" label="尖用量" width="120" v-if="priceCategory=='2'" />
				<el-table-column prop="useValue2" label="峰用量" width="120" v-if="priceCategory=='2'" />
				<el-table-column prop="useValue3" label="平用量" width="120" v-if="priceCategory=='2'" />
				<el-table-column prop="useValue4" label="谷用量" width="120" v-if="priceCategory=='2'" />
				<el-table-column prop="useValue5" label="深用量" width="120" v-if="priceCategory=='2'" />
				<el-table-column />
			</el-table>
		</div>
		
	</div>
	<!-- <div class="contentpager" v-if="showpaging">
		<el-pagination background layout="prev, pager, next,jumper,sizes,total" :total="pagingInfo.totalCount"
			:page-sizes="[500,1000,2000,3000]" v-model:current-page="params.pageNum" v-model:page-size="params.pageSize"
			@size-change="sizechange" @current-change="currentchange" />
	</div> -->
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted,
		onActivated,
		getCurrentInstance
	} from 'vue'
	import {
		ElMessageBox
	} from 'element-plus'
	import {
		GetServerData
	} from '@/http/index.js'
	// 获取全局this，proxy相当于vue2中的this，用于调用全局对象使用
	const {
		proxy
	} = getCurrentInstance()
	const treeAreaRef = ref()
	const treeProps = reactive({
		children: 'children',
		label: 'name'
	})
	let showTag = ref(false)


	let ammeterType = ref(localStorage.getItem("ammeterType"));
	let ammeterName1 = ref(localStorage.getItem("ammeterName1"));
	let ammeterName2 = ref(localStorage.getItem("ammeterName2"));

	let priceCategory = ref('1') //1单价，2复费率

	// 定义查询参数
	let params = reactive({
		keyword: '',
		areaId1: '',
		areaId2: '',
		areaId3: '',
		roomId: '',
		beginDate: '',
		endDate: '',
		meterType: '1',
		pageSize: 500,
		pageNum: 1,
		searchType:'1'
	})
	//let totalUse = ref(0)
	let selectedArea = ref('')
	let showType = ref('1')


	// 定义表格数据
	let tableDatas = ref([])
	let areaDatas = ref([])
	// 定义是否显示分页
	let showpaging = ref(true)
	let pagingInfo = reactive({
		totalCount: 0,
		totalPage: 0
	})


	let headerCellStype = ref(proxy.$headerCellStype)
	let contentCellStyle = ref(proxy.$contentCellStyle)

	// 定义表格引用，用来执行多选
	const multipleTable = ref()

	// 定义表格隔行变色，暂时没用到
	const tableRowClassName = ({
		row,
		rowIndex
	}) => {
		if (rowIndex % 2 === 0) {
			return 'even-row'
		} else {
			return 'odd-row'
		}
	}

	const ExportExcel = () => {
		proxy.$tools.ExportExcel(document.querySelector("#tableData"), "房间用电量查询（" + params.beginDate + "至" + params
			.endDate + "）.xlsx")
	}

	let showTypeChange = (value) => {
		//console.log(value);
	}

	const sizechange = async () => {
		params.pageNum = 1
		await search()
		multipleTable.value.$refs.bodyWrapper.scrollTo(0, 0);
	}
	const currentchange = async () => {
		await search()
		multipleTable.value.$refs.bodyWrapper.scrollTo(0, 0);
	}

	let currentDate = new Date();
	currentDate.setTime(currentDate.getTime() - 3600 * 1000 * 24 * 1)
	let defaultBeginDate = currentDate
	let defaultEndDate = currentDate
	let dateRange = ref([defaultBeginDate, defaultEndDate])
	let defaultDate = ref([defaultBeginDate, defaultEndDate])
	params.beginDate = proxy.$tools.DateTimeToString(defaultBeginDate);
	params.endDate = proxy.$tools.DateTimeToString(defaultEndDate);
	const shortcuts = ref([{
			text: '昨天',
			value: () => {
				let start = new Date()
				start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
				return [start, start]
			}
		}, {
			text: '前天',
			value: () => {
				let start = new Date()
				start.setTime(start.getTime() - 3600 * 1000 * 24 * 2)
				return [start, start]
			}
		}, {
			text: '之前一周',
			value: () => {
				let end = new Date()
				end.setTime(end.getTime() - 3600 * 1000 * 24 * 1)
				let start = new Date()
				start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
				return [start, end]
			}
		},
		{
			text: '之前一个月',
			value: () => {
				let end = new Date()
				let start = proxy.$tools.ReduceMonth(end, 1)
				end.setTime(end.getTime() - 3600 * 1000 * 24 * 1)
				return [start, end]
			}
		},
		{
			text: '之前二个月',
			value: () => {
				let end = new Date()
				let start = proxy.$tools.ReduceMonth(end, 2)
				end.setTime(end.getTime() - 3600 * 1000 * 24 * 1)
				return [start, end]
			}
		},
		{
			text: '之前三个月',
			value: () => {
				let end = new Date()
				let start = proxy.$tools.ReduceMonth(end, 3)
				end.setTime(end.getTime() - 3600 * 1000 * 24 * 1)
				return [start, end]
			}
		}
	])
	const resetSelectedArea = () => {
		selectedArea.value = ''
		showTag.value = false
		params.areaId1 = ''
		params.areaId2 = ''
		params.areaId3 = ''
		params.roomId = ''
	}

	const areaSelected = async (area) => {
		console.log(area)
		let grade = area.grade
		let id = area.id
		let selectedName = getSelectedArea(id)
		if (selectedName.length > 0) {
			selectedName = "所选区域：" + selectedName
			showTag.value = true
		} else {
			showTag.value = false
		}
		selectedArea.value = selectedName
		params.areaId1 = ''
		params.areaId2 = ''
		params.areaId3 = ''
		params.roomId = ''
		console.log(grade, id)
		if (grade == "1") {
			params.areaId1 = id;
		} else if (grade == "2") {
			params.areaId2 = id;
		} else if (grade == "3") {
			params.areaId3 = id;
		} else if (grade == "4") {
			params.roomId = id;
		}
	}

	const getSelectedArea = (id) => {
		let selectedNames = ''
		for (let i = 0; i < areaDatas.value.length; i++) {
			selectedNames += areaDatas.value[i].name
			if (areaDatas.value[i].id == id) {
				return selectedNames
			}
			for (let j = 0; j < areaDatas.value[i].children.length; j++) {
				selectedNames += " - " + areaDatas.value[i].children[j].name
				if (areaDatas.value[i].children[j].id == id) {
					return selectedNames
				}
				for (let k = 0; k < areaDatas.value[i].children[j].children.length; k++) {
					selectedNames += " - " + areaDatas.value[i].children[j].children[k].name
					if (areaDatas.value[i].children[j].children[k].id == id) {
						return selectedNames
					}
					for (let m = 0; m < areaDatas.value[i].children[j].children[k].children.length; m++) {
						selectedNames += " - " + areaDatas.value[i].children[j].children[k].children[m].name
						if (areaDatas.value[i].children[j].children[k].children[m].id == id) {
							return selectedNames
						}
						selectedNames = areaDatas.value[i].name + " - " + areaDatas.value[i].children[j].name + " - " +
							areaDatas.value[i].children[j].children[k].name
					}
					selectedNames = areaDatas.value[i].name + " - " + areaDatas.value[i].children[j].name
				}
				selectedNames = areaDatas.value[i].name
			}
			selectedNames = ''
		}
		return selectedNames
	}

	const dateChange = (value) => {
		params.beginDate = proxy.$tools.DateToString(value[0]);
		params.endDate = proxy.$tools.DateToString(value[1]);
	}

	const search = async () => {
		if (params.beginDate.length > 0 && params.endDate.length > 0) {
			let flag=true;
			if(params.searchType=='2'){
				if(params.roomId.length==0){
					flag=false;
				}
			}
			if(flag){
				let loading = proxy.$tools.ShowLoading()
				let response = await GetServerData('RoomUse/QueryAll', params)
				if (response.status == 1) {
					tableDatas.value = response.data
					priceCategory.value = response.otherData;
					//console.log(response.data)
					pagingInfo.totalCount = response.totalCount
					pagingInfo.totalPage = Math.ceil(pagingInfo.totalCount / params.pageSize)
					//totalUse.value = response.otherData.total01;
					console.log(multipleTable.value)
				} else {
					proxy.$tools.ShowMessage(response.message, false)
				}
				loading.close();
			}else{
				proxy.$tools.ShowMessage("明细模式只能查询具体的某一户！", false)
			}
		} else {
			proxy.$tools.ShowMessage("请选择开始时间和结束时间", false)
		}
	}

	const refresh = async () => {
		let loading = proxy.$tools.ShowLoading();
		tableDatas.value = [];
		await GetAllData()
		loading.close()
	}

	// 定义获取表格数据函数
	const GetRoomData = async () => {
		let response = await GetServerData('RoomUse/QueryAllRooms')
		if (response.status == 1) {
			areaDatas.value = response.data
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}

	// 定义获取所有数据函数
	const GetAllData = async () => {
		await GetRoomData()
	}

	let areaHeight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60))
	let tableheight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60))
	// 定义加载事件
	onMounted(async () => {
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth - 425) +
			'px'
		window.onresize = () => {
			tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
			areaHeight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
			document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
				425) + 'px'
		}
		let loading = proxy.$tools.ShowLoading();
		await GetAllData()
		loading.close()
	})
	onActivated(() => {
		tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
		areaHeight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
			425) + 'px'
	})
</script>

<style scoped lang="less">
</style>
