<template>

	<div class="contentheader">
		<el-space wrap>
			<span class="titleClass">区域缴费</span>
			<el-divider direction="vertical" />
			<el-button type="primary" size="small" class="buttonforheader" @click="search">查 询</el-button>
			<el-button type="primary" size="small" class="buttonforheader" @click="chargeConfirm">缴 费</el-button>
			<el-divider direction="vertical" />
			<span style="color:orange">需要缴费总金额：{{totalMoney}} 元</span>
		</el-space>
	</div>
	<div style="display: flex; flex-direction: row; flex-wrap: nowrap; align-items: flex-start;margin: 10px;">
		<div style="width: 200px;">
			<el-scrollbar :height="areaHeight" style="width:200px; border-radius: 6px;background-color: white;">
				<el-tree ref="treeAreaRef" :data="areaDatas" node-key="id" :expand-on-click-node="false"
					highlight-current :props="treeProps" @node-click="areaSelected" style="margin: 10px;">
				</el-tree>
			</el-scrollbar>
		</div>
		<div id="divTableData" style="width: 100%;border-radius: 6px;background-color: white; margin-left: 5px;">
			<el-table :data="tableDatas" fit="false" highlight-current-row="true" ref="multipleTable"
				tooltip-effect="dark" :height="tableheight" row-key="id" :header-cell-style="headerCellStype"
				:cell-style="contentCellStyle" style="width: 100%; cursor: pointer;" :row-class-name="tableRowClassName"
				id="tableData">
				<el-table-column fixed prop="id" label="id" width="0" v-if="false" />
				<el-table-column prop="areaName1" label="一级区域" width="100" />
				<el-table-column prop="areaName2" label="二级区域" width="100" />
				<el-table-column prop="areaName3" label="三级区域" width="100" />
				<el-table-column prop="roomName" label="房间名称" width="100" />
				<el-table-column prop="roomNum" label="房间编号" width="100" />
				<el-table-column prop="familyName" label="户主名称" width="150" v-if="false" />
				<el-table-column prop="familyPhone" label="户主电话" width="150" v-if="false" />
				<el-table-column prop="categoryName" label="账单类型" width="100" />
				<el-table-column prop="billYear" label="年" width="60" align="center" />
				<el-table-column prop="billMonth" label="月" width="45" align="center" />
				<el-table-column prop="billDay" label="日" width="45" align="center" />
				<el-table-column prop="beginTime" label="开始时间" width="150" v-if="false" />
				<el-table-column prop="endTime" label="结束时间" width="150" v-if="false" />
				<el-table-column prop="beginValue" label="开始值" width="120" />
				<el-table-column prop="endValue" label="结束值" width="120" />
				<el-table-column prop="useAmount" label="用量" width="120" />
				<el-table-column prop="price" label="单价" width="80" align="center" />
				<el-table-column prop="totalMoney" label="总价(元)" width="120" />
				<el-table-column prop="description" label="描述" width="300" />
				<el-table-column prop="ct" label="账单生成时间" width="150" align="center" />
				<el-table-column />
			</el-table>
		</div>
	</div>
	<el-dialog width="500px" v-model="dialogVisible" append-to-body=true title="选择缴费方式" center draggable
		:close-on-click-modal="false" :show-close="true">
		<el-form :model="chargeParams" ref="formRef" class="demo-ruleForm formContent">
			<el-form-item prop="payType" :rules="[{ required: true, message: '请输选择缴费方式' }]" label="缴费方式"
				:label-width="formLabelWidth">
				<el-select v-model="chargeParams.payType" placeholder="请选择缴费方式" :style="formContentStyle">
					<el-option v-for="item in payTypes" :key="item.value" :label="item.label" :value="item.value" />
				</el-select>
			</el-form-item>
			<center style="font-size: 20px;">需要缴费总金额：{{totalMoney}} 元</center>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-space wrap :size="30">
					<el-button size="mini" type="primary" @click="charge(formRef)" class="buttonforform"> 缴 费
					</el-button>
				</el-space>
			</span>
		</template>
	</el-dialog>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted,
		onActivated,
		getCurrentInstance
	} from 'vue'
	import {
		ElMessageBox
	} from 'element-plus'
	import {
		GetServerData
	} from '@/http/index.js'
	// 获取全局this，proxy相当于vue2中的this，用于调用全局对象使用
	const {
		proxy
	} = getCurrentInstance()
	const treeAreaRef = ref()
	const treeProps = reactive({
		children: 'children',
		label: 'name'
	})
	let dialogVisible=ref(false);
	const formRef = ref()
	let showTag = ref(false)
	// 定义查询参数
	let params = reactive({
		grade: '0',
		areaId1: '',
		areaId2: '',
		areaId3: ''
	})
	let chargeParams = ref({
		billIds: [],
		totalMoney: 0.0,
		payType: ''
	})
	let payTypes = reactive([{
			label: '现金',
			value: '1'
		},
		{
			label: '微信',
			value: '2'
		},
		{
			label: '支付宝',
			value: '3'
		},
		{
			label: '银联',
			value: '4'
		},
		{
			label: '其他',
			value: '5'
		}
	])
	let totalMoney = ref(0.0)
	let selectedArea = ref('')

	// 定义表格数据
	let tableDatas = ref([])
	let areaDatas = ref([])
	const formLabelWidth = ref('150px')
	// 定义表单项的内容宽度
	const formContentStyle = ref('width:300px')
	let headerCellStype = ref(proxy.$headerCellStype)
	let contentCellStyle = ref(proxy.$contentCellStyle)

	// 定义表格引用，用来执行多选
	const multipleTable = ref()

	// 定义表格隔行变色，暂时没用到
	const tableRowClassName = ({
		row,
		rowIndex
	}) => {
		if (rowIndex % 2 === 0) {
			return 'even-row'
		} else {
			return 'odd-row'
		}
	}

	const areaSelected = async (area) => {
		let grade = area.grade
		let id = area.id
		params.areaId1 = ''
		params.areaId2 = ''
		params.areaId3 = ''
		params.grade = grade;
		if (grade == "1") {
			params.areaId1 = id;
		} else if (grade == "2") {
			params.areaId2 = id;
		} else if (grade == "3") {
			params.areaId3 = id;
		}
	}

	const search = async () => {
		if (params.grade != '0' && (params.areaId1 != '' || params.areaId2 != '' || params.areaId3 != '')) {
			let loading = proxy.$tools.ShowLoading()
			let response = await GetServerData('ChargeArea/QueryAll', params)
			if (response.status == 1) {
				tableDatas.value = response.data
				totalMoney.value = response.otherData;
			} else {
				proxy.$tools.ShowMessage(response.message, false)
			}
			loading.close();
		} else {
			proxy.$tools.ShowMessage("请选择区域！", false)
		}
	}

	const charge = (form) => {
		form.validate(async (valid) => {
			if (valid) {
				let loading = proxy.$tools.ShowLoading()
				chargeParams.value.billIds = [];
				chargeParams.value.totalMoney = totalMoney.value;
				for (let i = 0; i < tableDatas.value.length; i++) {
					chargeParams.value.billIds.push(tableDatas.value[i].id);
				}
				let response = await GetServerData('ChargeArea/ChargeArea', chargeParams.value)
				let flag=false;
				if (response.status == 1) {
					flag=true;
					proxy.$tools.ShowMessage("缴费成功！", true)
				} else {
					proxy.$tools.ShowMessage("缴费失败：" + response.message, false)
				}
				dialogVisible.value = false
				loading.close();
				if(flag){
					search();
				}
			} else {
				proxy.$tools.ShowMessage('请您选择缴费方式！', false)
				return false;
			}
		})
	}
	
	const chargeConfirm = () => {
		if (tableDatas && tableDatas.value && tableDatas.value.length > 0) {
			dialogVisible.value = true
		} else {
			proxy.$tools.ShowMessage("没有要缴费的记录！", false)
		}
	}
	
	// 定义获取表格数据函数
	const GetAreaData = async () => {
		let response = await GetServerData('ChargeArea/QueryAllAreas')
		if (response.status == 1) {
			areaDatas.value = response.data
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}
	// 定义获取所有数据函数
	const GetAllData = async () => {
		await GetAreaData()
	}
	let areaHeight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60))
	let tableheight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60))
	// 定义加载事件
	onMounted(async () => {
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
				425) +
			'px'
		window.onresize = () => {
			tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight +
				60)
			areaHeight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight +
				60)
			document.getElementById("divTableData").style.width = (document.documentElement
				.clientWidth -
				425) + 'px'
		}
		let loading = proxy.$tools.ShowLoading();
		await GetAllData()
		loading.close()
	})
	onActivated(() => {
		tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
		areaHeight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
			425) + 'px'
	})
</script>

<style scoped lang="less">
</style>
