<template>
	<div class="contentheader">
		<el-space wrap>
			<span class="titleClass">用户管理</span>
			<el-divider direction="vertical" />
			<el-input v-model="params.name" placeholder="输入用户名称" size="small" style="width: 150px;"
				autocomplete="off" />
			<el-input v-model="params.mobilephone" placeholder="输入用户手机号" size="small" style="width: 150px;"
				autocomplete="off" />
			<el-button type="primary" size="small" class="buttonforheader" @click="search">查 询</el-button>
			<el-divider direction="vertical" />
			<el-button type="success" size="small" class="buttonforheader" @click="add">新 增</el-button>
			<el-button type="warning" size="small" class="buttonforheader" @click="deletesome">批量删除</el-button>
			<el-button type="info" size="small" class="buttonforheader" @click="resetpass" v-if="showResetPass">重置密码</el-button>
		</el-space>
	</div>
	<div id="divTableData" class="contentcontent">
		<el-table :data="tableDatas" fit="false" highlight-current-row="true" ref="multipleTable" tooltip-effect="dark"
			:height="tableheight" @selection-change="handleSelectionChange" @row-click="rowclick"
			:header-cell-style="headerCellStype" :cell-style="contentCellStyle" style="width: 100%; cursor: pointer;"
			row-key="id" :row-class-name="tableRowClassName">
			<el-table-column type="selection" width="55" />
			<el-table-column fixed prop="id" label="id" width="0" v-if="false" />
			<el-table-column fixed prop="isManager" label="isManager" width="0" v-if="false" />
			<el-table-column fixed prop="menuIds" label="menuIds" width="0" v-if="false" />
			<el-table-column fixed prop="roleIds" label="roleIds" width="0" v-if="false" />
			<el-table-column prop="menuNames" label="用户权限" width="600" v-if="false" />
			<el-table-column prop="roleNames" label="用户角色" width="200" />
			<el-table-column prop="account" label="用户账号" width="120" />
			<el-table-column prop="name" label="用户名称" width="120" />
			<el-table-column prop="mobilephone" label="手机号码" width="120" />
			<el-table-column prop="remark" label="备注" width="250" :show-overflow-tooltip="true" />
			<el-table-column prop="cuName" label="创建人" width="120" />
			<el-table-column prop="muName" label="修改人" width="120" />
			<el-table-column prop="ct" label="创建时间" width="150" align="center" />
			<el-table-column prop="mt" label="修改时间" width="150" align="center" />
			<el-table-column label="" align="center" />
			<el-table-column fixed="right" label="操作" width="150" align="left">
				<template #default="scope">
					<el-button size="mini" @click.stop="modify(scope.row)" type="success" class="buttonfortable">编 辑
					</el-button>
					<el-button size="mini" @click.stop="deleteitem(scope.row)" type="warning" class="buttonfortable"
						v-if="scope.row.isManager=='0'">删 除
					</el-button>
				</template>
			</el-table-column>
		</el-table>
	</div>
	<div class="contentpager" v-if="showpaging">
		<el-pagination background layout="prev, pager, next,jumper,sizes,total" :total="pagingInfo.totalCount"
			:page-sizes="[20,50,100]" v-model:current-page="params.pageNum" v-model:page-size="params.pageSize"
			@size-change="sizechange" @current-change="currentchange" />
	</div>
	<el-dialog width="1000px" v-model="dialogVisible" :title="dialogtitle" center draggable
		:close-on-click-modal="false" :show-close="true">
		<el-form :model="formdata" ref="formRef" class="demo-ruleForm formContent">
			<el-row>
				<el-col :span="12">
					<el-form-item prop="account" :rules="[{ required: true, message: '请输入用户账号' ,trigger:'blur' },{min:5,max:20,message:'用户账号的长度为5-20',trigger:'blur'}]" label="用户账号"
						:label-width="formLabelWidth">
						<el-input v-model="formdata.account" autocomplete="off" :style="formContentStyle" maxlength="20"
							placeholder="请输入用户账号" v-if="formdata.id.length==0" />
						<el-tag v-if="formdata.id.length!=0">{{formdata.account}}</el-tag>
					</el-form-item>
					<el-form-item prop="name" :rules="[{ required: true, message: '请输入用户名称' ,trigger:'blur' },{min:2,max:10,message:'用户名称的长度为2-10',trigger:'blur'}]" label="用户名称"
						:label-width="formLabelWidth">
						<el-input v-model="formdata.name" autocomplete="off" :style="formContentStyle" maxlength="10"
							placeholder="请输入用户名称" />
					</el-form-item>
					<el-form-item prop="name" label="手机号码"
						:label-width="formLabelWidth">
						<el-input v-model="formdata.mobilephone" autocomplete="off" :style="formContentStyle" maxlength="15"
							placeholder="请输入手机号码" />
					</el-form-item>
					<el-form-item label="选择角色" :label-width="formLabelWidth" v-if="isManager=='0'">
						<el-scrollbar :height="200">
							<el-tree ref="treeRoleRef" :data="roledata" :default-expand-all="true" show-checkbox
								node-key="id" :check-on-click-node="true" :default-checked-keys="defaultChecked"
								:expand-on-click-node="false" :props="treeProps" @check-change="roleNodeCheckedChange">
							</el-tree>
						</el-scrollbar>
					</el-form-item>
					<el-form-item label="备注" :label-width="formLabelWidth">
						<el-input v-model="formdata.remark" autocomplete="off" :style="formContentStyle" maxlength="200"
							placeholder="请输入备注" />
					</el-form-item>
				</el-col>
				<el-col :span="12" v-if="isManager=='0'">
					<el-scrollbar :height="500">
						<el-tree ref="treeMenuRef" :data="menudata" :default-expand-all="true" show-checkbox
							node-key="id" :check-on-click-node="true" :default-checked-keys="defaultChecked"
							:expand-on-click-node="false" :props="treeProps">
						</el-tree>
					</el-scrollbar>
				</el-col>
			</el-row>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-space wrap :size="30">
					<span v-if="showgoon">是否继续添加：
						<el-switch v-model="goon" style="margin-right: 10px;" />
					</span>
					<el-button size="mini" type="warning" @click="reset" class="buttonforform"> 重 置 </el-button>
					<el-button size="mini" type="primary" @click="submitForm(formRef)" class="buttonforform"> 确 定
					</el-button>
				</el-space>
			</span>
		</template>
	</el-dialog>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted,
		onActivated,
		getCurrentInstance
	} from 'vue'
	import {
		ElMessageBox
	} from 'element-plus'
	import {
		GetServerData
	} from '@/http/index.js'

	// 获取全局this，proxy相当于vue2中的this，用于调用全局对象使用
	const {
		proxy
	} = getCurrentInstance()

	// 定义查询参数
	let params = reactive({
		name: '',
		mobilephone: '',
		pageSize: 20,
		pageNum: 1
	})
	let isManager = ref('0')
	let pageNum = ref(1)
	let pagingInfo = reactive({
		totalCount: 0,
		totalPage: 0
	})
	// 定义表单标题
	let dialogtitle = ref("新 增 用 户")
	// 定义表单数据
	let formdata = reactive({
		id: '',
		account: '',
		name: '',
		mobilephone: '',
		remark: '',
		roles: '',
		menus: ''
	})
	let headerCellStype = ref(proxy.$headerCellStype)
	let contentCellStyle = ref(proxy.$contentCellStyle)

	const treeMenuRef = ref()
	const treeRoleRef = ref()
	let menudata = ref()
	let roledata = ref()
	const treeProps = reactive({
		children: 'menuItems',
		label: 'name'
	})
	let showResetPass=ref((localStorage.getItem("isManager")=='1'));
	// 定义表单显示与隐藏
	let dialogVisible = ref(false)
	// 定义是否显示继续添加
	let showgoon = ref(false)
	// 定义是否继续添加
	let goon = ref(false)
	// 定义是否显示分页
	let showpaging = ref(true)
	// 定义表格数据
	let tableDatas = ref([])
	// 定义选中的表格行
	let multipleSelection = reactive([])
	// 定义表格引用，用来执行多选
	const multipleTable = ref()
	// 定义表单引用，用来执行表单验证
	const formRef = ref()
	// 定义表单项的头部宽度
	const formLabelWidth = ref('150px')
	// 定义表单项的内容宽度
	const formContentStyle = ref('width:300px')

	// 定义表格隔行变色，暂时没用到
	const tableRowClassName = ({
		row,
		rowIndex
	}) => {
		if (rowIndex % 2 === 0) {
			return 'even-row'
		} else {
			return 'odd-row'
		}
	}
	// 定义表格多选
	const handleSelectionChange = (val) => {
		multipleSelection = val;
	}
	const toggleSelection = (rows) => {
		if (rows) {
			rows.forEach(row => {
				multipleTable.value.toggleRowSelection(row);
			});
		} else {
			multipleTable.value.clearSelection();
		}
	}
	const rowclick = (row, column, event) => {
		toggleSelection([row])
	}
	const sizechange = async () => {
		params.pageNum = 1
		let loading = proxy.$tools.ShowLoading();
		await GetTableData()
		loading.close()
	}
	const currentchange = async () => {
		let loading = proxy.$tools.ShowLoading();
		await GetTableData()
		loading.close()
	}

	// 定义表单提交事件，带表单验证
	const submitForm = (form) => {
		form.validate(async (valid) => {
			if (valid) {
				var reg=new RegExp('^[A-Za-z0-9]+$');
				if(reg.test(formdata.account)){
					let loading = proxy.$tools.ShowLoading();
					if(isManager.value=='0'){
						let checkedNodes = treeMenuRef.value.getCheckedNodes(false, false)
						let checkedNodeIds = ''
						checkedNodes.forEach(function(value, key, arr) {
							if (!value.menuItems) {
								checkedNodeIds = checkedNodeIds + value.id + ','
							}
						})
						if (checkedNodeIds.length > 0) {
							checkedNodeIds = checkedNodeIds.substr(0, checkedNodeIds.length - 1)
						}
						formdata.menus = checkedNodeIds;
						checkedNodes = treeRoleRef.value.getCheckedNodes(false, false)
						checkedNodeIds = ''
						checkedNodes.forEach(function(value, key, arr) {
							checkedNodeIds = checkedNodeIds + value.id + ','
						})
						if (checkedNodeIds.length > 0) {
							checkedNodeIds = checkedNodeIds.substr(0, checkedNodeIds.length - 1)
						}
						formdata.roles = checkedNodeIds;
					}else{
						formdata.menus ='';
						formdata.roles='';
					}
					if (formdata.id.length > 0) {
						let response = await GetServerData('User/Modify', formdata)
						if (response.status == 1) {
							proxy.$tools.ShowMessage('修改成功', true)
							dialogVisible.value = false
							await GetAllData();
						} else {
							proxy.$tools.ShowMessage(response.message, false)
						}
					} else {
						let response = await GetServerData('User/Add', formdata)
						if (response.status == 1) {
							proxy.$tools.ShowMessage('新增成功！', true)
							if (goon.value) {
								reset()
							} else {
								dialogVisible.value = false
							}
							await GetAllData();
						} else {
							proxy.$tools.ShowMessage(response.message, false)
						}
					}
					loading.close()
				}else{
					proxy.$tools.ShowMessage('账号只能是字母！', false)
					return false;
				}
			} else {
				proxy.$tools.ShowMessage('请您正确填写所有必填信息后，再提交！', false)
				return false;
			}
		})
	}
	// 重置表单事件
	const reset = () => {
		if (formRef.value) {
			formRef.value.resetFields();
		}
		formdata.id = '';
		formdata.account = '';
		formdata.name = '';
		formdata.mobilephone = '';
		formdata.remark = '';
		formdata.roles = '';
		formdata.menus = '';
		if (treeMenuRef.value) {
			treeMenuRef.value.setCheckedKeys([], false)
			treeRoleRef.value.setCheckedKeys([], false)
		}
	}
	// 定义新增按钮事件
	const add = () => {
		dialogtitle.value = "新 增 用 户"
		goon.value = false
		showgoon.value = true
		isManager.value = '0'
		reset()
		dialogVisible.value = true
	}
	// 定义编辑按钮事件
	const modify = (row) => {
		reset();
		dialogtitle.value = "修 改 用 户"
		showgoon.value = false
		formdata.id = row.id;
		formdata.account = row.account;
		formdata.name = row.name;
		formdata.mobilephone = row.mobilephone
		formdata.remark = row.remark;
		formdata.roles = row.roleIds;
		formdata.menus = row.menuIds;
		isManager.value = row.isManager
		dialogVisible.value = true;
		let timer = setTimeout(() => {
			if(row.roleIds){
				treeRoleRef.value.setCheckedKeys(row.roleIds.split(','), false)
			}
			if(row.menuIds){
				treeMenuRef.value.setCheckedKeys(row.menuIds.split(','), false)
			}
			clearTimeout(timer)
		}, 1);
	}
	// 定义删除按钮事件
	const deleteitem = (row) => {
		ElMessageBox.confirm('确定要删除这条记录吗?', "", {
				confirmButtonText: '确 定',
				cancelButtonText: '取 消',
				type: 'warning'
			})
			.then(async () => {
				let loading = proxy.$tools.ShowLoading();
				let response = await GetServerData('User/Delete', {
					id: row.id
				})

				if (response.status == 1) {
					proxy.$tools.ShowMessage('删除成功！', true)
					await GetAllData()
				} else {
					proxy.$tools.ShowMessage(response.message, false)
				}
				loading.close();
			})
	}
	const resetpass=()=>{
		var ids = ''
		multipleSelection.forEach((value, key, iterable) => {
			ids += "'" + value.id + "',";
		})
		if (ids.length > 0) {
			ids = ids.substr(0, ids.length - 1)
			ElMessageBox.confirm('确定要重置下面选中用户的密码吗？?', "", {
					confirmButtonText: '确 定',
					cancelButtonText: '取 消',
					type: 'warning'
				})
				.then(async () => {
					let loading = proxy.$tools.ShowLoading();
					let response = await GetServerData('User/ResetPass', {
						ids: ids
					})
					if (response.status == 1) {
						proxy.$tools.ShowMessage('重置密码成功！', true)
						await GetAllData()
					} else {
						proxy.$tools.ShowMessage(response.message, false)
					}
					loading.close()
				})
		}
	}
	
	// 定义批量删除按钮事件
	const deletesome = () => {
		var ids = ''
		multipleSelection.forEach((value, key, iterable) => {
			if (value.isManager == '0') {
				ids += "'" + value.id + "',";
			} else {
				proxy.$tools.ShowMessage("管理员无法删除！", false)
			}
		})
		if (ids.length > 0) {
			ids = ids.substr(0, ids.length - 1)
			ElMessageBox.confirm('确定要删除下面选中的所有记录吗?', "", {
					confirmButtonText: '确 定',
					cancelButtonText: '取 消',
					type: 'warning'
				})
				.then(async () => {
					let loading = proxy.$tools.ShowLoading();
					let response = await GetServerData('User/DeleteSome', {
						ids: ids
					})
					if (response.status == 1) {
						proxy.$tools.ShowMessage('删除成功！', true)
						await GetAllData()
					} else {
						proxy.$tools.ShowMessage(response.message, false)
					}
					loading.close()
				})
		}
	}
	// 定义查询按钮事件
	const search = async () => {
		let loading = proxy.$tools.ShowLoading();
		await GetTableData()
		loading.close()
	}
	// 定义刷新按钮事件
	const resetparams = () => {
		params.name = '';
	}
	const roleNodeCheckedChange = () => {
		let checkedMenus = new Set()
		let checkedRoles = treeRoleRef.value.getCheckedNodes(false, false);
		checkedRoles.forEach(function(value, key, arr) {
			if(value.menuIds){
				let temp = value.menuIds.split(',')
				temp.forEach(function(value1) {
					checkedMenus.add(value1)
				})
			}
		});
		treeMenuRef.value.setCheckedKeys(Array.from(checkedMenus), false)
	}
	// 定义获取表格数据函数
	const GetTableData = async () => {
		let response = await GetServerData('User/QueryAll', params)
		if (response.status == 1) {
			tableDatas.value = response.data
			pagingInfo.totalCount = response.totalCount
			pagingInfo.totalPage = Math.ceil(pagingInfo.totalCount / params.pageSize)
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}

	const GetMenuData = async () => {
		let response = await GetServerData('User/QueryMenus')
		if (response.status == 1) {
			menudata.value = response.data
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}

	const GetRoleData = async () => {
		let response = await GetServerData('User/QueryRoles')
		if (response.status == 1) {
			roledata.value = response.data
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}

	// 定义获取所有数据函数
	const GetAllData = async () => {
		await GetTableData()
		await GetMenuData()
		await GetRoleData()
	}

	let tableheight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight))
	// 定义加载事件
	onMounted(async () => {
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth - 220) +
			'px'
		window.onresize = () => {
			document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
					220) +
				'px'
			tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight)
		}
		let loading = proxy.$tools.ShowLoading();
		await GetAllData()
		loading.close()
	})
	onActivated(()=>{
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
				220) +
			'px'
		tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight)
	})
</script>

<style scoped lang="less">
</style>
