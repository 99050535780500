<template>
	<div class="contentheader">
		<el-space wrap>
			<span class="titleClass">注销缴费</span>
			<el-divider direction="vertical" />
			<el-input v-model="params.keyword" placeholder="输入关键字" size="small" style="width: 140px;" clearable
				autocomplete="off" />
			<el-date-picker v-model="dateRange" type="datetimerange" :shortcuts="shortcuts" range-separator="至"
				:defaultTime="defaultTime" start-placeholder="开始时间" end-placeholder="结束时间" @change="dateChange"
				size="small" />
			<el-select v-model="params.category" class="m-2" size="small" style="width: 100px;">
				<el-option key="0" label="账户余额" value="0" />
				<el-option key="1" :label="ammeterName1+'购电'" value="1" v-if="ammeterType!='0'" />
				<el-option key="2" :label="ammeterName2+'购电'" value="2" v-if="ammeterType=='2'" />
				<el-option key="3" :label="watermeterName1+'购水'" value="3" v-if="watermeterType!='0'" />
				<el-option key="4" :label="watermeterName2+'购水'" value="4" v-if="watermeterType=='2'" />
			</el-select>
			<el-button type="primary" size="small" class="buttonforheader" @click="search">查 询</el-button>
			<el-button type="primary" size="small" class="buttonforheader" @click="refresh">刷 新</el-button>
			<el-divider direction="vertical" />
		</el-space>
	</div>
	<div style="display: flex; flex-direction: row; flex-wrap: nowrap; align-items: flex-start;margin: 10px;">
		<div style="width: 200px;">
			<el-scrollbar :height="areaHeight" style="width:200px; border-radius: 6px;background-color: white;">
				<el-tree ref="treeAreaRef" :data="areaDatas" node-key="id" :expand-on-click-node="false"  highlight-current
					:props="treeProps" @node-click="areaSelected" style="margin: 10px;">
				</el-tree>
			</el-scrollbar>
		</div>
		<div id="divTableData" style="width: 100%;border-radius: 6px;background-color: white; margin-left: 5px;">
			<el-table :data="tableDatas" fit="false" highlight-current-row="true" ref="multipleTable"
				tooltip-effect="dark" :height="tableheight" row-key="id" :header-cell-style="headerCellStype"
				:cell-style="contentCellStyle" style="width: 100%; cursor: pointer;" id="tableData"
				:row-class-name="tableRowClassName">
				<el-table-column fixed prop="id" label="id" width="0" v-if="false" />
				<el-table-column fixed prop="category" label="category" width="0" v-if="false" />
				<el-table-column prop="userName" label="操作员" width="100" />
				<el-table-column prop="categoryName" label="注销类型" width="150" />
				<el-table-column prop="areaName1" label="一级区域" width="100" />
				<el-table-column prop="areaName2" label="二级区域" width="100" />
				<el-table-column prop="areaName3" label="三级区域" width="100" />
				<el-table-column prop="roomName" label="房间名称" width="100" />
				<el-table-column prop="roomNum" label="房间编号" width="100" />
				<el-table-column prop="dealMoney" label="缴费金额" width="120" align="center" />
				<el-table-column prop="dealValue" label="购量" width="120" align="center" />
				<el-table-column prop="ct" label="时间" width="150" align="center" />
				<el-table-column prop="remark" label="备注" width="300" />
				<el-table-column />
				<el-table-column fixed="right" label="操作" width="190" align="center">
					<template #default="scope">
						<el-button size="mini" @click.stop="confirmCancel(scope.row)" type="success" class="buttonfortable">
							注 销
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
	<el-dialog width="700px" v-model="dialogVisible" append-to-body=true :title="dialogtitle" center draggable
		:close-on-click-modal="false" :show-close="true">
		<div style="width: 500px; margin: 0 auto;text-align: center; border-radius: 10px; background-color:antiquewhite; padding: 10px;">
			<el-row>
				<el-col :span="24" style="text-align: center; font-size: 18px; line-height: 25px;">
					注销信息验证成功，请在60秒内完成注销操作 
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="10" style="text-align: right; font-size: 18px; line-height: 25px;">
					注销金额： 
				</el-col>
				<el-col :span="14" style="text-align: left; font-size: 18px; line-height: 25px;">
					 {{canceldata.dealMoney}} 元
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="10" style="text-align: right; font-size: 18px; line-height: 25px;">
					注销购量： 
				</el-col>
				<el-col :span="14" style="text-align: left; font-size: 18px; line-height: 25px;">
					 {{canceldata.dealValue}} {{canceldata.category=="0"?"元":(canceldata.category=="1"||canceldata.category=="2")?"度":"方"}}
				</el-col>
			</el-row>
		</div>
		<template #footer>
			<span class="dialog-footer">
				<el-button size="mini" type="primary" @click="cancelRecharge" class="buttonforform">注 销
				</el-button>
				<el-button size="mini" type="info" @click="cancelCancel" class="buttonforform">取 消
				</el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted,
		onActivated,
		getCurrentInstance
	} from 'vue'
	import {
		ElMessageBox
	} from 'element-plus'
	import {
		GetServerData
	} from '@/http/index.js'
	// 获取全局this，proxy相当于vue2中的this，用于调用全局对象使用
	const {
		proxy
	} = getCurrentInstance()
	const treeAreaRef = ref()
	const treeProps = reactive({
		children: 'children',
		label: 'name'
	})
	let dialogtitle = ref("注销明细")
	let dialogVisible=ref(false)
	// 定义表单引用，用来执行表单验证
	const formRef = ref()
	let showTag = ref(false)
	
	// 定义表单数据
	let canceldata = reactive({
		id:'',
		category:'0',
		valid:false,
		message:'',
		dealMoney:0.0,
		dealValue:0.0
	})
	
	// 定义查询参数
	let params = reactive({
		keyword: '',
		areaId1: '',
		areaId2: '',
		areaId3: '',
		roomId: '',
		beginTime: '',
		endTime: '',
		pageSize: 50,
		category:'0'
	})
	let selectedArea = ref('')

	let headerCellStype = ref(proxy.$headerCellStype)
	let contentCellStyle = ref(proxy.$contentCellStyle)

	let ammeterType=ref(localStorage.getItem("ammeterType"));
	let ammeterName1=ref(localStorage.getItem("ammeterName1"));
	let ammeterName2=ref(localStorage.getItem("ammeterName2"));
	let watermeterType=ref(localStorage.getItem("watermeterType"));
	let watermeterName1=ref(localStorage.getItem("watermeterName1"));
	let watermeterName2=ref(localStorage.getItem("watermeterName2"));

	// 定义表格数据
	let tableDatas = ref([])
	let tableDetailDatas=ref([])
	let areaDatas = ref([])


	// 定义表格引用，用来执行多选
	const multipleTable = ref()

	// 定义表格隔行变色，暂时没用到
	const tableRowClassName = ({
		row,
		rowIndex
	}) => {
		if (rowIndex % 2 === 0) {
			return 'even-row'
		} else {
			return 'odd-row'
		}
	}
	
	const cancelCancel=()=>{
		dialogVisible.value=false;
		canceldata.valid=false;
		canceldata.id='';
		canceldata.category='0';
		canceldata.dealMoney=0.0;
		canceldata.dealValue=0.0;
	}
	
	const confirmCancel=async (row)=>{
		canceldata.valid=false;
		canceldata.id='';
		canceldata.category='0';
		canceldata.dealMoney=0.0;
		canceldata.dealValue=0.0;
		let loading = proxy.$tools.ShowLoading()
		let response = await GetServerData('CancelRecharge/VerifyData', {
			id:row.id,
			category:row.category
		})
		if (response.status == 1) {
			if(response.data.valid){
				dialogtitle.value='('+row.areaName1+'-'+row.areaName2+'-'+row.areaName3+'-'+row.roomName+'-'+row.roomNum+')注销缴费';
				canceldata.id=response.data.id;
				canceldata.category=response.data.category;
				canceldata.valid=response.data.valid;
				canceldata.dealMoney=row.dealMoney;
				canceldata.dealValue=row.dealValue;
				dialogVisible.value=true
			} else {
				proxy.$tools.ShowMessage(response.message, false)
			}
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
		loading.close();
	}
	
	// 定义表单提交事件，带表单验证
	const cancelRecharge = async () => {
		let flag=false;
		if(canceldata.valid){
			let loading = proxy.$tools.ShowLoading()
			let response = await GetServerData('CancelRecharge/CancelRecharge', {
				id:canceldata.id,
				category:canceldata.category
			})
			if (response.status == 1) {
				flag=true;
				proxy.$tools.ShowMessage('注销成功！'+response.message, true)
			}else {
				proxy.$tools.ShowMessage(response.message, false)
			}
			loading.close();
		}else{
			proxy.$tools.ShowMessage('验证未通过，无法注销！', false)
		}
		//if(flag){
			cancelCancel();
			await search();
		//}
	}

	// const sizechange = async () => {
	// 	await search()
	// 	multipleTable.value.$refs.bodyWrapper.scrollTo(0, 0);
	// }

	let currentDateTime = new Date();
	let defaultBeginTime = new Date(currentDateTime.getFullYear(), currentDateTime.getMonth(), currentDateTime.getDate(),
		0, 0, 0)
	let defaultEndTime = new Date(currentDateTime.getFullYear(), currentDateTime.getMonth(), currentDateTime.getDate(), 23,
		23, 59)
	let dateRange = ref([defaultBeginTime, defaultEndTime])
	let defaultTime = ref([defaultBeginTime, defaultEndTime])
	params.beginTime = proxy.$tools.DateTimeToString(defaultBeginTime);
	params.endTime = proxy.$tools.DateTimeToString(defaultEndTime);
	const shortcuts = ref([{
			text: '今天',
			value: () => {
				let end = new Date()
				let start = new Date()
				start.setHours(0)
				start.setMinutes(0)
				start.setSeconds(0)
				end.setHours(23);
				end.setMinutes(59)
				end.setSeconds(59)
				return [start, end]
			}
		}, {
			text: '昨天',
			value: () => {
				let end = new Date()
				let start = new Date()
				start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
				start.setHours(0)
				start.setMinutes(0)
				start.setSeconds(0)
				end.setHours(23);
				end.setMinutes(59)
				end.setSeconds(59)
				return [start, end]
			}
		}, {
			text: '前天',
			value: () => {
				let end = new Date()
				let start = new Date()
				start.setTime(start.getTime() - 3600 * 1000 * 24 * 2)
				start.setHours(0)
				start.setMinutes(0)
				start.setSeconds(0)
				end.setHours(23);
				end.setMinutes(59)
				end.setSeconds(59)
				return [start, end]
			}
		}, {
			text: '之前一周',
			value: () => {
				let end = new Date()
				let start = new Date()
				start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
				end.setHours(23);
				end.setMinutes(59)
				end.setSeconds(59)
				start.setHours(0)
				start.setMinutes(0)
				start.setSeconds(0)
				return [start, end]
			}
		},
		{
			text: '之前一个月',
			value: () => {
				let end = new Date()
				let start = proxy.$tools.ReduceMonth(end, 1)
				end.setHours(23);
				end.setMinutes(59)
				end.setSeconds(59)
				return [start, end]
			}
		},
		{
			text: '之前二个月',
			value: () => {
				let end = new Date()
				let start = proxy.$tools.ReduceMonth(end, 2)
				end.setHours(23);
				end.setMinutes(59)
				end.setSeconds(59)
				return [start, end]
			}
		},
		{
			text: '之前三个月',
			value: () => {
				let end = new Date()
				let start = proxy.$tools.ReduceMonth(end, 3)
				end.setHours(23);
				end.setMinutes(59)
				end.setSeconds(59)
				return [start, end]
			}
		}
	])
	const resetSelectedArea = () => {
		selectedArea.value = ''
		showTag.value = false
		params.areaId1 = ''
		params.areaId2 = ''
		params.areaId3 = ''
		params.roomId = ''
	}

	const areaSelected = async (area) => {
		console.log(area)
		let grade = area.grade
		let id = area.id
		let selectedName = getSelectedArea(id)
		if (selectedName.length > 0) {
			selectedName = "所选区域：" + selectedName
			showTag.value = true
		} else {
			showTag.value = false
		}
		selectedArea.value = selectedName
		params.areaId1 = ''
		params.areaId2 = ''
		params.areaId3 = ''
		params.roomId = ''
		console.log(grade, id)
		if (grade == "1") {
			params.areaId1 = id;
		} else if (grade == "2") {
			params.areaId2 = id;
		} else if (grade == "3") {
			params.areaId3 = id;
		} else if (grade == "4") {
			params.roomId = id;
		}
	}

	const getSelectedArea = (id) => {
		let selectedNames = ''
		for (let i = 0; i < areaDatas.value.length; i++) {
			selectedNames += areaDatas.value[i].name
			if (areaDatas.value[i].id == id) {
				return selectedNames
			}
			for (let j = 0; j < areaDatas.value[i].children.length; j++) {
				selectedNames += " - " + areaDatas.value[i].children[j].name
				if (areaDatas.value[i].children[j].id == id) {
					return selectedNames
				}
				for (let k = 0; k < areaDatas.value[i].children[j].children.length; k++) {
					selectedNames += " - " + areaDatas.value[i].children[j].children[k].name
					if (areaDatas.value[i].children[j].children[k].id == id) {
						return selectedNames
					}
					for (let m = 0; m < areaDatas.value[i].children[j].children[k].children.length; m++) {
						selectedNames += " - " + areaDatas.value[i].children[j].children[k].children[m].name
						if (areaDatas.value[i].children[j].children[k].children[m].id == id) {
							return selectedNames
						}
						selectedNames = areaDatas.value[i].name + " - " + areaDatas.value[i].children[j].name + " - " +
							areaDatas.value[i].children[j].children[k].name
					}
					selectedNames = areaDatas.value[i].name + " - " + areaDatas.value[i].children[j].name
				}
				selectedNames = areaDatas.value[i].name
			}
			selectedNames = ''
		}
		return selectedNames
	}

	const dateChange = (value) => {
		params.beginTime = proxy.$tools.DateTimeToString(value[0]);
		params.endTime = proxy.$tools.DateTimeToString(value[1]);
	}

	const search = async () => {
		if (params.beginTime.length > 0 && params.endTime.length > 0) {
			let loading = proxy.$tools.ShowLoading()
			let response = await GetServerData('CancelRecharge/QueryAll', params)
			if (response.status == 1) {
				tableDatas.value = response.data
				
			} else {
				proxy.$tools.ShowMessage(response.message, false)
			}
			loading.close();
		} else {
			proxy.$tools.ShowMessage("请选择开始时间和结束时间", false)
		}
	}
	
	const refresh=async ()=>{
		let loading = proxy.$tools.ShowLoading();
		tableDatas.value=[];
		await GetAllData()
		loading.close()
	}

	// 定义获取表格数据函数
	const GetRoomData = async () => {
		let response = await GetServerData('CancelRecharge/QueryAllRooms')
		if (response.status == 1) {
			areaDatas.value = response.data
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}

	// 定义获取所有数据函数
	const GetAllData = async () => {
		await GetRoomData()
	}

	let areaHeight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60))
	let tableheight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60))
	// 定义加载事件
	onMounted(async () => {
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth - 425) +
			'px'
		window.onresize = () => {
			tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
			areaHeight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
			document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
				425) + 'px'
		}
		let loading = proxy.$tools.ShowLoading();
		await GetAllData()
		loading.close()
	})
	onActivated(()=>{
		tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
		areaHeight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
			425) + 'px'
	})
</script>

<style scoped lang="less">
</style>
