<template>

	<div class="contentheader">
		<el-space wrap>
			<span class="titleClass">用水量变化图表</span>
			<el-divider direction="vertical" />
			<el-button type="primary" size="small" class="buttonforheader" @click="refresh">刷新房间</el-button>
			<el-divider direction="vertical" />
			<el-radio-group v-model="params.areaType" class="ml-4" >
				<el-radio label="1" size="small">当前区域</el-radio>
				<el-radio label="2" size="small">下级区域</el-radio>
			</el-radio-group>
			<el-divider direction="vertical" />
			<el-radio-group v-model="params.searchType" class="ml-4" >
				<el-radio label="1" size="small">按日</el-radio>
				<el-radio label="2" size="small">按月</el-radio>
				<!-- <el-radio label="3" size="small">按年</el-radio> -->
			</el-radio-group>
			<el-divider direction="vertical" />
			<el-date-picker v-model="dateRange" type="daterange" :shortcuts="shortcuts" range-separator="至"
				:defaultTime="defaultDate" start-placeholder="开始日期" end-placeholder="结束日期" @change="dateChange"
				size="small" v-if="params.searchType=='1'" />
			<el-date-picker v-model="dateRange" type="monthrange" :shortcuts="shortcuts" range-separator="至"
				:defaultTime="defaultDate" start-placeholder="开始月份" end-placeholder="结束月份" @change="dateChange"
				size="small" v-if="params.searchType=='2'" />
			<el-divider direction="vertical" />
			<el-select v-model="params.meterType" class="m-2" size="small" style="width: 100px;"
				v-if="watermeterType=='2'">
				<el-option key="3" :label="watermeterName1" value="3" />
				<el-option key="4" :label="watermeterName2" value="4" />
			</el-select>
			<el-divider direction="vertical" />
			<el-checkbox v-model="params.compareBefore" label="与去年同期对比" size="large" />
			<el-divider direction="vertical" />
			<el-button type="primary" size="small" class="buttonforheader" @click="search">查 询</el-button>
			<!-- <el-tag class="ml-2" type="success" closable v-if="showTag" @close="resetSelectedArea">{{selectedArea}}
			</el-tag> -->
			<!-- <el-tag class="ml-2" type="danger">总用量：{{totalUse}}</el-tag> -->
		</el-space>
	</div>
	<div style="display: flex; flex-direction: row; flex-wrap: nowrap; align-items: flex-start;margin: 10px;">
		<div style="width: 200px;">
			<el-scrollbar :height="areaHeight" style="width:200px; border-radius: 6px;background-color: white;">
				<el-tree ref="treeAreaRef" :data="areaDatas" node-key="id" :expand-on-click-node="false"
					highlight-current :props="treeProps" @node-click="areaSelected" style="margin: 10px;">
				</el-tree>
			</el-scrollbar>
		</div>
		<div id="divChart" style="border-radius: 6px;background-color: white;margin-left:5px">
			<div class="chart" ref="usechartref" style="width: 100%; height: 100%;text-align: left;" v-if="showChart"></div>
			<div class="chart" style="width: 100%; height: 100%;" v-if="!showChart">
				
			</div>
		</div>
	</div>
	
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted,
		onActivated,
		getCurrentInstance,
		nextTick
	} from 'vue'
	import {
		ElMessageBox
	} from 'element-plus'
	import {
		GetServerData
	} from '@/http/index.js'
	// 获取全局this，proxy相当于vue2中的this，用于调用全局对象使用
	const {
		proxy
	} = getCurrentInstance()
	const treeAreaRef = ref()
	const treeProps = reactive({
		children: 'children',
		label: 'name'
	})
	let showTag = ref(false)

	let usechartref=ref(null);
	let showChart=ref(false);
	let usechart=null;

	let watermeterType = ref(localStorage.getItem("watermeterType"));
	let watermeterName1 = ref(localStorage.getItem("watermeterName1"));
	let watermeterName2 = ref(localStorage.getItem("watermeterName2"));

	let priceCategory = ref('1') //1单价，2复费率

	// 定义查询参数
	let params = reactive({
		areaId1: '',
		areaId2: '',
		areaId3: '',
		roomId: '',
		areaType:'1',
		searchType:'1',
		begin: '',
		end: '',
		meterType: '3',
		grade:'0',
		compareBefore:false
	})
	//let totalUse = ref(0)
	let selectedArea = ref('')

	// 定义表格数据
	let chartDatas = ref(null)
	let areaDatas = ref([])

	let headerCellStype = ref(proxy.$headerCellStype)
	let contentCellStyle = ref(proxy.$contentCellStyle)

	// 定义表格引用，用来执行多选
	const multipleTable = ref()

	// 定义表格隔行变色，暂时没用到
	const tableRowClassName = ({
		row,
		rowIndex
	}) => {
		if (rowIndex % 2 === 0) {
			return 'even-row'
		} else {
			return 'odd-row'
		}
	}

	const sizechange = async () => {
		params.pageNum = 1
		await search()
		multipleTable.value.$refs.bodyWrapper.scrollTo(0, 0);
	}
	const currentchange = async () => {
		await search()
		multipleTable.value.$refs.bodyWrapper.scrollTo(0, 0);
	}
	
	let currentDate1 = new Date();
	currentDate1.setTime(currentDate1.getTime() - 3600 * 1000 * 24 * 1)
	let defaultEndDate = currentDate1
	let currentDate2 = new Date();
	//currentDate2.setTime(currentDate2.getTime() - 3600 * 1000 * 24 * 30)
	let defaultBeginDate = proxy.$tools.ReduceMonth(currentDate2, 1)//currentDate2
	let dateRange = ref([defaultBeginDate, defaultEndDate])
	let defaultDate = ref([defaultBeginDate, defaultEndDate])
	params.begin = proxy.$tools.DateTimeToString(defaultBeginDate);
	params.end = proxy.$tools.DateTimeToString(defaultEndDate);
	
	const shortcuts = ref([
		{
			text: '之前一个月',
			value: () => {
				let end = new Date()
				let start = proxy.$tools.ReduceMonth(end, 1)
				end.setTime(end.getTime() - 3600 * 1000 * 24 * 1)
				return [start, end]
			}
		},
		{
			text: '之前二个月',
			value: () => {
				let end = new Date()
				let start = proxy.$tools.ReduceMonth(end, 2)
				end.setTime(end.getTime() - 3600 * 1000 * 24 * 1)
				return [start, end]
			}
		},
		{
			text: '之前三个月',
			value: () => {
				let end = new Date()
				let start = proxy.$tools.ReduceMonth(end, 3)
				end.setTime(end.getTime() - 3600 * 1000 * 24 * 1)
				return [start, end]
			}
		}
	])
	const resetSelectedArea = () => {
		selectedArea.value = ''
		showTag.value = false
		params.areaId1 = ''
		params.areaId2 = ''
		params.areaId3 = ''
		params.roomId = ''
	}

	const areaSelected = async (area) => {
		console.log(area)
		let grade = area.grade
		let id = area.id
		let selectedName = getSelectedArea(id)
		if (selectedName.length > 0) {
			selectedName = "所选区域：" + selectedName
			showTag.value = true
		} else {
			showTag.value = false
		}
		selectedArea.value = selectedName
		params.areaId1 = ''
		params.areaId2 = ''
		params.areaId3 = ''
		params.roomId = ''
		//console.log(grade, id)
		params.grade=grade;
		if (grade == "1") {
			params.areaId1 = id;
		} else if (grade == "2") {
			params.areaId2 = id;
		} else if (grade == "3") {
			params.areaId3 = id;
		} else if (grade == "4") {
			params.roomId = id;
		}
	}

	const getSelectedArea = (id) => {
		let selectedNames = ''
		for (let i = 0; i < areaDatas.value.length; i++) {
			selectedNames += areaDatas.value[i].name
			if (areaDatas.value[i].id == id) {
				return selectedNames
			}
			for (let j = 0; j < areaDatas.value[i].children.length; j++) {
				selectedNames += " - " + areaDatas.value[i].children[j].name
				if (areaDatas.value[i].children[j].id == id) {
					return selectedNames
				}
				for (let k = 0; k < areaDatas.value[i].children[j].children.length; k++) {
					selectedNames += " - " + areaDatas.value[i].children[j].children[k].name
					if (areaDatas.value[i].children[j].children[k].id == id) {
						return selectedNames
					}
					for (let m = 0; m < areaDatas.value[i].children[j].children[k].children.length; m++) {
						selectedNames += " - " + areaDatas.value[i].children[j].children[k].children[m].name
						if (areaDatas.value[i].children[j].children[k].children[m].id == id) {
							return selectedNames
						}
						selectedNames = areaDatas.value[i].name + " - " + areaDatas.value[i].children[j].name + " - " +
							areaDatas.value[i].children[j].children[k].name
					}
					selectedNames = areaDatas.value[i].name + " - " + areaDatas.value[i].children[j].name
				}
				selectedNames = areaDatas.value[i].name
			}
			selectedNames = ''
		}
		return selectedNames
	}

	const dateChange = (value) => {
		params.begin = proxy.$tools.DateToString(value[0]);
		params.end = proxy.$tools.DateToString(value[1]);
	}

	const search = async () => {
		console.log(params)
		if (params.begin.length > 0 && params.end.length > 0) {
			let loading = proxy.$tools.ShowLoading()
			let response = await GetServerData('WaterUseChart/QueryChartData', params)
			if (response.status == 1) {
				showChart.value=true;
				nextTick(()=>{
					usechart=proxy.$tools.DrawChart(usechartref.value,proxy.$chartTools.GetMultiLineOption('用水量曲线图','方',response.data.xAxisData,response.data.seriesData,response.data.legendData));
				});
				
			} else {
				showChart.value=false;
				proxy.$tools.ShowMessage(response.message, false)
			}
			loading.close();
		} else {
			proxy.$tools.ShowMessage("请选择开始时间和结束时间", false)
		}
	}

	const refresh = async () => {
		let loading = proxy.$tools.ShowLoading();
		await GetAllData()
		loading.close()
	}

	// 定义获取表格数据函数
	const GetRoomData = async () => {
		let response = await GetServerData('WaterUseChart/QueryAllRooms')
		if (response.status == 1) {
			areaDatas.value = response.data
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}

	// 定义获取所有数据函数
	const GetAllData = async () => {
		await GetRoomData()
	}

	let areaHeight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60))
	//let tableheight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60))
	// 定义加载事件
	onMounted(async () => {
		document.getElementById("divChart").style.width = (document.documentElement.clientWidth - 425) +
			'px';
		document.getElementById("divChart").style.height = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)+'px'
		window.onresize = () => {
			//tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
			areaHeight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
			document.getElementById("divChart").style.width = (document.documentElement.clientWidth -
				425) + 'px';
			document.getElementById("divChart").style.height = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)+'px'
		}
		let loading = proxy.$tools.ShowLoading();
		await GetAllData()
		loading.close()
		
	})
	onActivated(() => {
		//tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
		areaHeight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
		document.getElementById("divChart").style.width = (document.documentElement.clientWidth -
			425) + 'px';
		document.getElementById("divChart").style.height = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)+'px'
	})
</script>

<style scoped lang="less">
</style>
