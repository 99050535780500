<template>
	<div class="contentheader">
		<el-space wrap>
			<span class="titleClass">菜单管理</span>
			<el-divider direction="vertical" />
			<el-select v-model="params.preId" class="m-2" style="width: 200px;" placeholder="选择上级菜单" size="small">
				<el-option v-for="item in preMenus" :key="item.key" :label="item.value" :value="item.key" />
			</el-select>
			<el-input v-model="params.name" placeholder="输入菜单名称" size="small" style="width: 200px;"
				autocomplete="off" />
			<el-button type="primary" size="small" class="buttonforheader" @click="search">查 询</el-button>
			<el-divider direction="vertical" />
			<el-button type="success" size="small" class="buttonforheader" @click="add">新 增</el-button>
			<el-button type="warning" size="small" class="buttonforheader" @click="deletesome">批量删除</el-button>
			<!-- <el-button type="primary" size="small" class="buttonforheader" @click="resetparams">重置</el-button> -->
		</el-space>
	</div>
	<div id="divTableData" class="contentcontent">
		<el-table :data="tableDatas" fit="false" highlight-current-row="true" ref="multipleTable" tooltip-effect="dark"
			:height="tableheight" @selection-change="handleSelectionChange" @row-click="rowclick" row-key="id"
			:header-cell-style="headerCellStype" :cell-style="contentCellStyle" style="width: 100%; cursor: pointer;"
			:row-class-name="tableRowClassName">
			<el-table-column type="selection" width="55" />
			<el-table-column fixed prop="id" label="id" width="0" v-if="false" />
			<el-table-column fixed prop="preId" label="preId" width="0" v-if="false" />
			<el-table-column fixed prop="category" label="category" width="0" v-if="false" />
			<el-table-column fixed prop="preName" label="上级菜单" width="120" />
			<el-table-column prop="name" label="菜单名称" width="120" />
			<el-table-column prop="categoryName" label="菜单类型" width="90" align="center" />
			<el-table-column prop="controllerName" label="控制器名称" width="150" />
			<el-table-column prop="routeName" label="路由名称" width="150" />
			<el-table-column prop="remark" label="备注" width="250" :show-overflow-tooltip="true" />
			<el-table-column prop="cuName" label="创建人" width="120" />
			<el-table-column prop="muName" label="修改人" width="120" />
			<el-table-column prop="ct" label="创建时间" width="150" align="center" />
			<el-table-column prop="mt" label="修改时间" width="150" align="center" />
			<el-table-column />
			<el-table-column fixed="right" label="操作" width="150" align="left">
				<template #default="scope">
					<el-button size="mini" @click.stop="modify(scope.row)" type="success" class="buttonfortable">编 辑
					</el-button>
					<el-button size="mini" @click.stop="deleteitem(scope.row)" type="danger" class="buttonfortable">删 除
					</el-button>
				</template>
			</el-table-column>
		</el-table>
	</div>
	<div class="contentpager" v-if="showpaging">
		<el-pagination background layout="prev, pager, next,jumper,sizes,total" :total="pagingInfo.totalCount"
			:page-sizes="[50,100,200]" v-model:current-page="params.pageNum" v-model:page-size="params.pageSize"
			@size-change="sizechange" @current-change="currentchange" />
	</div>
	<el-dialog width="500px" v-model="dialogVisible" append-to-body=true :title="dialogtitle" center draggable
		:close-on-click-modal="false" :show-close="true">
		<el-form :model="formdata" ref="formRef" class="demo-ruleForm formContent">
			<el-form-item label="上级菜单" :label-width="formLabelWidth">
				<el-select v-model="formdata.preId" placeholder="请选择上级菜单" :style="formContentStyle">
					<el-option v-for="item in preMenus" :key="item.key" :label="item.value" :value="item.key" />
				</el-select>
			</el-form-item>
			<el-form-item label="菜单类型" :label-width="formLabelWidth">
				<el-radio-group v-model="formdata.category" class="ml-4">
					<el-radio label="0" size="small" style="margin-left: 20px;">系统</el-radio>
					<el-radio label="1" size="small">会员</el-radio>
					<el-radio label="2" size="small">全部</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item prop="name" :rules="[{ required: true, message: '请输入菜单名称' }]" label="菜单名称"
				:label-width="formLabelWidth">
				<el-input v-model="formdata.name" autocomplete="off" :style="formContentStyle" placeholder="请输入菜单名称" />
			</el-form-item>
			<el-form-item prop="controllerName" label="控制器名称" :label-width="formLabelWidth">
				<el-input v-model="formdata.controllerName" autocomplete="off" :style="formContentStyle"
					placeholder="请输入后台控制器名称" />
			</el-form-item>
			<el-form-item prop="routeName" label="路由名称" :label-width="formLabelWidth">
				<el-input v-model="formdata.routeName" autocomplete="off" :style="formContentStyle"
					placeholder="请输入前台路由名称" />
			</el-form-item>
			<el-form-item label="菜单ICON" :label-width="formLabelWidth">
				<el-input v-model="formdata.icon" autocomplete="off" :style="formContentStyle"
					placeholder="请输入菜单ICON" />
			</el-form-item>
			<el-form-item label="备注" :label-width="formLabelWidth">
				<el-input v-model="formdata.remark" autocomplete="off" :style="formContentStyle" placeholder="请输入备注" />
			</el-form-item>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-space wrap :size="30">
					<span v-if="showgoon">是否继续添加：
						<el-switch v-model="goon" style="margin-right: 10px;" />
					</span>
					<el-button size="mini" type="warning" @click="reset" class="buttonforform"> 重 置 </el-button>
					<!-- <el-button @click="dialogVisible = false">取消</el-button> -->
					<el-button size="mini" type="primary" @click="submitForm(formRef)" class="buttonforform"> 确 定
					</el-button>
				</el-space>
			</span>
		</template>
	</el-dialog>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted,
		onActivated,
		getCurrentInstance
	} from 'vue'
	import {
		ElMessageBox
	} from 'element-plus'
	import {
		GetServerData
	} from '@/http/index.js'
	// 获取全局this，proxy相当于vue2中的this，用于调用全局对象使用
	const {
		proxy
	} = getCurrentInstance()

	// 定义查询参数
	let params = reactive({
		preId: '',
		name: '',
		pageSize: 50,
		pageNum: 1
	})
	let pageNum = ref(1)
	let pagingInfo = reactive({
		totalCount: 0,
		totalPage: 0
	})
	// 定义combox数据
	let preMenus = ref([])
	// 定义表单标题
	let dialogtitle = ref("新 增 菜 单")
	// 定义表单数据
	let formdata = reactive({
		id: '',
		preId: '',
		category: '1',
		name: '',
		icon: '',
		controllerName: '',
		routeName: '',
		remark: ''
	})

	// 定义表单显示与隐藏
	let dialogVisible = ref(false)
	// 定义是否显示继续添加
	let showgoon = ref(false)
	// 定义是否继续添加
	let goon = ref(false)
	// 定义是否显示分页
	let showpaging = ref(true)
	// 定义表格数据
	let tableDatas = ref([])
	let headerCellStype = ref(proxy.$headerCellStype)
	let contentCellStyle = ref(proxy.$contentCellStyle)
	// 定义选中的表格行
	let multipleSelection = reactive([])
	// 定义表格引用，用来执行多选
	const multipleTable = ref()
	// 定义表单引用，用来执行表单验证
	const formRef = ref()
	// 定义表单项的头部宽度
	const formLabelWidth = ref('150px')
	// 定义表单项的内容宽度
	const formContentStyle = ref('width:300px')

	// 定义表格隔行变色，暂时没用到
	const tableRowClassName = ({
		row,
		rowIndex
	}) => {
		if (rowIndex % 2 === 0) {
			return 'even-row'
		} else {
			return 'odd-row'
		}
	}
	// 定义表格多选
	const handleSelectionChange = (val) => {
		multipleSelection = val;
	}
	const toggleSelection = (rows) => {
		if (rows) {
			rows.forEach(row => {
				multipleTable.value.toggleRowSelection(row);
			});
		} else {
			multipleTable.value.clearSelection();
		}
	}
	const rowclick = (row, column, event) => {
		toggleSelection([row])
	}
	const sizechange = async () => {
		params.pageNum = 1
		let loading = proxy.$tools.ShowLoading();
		await GetTableData()
		loading.close()
	}
	const currentchange = async () => {
		let loading = proxy.$tools.ShowLoading();
		await GetTableData()
		loading.close()
	}

	// 定义表单提交事件，带表单验证
	const submitForm = (form) => {
		form.validate(async (valid) => {
			if (valid) {
				let loading = proxy.$tools.ShowLoading();
				if (formdata.id.length > 0) {
					let response = await GetServerData('Menu/Modify', formdata)
					if (response.status == 1) {
						proxy.$tools.ShowMessage('修改成功', true)
						dialogVisible.value = false
						await GetAllData();
					} else {
						proxy.$tools.ShowMessage(response.message, false)
					}
				} else {
					let response = await GetServerData('Menu/Add', formdata)
					if (response.status == 1) {
						proxy.$tools.ShowMessage('新增成功！', true)
						if (goon.value) {
							reset()
						} else {
							dialogVisible.value = false
						}
						await GetAllData();
					} else {
						proxy.$tools.ShowMessage(response.message, false)
					}
				}
				loading.close()
			} else {
				proxy.$tools.ShowMessage('请您正确填写所有必填信息后，再提交！', false)
				return false;
			}
		})
	}
	// 重置表单事件
	const reset = () => {
		if (formRef.value) {
			formRef.value.resetFields();
		}
		formdata.id = '';
		formdata.preId = '';
		formdata.category = '1';
		formdata.name = '';
		formdata.icon = '';
		formdata.controllerName = '';
		formdata.routeName = '';
		formdata.remark = '';
	}
	// 定义新增按钮事件
	const add = () => {
		dialogtitle.value = "新 增 菜 单"
		goon.value = false
		showgoon.value = true
		reset()
		dialogVisible.value = true
	}
	// 定义编辑按钮事件
	const modify = (row) => {
		dialogtitle.value = "修 改 菜 单"
		showgoon.value = false
		formdata.id = row.id;
		formdata.preId = row.preId;
		formdata.category = row.category;
		formdata.name = row.name;
		formdata.icon = row.icon;
		formdata.controllerName = row.controllerName;
		formdata.routeName = row.routeName;
		formdata.remark = row.remark;
		dialogVisible.value = true
	}
	// 定义删除按钮事件
	const deleteitem = (row) => {
		ElMessageBox.confirm('确定要删除这条记录吗?', "", {
				confirmButtonText: '确 定',
				cancelButtonText: '取 消',
				type: 'warning'
			})
			.then(async () => {
				let loading = proxy.$tools.ShowLoading();
				let response = await GetServerData('Menu/Delete', {
					id: row.id
				})

				if (response.status == 1) {
					proxy.$tools.ShowMessage('删除成功！', false)
					await GetAllData()
				} else {
					proxy.$tools.ShowMessage(response.message, false)
				}
				loading.close();
			})
	}
	// 定义批量删除按钮事件
	const deletesome = () => {
		var ids = ''
		multipleSelection.forEach((value, key, iterable) => {
			ids += "'" + value.id + "',";
		})
		if (ids.length > 0) {
			ids = ids.substr(0, ids.length - 1)
			ElMessageBox.confirm('确定要删除下面选中的所有记录吗?', "", {
					confirmButtonText: '确 定',
					cancelButtonText: '取 消',
					type: 'warning'
				})
				.then(async () => {
					let loading = proxy.$tools.ShowLoading();
					let response = await GetServerData('Menu/DeleteSome', {
						ids: ids
					})
					if (response.status == 1) {
						proxy.$tools.ShowMessage('删除成功！', false)
						await GetAllData()
					} else {
						proxy.$tools.ShowMessage(response.message, false)
					}
					loading.close()
				})
		}
	}
	// 定义查询按钮事件
	const search = async () => {
		let loading = proxy.$tools.ShowLoading();
		await GetTableData()
		loading.close()
	}
	// 定义刷新按钮事件
	const resetparams = () => {
		params.preId = '';
		params.name = '';
	}
	// 定义获取表格数据函数
	const GetTableData = async () => {
		let response = await GetServerData('Menu/QueryAll', params)
		if (response.status == 1) {
			tableDatas.value = response.data
			pagingInfo.totalCount = response.totalCount
			pagingInfo.totalPage = Math.ceil(pagingInfo.totalCount / params.pageSize)
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}
	// 定义获取combox数据函数
	const GetComboxData = async () => {
		let response = await GetServerData('Menu/QueryPre')
		if (response.status == 1) {
			preMenus.value = response.data
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
		// http.get('Menu/QueryPre')
		// 	.then(function(response) {
		// 		if (response.status == 1) {
		// 			preMenus.value = response.data
		// 		} else {
		// 			proxy.$tools.ShowMessage(response.message, false)
		// 		}
		// 	})
	}
	// 定义获取所有数据函数
	const GetAllData = async () => {
		await GetTableData()
		await GetComboxData()
	}

	let tableheight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight))
	// 定义加载事件
	onMounted(async () => {
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth - 220) +
			'px'
		window.onresize = () => {
			tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight)
			document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
					220) +
				'px'
		}
		let loading = proxy.$tools.ShowLoading();
		await GetAllData()
		loading.close()
	})
	onActivated(() => {
		tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight)
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
			220) + 'px'
	})
</script>

<style scoped lang="less">
</style>
