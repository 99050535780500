<template>
	<!-- 主界面，总框架 -->
	<el-container class="largeframe">
		<!-- 框架头部 -->
		<el-header class="largeheader">
			<div style="height: 60px;margin-bottom: 5px; width: 500px; text-align: left; display: flex; flex-direction: row; ">
				<div class="headerfun">
					<el-image style="width: 50px; height: 50px" src="./ammeter.png" :fit="fill" @click="changeFunModule('1')"></el-image>
					<font :style="{color:ammetercolor,fontSize:'10px'}">电 表</font>
				</div>
				<div class="headerfun" v-if="false">
					<el-image style="width: 50px; height: 50px" src="./watermeter.png" :fit="fill" @click="changeFunModule('2')"></el-image>
					<font :style="{color:watermetercolor,fontSize:'10px'}">水 表</font>
				</div>
				<div class="headerfun" v-if="false">
					<el-image style="width: 50px; height: 50px" src="./allcategory.png" :fit="fill" @click="changeFunModule('3')"></el-image>
					<font :style="{color:allcategorycolor,fontSize:'10px'}">全 部</font>
				</div>
			</div>
			<dir class="largeheadertitle">
				绿色建筑能效管理系统
			</dir>
			<div style="height: 40px; margin-bottom: 5px; width: 500px; text-align: right; margin-right: 30px;">
				<label style="color: white; font-size: 25px; font-family: 'Times New Roman', Times, serif; line-height: 40px;">{{currentDateTime}}</label>
			</div>
		</el-header>
		<!-- 框架主体 -->
		<el-main style="width: 100%;height: 100%; margin: 0;padding: 0">
			<div class="largecontent" v-if="funModule=='1'">
				<div class="largecontentleft">
					<div class="largecontentcard">
						<div class="largecontentcardtitle">
							电表设备情况
						</div>
						<div class="cardmain flexcolumn">
							<div class="flexrow" style="width: 100%; height: 60px; align-items: center; justify-content: center;">
								<div class="flexrow" style="width: 100px; height: 60px; margin-left: 30px;">
									<el-image style="width: 50px; height: 50px" src="./allcount.png" :fit="fill"></el-image>
									<div class="flexcolumn" style="width: 50px; height: 50px;">
										<div style="width: 100%; height:35px; text-align: center; font-size:25px; line-height: 35px; color: aqua;">{{ammeterCountInfo.totalCount}}</div>
										<div style="width: 100%; height:15px; text-align: center; font-size:10px; line-height: 15px; color: white;">设备总数量</div>
									</div>
								</div>
								<div class="flexrow" style="width: 100px; height: 60px; margin-left: 30px;">
									<el-image style="width: 50px; height: 50px" src="./onlinecount.png" :fit="fill"></el-image>
									<div class="flexcolumn" style="width: 50px; height: 50px;">
										<div style="width: 100%; height:35px; text-align: center; font-size:25px; line-height: 35px; color: aqua;">{{ammeterCountInfo.onlineCount}}</div>
										<div style="width: 100%; height:15px; text-align: center; font-size:10px; line-height: 15px; color: white;">在线数量</div>
									</div>
								</div>
								<div class="flexrow" style="width: 100px; height: 60px; margin-left: 30px;">
									<el-image style="width: 50px; height: 50px" src="./offlinecount.png" :fit="fill"></el-image>
									<div class="flexcolumn" style="width: 50px; height: 50px;">
										<div style="width: 100%; height:35px; text-align: center; font-size:25px; line-height: 35px; color: aqua;">{{ammeterCountInfo.offlineCount}}</div>
										<div style="width: 100%; height:15px; text-align: center; font-size:10px; line-height: 15px; color: white;">离线数量</div>
									</div>
								</div>
							</div>
							<div class="chart" ref="ammetercountchartref" style="width: 100%; height: 100%;"></div>
						</div>
					</div>
					<div class="largecontentcard">
						<div class="largecontentcardtitle">
							本日抄电表情况
						</div>
						<div class="cardmain flexcolumn">
							<div class="flexrow" style="width: 100%; height: 60px; align-items: center; justify-content: center;">
								<div class="flexrow" style="width: 100px; height: 60px; margin-left: 30px;">
									<el-image style="width: 50px; height: 50px" src="./totalread.png" :fit="fill"></el-image>
									<div class="flexcolumn" style="width: 50px; height: 50px;">
										<div style="width: 100%; height:35px; text-align: center; font-size:25px; line-height: 35px; color: aqua;">{{ammeterReadRecordCountInfo.totalCount}}</div>
										<div style="width: 100%; height:15px; text-align: center; font-size:10px; line-height: 15px; color: white;">房间总数量</div>
									</div>
								</div>
								<div class="flexrow" style="width: 100px; height: 60px; margin-left: 30px;">
									<el-image style="width: 50px; height: 50px" src="./read.png" :fit="fill"></el-image>
									<div class="flexcolumn" style="width: 50px; height: 50px;">
										<div style="width: 100%; height:35px; text-align: center; font-size:25px; line-height: 35px; color: aqua;">{{ammeterReadRecordCountInfo.readCount}}</div>
										<div style="width: 100%; height:15px; text-align: center; font-size:10px; line-height: 15px; color: white;">已抄表数量</div>
									</div>
								</div>
								<div class="flexrow" style="width: 100px; height: 60px; margin-left: 30px;">
									<el-image style="width: 50px; height: 50px" src="./noread.png" :fit="fill"></el-image>
									<div class="flexcolumn" style="width: 50px; height: 50px;">
										<div style="width: 100%; height:35px; text-align: center; font-size:25px; line-height: 35px; color: aqua;">{{ammeterReadRecordCountInfo.noReadCount}}</div>
										<div style="width: 100%; height:15px; text-align: center; font-size:10px; line-height: 15px; color: white;">未抄表数量</div>
									</div>
								</div>
							</div>
							<div class="chart" ref="ammeterreadrecordcountchartref" style="width: 100%; height:100%;;"></div>
						</div>
					</div>
					<div class="largecontentcard">
						<!-- <div class="largecontentcardtitle">
							住户通断电情况
						</div>
						<div class="cardmain flexcolumn">
							<div class="flexrow" style="width: 100%; height: 60px; align-items: center; justify-content: center;">
								<div class="flexrow" style="width: 100px; height: 60px; margin-left: 30px;">
									<el-image style="width: 50px; height: 50px" src="./totaltongduan.png" :fit="fill"></el-image>
									<div class="flexcolumn" style="width: 50px; height: 50px;">
										<div style="width: 100%; height:35px; text-align: center; font-size:25px; line-height: 35px; color: aqua;">{{ammeterOnOffCountInfo.totalCount}}</div>
										<div style="width: 100%; height:15px; text-align: center; font-size:10px; line-height: 15px; color: white;">房间总数量</div>
									</div>
								</div>
								<div class="flexrow" style="width: 100px; height: 60px; margin-left: 30px;">
									<el-image style="width: 50px; height: 50px" src="./tongdian.png" :fit="fill"></el-image>
									<div class="flexcolumn" style="width: 50px; height: 50px;">
										<div style="width: 100%; height:35px; text-align: center; font-size:25px; line-height: 35px; color: aqua;">{{ammeterOnOffCountInfo.onCount}}</div>
										<div style="width: 100%; height:15px; text-align: center; font-size:10px; line-height: 15px; color: white;">通电数量</div>
									</div>
								</div>
								<div class="flexrow" style="width: 100px; height: 60px; margin-left: 30px;">
									<el-image style="width: 50px; height: 50px" src="./duandian.png" :fit="fill"></el-image>
									<div class="flexcolumn" style="width: 50px; height: 50px;">
										<div style="width: 100%; height:35px; text-align: center; font-size:25px; line-height: 35px; color: aqua;">{{ammeterOnOffCountInfo.offCount}}</div>
										<div style="width: 100%; height:15px; text-align: center; font-size:10px; line-height: 15px; color: white;">断电数量</div>
									</div>
								</div>
							</div>
							<div class="chart" ref="ammeteronoffcountchartref" style="width: 100%; height: 100%;"></div>
						</div> -->
						<div class="largecontentcardtitle">
							可疑房间数量
						</div>
						<div class="cardmain flexcolumn">
							<div class="chart" ref="dubiousRoomChartRef" style="width: 100%; height: 100%;"></div>
						</div>
					</div>
				</div>
				<div class="largecontentcenter">
					<div class="largecontentcard">
						<div class="largecontentcardtitle">
							日总用电量曲线图（度）
						</div>
						<div class="cardmain flexcolumn">
							<div class="chart" ref="ammeterdayusechartref" style="width: 100%; height: 100%;"></div>
						</div>
					</div>
					<div class="largecontentcard">
						<div class="largecontentcardtitle">
							月总用电量曲线图（度）
						</div>
						<div class="cardmain flexcolumn">
							<div class="chart" ref="ammetermonthusechartref" style="width: 100%; height: 100%;"></div>
						</div>
					</div>
					<div class="largecontentcard">
						<div class="largecontentcardtitle">
							月电表缴费曲线图（元）
						</div>
						<div class="cardmain flexcolumn">
							<div class="chart" ref="ammetermonthpaychartref" style="width: 100%; height: 100%;"></div>
						</div>
					</div>
				</div>
				<div class="largecontentright">
					<div class="largecontentcard">
						<div class="largecontentcardtitle">
							表端余量/余额过低数量
						</div>
						<div class="cardmain flexcolumn">
							<div class="chart" ref="ammeterlowcountchartref" style="width: 100%; height: 100%;"></div>
						</div>
					</div>
					<div class="largecontentcard">
						<div class="largecontentcardtitle">
							总用电量信息（度）
						</div>
						<div class="cardmain flexcolumn">
							<div class="flexrow" style="width: 95%; height: 60px; margin: 0 auto;">
								<div class="flexrow" style="width: 100%;height: 60px">
									<el-image style="width: 60px; height: 60px" src="./yongdianliang.png" :fit="fill"></el-image>
									<div class="flexcolumn" style="flex-grow:1; height: 60px; margin-left: 20px;">
										<div style="width: 100%; height:60px; text-align: left; font-size:25px; line-height: 60px; color: aqua;">总用电量：{{ammeteruseamountinfo.totalUseAmount}}</div>
									</div>
								</div>
							</div>
							<div class="flexrow" style="width: 95%; height: 60px; margin: 0 auto; margin-top: 10px; padding-top:10px;border-top: 1px solid gray;">
								<div class="flexcolumn" style="width: 50%; height: 60px;">
									<div style="width: 100%; height:35px; text-align: center; font-size:25px; line-height: 35px; color: aqua;">{{ammeteruseamountinfo.lastYearUseAmount}}</div>
									<div style="width: 100%; height:25px; text-align: center; font-size:15px; line-height: 25px; color: white;">去年总用电量</div>
								</div>
								<div class="flexcolumn" style="width: 50%; height: 60px;">
									<div style="width: 100%; height:35px; text-align: center; font-size:25px; line-height: 35px; color: aqua;">{{ammeteruseamountinfo.thisYearUseAmount}}</div>
									<div style="width: 100%; height:25px; text-align: center; font-size:15px; line-height: 25px; color: white;">今年总用电量</div>
								</div>
							</div>
							<div class="flexrow" style="width: 95%; height: 60px; margin: 0 auto; margin-top: 10px;padding-top:10px;border-top: 1px solid gray;">
								<div class="flexcolumn" style="width: 33%; height: 60px;">
									<div style="width: 100%; height:35px; text-align: center; font-size:25px; line-height: 35px; color: aqua;">{{ammeteruseamountinfo.lastWeekUseAmount}}</div>
									<div style="width: 100%; height:25px; text-align: center; font-size:15px; line-height: 25px; color: white;">上周总用电量</div>
								</div>
								<div class="flexcolumn" style="width: 33%; height: 60px;">
									<div style="width: 100%; height:35px; text-align: center; font-size:25px; line-height: 35px; color: aqua;">{{ammeteruseamountinfo.thisWeekUseAmount}}</div>
									<div style="width: 100%; height:25px; text-align: center; font-size:15px; line-height: 25px; color: white;">本周总用电量</div>
								</div>
								<div class="flexcolumn" style="width: 33%; height: 60px;">
									<div style="width: 100%; height:35px; text-align: center; font-size:25px; line-height: 35px; color: aqua;">{{ammeteruseamountinfo.lastDayUseAmount}}</div>
									<div style="width: 100%; height:25px; text-align: center; font-size:15px; line-height: 25px; color: white;">昨日总用电量</div>
								</div>
							</div>
						</div>
					</div>
					<div class="largecontentcard">
						<div class="largecontentcardtitle">
							电表缴费信息（元）
						</div>
						<div class="cardmain flexcolumn">
							<div class="flexrow" style="width: 95%; height: 60px; margin: 0 auto;">
								<div class="flexrow" style="width: 100%;height: 60px">
									<el-image style="width: 60px; height: 60px" src="./jiaofeiliang.png" :fit="fill"></el-image>
									<div class="flexcolumn" style="flex-grow:1; height: 60px; margin-left: 20px;">
										<div style="width: 100%; height:60px; text-align: left; font-size:25px; line-height: 60px; color: aqua;">总缴费量：{{ammeterpayamountinfo.totalPayAmount}}</div>
									</div>
								</div>
							</div>
							<div class="flexrow" style="width: 95%; height: 60px; margin: 0 auto; margin-top: 10px; padding-top:10px;border-top: 1px solid gray;">
								<div class="flexcolumn" style="width: 50%; height: 60px;">
									<div style="width: 100%; height:35px; text-align: center; font-size:25px; line-height: 35px; color: aqua;">{{ammeterpayamountinfo.lastYearPayAmount}}</div>
									<div style="width: 100%; height:25px; text-align: center; font-size:15px; line-height: 25px; color: white;">去年总缴费量</div>
								</div>
								<div class="flexcolumn" style="width: 50%; height: 60px;">
									<div style="width: 100%; height:35px; text-align: center; font-size:25px; line-height: 35px; color: aqua;">{{ammeterpayamountinfo.thisYearPayAmount}}</div>
									<div style="width: 100%; height:25px; text-align: center; font-size:15px; line-height: 25px; color: white;">今年总缴费量</div>
								</div>
							</div>
							<div class="flexrow" style="width: 95%; height: 60px; margin: 0 auto; margin-top: 10px;padding-top:10px;border-top: 1px solid gray;">
								<div class="flexcolumn" style="width: 25%; height: 60px;">
									<div style="width: 100%; height:35px; text-align: center; font-size:25px; line-height: 35px; color: aqua;">{{ammeterpayamountinfo.lastWeekPayAmount}}</div>
									<div style="width: 100%; height:25px; text-align: center; font-size:15px; line-height: 25px; color: white;">上周总缴费量</div>
								</div>
								<div class="flexcolumn" style="width: 25%; height: 60px;">
									<div style="width: 100%; height:35px; text-align: center; font-size:25px; line-height: 35px; color: aqua;">{{ammeterpayamountinfo.thisWeekPayAmount}}</div>
									<div style="width: 100%; height:25px; text-align: center; font-size:15px; line-height: 25px; color: white;">本周总缴费量</div>
								</div>
								<div class="flexcolumn" style="width: 25%; height: 60px;">
									<div style="width: 100%; height:35px; text-align: center; font-size:25px; line-height: 35px; color: aqua;">{{ammeterpayamountinfo.lastDayPayAmount}}</div>
									<div style="width: 100%; height:25px; text-align: center; font-size:15px; line-height: 25px; color: white;">昨日总缴费量</div>
								</div>
								<div class="flexcolumn" style="width: 25%; height: 60px;">
									<div style="width: 100%; height:35px; text-align: center; font-size:25px; line-height: 35px; color: aqua;">{{ammeterpayamountinfo.thisDayPayAmount}}</div>
									<div style="width: 100%; height:25px; text-align: center; font-size:15px; line-height: 25px; color: white;">今日总缴费量</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- <div class="largecontent" v-if="funModule=='2'">
				<div class="largecontentleft">
					<el-card class="largecontentcard" :body-style="{padding:'0px'}">
						<template #header>设备情况</template>
						<div ref="chartsref" style="width: 100%; height: 200px;">
							
						</div>
					</el-card>
				</div>
				<div class="largecontentcenter">
					456
				</div>
				<div class="largecontentright">
					456
				</div>
			</div> -->
			<!-- <div class="largecontent" v-if="funModule=='3'">
				<div class="largecontentleft">
					<el-card class="largecontentcard" :body-style="{padding:'0px'}">
						<template #header>设备情况</template>
						<div ref="chartsref" style="width: 100%; height: 200px;">
							
						</div>
					</el-card>
				</div>
				<div class="largecontentcenter">
					789
				</div>
				<div class="largecontentright">
					789
				</div>
			</div> -->
		</el-main>
	</el-container>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted,
		onUnmounted,
		onActivated,
		getCurrentInstance
	} from 'vue'
	import {
		ElMessageBox
	} from 'element-plus'
	import {
		GetServerData
	} from '@/http/index.js'
	// 获取全局this，proxy相当于vue2中的this，用于调用全局对象使用
	const {
		proxy
	} = getCurrentInstance()
	
	let ammetercountchartref=ref(null);
	let ammeterreadrecordcountchartref=ref(null);
	//let ammeteronoffcountchartref=ref(null);
	let ammeterlowcountchartref=ref(null);
	let ammeterdayusechartref=ref(null);
	let ammetermonthusechartref=ref(null);
	let ammetermonthpaychartref=ref(null);
	let dubiousRoomChartRef=ref(null);
	
	let ammetercountchart=null;
	let ammeterreadrecordcountchart=null;
	//let ammeteronoffcountchart=null;
	let ammeterlowcountchart=null;
	let ammeterdayusechart=null;
	let ammetermonthusechart=null;
	let ammetermonthpaychart=null;
	let dubiousRoomChart=null;
	
	let currentDateTime=ref('');
	let timeTimer=null;
	let ammeterTimer=null;
	let watermeterTimer=null;
	let allMeterTimer=null;
	
	let funModule=ref('1');
	let ammetercolor=ref('orange')
	let watermetercolor=ref('white')
	let allcategorycolor=ref('white')
	
	let ammeterCountInfo=ref({
		onlineCount:0,
		offlineCount:0,
		totalCount:0
	})
	let ammeterReadRecordCountInfo=ref({
		noReadCount:0,
		readCount:0,
		totalCount:0,
		unknownCount:0
	})
	let ammeterOnOffCountInfo=ref({
		onCount:0,
		offCount:0,
		totalCount:0,
		unknownCount:0
	})
	let ammeterLowCountInfo=ref({
		lowCount:0,
		totalCount:0,
		unknownCount:0
	})
	let dubiousRoomsCountInfo=ref({
		dubiousCount:0,
		totalCount:0
	})
	let ammeteruseamountinfo=ref({
		totalUseAmount:0,
		lastYearUseAmount:0,
		thisYearUseAmount:0,
		lastWeekUseAmount:0,
		thisWeekUseAmount:0,
		lastDayUseAmount:0
	})
	let ammeterpayamountinfo=ref({
		
	})
	
	let param={
		key:'F228F9E1E4C04653B68588CA0896691F'
	}
	
	const changeFunModule=async(module)=>{
		if(funModule.value!=module){
			funModule.value=module;
			let loading = proxy.$tools.ShowLoading();
			clearAmmeterTimer();
			clearWatermeterTimer();
			clearAllMeterTimer();
			ammetercolor.value='white';
			watermetercolor.value='white';
			allcategorycolor.value='white';
			switch(funModule.value){
				case '1':
					ammetercolor.value='orange';
					await getAmmeterData();
					startAmmeterTimer();
					break;
				case '2':
					watermetercolor.value='orange';
					await getWatermeterData();
					startWatermeterTimer();
					break;
				case '3':
					allcategorycolor.value='orange';
					await getAllMeterData();
					startAllMeterTimer();
					break;
			}
			loading.close()
		}
	}
	
	const getAmmeterData=async ()=>{
		let response = await GetServerData('LargeScreen/QueryAmmeterData', param)
		if (response.status == 1) {
			console.log(response.data);
			ammeterCountInfo.value=response.data.meterCount;
			//设备在线情况图表-饼图
			let ammetercountcolor=['#80FFA5','#ef5767'];
			let ammetercountdata=[
				{
					name:'在线数量',
					value:ammeterCountInfo.value.onlineCount
				},
				{
					name:'离线数量',
					value:ammeterCountInfo.value.offlineCount
				}
			];
			ammetercountchart=proxy.$tools.DrawChart(ammetercountchartref.value,proxy.$chartTools.GetPieOption('电表在线情况',ammetercountdata,'台',ammetercountcolor));
			//住户抄表情况图表-饼图
			ammeterReadRecordCountInfo.value=response.data.readRecordCount;
			let ammeterreadrecordcountcolor=['#80FFA5','#ef5767','#8b8b8b'];
			let ammeterreadrecordcountdata=[
				{
					name:'已抄表数量',
					value:ammeterReadRecordCountInfo.value.readCount
				},
				{
					name:'未抄表数量',
					value:ammeterReadRecordCountInfo.value.noReadCount
				}
				// ,
				// {
				// 	name:'未知数量',
				// 	value:ammeterReadRecordCountInfo.value.unknownCount
				// }
			];
			ammeterreadrecordcountchart=proxy.$tools.DrawChart(ammeterreadrecordcountchartref.value,proxy.$chartTools.GetPieOption('本日抄电表情况',ammeterreadrecordcountdata,'户',ammeterreadrecordcountcolor));
			//住户通断电情况图表-饼图
			// ammeterOnOffCountInfo.value=response.data.onOffCount;
			// let ammeteronoffcountcolor=['#80FFA5','#ef5767','#8b8b8b'];
			// let ammeteronoffcountdata=[
			// 	{
			// 		name:'通电数量',
			// 		value:ammeterOnOffCountInfo.value.onCount
			// 	},
			// 	{
			// 		name:'断电数量',
			// 		value:ammeterOnOffCountInfo.value.offCount
			// 	}
			// 	// ,
			// 	// {
			// 	// 	name:'未知数量',
			// 	// 	value:ammeterOnOffCountInfo.value.unknownCount
			// 	// }
			// ];
			//ammeteronoffcountchart=proxy.$tools.DrawChart(ammeteronoffcountchartref.value,proxy.$chartTools.GetPieOption('住户通断电情况',ammeteronoffcountdata,'户',ammeteronoffcountcolor));
			//住户低余量/余额图表-仪表盘图
			ammeterLowCountInfo.value=response.data.lowCount;
			let ammeterlowcountcolor=['#ee6666','#ffffff'];
			let ammeterlowcountdata=[
				{
					value:ammeterLowCountInfo.value.lowCount
				}
			];
			
			dubiousRoomsCountInfo.value=response.data.dubiousRoomCount;
			let dubiousRoomscountcolor=['#ee6666','#ffffff'];
			let dubiousRoomsdata=[
				{
					value:dubiousRoomsCountInfo.value.dubiousCount
				}
			];
			
			ammeterlowcountchart=proxy.$tools.DrawChart(ammeterlowcountchartref.value,proxy.$chartTools.GetGaugeOption('表端余量/余额过低数量',ammeterlowcountdata,'户',ammeterlowcountcolor,ammeterLowCountInfo.value.totalCount,1));
			dubiousRoomChart=proxy.$tools.DrawChart(dubiousRoomChartRef.value,proxy.$chartTools.GetGaugeOption('可疑房间数量',dubiousRoomsdata,'户',dubiousRoomscountcolor,dubiousRoomsCountInfo.value.totalCount,1));
			ammeterdayusechart=proxy.$tools.DrawChart(ammeterdayusechartref.value,proxy.$chartTools.GetSingleLineOption('用电量曲线图（日）','度',response.data.dayUseAmount.xAxisData,response.data.dayUseAmount.yAxisData,'#80FFA5'));
			ammetermonthusechart=proxy.$tools.DrawChart(ammetermonthusechartref.value,proxy.$chartTools.GetSingleLineOption('用电量曲线图（月）','度',response.data.monthUseAmount.xAxisData,response.data.monthUseAmount.yAxisData,'#80FFA5'));
			ammetermonthpaychart=proxy.$tools.DrawChart(ammetermonthpaychartref.value,proxy.$chartTools.GetSingleLineOption('缴费曲线图（电费/月）','元',response.data.monthPayAmount.xAxisData,response.data.monthPayAmount.yAxisData,'#f48d5a'));
			ammeteruseamountinfo.value=response.data.useAmountInfo;
			ammeterpayamountinfo.value=response.data.payAmountInfo;
		}else{
			proxy.$tools.ShowMessage(response.message, false)
		}
	}
	
	const getWatermeterData=async ()=>{
		
	}
	
	const getAllMeterData=async ()=>{
		
	}
	
	const clearTimeTimer=()=>{
		try{
			clearInterval(timeTimer);
		}catch{
			
		}
	}
	
	const startAmmeterTimer=()=>{
		ammeterTimer=setInterval(async()=>{
			await getAmmeterData();
		},1000*60*10);
	}
	
	const clearAmmeterTimer=()=>{
		try{
			clearInterval(ammeterTimer);
		}catch{
			
		}
	}
	
	const startWatermeterTimer=()=>{
		watermeterTimer=setInterval(async()=>{
			await getWatermeterData();
		},1000*60*10);
	}
	
	const clearWatermeterTimer=()=>{
		try{
			clearInterval(watermeterTimer);
		}catch{
			
		}
	}
	
	const startAllMeterTimer=()=>{
		allMeterTimer=setInterval(async()=>{
			await getAllMeterData();
		},1000*60*10);
	}
	
	const clearAllMeterTimer=()=>{
		try{
			clearInterval(allMeterTimer);
		}catch{
			
		}
	}
	
	

	onMounted(async () => {
		currentDateTime.value=proxy.$tools.GetCurrentDateTime();
		timeTimer=setInterval(()=>{
			currentDateTime.value=proxy.$tools.GetCurrentDateTime();
		},1000);
		window.onresize = () => {
			ammetercountchart.resize();
			ammeterreadrecordcountchart.resize();
			//ammeteronoffcountchart.resize();
			dubiousRoomChart.resize();
			ammeterlowcountchart.resize();
			ammeterdayusechart.resize();
			ammetermonthusechart.resize();
			ammetermonthpaychart.resize();
		}
		let loading = proxy.$tools.ShowLoading();
		await getAmmeterData();
		loading.close()
		startAmmeterTimer();
	})
	onUnmounted(()=>{
	    // 清除定时器以避免内存泄漏
		clearTimeTimer();
		clearAmmeterTimer();
		clearWatermeterTimer();
		clearAllMeterTimer();
	}) 
	
</script>

<style scoped lang="less">
	// 主界面，总框架
	.largeframe {
		height:100%;
		width: 100%;
		min-height: 900px;
		min-width: 1920px;
		margin: 0px;
		padding: 0px;
		background-color: #193661 !important;
		z-index: 999;
		overflow:hidden;
	}
	
	// 框架头部 
	.largeheader {
		height: 70px;
		margin: 0px;
		padding: 0px;
		//background-color: #0a1b33;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-end;
	}
	.headerfun{
		display: flex; 
		flex-direction: column; 
		align-items: center;
		margin-left: 30px; 
		cursor:pointer;
	}
	.largeheadertitle{
		height: 70px;
		width: 650px;
		background-image: url("../../public/largetitle.png");
		background-repeat: no-repeat;
		background-size:650px 70px;
		text-align: center;
		letter-spacing: 10px;
		line-height: 70px;
		color: white;
		font-size: 35px;
		font-weight: 600;
	}
	
	.largecontent{
		overflow:hidden;
		margin: 0px;
		padding: 0px;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-around;
		align-items: stretch;
		width: 100%;
		height: 100%;
	}
	.largecontentleft{
		display: flex;
		flex-direction: column; 
		width: 25vw;
		border-radius: 10px;
		height: 100%;
	}
	.largecontentcenter{
		display: flex;
		flex-direction: column; 
		width: 48vw;
		border-radius: 10px;
		height: 100%;
	}
	.largecontentright{
		display: flex;
		flex-direction: column;
		width: 25vw;
		border-radius: 10px;
		height: 100%;
		margin-right: 10px;
	}
	
	.largecontentcard{
		margin: 5px;
		background-color: #0a1b33;
		display: flex;
		flex-direction: column;
		border-radius: 10px;
		height: 30vh;
		width: 100%;
	}
	.largecontentcardtitle{
		text-align: center;
		color: white;
		width: 100%;
		height: 40px;
		font-size: 20px;
		line-height: 40px;
		letter-spacing: 10px;
		border-bottom: 2px solid #193661;
	}
	.cardmain{
		width: 100%;
		margin-top: 10px;
		flex-grow:1;
	}
	.chart{
		margin-top: 10px;
	}
	.flexrow{
		display: flex;
		flex-direction: row;
	}
	.flexcolumn{
		display: flex;
		flex-direction: column;
	}
	::v-deep .el-card__header {
		border: 1px solid #182941;
		font: 600;
		font-size: 30px;
		letter-spacing: 10px;
		text-align: left;
		line-height: 50px;
		margin: 0px;
		padding: 0px;
		padding-left: 10px;
		color: white;
	}
</style>