<template>
	<div class="contentheader">
		<el-space>
			<span class="titleClass">可疑房间查询</span>
			<el-divider direction="vertical" />
			<el-button type="primary" size="small" class="buttonforheader" @click="GetAllData">查 询</el-button>
			<el-divider direction="vertical" />
			<el-button type="info" size="small" class="buttonforheader" @click="ExportExcel">导 出</el-button>
		</el-space>
	</div>
	<div style="display: flex; flex-direction: row; flex-wrap: nowrap; align-items: flex-start;margin: 10px;">
		<div id="divTableData" style="width: 100%;border-radius: 6px;background-color: white; margin-left: 5px; overflow-y: scroll;" class="divTableDataClass">
			<el-table :data="tableDatas" fit="false" highlight-current-row="true" ref="multipleTable"
				tooltip-effect="dark" row-key="id" :header-cell-style="headerCellStype" :height="tableheight"
				:cell-style="contentCellStyle" style="cursor: pointer; width:100%;" id="elTableData"
				:row-class-name="tableRowClassName" >
				<el-table-column fixed prop="id" label="id" width="0" v-if="false" />
				<el-table-column prop="areaName1" label="一级区域" width="120" />
				<el-table-column prop="areaName2" label="二级区域" width="120" />
				<el-table-column prop="areaName3" label="三级区域" width="120" />
				<el-table-column prop="roomName" label="房间名称" width="150" />
				<el-table-column prop="roomNum" label="房间编号" width="150" />
				<el-table-column prop="familyName" label="户主名称" width="150" />
				<el-table-column prop="familyPhone" label="户主电话" width="150" />
				<el-table-column prop="reason" label="可疑原因" width="620" />
				<el-table-column />
			</el-table>
		</div>
	</div>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted,
		onActivated,
		getCurrentInstance
	} from 'vue'
	import {
		ElMessageBox
	} from 'element-plus'
	import {
		GetServerData
	} from '@/http/index.js'
	// 获取全局this，proxy相当于vue2中的this，用于调用全局对象使用
	const {
		proxy
	} = getCurrentInstance()
	
	let headerCellStype = ref(proxy.$headerCellStype)
	let contentCellStyle = ref(proxy.$contentCellStyle)
	// 定义表格数据
	let tableDatas = ref([])

	// 定义表格引用，用来执行多选
	const multipleTable = ref()

	// 定义表格隔行变色，暂时没用到
	const tableRowClassName = ({
		row,
		rowIndex
	}) => {
		if (rowIndex % 2 === 0) {
			return 'even-row'
		} else {
			return 'odd-row'
		}
	}

	const ExportExcel = () => {
		let fileName="可疑房间列表.xlsx";
		proxy.$tools.ExportExcel(document.querySelector("#elTableData"),fileName)
	}

	// 定义获取表格数据函数
	const GetData = async () => {
		let response = await GetServerData('DubiousRoom/QueryAllDubiousRooms')
		if (response.status == 1) {
			tableDatas.value = response.data
		} else {
			tableDatas.value =[];
			proxy.$tools.ShowMessage(response.message, false)
		}
	}

	// 定义获取所有数据函数
	const GetAllData = async () => {
		await GetData()
	}
	
	let tableheight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60))
	
	const resetSize=()=>{
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth - 225) + 'px';
		document.getElementById("divTableData").style.height = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)+'px'
	}

	// 定义加载事件
	onMounted(async () => {
		resetSize();
		window.onresize = () => {
			resetSize();
		}
		let loading = proxy.$tools.ShowLoading();
		await GetAllData()
		loading.close()
	})
	onActivated(()=>{
		resetSize();
	})
</script>

<style scoped lang="less">

</style>
