<template>
	<div class="contentheader">
		<el-space wrap>
			<span class="titleClass">短信模板</span>
			<el-divider direction="vertical" />
			<el-button type="primary" size="small" class="buttonforheader" @click="refresh">刷 新</el-button>
		</el-space>
	</div>
	<div id="divTableData" class="contentcontent">
		<el-table :data="tableDatas" fit="false" highlight-current-row="true" ref="multipleTable" tooltip-effect="dark"
			:height="tableheight" row-key="id" default-expand-all :header-cell-style="headerCellStype"
			:cell-style="contentCellStyle" style="width: 100%; cursor: pointer;" :row-class-name="tableRowClassName"
			>
			<el-table-column fixed prop="id" label="id" width="0" v-if="false" />
			<el-table-column fixed prop="category" label="category" width="0" v-if="false" />
			<el-table-column fixed prop="status" label="status" width="0" v-if="false" />
			<el-table-column prop="categoryName" label="模板类型" width="100" align="center" />
			<el-table-column prop="content" label="模板内容" width="850" align="left" />
			<el-table-column prop="remark" label="备注" width="200" align="left" />
			<el-table-column prop="muName" label="修改人" width="120" />
			<el-table-column prop="mt" label="修改时间" width="150" align="center" />
			<el-table-column />
			<el-table-column fixed="right" label="操作" width="100" align="left">
				<template #default="scope">
					<el-button size="mini" @click.stop="modify(scope.row)" type="success" class="buttonfortable">设 置
					</el-button>
				</template>
			</el-table-column>
		</el-table>
	</div>
	<el-dialog width="500px" v-model="dialogVisible" append-to-body=true :title="dialogtitle" center draggable
		:close-on-click-modal="false" :show-close="true">
		<el-form :model="formdata" ref="formRef" class="demo-ruleForm formContent">
			<el-form-item label="模板类型" :label-width="formLabelWidth">
				<el-select v-model="formdata.category" placeholder="请选择模板类型" :style="formContentStyle" disabled >
					<el-option key="1" :label="ammeterName1+'低电量'" value="1" />
					<el-option key="2" :label="ammeterName2+'低电量'" value="2" />
					<el-option key="3" :label="watermeterName1+'低水量'" value="3" />
					<el-option key="4" :label="watermeterName2+'低水量'" value="4" />
					<el-option key="5" label="低余额" value="5" />
					<el-option key="6" label="余额不足" value="6" />
				</el-select>
			</el-form-item>
			<el-form-item prop="content" :rules="[{ required: true, message: '请输入模板内容' }]" label="模板内容 ( 大括号{}以及大括号{}内的内容不能修改,其他内容可以修改 ) "
				:label-width="formLabelWidth">
				<el-input v-model="formdata.content" autocomplete="off" :style="formContentStyle" type="textarea" :rows="10"
					placeholder="请输入模板内容" maxlength="200"/>
			</el-form-item>
			<el-form-item prop="remark" label="备注"
				:label-width="formLabelWidth">
				<el-input v-model="formdata.remark" autocomplete="off" :style="formContentStyle" type="textarea" :rows="2"
					placeholder="请输入备注" />
			</el-form-item>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button size="mini" type="primary" @click="submitForm(formRef)" class="buttonforform"> 确 定
				</el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted,
		onActivated,
		getCurrentInstance
	} from 'vue'
	import {
		ElMessageBox
	} from 'element-plus'
	import {
		GetServerData
	} from '@/http/index.js'
	// 获取全局this，proxy相当于vue2中的this，用于调用全局对象使用
	const {
		proxy
	} = getCurrentInstance()

	let ammeterType=ref(localStorage.getItem("ammeterType"));
	let ammeterName1=ref(localStorage.getItem("ammeterName1"));
	let ammeterName2=ref(localStorage.getItem("ammeterName2"));
	
	let watermeterType=ref(localStorage.getItem("watermeterType"));
	let watermeterName1=ref(localStorage.getItem("watermeterName1"));
	let watermeterName2=ref(localStorage.getItem("watermeterName2"));
	
	// 定义表单标题
	let dialogtitle = ref("短信模板设置")
	// 定义表单数据
	let formdata = reactive({
		id: '',
		category: '',
		content:'',
		remark:''
	})

	// 定义表单显示与隐藏
	let dialogVisible = ref(false)
	let tableDatas = ref([])
	let headerCellStype = ref(proxy.$headerCellStype)
	let contentCellStyle = ref(proxy.$contentCellStyle)
	// 定义选中的表格行
	let multipleSelection = reactive([])
	// 定义表格引用，用来执行多选
	const multipleTable = ref()
	// 定义表单引用，用来执行表单验证
	const formRef = ref()
	// 定义表单项的头部宽度
	const formLabelWidth = ref('150px')
	// 定义表单项的内容宽度
	const formContentStyle = ref('width:300px')

	// 定义表格隔行变色，暂时没用到
	const tableRowClassName = ({
		row,
		rowIndex
	}) => {
		if (rowIndex % 2 === 0) {
			return 'even-row'
		} else {
			return 'odd-row'
		}
	}
	const toggleSelection = (rows) => {
		if (rows) {
			rows.forEach(row => {
				multipleTable.value.toggleRowSelection(row);
			});
		} else {
			multipleTable.value.clearSelection();
		}
	}
	const sizechange = async () => {
		params.pageNum = 1
		let loading = proxy.$tools.ShowLoading();
		await GetTableData()
		loading.close()
	}
	const currentchange = async () => {
		let loading = proxy.$tools.ShowLoading();
		await GetTableData()
		loading.close()
	}
	
	// 定义表单提交事件，带表单验证
	const submitForm = (form) => {
		form.validate(async (valid) => {
			if (valid) {
					let loading = proxy.$tools.ShowLoading();
					if (formdata.id.length > 0) {
						console.log(formdata)
						let response = await GetServerData('SMSTemplet/Modify', formdata)
						if (response.status == 1) {
							proxy.$tools.ShowMessage('设置成功', true)
							dialogVisible.value = false
							await GetAllData();
						} else {
							proxy.$tools.ShowMessage(response.message, false)
						}
					}
					loading.close()
			} else {
				proxy.$tools.ShowMessage('请您正确填写所有必填信息后，再提交！', false)
				return false;
			}
		})
	}
	// 定义编辑按钮事件
	const modify = (row) => {
		try {
			formRef.value.resetFields();
		} catch (e) {

		}

		formdata.id = row.id;
		formdata.category = row.category;
		formdata.content=row.content;
		formdata.remark=row.remark;
		dialogVisible.value = true
	}

	// 定义获取表格数据函数
	const GetTableData = async () => {
		let response = await GetServerData('SMSTemplet/QueryAll')
		if (response.status == 1) {
			tableDatas.value = response.data
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}
	// 定义获取所有数据函数
	const GetAllData = async () => {
		await GetTableData()
	}
	
	const refresh=async ()=>{
		let loading = proxy.$tools.ShowLoading();
		tableDatas.value=[];
		await GetAllData()
		loading.close()
	}

	let tableheight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60))
	// 定义加载事件
	onMounted(async () => {
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth - 220) +
			'px'
		window.onresize = () => {
			tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
			document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
					220) +
				'px'
		}
		let loading = proxy.$tools.ShowLoading();
		await GetAllData()
		loading.close()
	})
	onActivated(() => {
		tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
				220) +
			'px'
	})
</script>

<style scoped lang="less">
</style>
