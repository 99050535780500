<template>
	<div class="contentheader">
		<el-space wrap>
			<span class="titleClass">运行信息</span>
			<el-divider direction="vertical" />
			<el-button type="primary" size="small" class="buttonforheader" @click="search">刷 新</el-button>
		</el-space>
	</div>
	<div id="divTableData" class="contentcontent">
		<el-table :data="tableDatas" fit="false" highlight-current-row="true" ref="multipleTable" tooltip-effect="dark"
			:height="tableheight" row-key="uniqueCode"
			:header-cell-style="headerCellStype" :cell-style="contentCellStyle" style="width: 100%; cursor: pointer;"
			:row-class-name="tableRowClassName">
			<el-table-column prop="estateName" label="住宅区名称" width="250" align="center" />
			<el-table-column prop="name" label="DTU名称" width="250" />
			<el-table-column prop="location" label="安装位置" width="350" />
			<el-table-column prop="uniqueCode" label="唯一编码" width="200" align="center" />
			<el-table-column prop="status" label="状态" width="100" align="center"/>
			<el-table-column prop="lastRegisterTime" label="上次注册时间" width="200" align="center"/>
			<el-table-column prop="lastHeartbeatTime" label="上次心跳时间" width="200" align="center"/>
			<el-table-column />
		</el-table>
	</div>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted,
		onActivated,
		getCurrentInstance
	} from 'vue'
	import {
		ElMessageBox
	} from 'element-plus'
	import {
		GetServerData
	} from '@/http/index.js'
	// 获取全局this，proxy相当于vue2中的this，用于调用全局对象使用
	const {
		proxy
	} = getCurrentInstance()

	// 定义表格数据
	let tableDatas = ref([])
	let headerCellStype = ref(proxy.$headerCellStype)
	let contentCellStyle = ref(proxy.$contentCellStyle)
	
	// 定义表格隔行变色，暂时没用到
	const tableRowClassName = ({
		row,
		rowIndex
	}) => {
		if (rowIndex % 2 === 0) {
			return 'even-row'
		} else {
			return 'odd-row'
		}
	}
	
	// 定义查询按钮事件
	const search = async () => {
		let loading = proxy.$tools.ShowLoading();
		let response = await GetServerData('SystemMonitor/GetSystemInfo')
		if (response.status == 1) {
			tableDatas.value=response.data
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
		loading.close()
	}

	let tableheight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight+ 60))
	// 定义加载事件
	onMounted(async () => {
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth - 220) +
			'px'
		window.onresize = () => {
			tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight+ 60)
			document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
					220) +
				'px'
		}
		let loading = proxy.$tools.ShowLoading();
		await search()
		loading.close()
	})
	onActivated(() => {
		tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight+ 60)
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
			220) + 'px'
	})
</script>

<style scoped lang="less">
</style>
