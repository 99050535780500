<template>
	<!-- 主界面，总框架 -->
	<el-container class="frame">
		<!-- 框架头部 -->
		<el-header class="freamheader">
			<div style="display: flex;">
				<div class="freamheaderleft">
					<el-image style="width: 100%; height: 100%" :src="iconPath" :fit="fill" v-if="showIcon"></el-image>
				</div>
				<div class="freamheaderright">
					<table border="0" cellpadding="0" cellspacing="0" class="tableheader" style="width: 100%;">
						<tr>
							<td style="width: 600px; text-align: left; font-size: 23px; color: #fff; padding-left:20px; letter-spacing: 10px;"> 绿色建筑能效管理系统 </td>
							<td></td>
							<td style="width: 600px; user-select: none; text-align: right; padding-right: 30px;font-size: 20px; color: #fff; letter-spacing: 5px;">
								{{adminFlag?'':userestatename}}
							</td>
							<!-- <td style="width: 100px; user-select: none;" class="headertabletdclick">
								<router-link :to="{path:'/LargeScreen'}" target='_blank'>[大 屏 页 面]</router-link>
							</td> -->
							<td @click="openlargescreen" style="width: 100px; user-select: none;" class="headertabletdclick" v-if="!adminFlag">
								[大 屏 页 面]
							</td>
							<td @click="fullscreenshow" style="width: 100px; user-select: none;" class="headertabletdclick">
								{{(!fullscreen?"[全 屏 显 示]":"[取 消 全 屏]")}}
							</td>
							<td style="width: 150px;" class="headertabletdclick">
								<el-popover popper-class="popoverStyle" placement="bottom" :width="200" trigger="hover">
									<template #reference>
										<table border="0" cellpadding="0" cellspacing="0">
											<tr>
												<td style="width: 45px; text-align: center;">
													<el-image style="width: 30px; height: 30px" src="./person.png" :fit="fill"></el-image>
												</td>
												<td style="text-align: left; padding-left: 5px; width: 100px;">
													{{username}}
												</td>
											</tr>
										</table>
									</template>
									<div class="headerdivclick">
										<!-- <div class="headerdivclickitem" @click="personalCenter">个 人 中 心</div> -->
										<div class="headerdivclickitem" @click="changePass">修 改 密 码</div>
										<!-- <div class="headerdivclickitem" @click="bindWX">绑 定 微 信</div> -->
										<el-divider style="margin: 5px;" />
										<div class="headerdivclickitem" @click="logOff">退 出 登 录</div>
									</div>
								</el-popover>
							</td>
						</tr>
					</table>
				</div>
			</div>
		</el-header>
		<!-- 框架主体 -->
		<el-container>
			<!-- 主体菜单 -->
			<el-aside class="frameaside">
				<!-- 滚动区域，需要设置此组件的高度 -->
				<el-scrollbar :height="asideheight">
					<el-menu active-text-color="#409EFF" background-color="#606266" class="el-menu-vertical-demo" :default-active="activeIndex"
						text-color="#fff">
						<el-submenu :index="String(index1)" v-for="(item,index1) in menugroups" popper-offset="0">
							<template #title style="height: 30px !important;">
								<span class="groupTitle">{{item.name}}</span>
							</template>
							<el-menu-item :index='index1+"-"+index2' v-for="(menuitem,index2) in item.menuItems"
								@click="onMenuClick(menuitem)">
								<el-icon>
									<setting />
								</el-icon>
								<span class="itemTitle">{{menuitem.name}}</span>
							</el-menu-item>
						</el-submenu>
					</el-menu>
				</el-scrollbar>
			</el-aside>
			<!-- 主体内容 -->
			<el-main class="framemain">
				<el-container class="mainframe">
					<!-- 内容头部，可以用来放标签页 -->
					<el-header class="mainheader">
						<el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleTabsClick"
							@tab-remove="removeTab">
							<el-tab-pane label="首页" name="/Index" key="/Index" :closable="false">
								<template #label>
									<span class="tabTitileClass">首 页</span>
								</template>
							</el-tab-pane>
							<el-tab-pane v-for="item in routerTabs" :label="item.title" :name="item.key" :key="item.key"
								:closable="true">
								<template #label>
									<span class="tabTitileClass">{{item.title}}</span>
								</template>
							</el-tab-pane>
						</el-tabs>
					</el-header>
					<!-- 内容主体 -->
					<el-main class="mainmain">
						<!-- router-view></router-view> -->
						<router-view v-slot="{ Component }">
							<keep-alive>
								<component :is="Component" />
							</keep-alive>
						</router-view>
					</el-main>
				</el-container>
			</el-main>
		</el-container>
	</el-container>

	<el-dialog width="500px" v-model="changePsssDialogVisible" append-to-body=true title="修改密码"
		:close-on-click-modal="false" :show-close="true">
		<el-form :model="changePsssformdata" ref="changePsssformRef" class="demo-ruleForm">
			<el-form-item prop="oldPass" :rules="[{ required: true, message: '请输入旧密码' }]" label="旧密码"
				:label-width="formLabelWidth">
				<el-input v-model="changePsssformdata.oldPass" type="password" autocomplete="off" show-password
					:style="formContentStyle" placeholder="请输入旧密码" />
			</el-form-item>
			<el-form-item prop="newPass1" :rules="[{ required: true, message: '请输入新密码' }]" label="新密码"
				:label-width="formLabelWidth">
				<el-input v-model="changePsssformdata.newPass1" type="password" autocomplete="off" show-password
					:style="formContentStyle" placeholder="请输入新密码" />
			</el-form-item>
			<el-form-item prop="newPass2" :rules="[{ required: true, message: '请再次输入新密码' }]" label="确认新密码"
				:label-width="formLabelWidth">
				<el-input v-model="changePsssformdata.newPass2" type="password" autocomplete="off" show-password
					:style="formContentStyle" placeholder="请再次输入新密码" />
			</el-form-item>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button @click="changePsssDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="submitChangePassForm(changePsssformRef)">修改密码</el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted,
		getCurrentInstance
	} from "vue";
	import router from "@/router"
	import {
		ElMessageBox
	} from 'element-plus'
	import {
		GetServerData
	} from '@/http/index.js'
	const {
		proxy
	} = getCurrentInstance()
	const instance= getCurrentInstance();

	let activeIndex=ref('')
	let routerTabs = ref([]);
	const activeName = ref('/Index')
	let iconPath=ref('./headersign.png');
	let showIcon=ref(true);
	
	const SelectMenu=(path)=>{
		let flag=false;
		for (let i = 0; i < menugroups.value.length; i++) {
			for (let j = 0; j < menugroups.value[i].menuItems.length; j++) {
				if(menugroups.value[i].menuItems[j].path==path){
					activeIndex.value=i+'-'+j;
					flag=true;
					break;
				}
			}
			if(flag){
				break;
			}
		}
		if(!flag){
			activeIndex.value='';
		}
	}
	
	const handleTabsClick = (tab, event) => {
		router.replace(tab.props.name)
		SelectMenu(tab.props.name)
	}
	const removeTab = (name) => {
		//console.log(name);
		//console.log(instance.$vnode.parent.componentInstance.cache)
		let index = -1;
		for (let i = 0; i < routerTabs.value.length; i++) {
			if (routerTabs.value[i].key == name) {
				index = i;
				break;
			}
		}
		
		if (routerTabs.value[index].key == activeName.value) {
			routerTabs.value.splice(index, 1);
			if (routerTabs.value.length == 0) {
				activeName.value = "/Index"
				router.replace("/Index")
			} else {
				if (index == routerTabs.value.length) {
					activeName.value = routerTabs.value[index - 1].key;
					router.replace(routerTabs.value[index - 1].key);
				} else if (index == 0) {
					activeName.value = routerTabs.value[0].key;
					router.replace(routerTabs.value[0].key);
				} else {
					activeName.value = routerTabs.value[index].key;
					router.replace(routerTabs.value[index].key);
				}
			}
			//console.log(activeName.value)
			SelectMenu(activeName.value)
		} else {
			routerTabs.value.splice(index, 1);
		}
	}


	// 定义菜单数组
	let menugroups = ref()
	// 从缓存中取出菜单数组
	menugroups.value = JSON.parse(localStorage.getItem("usermenus"))
	// 动态设置菜单区域的高度，在onMounted函数中更改
	let asideheight = ref(`${document.documentElement.clientHeight}` - 55)
	let fullscreen = ref(false)
	let username = ref();
	username.value = localStorage.getItem("username")
	let userestatename=ref();
	userestatename.value = localStorage.getItem("userestatename")
	let logOffFlag=ref(false);
	let adminFlag=ref(false);

	// 定义表单项的头部宽度
	const formLabelWidth = ref('150px')
	// 定义表单项的内容宽度
	const formContentStyle = ref('width:300px')
	let changePsssDialogVisible = ref(false)
	const changePsssformRef = ref()
	let changePsssformdata = reactive({
		account:'',
		oldPass: '',
		newPass1: '',
		newPass2: ''
	})
	const appName = proxy.$config.appName
	const changePass = () => {
		if (changePsssformRef.value) {
			changePsssformRef.value.resetFields();
		}
		changePsssformdata.oldPass = '';
		changePsssformdata.newPass1 = '';
		changePsssformdata.newPass2 = '';
		changePsssDialogVisible.value = true
	}
	// 定义表单提交事件，带表单验证
	const submitChangePassForm = (form) => {
		form.validate(async (valid) => {
			if (valid) {
				if(changePsssformdata.newPass1==changePsssformdata.newPass2){
					let loading = proxy.$tools.ShowLoading();
					changePsssformdata.account=localStorage.getItem("useraccount");
					let response = await GetServerData('User/ChangePass', changePsssformdata)
					if (response.status == 1) {
						proxy.$tools.ShowMessage('修改成功', true)
						changePsssDialogVisible.value = false
					} else {
						proxy.$tools.ShowMessage(response.message, false)
					}
					loading.close()
				}else{
					changePsssformdata.newPass1='';
					changePsssformdata.newPass2='';
					changePsssformdata.oldPass = '';
					proxy.$tools.ShowMessage('两次输入的新密码不一致，请重新输入！', false)
					return false;
				}
				//proxy.$tools.ShowMessage("此功能还未开发", false)
			} else {
				proxy.$tools.ShowMessage('请您正确填写所有必填信息后，再提交！', false)
				return false;
			}
		})
	}

	// 二级菜单点击事件
	const onMenuClick = (menuitem) => {
		if (!CheckRouterTabsExist(menuitem.path)) {
			routerTabs.value.push({
				title: menuitem.name,
				key: menuitem.path
			})
		}
		activeName.value = menuitem.path;
		//console.log(menuitem.path)
		router.replace(menuitem.path)
	}
	const CheckRouterTabsExist = (key) => {
		let flag = false;
		for (let i = 0; i < routerTabs.value.length; i++) {
			if (routerTabs.value[i].key == key) {
				flag = true;
				break;
			}
		}
		return flag;
	}
	
	const openlargescreen=()=>{
		let routeUrl = router.resolve({
		          path: "/LargeScreen"
		     });
		window.open(routeUrl.href, '_blank');
	}
	const fullscreenshow = () => {
		let element = document.documentElement;
		// 判断是否已经是全屏
		// 如果是全屏，退出
		if (fullscreen.value) {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.msExitFullscreen) {
				document.msExitFullscreen();
			}
			console.log('已还原！');
		} else { // 否则，进入全屏
			if (element.requestFullscreen) {
				element.requestFullscreen();
			} else if (element.webkitRequestFullScreen) {
				element.webkitRequestFullScreen();
			} else if (element.mozRequestFullScreen) {
				element.mozRequestFullScreen();
			} else if (element.msRequestFullscreen) {
				// IE11
				element.msRequestFullscreen();
			}
			console.log('已全屏！');
		}
		// 改变当前全屏状态
		fullscreen.value = !fullscreen.value;
	}

	const personalCenter = () => {
		proxy.$tools.ShowMessage("此功能还未开发", false)
	}

	const bindWX = () => {
		proxy.$tools.ShowMessage("此功能还未开发", false)
	}

	const logOff = () => {
		ElMessageBox.confirm('确定要退出吗?', "", {
				confirmButtonText: '退 出',
				cancelButtonText: '取 消',
				type: 'warning'
			})
			.then(() => {
				logOffFlag.value=true;
				let refreshNum=localStorage.getItem("refreshNum");
				localStorage.clear();
				localStorage.setItem("refreshNum",refreshNum);
				localStorage.setItem('isExpired','0');
				router.replace('/');
			})
	}

	onMounted(() => {
		adminFlag.value=(localStorage.getItem("adminFlag")=='1');
		// 设置菜单区域的高度为浏览器可视区域高度-freamheader的高度
		asideheight.value = `${document.documentElement.clientHeight}` - 55
		window.addEventListener('beforeunload', (e)=>{
			activeName.value = "/Index"
			router.replace("/Index")
		})
		activeName.value = "/Index"
		router.replace("/Index")
		let customIcon=localStorage.getItem("customIcon");
		let showDefaultIcon=localStorage.getItem("showDefaultIcon");
		if(showDefaultIcon=='0'){
			if(customIcon&&customIcon.length>0){
				iconPath.value=customIcon;
			}else{
				showIcon.value=false;
			}
		}
	})
</script>

<style scoped lang="less">
	// 主界面，总框架
	.frame {
		height: 100%;
		width: 100%;
		margin: 0px;
		padding: 0px;
	}

	// 框架头部 
	.freamheader {
		height: 50px;
		margin: 0px;
		padding: 0px;
		border-bottom: solid 1px #606266;

	}

	.freamheaderleft {
		height: 50px;
		flex-basis: 200px;
		flex-grow: 0;
		text-align: center;
		background-color: #337ecc;
		margin: 0px;
		padding: 0px;
	}

	.freamheaderright {
		height: 50px;
		flex-grow: 1;
		text-align: right;
		background-color: #79bbff;
		margin: 0px;
		padding: 0px;
	}

	// 主体菜单
	.frameaside {
		overflow: hidden;
		background-color: #606266;
		margin: 0px;
		padding: 0px;
		width: 200px;
	}

	// 主体内容
	.framemain {
		margin: 0px;
		padding: 0px;
		z-index: 999;
		background-color: #DCDFE6;
	}

	// 主体框架
	.mainframe {
		width: 100%;
		height: 100%;
		margin: 0px;
		padding: 0px;
	}

	// 内容头部，可以用来放标签页
	.mainheader {
		height: 35px;
		width: 100%;
		margin: 0px;
		padding: 0px;
		background-color: white;
		text-align: left;
		margin-top: 10px;
	}

	// 内容主体
	.mainmain {
		width: 100%;
		margin: 0px;
		padding: 0px;
		height: 100%;
	}

	// 去掉菜单右边的白边
	.el-menu {
		width: 100%;
		border: 0 !important;
	}

	// 设置一级菜单的文字样式
	.groupTitle {
		font-size: 15px !important;
		letter-spacing: 4px !important;
		margin-left: -100px !important;
	}

	// 设置二级菜单的样式
	.el-menu-item {
		height: 40px !important;
		line-height: 40px !important;
		border: none !important;
		text-align: left !important;
	}

	.el-menu-item.is-active{
		background-color: #dedfe0 !important;
		border: none  !important;
	}

	.headertabletdclick {
		cursor: pointer;
		margin: 0px;
		padding: 0px;
		height: 50px;
		text-align: center;
	}

	.headertabletdclick:hover {
		background-color: #337ecc;
	}

	.headerdivclick {
		width: 200px;
		margin: 0px;
		padding: 0px;
	}

	.headerdivclickitem {
		width: 200px;
		border-radius: 5px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		margin: 5px;
		cursor: pointer;
	}

	.headerdivclickitem:hover {
		background-color: #dadee7;
	}

	.el-popover.popoverStyle {
		padding: 0px !important;
		margin: 0px !important;
	}

	.tabTitileClass {
		width: 100px;
		display: inline-block;
		//padding-left: 10px;
		text-align: center;
		user-select: none;
	}

	//定义下面内容框的样式
	::v-deep .el-tabs__content {
		height: 0px;
		width: 0px;
		display: none;
		//background-color: transparent;
	}
	
	//去掉tab与content之间的空隙
	::v-deep .el-tabs__header{
		margin: 0px;
	}
	
	// 灰线高度
	::v-deep .el-tabs__nav-wrap::after{
		height: 0px;
	}

	// 定义标签的样式
	::v-deep .el-tabs__item {
		width: auto;
		height: 100%;
		margin: 0px;
		padding: 0px;
		font-size: 15px;
		border: 1px solid #dedfe0;
	}

	// 鼠标悬停样式
	::v-deep .el-tabs__item:hover {
		background-color: #79bbff !important;
	}

	// 定义激活标签的样式
	::v-deep .el-tabs__item.is-active {
		color: white;
		background-color: #409EFF;
	}

	// 定义激活标签的样式
	::v-deep .el-tabs__active-bar {
		display: none;
	}

	// 定义左边箭头的样式
	::v-deep .el-icon-arrow-left {
		color: white;
		font-size: 20px;
	}
	::v-deep .el-tabs__nav-prev{
		margin: 0px  !important;
		padding: 0px  !important;
		width: 20px !important;
		background-color: #79bbff !important;
		text-align: center !important;
	}

	// 定义右边箭头的样式
	::v-deep .el-icon-arrow-right {
		color: white;
		font-size: 20px;
	}
	::v-deep .el-tabs__nav-next{
		margin: 0px  !important;
		padding: 0px  !important;
		width: 20px !important;
		background-color: #79bbff !important;
		text-align: center !important;
	}
	
	

	// 定义整个标签组的样式
	::v-deep .el-tabs__nav {
		//background-color: #79bbff;
	}
	
	::v-deep .el-tabs__nav-scroll{
	    margin: 0px;
		padding: 0px;
	}
	
	::v-deep .el-tabs__header.is-top {
	  margin: 0;
	}
	::v-deep .el-tabs {
	  //border: #2d4d7b solid 1px;
	  margin: 0px;
	  padding: 0px;
	  background: transparent;
	  color: black;
	  //border-radius: 10px 10px 0px 0px;
	  .el-tabs__header {
	    .el-tabs__nav-wrap {
	      .el-tabs__nav-scroll {
	        .el-tabs__nav {
	          .el-tabs__active-bar {
	            display: none;
	          }
	          .el-tabs__item {
	            width: 160px;
	            height: 35px;
	            padding: 0px;
				margin: 0px;
	            text-align: center;
	            line-height: 35px;
	            font-size: 15px;
	            color: black;
				background: white;
	            border-radius: 10px 10px 0 0;
				
	          }
	          .el-tabs__nav-wrap.is-scrollable {
	            padding: 0;
	          }
	          .el-tabs__item.is-active {
	            color: white;
	            background:  #409EFF;
	          }
			  .el-icon-close{
				  margin: 0px;
				  padding: 0px;
				  font-size: 20px;
				  margin-right: -15px;
				  margin-left: 15px;
			  }
	        }
	      }
	    }
	  }
	  .el-tabs__nav-wrap::after {
	    display: none;
	  }
	}
</style>
