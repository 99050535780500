<template>

	<div class="contentheader">
		<el-space wrap>
			<span class="titleClass">抄电表</span>
			<el-divider direction="vertical" />
			<el-radio-group v-model="params.category" class="ml-4" @change="categoryChange" v-if="ammeterType=='2'">
				<el-radio label="1" size="small">{{ammeterName1}}</el-radio>
				<el-radio label="2" size="small">{{ammeterName2}}</el-radio>
			</el-radio-group>
			<el-divider direction="vertical" />
			<el-input v-model="params.keyword" placeholder="输入关键字" size="small" style="width: 150px;"
				autocomplete="off" />
			<el-button type="primary" size="small" class="buttonforheader" @click="search">查 询</el-button>
			<el-button type="primary" size="small" class="buttonforheader" @click="refresh">刷 新</el-button>
			<el-divider direction="vertical" />
			<el-button type="success" size="small" class="buttonforheader" @click="read">抄 表</el-button>
			<el-button type="warning" size="small" class="buttonforheader" @click="save">保 存</el-button>
		</el-space>
	</div>
	<div style="display: flex; flex-direction: row; flex-wrap: nowrap; align-items: flex-start;margin: 10px;">
		<div style="width: 200px;">
			<el-scrollbar :height="areaHeight" style="width:200px; border-radius: 6px;background-color: white;">
				<el-tree ref="treeAreaRef" :data="areaDatas" node-key="id" :expand-on-click-node="false"
					highlight-current :props="treeProps" @node-click="areaSelected" style="margin: 10px;">
				</el-tree>
			</el-scrollbar>
		</div>
		<div id="divTableData" style="width: 100%;border-radius: 6px;background-color: white; margin-left: 5px;">
			<el-table :data="tableDatas" fit="false" highlight-current-row="true" ref="multipleTable"
				tooltip-effect="dark" :height="tableheight" @selection-change="handleSelectionChange"
				@row-click="rowclick" row-key="id" default-expand-all :header-cell-style="headerCellStype"
				:cell-style="contentCellStyle" style="width: 100%; cursor: pointer;" :row-class-name="tableRowClassName"
				>
				<el-table-column type="selection" width="55" />
				<el-table-column fixed prop="roomId" label="roomId" width="0" v-if="false" />
				<el-table-column fixed prop="priceCategory" label="priceCategory" width="0" v-if="false" />
				<el-table-column fixed prop="payType" label="payType" width="0" v-if="false" />
				<el-table-column prop="areaName1" label="一级区域" width="100" />
				<el-table-column prop="areaName2" label="二级区域" width="100" />
				<el-table-column prop="areaName3" label="三级区域" width="100" />
				<!-- <el-table-column prop="protocolName" label="协议名称" width="100" /> -->
				<el-table-column prop="roomName" label="房间名称" width="100" />
				<el-table-column prop="roomNum" label="房间编号" width="100" />
				<el-table-column prop="familyName" label="户主名称" width="100" />
				<el-table-column prop="familyPhone" label="户主电话" width="100" />
				<el-table-column prop="recordValue" label="抄表值" width="100" />
				<el-table-column prop="recordValue1" label="尖" width="100" v-if="showMoreRecords"/>
				<el-table-column prop="recordValue2" label="峰" width="100" v-if="showMoreRecords" />
				<el-table-column prop="recordValue3" label="平" width="100" v-if="showMoreRecords" />
				<el-table-column prop="recordValue4" label="谷" width="100" v-if="showMoreRecords" />
				<el-table-column prop="recordValue5" label="深" width="100" v-if="showMoreRecords" />
				<el-table-column prop="meterBalance" label="表端余额" width="100" v-if="showMeterBalance" />
				<el-table-column prop="recordTime" label="抄表时间" width="150" />
				<el-table-column prop="message" label="执行结果" width="250" />
				<el-table-column />
			</el-table>
		</div>
	</div>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted,
		onActivated,
		getCurrentInstance
	} from 'vue'
	import {
		ElMessageBox
	} from 'element-plus'
	import {
		GetServerData
	} from '@/http/index.js'
	// 获取全局this，proxy相当于vue2中的this，用于调用全局对象使用
	const {
		proxy
	} = getCurrentInstance()
	const treeAreaRef = ref()
	const treeProps = reactive({
		children: 'children',
		label: 'name'
	})
	// 定义查询参数
	let params = reactive({
		keyword: '',
		category: '1'
	})

	let ammeterType=ref(localStorage.getItem("ammeterType"));
	let ammeterName1=ref(localStorage.getItem("ammeterName1"));
	let ammeterName2=ref(localStorage.getItem("ammeterName2"));
	
	// 定义表格数据
	let tableDatas = ref([])
	let roomDatas = ref([])
	let areaDatas = ref([])
	let saveDatas = ref([])
	let showMoreRecords=ref(false)
	let showMeterBalance=ref(false)
	let selectedArea=ref()

	let headerCellStype = ref(proxy.$headerCellStype)
	let contentCellStyle = ref(proxy.$contentCellStyle)
	// 定义选中的表格行
	let multipleSelection = reactive([])
	// 定义表格引用，用来执行多选
	const multipleTable = ref()

	// 定义表格隔行变色，暂时没用到
	const tableRowClassName = ({
		row,
		rowIndex
	}) => {
		if (rowIndex % 2 === 0) {
			return 'even-row'
		} else {
			return 'odd-row'
		}
	}
	// 定义表格多选
	const handleSelectionChange = (val) => {
		multipleSelection = val;
	}
	const toggleSelection = (rows) => {
		if (rows) {
			rows.forEach(row => {
				multipleTable.value.toggleRowSelection(row);
			});
		} else {
			multipleTable.value.clearSelection();
		}
	}
	const rowclick = (row, column, event) => {
		toggleSelection([row])
	}

	const areaSelected = (area) => {
		let loading = proxy.$tools.ShowLoading();
		selectedArea.value=area;
		setTimeout(()=>{
			tableDatas.value = []
			let grade = area.grade
			let id = area.id
			if (grade == "1") {
				tableDatas.value = roomDatas.value.filter(room => room.areaId1 == id)
			} else if (grade == "2") {
				tableDatas.value = roomDatas.value.filter(room => room.areaId2 == id)
			} else if (grade == "3") {
				tableDatas.value = roomDatas.value.filter(room => room.areaId3 == id)
			}
			loading.close();
		},300)
	}
	
	const resetResult=()=>{
		showMeterBalance.value=false;
		showMoreRecords.value=false;
		if(tableDatas.value.length){
			for (let j = 0; j < tableDatas.value.length; j++) {
				tableDatas.value[j].recordValue = ''
				tableDatas.value[j].recordTime = ''
				tableDatas.value[j].recordValue1 = ''
				tableDatas.value[j].recordValue2 = ''
				tableDatas.value[j].recordValue3 = ''
				tableDatas.value[j].recordValue4 = ''
				tableDatas.value[j].recordValue5 = ''
				tableDatas.value[j].meterBalance = ''
				tableDatas.value[j].message = ''
			}
		}
	}

	const categoryChange = async () => {
		let loading = proxy.$tools.ShowLoading();
		tableDatas.value=[]
		await GetRoomData()
		if (params.keyword.length > 0){
			search()
		}else{
			if(selectedArea&&selectedArea.value){
				areaSelected(selectedArea.value)
			}
		}
		loading.close()
	}

	const search = () => {
		if (params.keyword.length > 0) {
			let loading = proxy.$tools.ShowLoading();
			setTimeout(()=>{
				tableDatas.value = []
				tableDatas.value = roomDatas.value.filter(room =>
					room.areaName1?.indexOf(params.keyword) > -1 ||
					room.areaName2?.indexOf(params.keyword) > -1 || 
					room.areaName3?.indexOf(params.keyword) > -1 || 
					room.roomName?.indexOf(params.keyword) > -1 || 
					room.roomNum?.indexOf(params.keyword) > -1 || 
					room.familyName?.toString().indexOf(params.keyword) > -1 || 
					room.familyPhone?.toString().indexOf(params.keyword) > -1
				)
				loading.close();
			},300)
		} else {
			proxy.$tools.ShowMessage('请输入关键字', false)
		}
	}

	const read = async () => {
		if (multipleSelection.length > 0) {
			resetResult();
			let loading = proxy.$tools.ShowLoading()
			for (let i = 0; i < multipleSelection.length; i++) {
				let roomId = multipleSelection[i].roomId
				let response = await GetServerData('ReadAmmeter/ReadRoomAmmeter'+ (params.category == '1' ?
					'1' : '2'), '"' + roomId + '"')
					//console.log(response.data);
				let recordValue = '---'
				let recordTime = '---'
				let recordValue1 = '---'
				let recordValue2 = '---'
				let recordValue3 = '---'
				let recordValue4 = '---'
				let recordValue5 = '---'
				let meterBalance = '---'
				let message = ''
				if (response.status == 1) {
					recordTime = response.data.recordTime
					if(response.data.priceCategory=='1'){
						recordValue = response.data.recordValue
					}else if(response.data.priceCategory=='2'){
						showMoreRecords.value=true;
						recordValue = response.data.recordValue
						recordValue1 = response.data.recordValue1
						recordValue2 = response.data.recordValue2
						recordValue3 = response.data.recordValue3
						recordValue4 = response.data.recordValue4
						recordValue5 = response.data.recordValue5
						if(response.data.payType=="1"){
							showMeterBalance.value=true;
							let response2 = await GetServerData('ReadAmmeter/ReadRoomAmmeterBalance'+ (params.category == '1' ?
								'1' : '2'), '"' + roomId + '"')
							if (response2.status == 1) {
								meterBalance = response2.data.meterBalance
							}
						}
					}
				} else {
					message = response.message
				}
				multipleSelection[i].recordValue = recordValue
				multipleSelection[i].recordTime = recordTime
				multipleSelection[i].recordValue1 = recordValue1
				multipleSelection[i].recordValue2 = recordValue2
				multipleSelection[i].recordValue3 = recordValue3
				multipleSelection[i].recordValue4 = recordValue4
				multipleSelection[i].recordValue5 = recordValue5
				multipleSelection[i].meterBalance = meterBalance
				multipleSelection[i].message = message
				// let thisSelected=proxy.$enumerable.from(tableDatas.value).where(x=>x.roomId==roomId).toArray()[0];
				// thisSelected.recordValue = recordValue
				// thisSelected.recordTime = recordTime
				// thisSelected.recordValue1 = recordValue1
				// thisSelected.recordValue2 = recordValue2
				// thisSelected.recordValue3 = recordValue3
				// thisSelected.recordValue4 = recordValue4
				// thisSelected.recordValue5 = recordValue5
				// thisSelected.meterBalance = meterBalance
				// thisSelected.message = message
				// for (let j = 0; j < tableDatas.value.length; j++) {
				// 	if (tableDatas.value[j].roomId == roomId) {
				// 		tableDatas.value[j].recordValue = recordValue
				// 		tableDatas.value[j].recordTime = recordTime
				// 		tableDatas.value[j].recordValue1 = recordValue1
				// 		tableDatas.value[j].recordValue2 = recordValue2
				// 		tableDatas.value[j].recordValue3 = recordValue3
				// 		tableDatas.value[j].recordValue4 = recordValue4
				// 		tableDatas.value[j].recordValue5 = recordValue5
				// 		tableDatas.value[j].meterBalance = meterBalance
				// 		tableDatas.value[j].message = message
				// 		//tableDatas.value[j].priceCategory=response.data.priceCategory
				// 		//tableDatas.value[j].payType=response.data.payType
				// 		break;
				// 	}
				// }
			}
			loading.close()
		} else {
			proxy.$tools.ShowMessage('请选择需要抄表的房间', false)
		}
	}

	const save = async () => {
		if (multipleSelection.length > 0) {
			let loading = proxy.$tools.ShowLoading()
			saveDatas.value = [];
			for (let i = 0; i < multipleSelection.length; i++) {
				let roomId = multipleSelection[i].roomId
				let recordTime = multipleSelection[i].recordTime
				if (recordTime != "---" && recordTime.length >0) {
					saveDatas.value.push({
						roomId: roomId,
						recordValue: multipleSelection[i].recordValue,
						recordTime: recordTime,
						recordValue1:multipleSelection[i].recordValue1,
						recordValue2:multipleSelection[i].recordValue2,
						recordValue3:multipleSelection[i].recordValue3,
						recordValue4:multipleSelection[i].recordValue4,
						recordValue5:multipleSelection[i].recordValue5,
						meterBalance:multipleSelection[i].meterBalance,
						priceCategory:multipleSelection[i].priceCategory,
						payType:multipleSelection[i].payType
					})
				}
			}
			if (saveDatas.value.length > 0) {
				let response = await GetServerData('ReadAmmeter/SaveRoomAmmeter' + (params.category == '1' ?
					'1s' : '2s'), saveDatas.value)
				if (response.status == 1) {
					proxy.$tools.ShowMessage('保存成功！', true)
				} else {
					proxy.$tools.ShowMessage(response.message, false)
				}
			} else {
				proxy.$tools.ShowMessage('没有找到需要保存的抄表值数据', false)
			}
			loading.close()
		} else {
			proxy.$tools.ShowMessage('请选择需要保存抄表值的房间', false)
		}
	}

	// 定义获取表格数据函数
	const GetRoomData = async () => {
		let response = await GetServerData('ReadAmmeter/QueryAllRoom' + (params.category == '1' ? '1s' : '2s'))
		if (response.status == 1) {
			roomDatas.value = response.data
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}

	// 定义获取表格数据函数
	const GetAreaData = async () => {
		let response = await GetServerData('ReadAmmeter/QueryAllAreas')
		if (response.status == 1) {
			areaDatas.value = response.data
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}

	// 定义获取所有数据函数
	const GetAllData = async () => {
		await GetRoomData()
		await GetAreaData()
	}
	
	const refresh=async ()=>{
		let loading = proxy.$tools.ShowLoading();
		tableDatas.value=[];
		await GetAllData()
		loading.close()
	}

	let areaHeight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60))
	let tableheight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60))
	// 定义加载事件
	onMounted(async () => {
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth - 425) +
			'px'
		window.onresize = () => {
			tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
			areaHeight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
			document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
					425) +
				'px'
		}
		let loading = proxy.$tools.ShowLoading();
		await GetAllData()
		loading.close()
	})
	onActivated(() => {
		tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
		areaHeight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
				425) +
			'px'
	})
</script>

<style scoped lang="less">
</style>
