<template>

	<div class="contentheader">
		<el-space wrap>
			<span class="titleClass">赠送水量</span>
			<el-divider direction="vertical" />
			<el-input v-model="params.keyword" placeholder="输入关键字" size="small" style="width: 150px;"
				autocomplete="off" />
			<el-button type="primary" size="small" class="buttonforheader" @click="search">查 询</el-button>
			<el-button type="primary" size="small" class="buttonforheader" @click="refresh">刷 新</el-button>
			<el-divider direction="vertical" />
			<el-button type="success" size="small" class="buttonforheader" @click="giftManywatermeter1" v-if="watermeterType!='0'">{{'批量赠'+watermeterName1+'水量'}}</el-button>
			<el-button type="warning" size="small" class="buttonforheader" @click="giftManywatermeter2" v-if="watermeterType=='2'">{{'批量赠'+watermeterName2+'水量'}}</el-button>
		</el-space>
	</div>
	<div style="display: flex; flex-direction: row; flex-wrap: nowrap; align-items: flex-start;margin: 10px;">
		<div style="width: 200px;">
			<el-scrollbar :height="areaHeight" style="width:200px; border-radius: 6px;background-color: white;">
				<el-tree ref="treeAreaRef" :data="areaDatas" node-key="id" :expand-on-click-node="false"
					:props="treeProps" @node-click="areaSelected" style="margin: 10px;" highlight-current>
				</el-tree>
			</el-scrollbar>
		</div>
		<div id="divTableData" style="width: 100%;border-radius: 6px;background-color: white; margin-left: 5px;">
			<el-table :data="tableDatas" fit="false" highlight-current-row="true" ref="multipleTable"
				tooltip-effect="dark" :height="tableheight" @selection-change="handleSelectionChange"
				@row-click="rowclick" row-key="id" default-expand-all :header-cell-style="headerCellStype"
				:cell-style="contentCellStyle" style="width: 100%; cursor: pointer;" :row-class-name="tableRowClassName"
				>
				<el-table-column type="selection" width="55" />
				<el-table-column fixed prop="roomId" label="roomId" width="0" v-if="false" />
				<el-table-column fixed prop="watermeterId1" label="watermeterId1" width="0" v-if="false" />
				<el-table-column fixed prop="watermeterPayType1" label="watermeterPayType1" width="0" v-if="false" />
				<el-table-column fixed prop="watermeterId2" label="watermeterId2" width="0" v-if="false" />
				<el-table-column fixed prop="watermeterPayType2" label="watermeterPayType2" width="0" v-if="false" />
				<el-table-column prop="areaName1" label="一级区域" width="100" />
				<el-table-column prop="areaName2" label="二级区域" width="100" />
				<el-table-column prop="areaName3" label="三级区域" width="100" />
				<el-table-column prop="roomName" label="房间名称" width="100" />
				<el-table-column prop="roomNum" label="房间编号" width="100" />
				<el-table-column prop="familyName" label="户主名称" width="200" />
				<el-table-column prop="familyPhone" label="户主电话" width="150" align="center" />
				<el-table-column prop="statusName" label="执行状态" width="150" />
				<el-table-column prop="message" label="信息" width="300" />
				<el-table-column />
				<el-table-column fixed="right" label="操作" width="250" align="left">
					<template #default="scope">
						<el-button size="mini" @click.stop="giftwatermeter1(scope.row)" type="success"
							class="buttonfortable" v-if="watermeterType!='0'">
							{{'赠'+watermeterName1+'水量'}}
						</el-button>
						<el-button size="mini" @click.stop="giftwatermeter1(scope.row)" type="warning"
							class="buttonfortable" v-if="watermeterType=='2'">
							{{'赠'+watermeterName2+'水量'}}
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>

	<el-dialog width="700px" v-model="dialogVisible" append-to-body=true :title="dialogtitle" center draggable
		:close-on-click-modal="false" :show-close="true">
		<el-form :model="formdata" ref="formRef" class="demo-ruleForm formContent">
			<el-form-item prop="amount" :rules="[{ required: true, message: '请输入赠送数量' ,trigger:'blur' }]" label="赠送数量"
				:label-width="formLabelWidth">
				<el-input type="number" v-model.number="formdata.amount" autocomplete="off" :style="formContentStyle" >
					<template #append>度</template>
				</el-input>
			</el-form-item>
			<el-form-item prop="remark" label="备注" :label-width="formLabelWidth">
				<el-input v-model="formdata.remark" autocomplete="off" :style="formContentStyle" type="textarea" maxlength="200"
					:rows="2" />
			</el-form-item>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button size="mini" type="primary" @click="submitForm(formRef)" class="buttonforform">
					{{dialogButtonText}}</el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted,
		onActivated,
		getCurrentInstance
	} from 'vue'
	import {
		ElMessageBox
	} from 'element-plus'
	import {
		GetServerData
	} from '@/http/index.js'
	// 获取全局this，proxy相当于vue2中的this，用于调用全局对象使用
	const {
		proxy
	} = getCurrentInstance()
	const treeAreaRef = ref()
	const treeProps = reactive({
		children: 'children',
		label: 'name'
	})
	// 定义查询参数
	let params = reactive({
		keyword: ''
	})

	let watermeterType=ref(localStorage.getItem("watermeterType"));
	let watermeterName1=ref(localStorage.getItem("watermeterName1"));
	let watermeterName2=ref(localStorage.getItem("watermeterName2"));

	// 定义表单标题
	let dialogtitle = ref("赠 送 水 量")
	// 定义表单显示与隐藏
	let dialogVisible = ref(false)
	// 定义表单引用，用来执行表单验证
	const formRef = ref()
	let dialogButtonText = ref('');

	// 定义表单项的头部宽度
	const formLabelWidth = ref('150px')
	// 定义表单项的内容宽度
	const formContentStyle = ref('width:300px')
	// 定义表单数据
	let formdata = reactive({
		amount: 0.0,
		category: '1',
		remark: ''
	})

	let onlyRoomId = ref('');

	// 定义表格数据
	let tableDatas = ref([])
	let roomDatas = ref([])
	let areaDatas = ref([])
	let saveDatas = ref([])


	let headerCellStype = ref(proxy.$headerCellStype)
	let contentCellStyle = ref(proxy.$contentCellStyle)
	// 定义选中的表格行
	let multipleSelection = reactive([])
	// 定义表格引用，用来执行多选
	const multipleTable = ref()

	// 定义表格隔行变色，暂时没用到
	const tableRowClassName = ({
		row,
		rowIndex
	}) => {
		if (rowIndex % 2 === 0) {
			return 'even-row'
		} else {
			return 'odd-row'
		}
	}
	// 定义表格多选
	const handleSelectionChange = (val) => {
		multipleSelection = val;
	}
	const toggleSelection = (rows) => {
		if (rows) {
			rows.forEach(row => {
				multipleTable.value.toggleRowSelection(row);
			});
		} else {
			multipleTable.value.clearSelection();
		}
	}
	const rowclick = (row, column, event) => {
		toggleSelection([row])
	}

	const areaSelected = (area) => {
		let loading = proxy.$tools.ShowLoading();
		setTimeout(()=>{
			tableDatas.value = []
			let grade = area.grade
			let id = area.id
			if (grade == "1") {
				tableDatas.value = roomDatas.value.filter(room => room.areaId1 == id)
			} else if (grade == "2") {
				tableDatas.value = roomDatas.value.filter(room => room.areaId2 == id)
			} else if (grade == "3") {
				tableDatas.value = roomDatas.value.filter(room => room.areaId3 == id)
			}
			loading.close();
		},300)
	}

	const search = () => {
		if (params.keyword.length > 0) {
			let loading = proxy.$tools.ShowLoading();
			setTimeout(()=>{
				tableDatas.value = []
				tableDatas.value = roomDatas.value.filter(room =>
					room.areaName1?.indexOf(params.keyword) > -1 ||
					room.areaName2?.indexOf(params.keyword) > -1 || 
					room.areaName3?.indexOf(params.keyword) > -1 || 
					room.roomName?.indexOf(params.keyword) > -1 || 
					room.roomNum?.indexOf(params.keyword) > -1 || 
					room.familyName?.toString().indexOf(params.keyword) > -1 || 
					room.familyPhone?.toString().indexOf(params.keyword) > -1
				)
				loading.close();
			},300)
		} else {
			proxy.$tools.ShowMessage('请输入关键字', false)
		}
	}

	const giftwatermeter1 = (row) => {
		if(row.watermeterId1&&row.watermeterId1.length>0){
			if(row.watermeterPayType1&&row.watermeterPayType1=='1'){
				dialogtitle.value = '赠'+watermeterName1.value+'水量'
				onlyRoomId.value = row.roomId;
				dialogtitle.value = '给 ' + row.roomName + ' | ' + row.roomNum + ' | ' + row.familyName + ' | ' + row
					.familyPhone + ' 赠'+watermeterName1.value+'水量';
				dialogButtonText.value = '赠送水';
				gift('3');
			}else{
				proxy.$tools.ShowMessage(watermeterName1.value+'是后付费，不能进行赠水操作！', false)
			}
		}else{
			proxy.$tools.ShowMessage('没有'+watermeterName1.value+'，无法进行操作！', false)
		}
		
	}
	
	const giftwatermeter2 = (row) => {
		if(row.watermeterId2&&row.watermeterId2.length>0){
			if(row.watermeterPayType2&&row.watermeterPayType2=='1'){
				dialogtitle.value = '赠'+watermeterName2.value+'水量'
				onlyRoomId.value = row.roomId;
				dialogtitle.value = '给 ' + row.roomName + ' | ' + row.roomNum + ' | ' + row.familyName + ' | ' + row
					.familyPhone + ' 赠'+watermeterName2.value+'水量';
				dialogButtonText.value = '赠送水';
				gift('4');
			}else{
				proxy.$tools.ShowMessage(watermeterName2.value+'是后付费，不能进行赠水操作！', false)
			}
		}else{
			proxy.$tools.ShowMessage('没有'+watermeterName2.value+'，无法进行操作！', false)
		}
		
	}

	const giftManywatermeter1 = () => {
		onlyRoomId.value = '';
		dialogtitle.value = '批量赠'+watermeterName1.value+'水量'
		dialogButtonText.value = '批量赠送水';
		gift('3');
	}

	const giftManywatermeter2 = () => {
		onlyRoomId.value = '';
		dialogtitle.value = '批量赠'+watermeterName2.value+'水量'
		dialogButtonText.value = '批量赠送水';
		gift('4');
	}

	const gift = (category) => {
		if (onlyRoomId.value.length > 0 || multipleSelection.length > 0) {
			formdata.amount = 0.0;
			formdata.category = category;
			dialogVisible.value = true;
		} else {
			proxy.$tools.ShowMessage('请选择所需要赠送的房间！', false)
		}
	}

	const submitForm = async (form) => {
		form.validate(async (valid) => {
			if (valid) {
				if (formdata.amount > 0) {
					if (onlyRoomId.value.length > 0) {
						dialogVisible.value = false;
						let loading = proxy.$tools.ShowLoading();
						let message = '';
						let statusName = '';
						let roomId = onlyRoomId.value
						let data = {
							amount: formdata.amount,
							category: formdata.category,
							roomId: roomId,
							remark: formdata.remark
						}
						console.log(data)
						let response = await GetServerData('GiftWaterMeter/GiftMeter', data)
						if (response.status == 1) {
							statusName = response.data
							message = response.message
						} else {
							message = response.message
						}
						for (let j = 0; j < tableDatas.value.length; j++) {
							if (tableDatas.value[j].roomId == roomId) {
								tableDatas.value[j].statusName = statusName;
								tableDatas.value[j].message = message;
								break;
							}
						}
						loading.close();
					} else {
						if (multipleSelection.length > 0) {
							dialogVisible.value = false;
							let loading = proxy.$tools.ShowLoading()
							for (let i = 0; i < multipleSelection.length; i++) {
								let message = '';
								let statusName = '';
								let watermeterId=multipleSelection[i].watermeterId1
								if(formdata.category=="4"){
									watermeterId=multipleSelection[i].watermeterId2
								}
								let watermeterPayType=multipleSelection[i].watermeterPayType1
								if(formdata.category=="4"){
									watermeterPayType=multipleSelection[i].watermeterPayType2
								}
								let watermeterName=watermeterName1;
								if(formdata.category=="4"){
									watermeterName=watermeterName2
								}
								if(watermeterId&&watermeterId.length>0){
									if(watermeterPayType&&watermeterPayType=="1"){
										let roomId = multipleSelection[i].roomId
										let data = {
											amount: formdata.amount,
											category: formdata.category,
											roomId: roomId,
											remark: formdata.remark
										}
										let response = await GetServerData('GiftWaterMeter/GiftMeter', data)
										if (response.status == 1) {
											statusName = response.data
											message = response.message
										} else {
											message = response.message
										}
									}else{
										statusName="无法执行"
										message=watermeterName.value+"是后付费，无法执行"
									}
								}else{
									statusName="无法执行"
									message="没有"+watermeterName.value+"，无法执行"
								}
								for (let j = 0; j < tableDatas.value.length; j++) {
									if (tableDatas.value[j].roomId == roomId) {
										tableDatas.value[j].statusName = statusName;
										tableDatas.value[j].message = message
										break;
									}
								}
							}
							loading.close()
						} else {
							proxy.$tools.ShowMessage('请选择所需要赠送的房间！', false)
						}
					}
				} else {
					proxy.$tools.ShowMessage('请输入要赠送的量！', false)
				}
			}
		})
	}

	// 定义获取表格数据函数
	const GetRoomData = async () => {
		let response = await GetServerData('GiftWaterMeter/QueryAllRooms')
		if (response.status == 1) {
			roomDatas.value = response.data
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}

	// 定义获取表格数据函数
	const GetAreaData = async () => {
		let response = await GetServerData('GiftWaterMeter/QueryAllAreas')
		if (response.status == 1) {
			areaDatas.value = response.data
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}

	// 定义获取所有数据函数
	const GetAllData = async () => {
		await GetRoomData()
		await GetAreaData()
	}
	
	const refresh=async ()=>{
		let loading = proxy.$tools.ShowLoading();
		tableDatas.value=[];
		await GetAllData()
		loading.close()
	}

	let areaHeight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60))
	let tableheight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60))
	// 定义加载事件
	onMounted(async () => {
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth - 425) +
			'px'
		window.onresize = () => {
			tableheight.value = (`${document.documentElement.clientHeight}` - proxy
				.$reduceHeight + 60)
			areaHeight.value = (`${document.documentElement.clientHeight}` - proxy
				.$reduceHeight + 60)
			document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
				425) + 'px'
		}
		let loading = proxy.$tools.ShowLoading();
		await GetAllData()
		loading.close()
	})
	onActivated(() => {
		tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
		areaHeight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
				425) +
			'px'
	})
</script>

<style scoped lang="less">
</style>
