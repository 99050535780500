<template>
	<div class="contentheader">
		<el-space wrap>
			<span class="titleClass">注销缴费</span>
			<el-divider direction="vertical" />
			<el-button type="primary" size="small" class="buttonforheader" @click="refresh">刷 新</el-button>
		</el-space>
	</div>
	<div style="display: flex; flex-direction: row; flex-wrap: nowrap; align-items: flex-start;margin: 10px;">
		<div style="width: 200px;">
			<el-scrollbar :height="areaHeight" style="width:200px; border-radius: 6px;background-color: white;">
				<el-tree ref="treeAreaRef" :data="areaDatas" node-key="id" :expand-on-click-node="false"  highlight-current
					:props="treeProps" @node-click="areaSelected" style="margin: 10px;">
				</el-tree>
			</el-scrollbar>
		</div>
		<div id="divTableData" style="width: 100%;border-radius: 6px;background-color: white; margin-left: 5px;">
			<el-table :data="tableDatas" fit="false" highlight-current-row="true" ref="multipleTable"
				tooltip-effect="dark" :height="tableheight" row-key="id" :header-cell-style="headerCellStype"
				:cell-style="contentCellStyle" style="width: 100%; cursor: pointer;" id="tableData"
				:row-class-name="tableRowClassName">
				<el-table-column fixed prop="id" label="id" width="0" v-if="false" />
				<el-table-column prop="userName" label="操作员" width="100" />
				<el-table-column prop="areaName1" label="一级区域" width="100" />
				<el-table-column prop="areaName2" label="二级区域" width="100" />
				<el-table-column prop="areaName3" label="三级区域" width="100" />
				<el-table-column prop="roomName" label="房间名称" width="100" />
				<el-table-column prop="roomNum" label="房间编号" width="100" />
				<el-table-column prop="familyName" label="户主名称" width="150" />
				<el-table-column prop="familyPhone" label="户主电话" width="150" />
				<el-table-column prop="categoryName" label="收费类型" width="100" />
				<el-table-column prop="payTypeName" label="付款方式" width="100" />
				<el-table-column prop="shouldPayMoney" label="应缴金额" width="120" align="center" />
				<el-table-column prop="realPayMoney" label="实缴金额" width="120" align="center" />
				<el-table-column prop="oldBalance" label="原始余额" width="120" align="center" />
				<el-table-column prop="newBalance" label="新余额" width="120" align="center" />
				<el-table-column prop="turnToBalance" label="转入余额" width="120" align="center" />
				<el-table-column prop="ct" label="时间" width="150" align="center" />
				<el-table-column prop="remark" label="描述" width="300" />
				<el-table-column />
				<el-table-column fixed="right" label="操作" width="190" align="center">
					<template #default="scope">
						<el-button size="mini" @click.stop="lookDetail(scope.row)" type="success" class="buttonfortable">
							查看明细
						</el-button>
						<el-button size="mini" @click.stop="VerifyPayment(scope.row)" type="warning" class="buttonfortable">
							注销缴费
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
	<el-dialog width="800px" v-model="dialogVisible" append-to-body=true :title="dialogtitle"
		:close-on-click-modal="true" :show-close="true">
		<el-table :data="tableDetailDatas" fit="false" highlight-current-row="true" 
			tooltip-effect="dark" :height="400" row-key="id" :header-cell-style="headerCellStype"
				:cell-style="contentCellStyle" style="width: 100%; cursor: pointer;"
				:row-class-name="tableRowClassName">
			<el-table-column fixed prop="id" label="id" width="0" v-if="false" />
			<el-table-column prop="payItemName" label="收费项目" width="100" />
			<el-table-column prop="price" label="单价" width="100" />
			<el-table-column prop="amount" label="数量" width="120" />
			<el-table-column prop="totalMoney" label="总价" width="120" />
			<el-table-column prop="description" label="描述" width="300" />
		</el-table>
	</el-dialog>
	<el-dialog width="800px" v-model="dialogVisible2" append-to-body=true :title="dialogtitle2" center draggable
		:close-on-click-modal="false" :show-close="true">
		<div style="width: 500px; margin: 0 auto;text-align: center; border-radius: 10px; background-color:antiquewhite; padding: 10px;">
			<el-row>
				<el-col :span="24" style="text-align: center; font-size: 18px; line-height: 30px;">
					注销信息验证成功，请在60秒内完成注销操作 
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24" style="text-align: center; font-size: 18px; line-height: 30px;" v-html="cancelMessage">
					
				</el-col>
			</el-row>
		</div>
		<template #footer>
			<span class="dialog-footer">
				<el-button size="mini" type="primary" @click="cancelPayment" class="buttonforform">注 销
				</el-button>
				<el-button size="mini" type="info" @click="cancelCancel" class="buttonforform">取 消
				</el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted,
		onActivated,
		getCurrentInstance
	} from 'vue'
	import {
		ElMessageBox
	} from 'element-plus'
	import {
		GetServerData
	} from '@/http/index.js'
	// 获取全局this，proxy相当于vue2中的this，用于调用全局对象使用
	const {
		proxy
	} = getCurrentInstance()
	const treeAreaRef = ref()
	const treeProps = reactive({
		children: 'children',
		label: 'name'
	})
	let dialogtitle = ref("缴费明细")
	let dialogVisible=ref(false)
	let dialogtitle2 = ref("注销缴费")
	let dialogVisible2=ref(false)
	let cancelMessage=ref('')
	// 定义查询参数
	let params = reactive({
		roomId: ''
	})
	let paymentId=ref('')

	let headerCellStype = ref(proxy.$headerCellStype)
	let contentCellStyle = ref(proxy.$contentCellStyle)


	// 定义表格数据
	let tableDatas = ref([])
	let tableDetailDatas=ref([])
	let areaDatas = ref([])


	// 定义表格引用，用来执行多选
	const multipleTable = ref()

	// 定义表格隔行变色，暂时没用到
	const tableRowClassName = ({
		row,
		rowIndex
	}) => {
		if (rowIndex % 2 === 0) {
			return 'even-row'
		} else {
			return 'odd-row'
		}
	}
	
	const lookDetail=async (row)=>{
			let loading = proxy.$tools.ShowLoading()
			let response = await GetServerData('CancelPayment/QueryDetail', row.id)
			if (response.status == 1) {
				tableDetailDatas.value = response.data
				dialogtitle.value='缴费明细('+row.areaName1+'-'+row.areaName2+'-'+row.areaName3+'-'+row.roomName+'-'+row.roomNum+')'+row.ct;
				dialogVisible.value=true
			} else {
				proxy.$tools.ShowMessage(response.message, false)
			}
			loading.close();
	}

	const areaSelected = async (area) => {
		let grade = area.grade
		params.roomId = ''
		if (grade == "4") {
			params.roomId = area.id;
			await search();
		}
	}
	
	const VerifyPayment=async(row)=>{
		let loading = proxy.$tools.ShowLoading()
		let response = await GetServerData('CancelPayment/VerifyData', {
			paymentId:row.id
		})
		if (response.status == 1) {
			paymentId.value=row.id;
			dialogtitle2.value='注销缴费('+row.areaName1+'-'+row.areaName2+'-'+row.areaName3+'-'+row.roomName+'-'+row.roomNum+')'+row.ct;
			cancelMessage.value=response.data;
			dialogVisible2.value=true;
		} else {
			paymentId.value='';
			proxy.$tools.ShowMessage(response.message, false)
		}
		loading.close();
	}
	
	const cancelCancel=()=>{
		dialogVisible2.value=false;
		paymentId.value='';
		cancelMessage.value='';
	}
	
	const cancelPayment=async()=>{
		let loading = proxy.$tools.ShowLoading()
		let response = await GetServerData('CancelPayment/CancelPayment', {
			paymentId:paymentId.value
		})
		loading.close();
		dialogVisible2.value=false;
		paymentId.value='';
		cancelMessage.value=''; 
		if (response.status == 1) {
			proxy.$tools.ShowMessage("注销缴费成功！",true);
			tableDatas.value =[];
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}

	const search = async () => {
		if (params.roomId && params.roomId.length > 0) {
			let loading = proxy.$tools.ShowLoading()
			let response = await GetServerData('CancelPayment/QueryAll', {
				roomId:params.roomId
			})
			if (response.status == 1) {
				tableDatas.value = response.data
			} else {
				tableDatas.value =[];
				proxy.$tools.ShowMessage(response.message, false)
			}
			loading.close();
		} else {
			proxy.$tools.ShowMessage("请选择左侧列表中的房间！", false)
		}
	}
	
	const refresh=async ()=>{
		let loading = proxy.$tools.ShowLoading();
		tableDatas.value=[];
		await GetAllData()
		loading.close()
	}

	// 定义获取表格数据函数
	const GetRoomData = async () => {
		let response = await GetServerData('CancelPayment/QueryAllRooms')
		if (response.status == 1) {
			areaDatas.value = response.data
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}

	// 定义获取所有数据函数
	const GetAllData = async () => {
		await GetRoomData()
	}

	let areaHeight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60))
	let tableheight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60))
	// 定义加载事件
	onMounted(async () => {
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth - 425) +
			'px'
		window.onresize = () => {
			tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
			areaHeight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
			document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
				425) + 'px'
		}
		let loading = proxy.$tools.ShowLoading();
		await GetAllData()
		loading.close()
	})
	onActivated(()=>{
		tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
		areaHeight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
			425) + 'px'
	})
</script>

<style scoped lang="less">
</style>
