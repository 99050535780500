<template>
	<div class="contentheader">
		<el-space wrap>
			<span class="titleClass">DTU管理</span>
			<el-divider direction="vertical" />
			<el-input v-model="params.name" placeholder="输入DTU名称" size="small" style="width: 150px;"
				autocomplete="off" />
			<el-input v-model="params.address" placeholder="输入DTU安装位置" size="small" style="width: 150px;"
				autocomplete="off" />
			<el-button type="primary" size="small" class="buttonforheader" @click="search">查 询</el-button>
			<el-divider direction="vertical" />
			<el-button type="success" size="small" class="buttonforheader" @click="add">新 增</el-button>
			<el-button type="warning" size="small" class="buttonforheader" @click="deletesome">批量删除</el-button>
			<!-- <el-button type="primary" size="small" class="buttonforheader" @click="resetparams">重 置</el-button> -->
		</el-space>
	</div>
	<div id="divTableData" class="contentcontent">
		<el-table :data="tableDatas" fit="false" highlight-current-row="true" ref="multipleTable" tooltip-effect="dark"
			:height="tableheight" @selection-change="handleSelectionChange" @row-click="rowclick" row-key="id"
			:header-cell-style="headerCellStype" :cell-style="contentCellStyle" style="width: 100%; cursor: pointer;"
			:row-class-name="tableRowClassName">
			<el-table-column type="selection" width="55" />
			<el-table-column fixed prop="id" label="id" width="0" v-if="false" />
			<el-table-column fixed prop="category" label="category" width="0" v-if="false" />
			<el-table-column fixed prop="privately" label="privately" width="0" v-if="false" />
			<el-table-column prop="name" label="DTU名称" width="120" />
			<el-table-column prop="categoryName" label="DTU类型" width="90" align="center" />
			<el-table-column prop="privatelyName" label="私有协议" width="90" align="center" />
			<!-- <el-table-column prop="address" label="地址" width="250" /> -->
			<el-table-column prop="location" label="安装位置" width="250" />
			<el-table-column prop="uniqueCode" label="唯一编码" width="250" v-if="false" />
			<el-table-column prop="remark" label="备注" width="250" :show-overflow-tooltip="true" />
			<el-table-column prop="cuName" label="创建人" width="120" />
			<el-table-column prop="muName" label="修改人" width="120" />
			<el-table-column prop="ct" label="创建时间" width="150" align="center" />
			<el-table-column prop="mt" label="修改时间" width="150" align="center" />
			<el-table-column />
			<el-table-column fixed="right" label="操作" width="350" align="left">
				<template #default="scope">
					<el-button size="mini" @click.stop="modify(scope.row)" type="success" class="buttonfortable">编 辑
					</el-button>
					<el-button size="mini" @click.stop="deleteitem(scope.row)" type="warning" class="buttonfortable">删 除
					</el-button>
					<el-button size="mini" @click.stop="look(scope.row)" type="danger" class="buttonfortable">查 包
					</el-button>
					<el-button size="mini" @click.stop="setRegisterCode(scope.row)" type="info" class="buttonfortable" v-if="scope.row.privately=='1'">设置注册包
					</el-button>
				</template>
			</el-table-column>
		</el-table>
	</div>
	<div class="contentpager" v-if="showpaging">
		<el-pagination background layout="prev, pager, next,jumper,sizes,total" :total="pagingInfo.totalCount"
			:page-sizes="[20,50,100]" v-model:current-page="params.pageNum" v-model:page-size="params.pageSize"
			@size-change="sizechange" @current-change="currentchange" />
	</div>
	<el-dialog width="500px" v-model="setRegisterCodeDialogVisible" append-to-body=true title="修改注册包" center draggable
		:close-on-click-modal="false" :show-close="true">
		<el-form :model="registercodeformdata" class="demo-ruleForm formContent">
			<el-form-item label="注册包" :label-width="formLabelWidth">
				<el-input v-model="registercodeformdata.registerCode" autocomplete="off" :style="formContentStyle" maxlength="100"
					placeholder="请输入注册包" />
			</el-form-item>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button size="mini" type="primary" @click="submitSetRegisterCodeForm" class="buttonforform"> 确 定
				</el-button>
			</span>
		</template>
	</el-dialog>
	<el-dialog width="500px" v-model="dialogVisible" append-to-body=true :title="dialogtitle" center draggable
		:close-on-click-modal="false" :show-close="true">
		<el-form :model="formdata" ref="formRef" class="demo-ruleForm formContent">
			<el-form-item label="DTU类型" :label-width="formLabelWidth">
				<el-radio-group v-model="formdata.category" class="ml-4">
					<el-radio label="2" size="small" style="margin-left: 20px;">TCPTP</el-radio>
					<el-radio label="1" size="small" v-if="false">COM</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item prop="name" :rules="[{ required: true, message: '请输入DTU名称' ,trigger:'blur'},{min:3,max:30,message:'DTU名称的长度为3-30',trigger:'blur'}]" label="DTU名称"
				:label-width="formLabelWidth">
				<el-input v-model="formdata.name" autocomplete="off" :style="formContentStyle" placeholder="请输入DTU名称" maxlength="30" />
			</el-form-item>
			<!-- <el-form-item prop="address" :rules="[{ required: true, message: '请输入DTU地址' }]"  label="DTU地址" :label-width="formLabelWidth">
				<el-input v-model="formdata.address" autocomplete="off" :style="formContentStyle"
					placeholder="请输入DTU地址" />
			</el-form-item> -->
			<el-form-item label="安装位置" :label-width="formLabelWidth">
				<el-input v-model="formdata.location" autocomplete="off" :style="formContentStyle" maxlength="100"
					placeholder="请输入安装位置" />
			</el-form-item>
			<el-form-item label="备注" :label-width="formLabelWidth">
				<el-input v-model="formdata.remark" autocomplete="off" :style="formContentStyle" placeholder="请输入备注"  maxlength="200"/>
			</el-form-item>
			<el-form-item label="私有协议" :label-width="formLabelWidth">
				<el-switch v-model="formdata.privately" active-text="是" active-value="1" inactive-value="0"
					inactive-text="否">
				</el-switch>
			</el-form-item>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-space wrap :size="30">
					<span v-if="showgoon">是否继续添加：
						<el-switch v-model="goon" style="margin-right: 10px;" />
					</span>
					<el-button size="mini" type="warning" @click="reset" class="buttonforform"> 重 置 </el-button>
					<!-- <el-button @click="dialogVisible = false">取消</el-button> -->
					<el-button size="mini" type="primary" @click="submitForm(formRef)" class="buttonforform"> 确 定
					</el-button>
				</el-space>
			</span>
		</template>
	</el-dialog>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted,
		onActivated,
		getCurrentInstance
	} from 'vue'
	import {
		ElMessageBox
	} from 'element-plus'
	import {
		GetServerData
	} from '@/http/index.js'
	// 获取全局this，proxy相当于vue2中的this，用于调用全局对象使用
	const {
		proxy
	} = getCurrentInstance()

	// 定义查询参数
	let params = reactive({
		address: '',
		name: '',
		pageSize: 20,
		pageNum: 1
	})
	let pageNum = ref(1)
	let pagingInfo = reactive({
		totalCount: 0,
		totalPage: 0
	})
	// 定义combox数据
	let preMenus = ref([])
	// 定义表单标题
	let dialogtitle = ref("新 增 DTU")
	// 定义表单数据
	let formdata = reactive({
		id: '',
		category: '2',
		name: '',
		address: '',
		location: '',
		remark: '',
		privately:'0'
	})
	
	let registercodeformdata=reactive({
		id: '',
		registerCode:''
	})

	// 定义表单显示与隐藏
	let dialogVisible = ref(false)
	let setRegisterCodeDialogVisible = ref(false)
	// 定义是否显示继续添加
	let showgoon = ref(false)
	// 定义是否继续添加
	let goon = ref(false)
	// 定义是否显示分页
	let showpaging = ref(true)
	// 定义表格数据
	let tableDatas = ref([])
	let headerCellStype = ref(proxy.$headerCellStype)
	let contentCellStyle = ref(proxy.$contentCellStyle)
	// 定义选中的表格行
	let multipleSelection = reactive([])
	// 定义表格引用，用来执行多选
	const multipleTable = ref()
	// 定义表单引用，用来执行表单验证
	const formRef = ref()
	// 定义表单项的头部宽度
	const formLabelWidth = ref('150px')
	// 定义表单项的内容宽度
	const formContentStyle = ref('width:300px')

	// 定义表格隔行变色，暂时没用到
	const tableRowClassName = ({
		row,
		rowIndex
	}) => {
		if (rowIndex % 2 === 0) {
			return 'even-row'
		} else {
			return 'odd-row'
		}
	}
	// 定义表格多选
	const handleSelectionChange = (val) => {
		multipleSelection = val;
	}
	const toggleSelection = (rows) => {
		if (rows) {
			rows.forEach(row => {
				multipleTable.value.toggleRowSelection(row);
			});
		} else {
			multipleTable.value.clearSelection();
		}
	}
	const rowclick = (row, column, event) => {
		toggleSelection([row])
	}
	const sizechange = async () => {
		params.pageNum = 1
		let loading = proxy.$tools.ShowLoading();
		await GetTableData()
		loading.close()
	}
	const currentchange = async () => {
		let loading = proxy.$tools.ShowLoading();
		await GetTableData()
		loading.close()
	}
	
	const submitSetRegisterCodeForm=async()=>{
		let loading = proxy.$tools.ShowLoading();
		if (registercodeformdata.id.length > 0) {
			let response = await GetServerData('DTU/SetRegisterCode', registercodeformdata)
			if (response.status == 1) {
				proxy.$tools.ShowMessage('修改成功', true)
				setRegisterCodeDialogVisible.value = false
				await GetAllData();
			} else {
				proxy.$tools.ShowMessage(response.message, false)
			}
		}else{
			proxy.$tools.ShowMessage("请先选择需要修改的DTU！", false)
		}
		loading.close()
	}

	// 定义表单提交事件，带表单验证
	const submitForm = (form) => {
		form.validate(async (valid) => {
			if (valid) {
				let loading = proxy.$tools.ShowLoading();
				if (formdata.id.length > 0) {
					let response = await GetServerData('DTU/Modify', formdata)
					if (response.status == 1) {
						proxy.$tools.ShowMessage('修改成功', true)
						dialogVisible.value = false
						await GetAllData();
					} else {
						proxy.$tools.ShowMessage(response.message, false)
					}
				} else {
					let response = await GetServerData('DTU/Add', formdata)
					if (response.status == 1) {
						proxy.$tools.ShowMessage('新增成功！', true)
						if (goon.value) {
							reset()
						} else {
							dialogVisible.value = false
						}
						await GetAllData();
					} else {
						proxy.$tools.ShowMessage(response.message, false)
					}
				}
				loading.close()
			} else {
				proxy.$tools.ShowMessage('请您正确填写所有必填信息后，再提交！', false)
				return false;
			}
		})
	}
	// 重置表单事件
	const reset = () => {
		if (formRef.value) {
			formRef.value.resetFields();
		}
		formdata.id = '';
		formdata.category = '2';
		formdata.name = '';
		formdata.address = '';
		formdata.location = '';
		formdata.remark = '';
	}
	// 定义新增按钮事件
	const add = () => {
		dialogtitle.value = "新 增 DTU"
		goon.value = false
		showgoon.value = true
		reset()
		dialogVisible.value = true
	}
	// 定义编辑按钮事件
	const modify = (row) => {
		dialogtitle.value = "修 改 DTU"
		showgoon.value = false
		formdata.id = row.id;
		formdata.category = row.category;
		formdata.name = row.name;
		formdata.address = row.address;
		formdata.location = row.location;
		formdata.remark = row.remark;
		formdata.privately=row.privately;
		dialogVisible.value = true
	}
	// 定义查包按钮事件
	const look = (row) => {
		if(row.privately=='0'){
			let message = '注册包：FFFEFFFEAB' + row.uniqueCode + 'ABEEEE' + '<br/>心跳包：FFFEFFFEBA' + row.uniqueCode + 'BAEEEE';
			proxy.$tools.ShowMessageBoxWithWrap(row.name + '的注册包和心跳包：', message);
		}else{
			let message = '注册包：' + row.uniqueCode ;
			proxy.$tools.ShowMessageBoxWithWrap(row.name + '的注册包：', message);
		}
	}
	
	const setRegisterCode = async (row)=>{
		registercodeformdata.id=row.id;
		registercodeformdata.registerCode=row.uniqueCode;
		setRegisterCodeDialogVisible.value=true;
	}
	// 定义删除按钮事件
	const deleteitem = (row) => {
		ElMessageBox.confirm('确定要删除这条记录吗?', "", {
				confirmButtonText: '确 定',
				cancelButtonText: '取 消',
				type: 'warning'
			})
			.then(async () => {
				let loading = proxy.$tools.ShowLoading();
				let response = await GetServerData('DTU/Delete', {
					id: row.id
				})

				if (response.status == 1) {
					proxy.$tools.ShowMessage('删除成功！', true)
					await GetAllData()
				} else {
					proxy.$tools.ShowMessage(response.message, false)
				}
				loading.close();
			})
	}
	// 定义批量删除按钮事件
	const deletesome = () => {
		var ids = ''
		multipleSelection.forEach((value, key, iterable) => {
			ids += "'" + value.id + "',";
		})
		if (ids.length > 0) {
			ids = ids.substr(0, ids.length - 1)
			ElMessageBox.confirm('确定要删除下面选中的所有记录吗?', "", {
					confirmButtonText: '确 定',
					cancelButtonText: '取 消',
					type: 'warning'
				})
				.then(async () => {
					let loading = proxy.$tools.ShowLoading();
					let response = await GetServerData('DTU/DeleteSome', {
						ids: ids
					})
					if (response.status == 1) {
						proxy.$tools.ShowMessage('删除成功！', true)
						await GetAllData()
					} else {
						proxy.$tools.ShowMessage(response.message, false)
					}
					loading.close()
				})
		}
	}
	// 定义查询按钮事件
	const search = async () => {
		let loading = proxy.$tools.ShowLoading();
		await GetTableData()
		loading.close()
	}
	// 定义刷新按钮事件
	const resetparams = () => {
		params.address = '';
		params.name = '';
	}
	// 定义获取表格数据函数
	const GetTableData = async () => {
		let response = await GetServerData('DTU/QueryAll', params)
		if (response.status == 1) {
			tableDatas.value = response.data
			pagingInfo.totalCount = response.totalCount
			pagingInfo.totalPage = Math.ceil(pagingInfo.totalCount / params.pageSize)
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}
	// 定义获取所有数据函数
	const GetAllData = async () => {
		await GetTableData()
	}

	let tableheight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight))
	// 定义加载事件
	onMounted(async () => {
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth - 220) +
			'px'
		window.onresize = () => {
			tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight)
			document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
					220) +
				'px'
		}
		let loading = proxy.$tools.ShowLoading();
		await GetAllData()
		loading.close()
	})
	onActivated(() => {
		tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight)
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
			220) + 'px'
	})
</script>

<style scoped lang="less">
</style>
