<template>
	<div class="contentheader">
		<el-space wrap>
			<span class="titleClass">账单结算</span> 
			<el-divider direction="vertical" />
			<el-button type="primary" size="small" class="buttonforheader" @click="refresh">刷 新</el-button>
			<el-button type="primary" size="small" class="buttonforheader" @click="searchBill">获取账单</el-button>
			<el-divider direction="vertical" />
			<!-- <el-button type="success" size="small" class="buttonforheader" @click="createAmmeterBill" v-if="ammeterType!='0'&&ammeterType!=''">生成{{ammeterName1}}电账单</el-button>
			<el-button type="success" size="small" class="buttonforheader" @click="createAmmeter2Bill" v-if="ammeterType=='2'">生成{{ammeterName2}}电账单</el-button>
			<el-button type="warning" size="small" class="buttonforheader" @click="createWatermeter1Bill" v-if="watermeterType!='0'&&watermeterType!=''">生成{{watermeterName1}}水账单</el-button>
			<el-button type="danger" size="small" class="buttonforheader" @click="createWatermeter2Bill" v-if="watermeterType=='2'">生成{{watermeterName2}}水账单</el-button> -->
			<el-button type="success" size="small" class="buttonforheader" @click="createAmmeterBill" v-if="ammeterType!='0'&&ammeterType!=''">生成电账单</el-button>
			<el-button type="warning" size="small" class="buttonforheader" @click="createWatermeterBill" v-if="watermeterType!='0'&&watermeterType!=''">生成水账单</el-button>
			<el-divider direction="vertical" />
			<el-button type="info" size="small" class="buttonforheader" @click="countSelectedBills">结算所选账单</el-button>
			
			<!-- <el-divider direction="vertical" />
			<el-tag class="ml-2" type="success" closable v-if="showTag" @close="resetSelectedArea">{{selectedArea}}
			</el-tag> -->
		</el-space>
	</div>
	<div style="display: flex; flex-direction: row; flex-wrap: nowrap; align-items: flex-start;margin: 10px; width: 100%;">
		<div style="width: 200px;">
			<el-scrollbar :height="areaHeight" style="width:200px; border-radius: 6px;background-color: white;">
				<el-tree ref="treeAreaRef" :data="areaDatas" node-key="id" :expand-on-click-node="false"
					:props="treeProps" @node-click="areaSelected" style="margin: 10px;" highlight-current>
				</el-tree>
			</el-scrollbar>
		</div>
		<div id="divTableData" style="border-radius: 6px;background-color: white; margin-left: 5px;">
			<el-table :data="tableDatas" fit="false" highlight-current-row="true" ref="multipleTable"
				tooltip-effect="dark" :height="tableheight" @selection-change="handleSelectionChange"
				@row-click="rowclick" row-key="id" default-expand-all
				:header-cell-style="headerCellStype"
				:cell-style="contentCellStyle" style="width: 100%; cursor: pointer;"
				:row-class-name="tableRowClassName"
				>
				<el-table-column type="selection" width="55" />
				<el-table-column fixed prop="id" label="id" width="0" v-if="false" />
				<el-table-column prop="category" label="category" width="0" v-if="false" />
				<el-table-column prop="status" label="status" width="0" v-if="false" />
				<el-table-column fixed prop="roomName" label="房间名称" width="100" />
				<el-table-column prop="roomNum" label="房间编号" width="100" />
				<el-table-column prop="familyName" label="户主名称" width="100" v-if="false"  />
				<el-table-column prop="familyPhone" label="户主电话" width="120" align="center" v-if="false"  />
				<el-table-column prop="categoryName" label="账单类型" width="80" align="center" />
				<el-table-column prop="billYear" label="年" width="55" align="center" />
				<el-table-column prop="billMonth" label="月" width="40" align="center" />
				<el-table-column prop="billDay" label="日" width="40" align="center" />
				<el-table-column prop="beginTime" label="开始时间" width="150" v-if="false" />
				<el-table-column prop="endTime" label="结束时间" width="150" v-if="false" />
				<el-table-column prop="beginValue" label="开始值" width="90" />
				<el-table-column prop="endValue" label="结束值" width="90" />
				<el-table-column prop="useAmount" label="用量" width="90" />
				<el-table-column prop="price" label="单价" width="60" align="center" />
				<el-table-column prop="totalMoney" label="总价(元)" width="90" />
				<el-table-column prop="statusName" label="状态" width="65" align="center" />
				<el-table-column prop="description" label="描述" width="300" />
				<el-table-column prop="ct" label="账单生成时间" width="150" align="center" />
				<el-table-column prop="deviceOffTime" label="设备关阀时间" width="150" align="center" />
				<el-table-column />
				<el-table-column fixed="right" label="操作" width="100" align="center">
					<template #default="scope">
						<el-button size="mini" @click.stop="countBill(scope.row)" type="success" class="buttonfortable">
							结算账单
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
	<el-dialog width="1000px" v-model="dialogVisible" append-to-body=true :title="dialogtitle" center draggable
		:close-on-click-modal="false" :show-close="true">
		<el-form :model="formdata" ref="formRef" class="demo-ruleForm formContent">
			<el-row>
				<el-col :span="12">
					<el-form-item prop="shouldPayMoney" label="应交金额" :label-width="formLabelWidth">
						<el-input v-model="formdata.shouldPayMoney" autocomplete="off" :style="formContentStyle" type="number"
							readonly >
						<template #append>元</template>
					</el-input>
					</el-form-item>
					<el-form-item prop="oldBalance" label="上次余额" :label-width="formLabelWidth">
						<el-input v-model="formdata.oldBalance" autocomplete="off" :style="formContentStyle" readonly  type="number">
						<template #append>元</template>
					</el-input>
					</el-form-item>
					<el-form-item prop="shouldTakeMoney" label="应收金额" :label-width="formLabelWidth">
						<el-input v-model="formdata.shouldTakeMoney" autocomplete="off" :style="formContentStyle" type="number"
							readonly >
						<template #append>元</template>
					</el-input>
					</el-form-item>
					<el-form-item prop="newBalance" label="账户余额" :label-width="formLabelWidth">
						<el-input v-model="formdata.newBalance" autocomplete="off" :style="formContentStyle" readonly  type="number">
						<template #append>元</template>
					</el-input>
					</el-form-item>
					<el-form-item prop="description" label="缴费内容" :label-width="formLabelWidth">
						<el-input v-model="formdata.description" autocomplete="off" :style="formContentStyle" readonly
							type="textarea" :rows="5" />
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item prop="realPayMoney" label="实收金额" :rules="[{ required: true, message: '请输入实收金额',trigger:'blur' }]"
						:label-width="formLabelWidth">
						<el-input ref="txtPayMoneyRef" v-model="formdata.realPayMoney" autocomplete="off" type="number"
							:style="formContentStyle" @blur="calcBalance" @focus="selectAllText($event)" autofocus >
						<template #append>元</template>
					</el-input>
					</el-form-item>
					<el-form-item prop="payType" :rules="[{ required: true, message: '请输选择缴费方式' }]" label="缴费方式"
						:label-width="formLabelWidth">
						<el-select v-model="formdata.payType" placeholder="请选择缴费方式" :style="formContentStyle">
							<el-option v-for="item in payTypes" :key="item.value" :label="item.label"
								:value="item.value" />
						</el-select>
					</el-form-item>
					<el-form-item prop="remark" label="备注" :label-width="formLabelWidth">
						<el-input v-model="formdata.remark" autocomplete="off" :style="formContentStyle" type="textarea" maxlength="200"
							:rows="3" />
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button size="mini"  type="primary" @click="submitForm(formRef)" class="buttonforform"> 结 算 </el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted,
		onActivated,
		getCurrentInstance
	} from 'vue'
	import {
		ElMessageBox
	} from 'element-plus'
	import {
		GetServerData
	} from '@/http/index.js'
	// 获取全局this，proxy相当于vue2中的this，用于调用全局对象使用
	const {
		proxy
	} = getCurrentInstance()

	let payTypes = reactive([{
			label: '现金',
			value: '1'
		},
		{
			label: '微信',
			value: '2'
		},
		{
			label: '支付宝',
			value: '3'
		},
		{
			label: '银联',
			value: '4'
		}
	])
	let params = reactive({
		areaId1: '',
		areaId2: '',
		areaId3: '',
		roomId: ''
	})
	
	let ammeterType=ref(localStorage.getItem("ammeterType"));
	let ammeterName1=ref(localStorage.getItem("ammeterName1"));
	let ammeterName2=ref(localStorage.getItem("ammeterName2"));
	
	let watermeterType=ref(localStorage.getItem("watermeterType"));
	let watermeterName1=ref(localStorage.getItem("watermeterName1"));
	let watermeterName2=ref(localStorage.getItem("watermeterName2"));
	
	
	// 定义表单标题
	let dialogtitle = ref("账 单 结 算")
	// 定义表单显示与隐藏
	let dialogVisible = ref(false)
	// 定义表单引用，用来执行表单验证
	const formRef = ref()
	const txtPayMoneyRef = ref()
	// 定义表单项的头部宽度
	const formLabelWidth = ref('150px')
	// 定义表单项的内容宽度
	const formContentStyle = ref('width:300px')
	// 定义表单数据
	let formdata = reactive({
		shouldPayMoney: 0,
		oldBalance: 0,
		shouldTakeMoney: 0,
		realPayMoney: 0,
		newBalance: 0,
		description: '',
		payType: '1',
		billIds: [],
		remark: ''
	})
	let paymentId = ref('')
	const treeAreaRef = ref()
	const treeProps = reactive({
		children: 'children',
		label: 'name'
	})
	let showTag = ref(false)

	let selectedArea = ref('')


	// 定义表格数据
	let tableDatas = ref([])
	let areaDatas = ref([])

	let headerCellStype = ref(proxy.$headerCellStype)
	let contentCellStyle = ref(proxy.$contentCellStyle)
	// 定义选中的表格行
	let multipleSelection = reactive([])
	// 定义表格引用，用来执行多选
	const multipleTable = ref()
	// 定义表格隔行变色，暂时没用到
	const tableRowClassName = ({
		row,
		rowIndex
	}) => {
		if (rowIndex % 2 === 0) {
			return 'even-row'
		} else {
			return 'odd-row'
		}
	}
	// 定义表格多选
	const handleSelectionChange = (val) => {
		multipleSelection = val;
	}
	const toggleSelection = (rows) => {
		if (rows) {
			rows.forEach(row => {
				multipleTable.value.toggleRowSelection(row);
			});
		} else {
			multipleTable.value.clearSelection();
		}
	}
	const rowclick = (row, column, event) => {
		toggleSelection([row])
	}

	const resetSelectedArea = () => {
		selectedArea.value = ''
		showTag.value = false
		params.areaId1 = ''
		params.areaId2 = ''
		params.areaId3 = ''
		params.roomId = ''
	}

	const areaSelected = async (area) => {
		//console.log(area)
		let grade = area.grade
		if (grade == "4") {
			let id = area.id
			let selectedName = getSelectedArea(id)
			if (selectedName.length > 0) {
				selectedName = "所选区域：" + selectedName
				showTag.value = true
			} else {
				showTag.value = false
			}
			selectedArea.value = selectedName
			params.areaId1 = ''
			params.areaId2 = ''
			params.areaId3 = ''
			params.roomId = ''
			//console.log(grade, id)
			if (grade == "1") {
				params.areaId1 = id;
			} else if (grade == "2") {
				params.areaId2 = id;
			} else if (grade == "3") {
				params.areaId3 = id;
			} else if (grade == "4") {
				params.roomId = id;
			}
			searchBill();
		} else {
			params.roomId = '';
		}
	}

	const getSelectedArea = (id) => {
		let selectedNames = ''
		for (let i = 0; i < areaDatas.value.length; i++) {
			selectedNames += areaDatas.value[i].name
			if (areaDatas.value[i].id == id) {
				return selectedNames
			}
			for (let j = 0; j < areaDatas.value[i].children.length; j++) {
				selectedNames += " - " + areaDatas.value[i].children[j].name
				if (areaDatas.value[i].children[j].id == id) {
					return selectedNames
				}
				for (let k = 0; k < areaDatas.value[i].children[j].children.length; k++) {
					selectedNames += " - " + areaDatas.value[i].children[j].children[k].name
					if (areaDatas.value[i].children[j].children[k].id == id) {
						return selectedNames
					}
					for (let m = 0; m < areaDatas.value[i].children[j].children[k].children.length; m++) {
						selectedNames += " - " + areaDatas.value[i].children[j].children[k].children[m].name
						if (areaDatas.value[i].children[j].children[k].children[m].id == id) {
							return selectedNames
						}
						selectedNames = areaDatas.value[i].name + " - " + areaDatas.value[i].children[j].name + " - " +
							areaDatas.value[i].children[j].children[k].name
					}
					selectedNames = areaDatas.value[i].name + " - " + areaDatas.value[i].children[j].name
				}
				selectedNames = areaDatas.value[i].name
			}
			selectedNames = ''
		}
		return selectedNames
	}

	const searchRoomBill = async () => {
		let response = await GetServerData('BillCount/QueryAll', params.roomId)
		if (response.status == 1) {
			tableDatas.value = response.data
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}

	const searchBill = async () => {
		if (params.roomId.length > 0) {
			let loading = proxy.$tools.ShowLoading()
			await searchRoomBill()
			loading.close();
		} else {
			proxy.$tools.ShowMessage("请选择具体的房间后再查询账单！", false)
		}
	}

	const calcBalance = () => {
		if (formdata.realPayMoney) {
			formdata.newBalance =formdata.realPayMoney - formdata.shouldPayMoney+ formdata.oldBalance;
			formdata.newBalance = parseFloat(formdata.newBalance.toFixed(2))
			if (formdata.newBalance < 0) {
				proxy.$tools.ShowMessage("金额不足", false)
			}
		}
	}

	const selectAllText = (event) => {
		event.currentTarget.select();
	}

	// 定义表单提交事件，带表单验证
	const submitForm = (form) => {
		//console.log(formdata)
		form.validate(async (valid) => {
			if (valid) {
				let loading = proxy.$tools.ShowLoading();
				let response = await GetServerData('BillCount/CountBill', {
					"roomId": params.roomId,
					"billIds": formdata.billIds,
					"payMoney": formdata.realPayMoney,
					"payType": formdata.payType,
					"remark": formdata.remark
				})
				if (response.status == 1) {
					proxy.$tools.ShowMessageDuration('结算成功', true,proxy.$config.showImportantTipsSecond)
					paymentId.value = response.data
					dialogVisible.value = false
					await searchRoomBill();
				} else {
					proxy.$tools.ShowMessageDuration(response.message, false,proxy.$config.showImportantTipsSecond)
				}
				loading.close()
			} else {
				proxy.$tools.ShowMessage('请您正确填写所有必填信息后，再提交！', false)
				return false;
			}
		})
	}
	// 重置表
	const countSelectedBills = async () => {
		if (params.roomId.length > 0) {
			formdata.remark = '';
			formdata.billIds = [];
			formdata.description = '';
			formdata.shouldPayMoney = 0;
			multipleSelection.forEach((value, key, iterable) => {
				formdata.billIds.push(value.id);
			})
			if (formdata.billIds.length > 0) {
				let flag = false;
				let loading = proxy.$tools.ShowLoading()
				let response = await GetServerData('BillCount/QueryBalance', params.roomId)
				if (response.status == 1) {
					formdata.oldBalance = parseFloat(response.data);
					multipleSelection.forEach((value, key, iterable) => {
						formdata.description += value.description + "\r\n";
						formdata.shouldPayMoney += value.totalMoney;
					})
					formdata.shouldTakeMoney = formdata.shouldPayMoney - formdata.oldBalance;
					if (formdata.shouldTakeMoney <= 0) {
						formdata.realPayMoney = 0;
						formdata.newBalance = Math.abs(formdata.shouldTakeMoney);
						formdata.shouldTakeMoney = 0;
					} else {
						formdata.realPayMoney = formdata.shouldTakeMoney;
						formdata.newBalance = 0;
					}
					formdata.shouldPayMoney = parseFloat(formdata.shouldPayMoney.toFixed(2))
					formdata.shouldTakeMoney = parseFloat(formdata.shouldTakeMoney.toFixed(2))
					formdata.realPayMoney = parseFloat(formdata.realPayMoney.toFixed(2))
					formdata.newBalance = parseFloat(formdata.newBalance.toFixed(2))
					flag = true;
				} else {
					proxy.$tools.ShowMessage(response.message, false)
				}
				loading.close();
				if (flag) {
					dialogVisible.value = true;
					let timer = setTimeout(() => {
						txtPayMoneyRef.value.focus();
						clearTimeout(timer)
					}, 500);
				}
			} else {
				proxy.$tools.ShowMessage("请选择需要结算的账单后再结算账单！", false)
			}
		} else {
			proxy.$tools.ShowMessage("请选择具体的房间后再结算账单！", false)
		}
	}

	const countBill = async (row) => {
		if (params.roomId.length > 0) {
			formdata.remark = '';
			let flag = false;
			let loading = proxy.$tools.ShowLoading()
			let response = await GetServerData('BillCount/QueryBalance', params.roomId)
			if (response.status == 1) {
				formdata.oldBalance = parseFloat(response.data);
				formdata.billIds = [row.id];
				formdata.description = row.description;
				formdata.shouldPayMoney = row.totalMoney;
				formdata.shouldTakeMoney = formdata.shouldPayMoney - formdata.oldBalance;
				if (formdata.shouldTakeMoney <= 0) {
					formdata.realPayMoney = 0;
					formdata.newBalance = Math.abs(formdata.shouldTakeMoney);
					formdata.shouldTakeMoney = 0;
				} else {
					formdata.realPayMoney = formdata.shouldTakeMoney;
					formdata.newBalance = 0;
				}
				formdata.shouldPayMoney = parseFloat(formdata.shouldPayMoney.toFixed(2))
				formdata.shouldTakeMoney = parseFloat(formdata.shouldTakeMoney.toFixed(2))
				formdata.realPayMoney = parseFloat(formdata.realPayMoney.toFixed(2))
				formdata.newBalance = parseFloat(formdata.newBalance.toFixed(2))
				flag = true;
			} else {
				proxy.$tools.ShowMessage(response.message, false)
			}
			loading.close();
			if (flag) {
				dialogVisible.value = true;
				let timer = setTimeout(() => {
					txtPayMoneyRef.value.focus();
					clearTimeout(timer)
				}, 500);
			}
		} else {
			proxy.$tools.ShowMessage("请选择具体的房间后再结算账单！", false)
		}
	}

	const createAmmeterBill = () => {
		createBill("1")
	}
	
	const createWatermeterBill = () => {
		createBill("3")
	}

	// const createAmmeter2Bill = () => {
	// 	createBill("2")
	// }
	// const createWatermeter1Bill = () => {
	// 	createBill("3")
	// }
	// const createWatermeter2Bill = () => {
	// 	createBill("4")
	// }
	const createBill = async (category) => {
		if (params.roomId.length > 0) {
			let loading = proxy.$tools.ShowLoading()
			var data = {
				"roomId": params.roomId,
				"category": category
			}
			//console.log(data)
			let response = await GetServerData('BillCount/CreateBill', data)
			if (response.status == 1) {
				proxy.$tools.ShowMessageDuration('生成账单成功', true,proxy.$config.showImportantTipsSecond)
				await searchRoomBill()
			} else {
				proxy.$tools.ShowMessageDuration(response.message, false,proxy.$config.showImportantTipsSecond)
			}
			loading.close();
		} else {
			proxy.$tools.ShowMessage("请选择具体的房间后再生成账单！", false)
		}
	}

	// 定义获取表格数据函数
	const GetRoomData = async () => {
		let response = await GetServerData('BillCount/QueryAllRooms')
		if (response.status == 1) {
			areaDatas.value = response.data
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}

	// 定义获取所有数据函数
	const GetAllData = async () => {
		await GetRoomData()
	}
	
	const refresh=async ()=>{
		let loading = proxy.$tools.ShowLoading();
		tableDatas.value=[];
		await GetAllData()
		loading.close()
	}

	let areaHeight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight+60))
	let tableheight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight+60))
	// 定义加载事件
	onMounted(async () => {
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth - 425) +
			'px'
		window.onresize = () => {
			tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
			areaHeight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
			document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
				425) + 'px'
		}
		let loading = proxy.$tools.ShowLoading();
		await GetAllData()
		loading.close()
	})
	onActivated(() => {
		tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
		areaHeight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
				425) +
			'px'
	})
</script>

<style scoped lang="less">
</style>
