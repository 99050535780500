import {
	createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'
import 'element-plus/lib/theme-chalk/index.css'
import * as tools from './tools/common.js'
import { htmlPdf } from "./tools/htmlToPDF.js"  
import * as chartTools from './tools/chartTool.js'
import * as echarts from 'echarts'
import Enumerable from 'linq'

//import * as ElIcons from '@element-plus/icons'

const app = createApp(App)
installElementPlus(app)
// 设置网页标题名称
const config=JSON.parse(localStorage.getItem("config"))
document.title=config.appName

app.config.globalProperties.$pdf=htmlPdf
app.config.globalProperties.$enumerable=Enumerable
//linq函数使用驼峰命名：let thisSelected=proxy.$enumerable.from(tableDatas.value).where(x=>x.roomId==roomId).toArray()[0];
//app.config.globalProperties.$echarts=echarts;

// 设置全局配置类
app.config.globalProperties.$config=config

// 设置全局工具类
app.config.globalProperties.$tools = tools
app.config.globalProperties.$chartTools = chartTools


// for(const name in ElIcons){
//   app.component('elIcon' + name, ElIcons[name]);
// }

// 设置内容页中表格高度的减小值
app.config.globalProperties.$reduceHeight=230
// 设置内容页中表格的表头样式
app.config.globalProperties.$headerCellStype={background:'#606266',height:'35px',padding:'0px',margin:'0px',color:'white'}
// 设置内容页中表格的表头样式-双行
app.config.globalProperties.$headerCellStypeDouble={background:'#606266',height:'60px',padding:'0px',margin:'0px',color:'white',textAlign:'center'}
// 设置内容页中表格的默认样式
app.config.globalProperties.$contentCellStyle={
		height:'35px',padding:'0px',margin:'0px','line-height':'30px'
	}
app.config.globalProperties.$showNoPermissionCount=0;
app.use(store).use(router).mount('#app')
