<template>
	<div class="contentheader">
		<el-space wrap>
			<span class="titleClass">基础设置</span>
			<el-divider direction="vertical" />
		</el-space>
	</div>
	<div id="divTableData" class="contentcontent">
		<el-button type="primary" size="small" @click="resetUseAmount">重新计算全部的统计数据（慎用）</el-button>
	</div>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted,
		onActivated,
		getCurrentInstance
	} from 'vue'
	import {
		ElMessageBox
	} from 'element-plus'
	import {
		GetServerData
	} from '@/http/index.js'
	// 获取全局this，proxy相当于vue2中的this，用于调用全局对象使用
	const {
		proxy
	} = getCurrentInstance()
	
	// 定义查询按钮事件
	const resetUseAmount = async () => {
		let loading = proxy.$tools.ShowLoading();
		let response = await GetServerData('SystemConfig/ResetUseAmount')
		if (response.status == 1) {
			proxy.$tools.ShowMessage(response.message, true)
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
		loading.close()
	}

	onMounted(async () => {
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth - 220) +
			'px'
		document.getElementById("divTableData").style.height = (`${document.documentElement.clientHeight}` -
				proxy.$reduceHeight + 60) +
			'px'
		window.onresize = () => {
			document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
					220) +
				'px'
			document.getElementById("divTableData").style.height = (
					`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60) +
				'px'
		}
	})
	onActivated(() => {
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth - 220) +
			'px'
		document.getElementById("divTableData").style.height = (`${document.documentElement.clientHeight}` - proxy
				.$reduceHeight + 60) +
			'px'
	})
</script>

<style scoped lang="less">
</style>
