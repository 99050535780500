import {
	ElMessage,
	ElLoading,
	ElMessageBox
} from 'element-plus'
import FileSaver from 'file-saver'
import XLSX from 'xlsx'
import * as echarts from 'echarts'


export function ExportExcel(selecter, fileName) {
	ElMessageBox.confirm("您确定要将表格导出为EXCEL？, 是否继续?", "提 示", {
			confirmButtonText: "确 定",
			cancelButtonText: "取 消",
			type: "warning",
		})
		.then(() => {
			let xlsxParam = {
				raw: true
			};
			let wb;
			let fix = document.querySelector(".el-table__fixed");
			let fixright=document.querySelector(".el-table__fixed-right");
			if (fix) {
				selecter.removeChild(fix)
			    console.log("have fixleft columns");
			}
			if(fixright){
				selecter.removeChild(fixright)
				console.log("have fixright columns");
			}
			wb = XLSX.utils.table_to_book(selecter,xlsxParam);
			if(fix){
				selecter.appendChild(fix);
			}
			if(fixright){
				selecter.appendChild(fixright);
			}
			
			//let wb = XLSX.utils.table_to_book(selecter, xlsxParam);
			
			let wbout = XLSX.write(wb, {
				bookType: "xlsx",
				bookSST: true,
				type: "array",
			});
			try {
				FileSaver.saveAs(
					new Blob([wbout], {
						type: "application/octet-stream"
					}),
					fileName
					//"赠送记录（"+params.beginTime+"至"+params.endTime+"）.xlsx"
				);
			} catch (e) {
				console.log(e, wbout);
			}
			return wbout;
		})
		.catch((e) => {
			console.log(e);
		});
}

export function ShowMessage(message, type) {
	if (type) {
		ElMessage({
			message: message,
			type: 'success',
			duration: 2000
		})
	} else {
		if(message!='您的登录信息已过期，请重新登录！'){
			ElMessage({
				message: message,
				type: 'error',
				duration: 2000
			});
		}
	}
}

export function ShowMessageDuration(message, type, duration) {
	if (type) {
		ElMessage({
			message: message,
			type: 'success',
			duration: duration * 1000
		})
	} else {
		ElMessage({
			message: message,
			type: 'error',
			duration: duration * 1000
		})
	}
}

export function ShowMessageBoxWithWrap(title, message) {
	ElMessageBox.alert(message, title, {
		confirmButtonText: '确 定',
		dangerouslyUseHTMLString: true
	})
}

export function ShowMessageBox(title, message) {
	ElMessageBox.alert(message, title, {
		confirmButtonText: '确 定'
	})
}

export function ShowLoading() {
	return ElLoading.service({
		lock: true,
		text: '数据正在加载中，请稍后 ... ...',
		background: 'rgba(0, 0, 0, 0.7)',
	})
}
export function DateTimeToString(date) {
	let year = date.getFullYear();
	let month = date.getMonth() + 1;
	let day = date.getDate();
	let hour = date.getHours();
	let minute = date.getMinutes();
	let second = date.getSeconds();
	let datestr = year + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day + ' ' + (hour <
			10 ? '0' : '') + hour + ':' + (minute < 10 ? '0' : '') + minute + ':' + (second < 10 ? '0' : '') +
		second;
	return datestr;
}

export function DateToString(date) {
	let year = date.getFullYear();
	let month = date.getMonth() + 1;
	let day = date.getDate();
	let hour = date.getHours();
	let minute = date.getMinutes();
	let second = date.getSeconds();
	let datestr = year + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
	return datestr;
}
export function ReduceMonth(date, reduce) {
	let year = date.getFullYear();
	let month = date.getMonth();
	let day = date.getDate();
	month = month - reduce;
	if (month < 0) {
		year = year - 1;
		month = month + 12;
	}
	if (month == 1) {
		if ((year % 100 != 0 && year % 4 == 0) || (year % 100 == 0 && year % 400 == 0)) {
			if (day > 29) {
				day = 29;
			}
		} else {
			if (day > 28) {
				day = 28;
			}
		}
	}
	if (month == 3 || month == 5 || month == 8 || month == 10) {
		if (day > 30) {
			day = 30;
		}
	}
	return new Date(year, month, day, 0, 0, 0);
}

export function GetUrlParams(url) {
	let result = new Array();
	let urlSplit = url.split('?');
	if (urlSplit.length == 2) {
		let urlParamSplit = urlSplit[1].split('&');
		for (let i = 0; i < urlParamSplit.length; i++) {
			let urlParam = urlParamSplit[i].split('=');
			if (urlParam.length == 2) {
				result[urlParam[0]] = urlParam[1];
			}
		}
	}
	return result;
}

export function getPropertyCount(o) {
	let n, count = 0;
	for (n in o) {
		if (o.hasOwnProperty(n)) {
			count++;
		}
	}
	return count;
}

export function IsNumber(value){
	return !isNaN(parseFloat(value)) && isFinite(value);
}

export function PadLeftString(str,len,char){
	let i=str.length;
	for(;i<len;i++){
		str=char+str;
	}
	return str;
}

export function DrawChart(div,option){
	let mychart=echarts.init(div);
	mychart.clear();
	mychart.setOption(option);
	return mychart;
}

export function GetCurrentDateTime(){
	var date = new Date();
	var year = date.getFullYear(); //月份从0~11，所以加一
	let month = date.getMonth();
	var dateArr = [
	    date.getMonth() + 1,
	    date.getDate(),
	    date.getHours(),
	    date.getMinutes(),
	    date.getSeconds(),
	];
	//如果格式是MM则需要此步骤，如果是M格式则此循环注释掉
	for (var i = 0; i < dateArr.length; i++) {
	    if (dateArr[i] >= 0 && dateArr[i] <= 9) {
	        dateArr[i] = "0" + dateArr[i];
	    }
	}
	var strDate =
	    year +
	    "-" +
	    dateArr[0] +
	    "-" +
	    dateArr[1] +
	    " " +
	    dateArr[2] +
	    ":" +
	    dateArr[3] +
	    ":" +
	    dateArr[4];
	return strDate;
	
}