<template>

	<div class="contentheader">
		<el-space wrap>
			<span class="titleClass">恶性负载设置</span>
			<el-divider direction="vertical" />
			<el-input v-model="params.keyword" placeholder="请输入关键字" size="small" style="width: 150px;"
				autocomplete="off" />
			<el-button type="primary" size="small" class="buttonforheader" @click="search">查 询</el-button>
			<el-button type="primary" size="small" class="buttonforheader" @click="refresh">刷 新</el-button>
			<el-divider direction="vertical" />
			<el-button type="success" size="small" class="buttonforheader" @click="setLoadIncrementParameter">设负载增量参数</el-button>
			<el-button type="warning" size="small" class="buttonforheader" @click="setLimitPower">设限定功率</el-button>
			<el-button type="danger" size="small" class="buttonforheader" @click="setCutCount">设断电次数</el-button>
			<el-button type="info" size="small" class="buttonforheader" @click="setPowerFactor">设功率因数</el-button>
			<!-- <el-button type="primary" size="small" class="buttonforheader" @click="setStartPower">设启动功率</el-button> -->
			<!-- <el-button type="success" size="small" class="buttonforheader" @click="readAllData" v-if="true">抄参数</el-button> -->
		</el-space>
	</div>
	<div style="display: flex; flex-direction: row; flex-wrap: nowrap; align-items: flex-start;margin: 10px;">
		<div style="width: 200px;">
			<el-scrollbar :height="areaHeight" style="width:200px; border-radius: 6px;background-color: white;">
				<el-tree ref="treeAreaRef" :data="areaDatas" node-key="id" :expand-on-click-node="false"
					highlight-current :props="treeProps" @node-click="areaSelected" style="margin: 10px;">
				</el-tree>
			</el-scrollbar>
		</div>
		<div id="divTableData" style="width: 100%;border-radius: 6px;background-color: white; margin-left: 5px;">
			<el-table :data="tableDatas" fit="false" highlight-current-row="true" ref="multipleTable"
				tooltip-effect="dark" :height="tableheight" @selection-change="handleSelectionChange"
				@row-click="rowclick" row-key="id" default-expand-all :header-cell-style="headerCellStype"
				:cell-style="contentCellStyle" style="width: 100%; cursor: pointer;" :row-class-name="tableRowClassName"
				>
				<el-table-column type="selection" width="55" />
				<el-table-column fixed prop="roomId" label="roomId" width="0" v-if="false" />
				<el-table-column prop="areaName1" label="一级区域" width="100" />
				<el-table-column prop="areaName2" label="二级区域" width="100" />
				<el-table-column prop="areaName3" label="三级区域" width="100" />
				<el-table-column prop="protocolName" label="协议名称" width="100" />
				<el-table-column prop="roomName" label="房间名称" width="100" />
				<el-table-column prop="roomNum" label="房间编号" width="100" />
				<el-table-column prop="familyName" label="户主名称" width="100" />
				<el-table-column prop="familyPhone" label="户主电话" width="120" />
				<el-table-column prop="message" label="信息" width="250" />
				<el-table-column />
			</el-table>
		</div>
	</div>
	<el-dialog width="500px" v-model="dialogVisibleOne" append-to-body=true :title="dialogtitle" center draggable
		:close-on-click-modal="false" :show-close="true">
		<el-form :model="formdataOneData" ref="formRefOne" class="demo-ruleForm formContent">
			<el-form-item  prop="setData" label="设置值" :label-width="formLabelWidth" :rules="[{ required: true, message: '请输入设置值'  ,trigger:'blur'}]" >
				<el-input v-model="formdataOneData.setData" autocomplete="off" :style="formContentStyle" placeholder="请输入设置值" type="number" />
			</el-form-item>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button size="mini" type="primary" @click="submitFormOne(formRefOne)" class="buttonforform"> 设 置 </el-button>
			</span>
		</template>
	</el-dialog>
	
	<el-dialog width="510px" v-model="dialogVisibleMany" append-to-body=true :title="dialogtitle" center draggable
		:close-on-click-modal="false" :show-close="true">
		<el-form :model="formdataManyData" ref="formRefMany" class="demo-ruleForm formContent">
			<el-row :gutter="10">
				<el-col :span="8">
					
				</el-col>
				<el-col :span="8">
					下 限
				</el-col>
				<el-col :span="8">
					上 限
				</el-col>
			</el-row>
			<el-row :gutter="10" align="middle">
				<el-col :span="8">
					允许范围1（W）
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataManyData.rangeLow1" autocomplete="off"  placeholder="下限" type="number" />
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataManyData.rangeHigh1" autocomplete="off"  placeholder="上限" type="number" />
				</el-col>
			</el-row>
			
			<el-row :gutter="10" align="middle">
				<el-col :span="8">
					限定范围1（W）
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataManyData.limitLow1" autocomplete="off"  placeholder="下限" type="number" />
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataManyData.limitHigh1" autocomplete="off"  placeholder="上限" type="number" />
				</el-col>
			</el-row>
			
			<el-row :gutter="10" align="middle">
				<el-col :span="8">
					限定参数1
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataManyData.limitParamLow1" autocomplete="off"  placeholder="下限" type="number" />
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataManyData.limitParamHigh1" autocomplete="off"  placeholder="上限" type="number" />
				</el-col>
			</el-row>
			<el-row :gutter="10" align="middle">
				<el-col :span="8">
					允许范围2（W）
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataManyData.rangeLow2" autocomplete="off"  placeholder="下限" type="number" />
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataManyData.rangeHigh2" autocomplete="off"  placeholder="上限" type="number" />
				</el-col>
			</el-row>
			
			<el-row :gutter="10" align="middle">
				<el-col :span="8">
					限定范围2（W）
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataManyData.limitLow2" autocomplete="off"  placeholder="下限" type="number" />
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataManyData.limitHigh2" autocomplete="off"  placeholder="上限" type="number" />
				</el-col>
			</el-row>
			
			<el-row :gutter="10" align="middle">
				<el-col :span="8">
					允限定参数2
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataManyData.limitParamLow2" autocomplete="off"  placeholder="下限" type="number" />
				</el-col>
				<el-col :span="8">
					<el-input v-model="formdataManyData.limitParamHigh2" autocomplete="off"  placeholder="上限" type="number" />
				</el-col>
			</el-row>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button size="mini" type="primary" @click="submitFormMany(formRefMany)" class="buttonforform"> 设 置 </el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted,
		onActivated,
		getCurrentInstance
	} from 'vue'
	import {
		ElMessageBox
	} from 'element-plus'
	import {
		GetServerData
	} from '@/http/index.js'
	// 获取全局this，proxy相当于vue2中的this，用于调用全局对象使用
	const {
		proxy
	} = getCurrentInstance()
	const treeAreaRef = ref()
	const treeProps = reactive({
		children: 'children',
		label: 'name'
	})
	// 定义表单项的头部宽度
	const formLabelWidth = ref('150px')
	// 定义表单项的内容宽度
	const formContentStyle = ref('width:300px')
	// 定义表单项的内容宽度
	const formContentStyle2 = ref('width:100%')
	// 定义表单显示与隐藏
	let dialogVisibleOne = ref(false)
	// 定义表单显示与隐藏
	let dialogVisibleMany = ref(false)
	// 定义查询参数
	let params = reactive({
		keyword: ''
	})
	
	// 定义表单标题
	let dialogtitle = ref('')
	// 定义表单引用，用来执行表单验证
	const formRefOne = ref()
	const formRefMany = ref()
	
	// 定义表格数据
	let tableDatas = ref([])
	let roomDatas = ref([])
	let areaDatas = ref([])

	let headerCellStype = ref(proxy.$headerCellStype)
	let contentCellStyle = ref(proxy.$contentCellStyle)
	// 定义选中的表格行
	let multipleSelection = reactive([])
	// 定义表格引用，用来执行多选
	const multipleTable = ref()

	// 定义表格隔行变色，暂时没用到
	const tableRowClassName = ({
		row,
		rowIndex
	}) => {
		if (rowIndex % 2 === 0) {
			return 'even-row'
		} else {
			return 'odd-row'
		}
	}
	// 定义表格多选
	const handleSelectionChange = (val) => {
		multipleSelection = val;
	}
	const toggleSelection = (rows) => {
		if (rows) {
			rows.forEach(row => {
				multipleTable.value.toggleRowSelection(row);
			});
		} else {
			multipleTable.value.clearSelection();
		}
	}
	const rowclick = (row, column, event) => {
		toggleSelection([row])
	}

	const areaSelected = async (area) => {
		console.log(area)
		tableDatas.value = []
		let grade = area.grade
		let id = area.id
		if (grade == "1") {
			tableDatas.value = await roomDatas.value.filter(room => room.areaId1 == id)
		} else if (grade == "2") {
			tableDatas.value = await roomDatas.value.filter(room => room.areaId2 == id)
		} else if (grade == "3") {
			tableDatas.value = await roomDatas.value.filter(room => room.areaId3 == id)
		}
	}

	const search = async () => {
		if (params.keyword.length > 0) {
			tableDatas.value = await roomDatas.value.filter(room => room.areaName1.indexOf(params.keyword) > -1 ||
				room
				.areaName2.indexOf(params.keyword) > -1 || room.areaName3.indexOf(params.keyword) > -1 || room
				.roomName.indexOf(params.keyword) > -1 || room.roomNum.indexOf(params.keyword) > -1 || room
				.familyName.indexOf(params.keyword) > -1 || room.familyPhone.indexOf(params.keyword) > -1)
		} else {
			proxy.$tools.ShowMessage('请输入关键字', false)
		}
	}
	
	let formdataOneData= reactive({
		category:'',
		setData: ''
	})
	
	let formdataManyData= reactive({
		rangeHigh1:'150',
		rangeLow1:'100',
		limitHigh1:'300',
		limitLow1:'200',
		limitParamHigh1:'0',
		limitParamLow1:'0',
		rangeHigh2:'150',
		rangeLow2:'100',
		limitHigh2:'300',
		limitLow2:'200',
		limitParamHigh2:'0',
		limitParamLow2:'0'
	})
	
	const setStartPower=()=>{
		dialogVisibleOne.value=true;
		dialogtitle.value='设启动功率';
		formdataOneData.category='1';
		formdataOneData.setData='';
	}
	
	const setLimitPower=()=>{
		dialogVisibleOne.value=true;
		dialogtitle.value='设限定功率';
		formdataOneData.category='2';
		formdataOneData.setData='';
	}
	
	const setPowerFactor=()=>{
		dialogVisibleOne.value=true;
		dialogtitle.value='设功率因数';
		formdataOneData.category='3';
		formdataOneData.setData='';
	}
	
	const setCutCount=()=>{
		dialogVisibleOne.value=true;
		dialogtitle.value='设断电次数';
		formdataOneData.category='4';
		formdataOneData.setData='';
	}
	
	const setLoadIncrementParameter=()=>{
		dialogVisibleMany.value=true;
		dialogtitle.value='设负载增量参数';
	}

	const submitFormOne =async (form) => {
		form.validate(async (valid) => {
			if (valid) {
				let flag=false;
				if(formdataOneData.category=='3'){
					let temp=0;
					try{
						temp=parseFloat(formdataOneData.setData);
					}catch(e){
						temp=-1;
					}
					if(temp>0&&temp<1){
						flag=true;
					}else{
						proxy.$tools.ShowMessage('功率因数的值必须大于0且小于1', false)
					}
				}else if(formdataOneData.category=='4'){
					let temp=0;
					try{
						temp=parseInt(formdataOneData.setData);
					}catch(e){
						temp=-1;
					}
					if(temp>=0&&temp<10){
						flag=true;
					}else{
						proxy.$tools.ShowMessage('断电次数的值必须小于等于9', false)
					}
				}
				else{
					flag=true;
				}
				if(flag){
					if (multipleSelection.length > 0) {
						let loading = proxy.$tools.ShowLoading()
						dialogVisibleOne.value=false;
						for (let i = 0; i < multipleSelection.length; i++) {
							let roomId = multipleSelection[i].roomId
							let response = await GetServerData('MalignantLoadSet/SetOne', {
								roomId:roomId,
								category:formdataOneData.category,
								setData:formdataOneData.setData
							})
							let message =  response.message
							for (let j = 0; j < tableDatas.value.length; j++) {
								if (tableDatas.value[j].roomId == roomId) {
									tableDatas.value[j].message = message
									break;
								}
							}
						}
						loading.close()
					}else {
						proxy.$tools.ShowMessage('请选择需要操作的房间', false)
					}
				}
			} else {
				proxy.$tools.ShowMessage('请您正确填写所有必填信息后，再提交！', false)
				return false;
			}
		})
	}

	const submitFormMany =async (form) => {
		form.validate(async (valid) => {
			if (valid) {
				if (multipleSelection.length > 0) {
					let loading = proxy.$tools.ShowLoading()
					dialogVisibleMany.value=false;
					for (let i = 0; i < multipleSelection.length; i++) {
						let roomId = multipleSelection[i].roomId
						let response = await GetServerData('MalignantLoadSet/SetMany', {
							roomId:roomId,
							rangeHigh1:formdataManyData.rangeHigh1,
							rangeLow1:formdataManyData.rangeLow1,
							limitHigh1:formdataManyData.limitHigh1,
							limitLow1:formdataManyData.limitLow1,
							limitParamHigh1:formdataManyData.limitParamHigh1,
							limitParamLow1:formdataManyData.limitParamLow1,
							rangeHigh2:formdataManyData.rangeHigh2,
							rangeLow2:formdataManyData.rangeLow2,
							limitHigh2:formdataManyData.limitHigh2,
							limitLow2:formdataManyData.limitLow2,
							limitParamHigh2:formdataManyData.limitParamHigh2,
							limitParamLow2:formdataManyData.limitParamLow2
						})
						let message =  response.message
						for (let j = 0; j < tableDatas.value.length; j++) {
							if (tableDatas.value[j].roomId == roomId) {
								tableDatas.value[j].message = message
								break;
							}
						}
					}
					loading.close();
				}else {
					proxy.$tools.ShowMessage('请选择需要操作的房间', false)
				}
			} else {
				proxy.$tools.ShowMessage('请您正确填写所有必填信息后，再提交！', false)
				return false;
			}
		})
	}
	
	const readAllData=async ()=>{
		if (multipleSelection.length > 0) {
			let loading = proxy.$tools.ShowLoading()
			for (let i = 0; i < multipleSelection.length; i++) {
				let roomId = multipleSelection[i].roomId
				let response = await GetServerData('MalignantLoadSet/Read', '"' + roomId + '"')
				let message =  response.message
				for (let j = 0; j < tableDatas.value.length; j++) {
					if (tableDatas.value[j].roomId == roomId) {
						tableDatas.value[j].message = message
						break;
					}
				}
			}
			loading.close()
		}else {
			proxy.$tools.ShowMessage('请选择需要操作的房间', false)
		}
	}

	// 定义获取表格数据函数
	const GetRoomData = async () => {
		let response = await GetServerData('SetAmmeter/QueryAllRooms')
		if (response.status == 1) {
			roomDatas.value = response.data
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}

	// 定义获取表格数据函数
	const GetAreaData = async () => {
		let response = await GetServerData('SetAmmeter/QueryAllAreas')
		if (response.status == 1) {
			areaDatas.value = response.data
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}

	// 定义获取所有数据函数
	const GetAllData = async () => {
		await GetRoomData()
		await GetAreaData()
	}
	
	const refresh=async ()=>{
		let loading = proxy.$tools.ShowLoading();
		tableDatas.value=[];
		await GetAllData()
		loading.close()
	}

	let areaHeight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60))
	let tableheight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60))
	// 定义加载事件
	onMounted(async () => {
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth - 425) +
			'px'
		window.onresize = () => {
			tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
			areaHeight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
			document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
					425) +
				'px'
		}
		let loading = proxy.$tools.ShowLoading();
		await GetAllData()
		loading.close()
	})
	onActivated(() => {
		tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
		areaHeight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight + 60)
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
				425) +
			'px'
	})
</script>

<style scoped lang="less">
	.el-row {
	  margin-bottom: 10px;
	}
	.el-col{
		text-align: center;
	}
</style>
