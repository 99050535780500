<template>

	<div class="contentheader">
		<el-space wrap>
			<span class="titleClass">欠费房间查询</span>
			<el-divider direction="vertical" />
			<el-input v-model="params.keyword" placeholder="输入关键字" size="small" style="width: 140px;" clearable
				autocomplete="off" />
			<el-button type="primary" size="small" class="buttonforheader" @click="search">查 询</el-button>
			<el-button type="primary" size="small" class="buttonforheader" @click="refresh">刷 新</el-button>
			<el-divider direction="vertical" />
			<el-button type="info" size="small" class="buttonforheader" @click="ExportExcel">导 出</el-button>
			<el-divider direction="vertical" />
			<!-- <el-tag class="ml-2" type="success" closable v-if="showTag" @close="resetSelectedArea">{{selectedArea}}
			</el-tag> -->
		</el-space>
	</div>
	<div style="display: flex; flex-direction: row; flex-wrap: nowrap; align-items: flex-start;margin: 10px;">
		<div style="width: 200px;">
			<el-scrollbar :height="areaHeight" style="width:200px; border-radius: 6px;background-color: white;">
				<el-tree ref="treeAreaRef" :data="areaDatas" node-key="id" :expand-on-click-node="false" highlight-current
					:props="treeProps" @node-click="areaSelected" style="margin: 10px;">
				</el-tree>
			</el-scrollbar>
		</div>
		<div id="divTableData" style="width: 100%;border-radius: 6px;background-color: white; margin-left: 5px;">
			<el-table :data="tableDatas" fit="false" stripe highlight-current-row="true" ref="multipleTable"
				tooltip-effect="dark" :height="tableheight" @selection-change="handleSelectionChange" @row-click="rowclick"
				row-key="id" :header-cell-style="headerCellStype"
				:cell-style="contentCellStyle" style="width: 100%; cursor: pointer;" id="tableData"
				:row-class-name="tableRowClassName">
				<el-table-column type="selection" width="55" />
				<el-table-column fixed prop="id" label="id" width="0" v-if="false" />
				<el-table-column prop="areaName1" label="一级区域" width="100" />
				<el-table-column prop="areaName2" label="二级区域" width="100" />
				<el-table-column prop="areaName3" label="三级区域" width="100" />
				<el-table-column prop="roomName" label="房间名称" width="100" />
				<el-table-column prop="roomNum" label="房间编号" width="100" />
				<el-table-column prop="familyName" label="户主名称" width="100" />
				<el-table-column prop="familyPhone" label="户主电话" width="100" />
				<el-table-column prop="categoryName" label="抄表类型" width="100" />
				<el-table-column prop="recordValue" label="抄表值" width="100" />
				<el-table-column prop="recordTime" label="抄表时间" width="150" />
				<el-table-column />
			</el-table>
		</div>
	</div>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted,
		onActivated,
		getCurrentInstance
	} from 'vue'
	import {
		ElMessageBox
	} from 'element-plus'
	import {
		GetServerData
	} from '@/http/index.js'
	// 获取全局this，proxy相当于vue2中的this，用于调用全局对象使用
	const {
		proxy
	} = getCurrentInstance()
	const treeAreaRef = ref()
	const treeProps = reactive({
		children: 'children',
		label: 'name'
	})
	let showTag = ref(false)
	// 定义查询参数
	let params = reactive({
		keyword: '',
		areaId1: '',
		areaId2: '',
		areaId3: '',
		roomId: ''
	})

	let selectedArea = ref('')


	let headerCellStype = ref(proxy.$headerCellStype)
	let contentCellStyle = ref(proxy.$contentCellStyle)
	// 定义表格数据
	let tableDatas = ref([])
	let areaDatas = ref([])

	// 定义选中的表格行
	let multipleSelection = reactive([])
	// 定义表格引用，用来执行多选
	const multipleTable = ref()

	// 定义表格隔行变色，暂时没用到
	const tableRowClassName = ({
		row,
		rowIndex
	}) => {
		if (rowIndex % 2 === 0) {
			return 'even-row'
		} else {
			return 'odd-row'
		}
	}
	// 定义表格多选
	const handleSelectionChange = (val) => {
		multipleSelection = val;
	}
	const toggleSelection = (rows) => {
		if (rows) {
			rows.forEach(row => {
				multipleTable.value.toggleRowSelection(row);
			});
		} else {
			multipleTable.value.clearSelection();
		}
	}
	const rowclick = (row, column, event) => {
		toggleSelection([row])
	}
	
	
	const ExportExcel=()=>{
		proxy.$tools.ExportExcel(document.querySelector("#tableData"),"欠费房间查询.xlsx")
	}

	const sizechange = async () => {
		params.pageNum = 1
		await search()
		multipleTable.value.$refs.bodyWrapper.scrollTo(0, 0);
	}
	const currentchange = async () => {
		await search()
		multipleTable.value.$refs.bodyWrapper.scrollTo(0, 0);
	}

	const resetSelectedArea = () => {
		selectedArea.value = ''
		showTag.value = false
		params.areaId1 = ''
		params.areaId2 = ''
		params.areaId3 = ''
		params.roomId = ''
	}

	const areaSelected = async (area) => {
		console.log(area)
		let grade = area.grade
		let id = area.id
		let selectedName = getSelectedArea(id)
		if (selectedName.length > 0) {
			selectedName = "所选区域：" + selectedName
			showTag.value = true
		} else {
			showTag.value = false
		}
		selectedArea.value = selectedName
		params.areaId1 = ''
		params.areaId2 = ''
		params.areaId3 = ''
		params.roomId = ''
		console.log(grade, id)
		if (grade == "1") {
			params.areaId1 = id;
		} else if (grade == "2") {
			params.areaId2 = id;
		} else if (grade == "3") {
			params.areaId3 = id;
		} else if (grade == "4") {
			params.roomId = id;
		}
	}

	const getSelectedArea = (id) => {
		let selectedNames = ''
		for (let i = 0; i < areaDatas.value.length; i++) {
			selectedNames += areaDatas.value[i].name
			if (areaDatas.value[i].id == id) {
				return selectedNames
			}
			for (let j = 0; j < areaDatas.value[i].children.length; j++) {
				selectedNames += " - " + areaDatas.value[i].children[j].name
				if (areaDatas.value[i].children[j].id == id) {
					return selectedNames
				}
				for (let k = 0; k < areaDatas.value[i].children[j].children.length; k++) {
					selectedNames += " - " + areaDatas.value[i].children[j].children[k].name
					if (areaDatas.value[i].children[j].children[k].id == id) {
						return selectedNames
					}
					for (let m = 0; m < areaDatas.value[i].children[j].children[k].children.length; m++) {
						selectedNames += " - " + areaDatas.value[i].children[j].children[k].children[m].name
						if (areaDatas.value[i].children[j].children[k].children[m].id == id) {
							return selectedNames
						}
						selectedNames = areaDatas.value[i].name + " - " + areaDatas.value[i].children[j].name + " - " +
							areaDatas.value[i].children[j].children[k].name
					}
					selectedNames = areaDatas.value[i].name + " - " + areaDatas.value[i].children[j].name
				}
				selectedNames = areaDatas.value[i].name
			}
			selectedNames = ''
		}
		return selectedNames
	}

	const search = async () => {
			let loading = proxy.$tools.ShowLoading()
			let response = await GetServerData('OweRoom/QueryAll', params)
			if (response.status == 1) {
				tableDatas.value = response.data
			} else {
				proxy.$tools.ShowMessage(response.message, false)
			}
			loading.close();
	}

	// 定义获取表格数据函数
	const GetRoomData = async () => {
		let response = await GetServerData('OweRoom/QueryAllRooms')
		if (response.status == 1) {
			areaDatas.value = response.data
		} else {
			proxy.$tools.ShowMessage(response.message, false)
		}
	}
	
	const refresh=async ()=>{
		let loading = proxy.$tools.ShowLoading();
		tableDatas.value=[];
		await GetAllData()
		loading.close()
	}

	// 定义获取所有数据函数
	const GetAllData = async () => {
		await GetRoomData()
	}
	
	let areaHeight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight+60))
	let tableheight = ref((`${document.documentElement.clientHeight}` - proxy.$reduceHeight+60))

	// 定义加载事件
	onMounted(async () => {
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth - 425) +
			'px'
		window.onresize = () => {
			document.getElementById("divTableData").style.width = (document.documentElement.clientWidth - 425) +
				'px'
			tableheight.value = (`${document.documentElement.clientHeight}`- proxy.$reduceHeight+60)
			areaHeight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight+60)
		}
		let loading = proxy.$tools.ShowLoading();
		await GetAllData()
		loading.close()
	})
	onActivated(()=>{
		tableheight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight+60)
		areaHeight.value = (`${document.documentElement.clientHeight}` - proxy.$reduceHeight+60)
		document.getElementById("divTableData").style.width = (document.documentElement.clientWidth -
			425) + 'px'
	})
</script>

<style scoped lang="less">
</style>
